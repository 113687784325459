import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { solverStep1Dto } from '../../Model/solver-step1.dto';
import { solverStep5Dto } from '../../Model/solver-step5.dto';
import { UpdateImpactInitiativesTasksRequest } from '../../../solver-forms-module/types/solution-form-6-type';
import { SolutionForm9UpdateRequest } from '../../../solver-forms-module/types/solution-form-9-types';
import { SolutionReferences } from '../../../shared/input-solution-references/types/input-solution-references-types';
@Injectable({
    providedIn: 'root',
})
export class SolverFlowService {
    constructor(
        private http: HttpClient,
        private authService: AuthService // Inject AuthService
    ) {}

    // Step 1
    solutionExplorer(
        data: solverStep1Dto,
        challengeId: number,
        solutionId?: number
    ): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );

                // Build the API URL based on the presence of solutionId
                let url = `${environment.apiUrl}/solution/explorer-checklist/save-next/${challengeId}`;
                // Add solutionId as a query parameter if it exists
                const params = new HttpParams().set(
                    'solutionId',
                    solutionId ? solutionId.toString() : ''
                );

                return this.http.post<any>(url, data, { headers, params });
            })
        );
    }

    getProblemExplorer(solutionId: any): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<any>(
                    `${environment.apiUrl}/solution/explorer-checklist/${solutionId}`, // Path need to be changed
                    { headers }
                );
            })
        );
    }

    // Step 5
    updateproblemExplorerStep5(
        data: solverStep5Dto,
        challengeId: any,
        solutionId: any,
        isDraft?: boolean
    ): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const type = isDraft ? 'draft' : 'save-next';
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                //
                return this.http.patch<any>(
                    `${environment.apiUrl}/solution/research-synthesis/${type}/${challengeId}/${solutionId}`, // update the path when api is ready
                    data,
                    { headers }
                );
            })
        );
    }

    fetchBestPractices(solutionId: any): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<any>(
                    `${environment.apiUrl}/solution/best-practices-list/${solutionId}`, // update the path when api is ready

                    { headers }
                );
            })
        );
    }

    createbestPractice(
        solutionId: any,
        data: any,
        bestPracticeId?: any
    ): Observable<any> {
        let queryParams = new HttpParams().set(
            'bestPracticeId',
            bestPracticeId
        );
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<any>(
                    `${environment.apiUrl}/solution/best-practices/${solutionId}`, // update the path when api is ready
                    data,
                    { headers, params: bestPracticeId && queryParams }
                );
            })
        );
    }

    deleteBestPractice(
        solutionId: number,
        bestPracticeId: number
    ): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.delete<any>(
                    `${environment.apiUrl}/solution/best-practices/${solutionId}/${bestPracticeId}`, // update the path when api is ready
                    { headers }
                );
            })
        );
    }

    saveBestPracticeForm2(
        solutionId: number,
        data: SolutionReferences,
        isDraft?: boolean
    ): Observable<any> {
        let type = isDraft ? 'draft' : 'save-next';
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );

                return this.http.patch<any>(
                    `${environment.apiUrl}/solution/best-practices/${type}/${solutionId}`,
                    data,
                    { headers }
                );
            })
        );
    }

    // step 6
    createActionableItems(
        id: number, //solution id
        data: any, // actionable items
        isDraft?: boolean // save as draft or save and next
    ): Observable<any> {
        let type = isDraft ? 'draft' : 'save-next';
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<any>(
                    `${environment.apiUrl}/solution/solution-opportunity/${type}/${id}`, // update the path when api is ready
                    data,
                    { headers }
                );
            })
        );
    }

    // step 6
    fetchTermActionables(id: number): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<any>(
                    `${environment.apiUrl}/solution/solution-opportunity/${id}`, // Path need to be changed
                    { headers }
                );
            })
        );
    }

    getProblemExplorerStep5(solutionId: any): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                // /api/solution/research-synthesis/{solutionId}
                return this.http.get<any>(
                    `${environment.apiUrl}/solution/research-synthesis/${solutionId}`,
                    { headers }
                );
            })
        );
    }

    // Step 9
    updateproblemExplorerStep9(
        id: number,
        data: SolutionForm9UpdateRequest,
        isDraft?: boolean
    ): Observable<any> {
        let type = isDraft ? 'draft' : 'save-next';
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<any>(
                    `${environment.apiUrl}/solution/executive-summary/${type}/${id}`, // update the path when api is ready
                    data,
                    { headers }
                );
            })
        );
    }

    getProblemExplorerStep9(id: string): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<any>(
                    `${environment.apiUrl}/challenge/get-basic-information/${id}`, // Path need to be changed
                    { headers }
                );
            })
        );
    }

    updateSolutionForm7(
        solutionId: number,
        request: SolutionReferences,
        isDraft?: boolean
    ): Observable<any> {
        let type = isDraft ? 'draft' : 'save-next';
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<any>(
                    `${environment.apiUrl}/solution/high-impact-initiatives/${type}/${solutionId}`,
                    request,
                    { headers }
                );
            })
        );
    }

    UpdateImpactInitiativesTasksRequestStep7(
        solutionId: number,
        request: UpdateImpactInitiativesTasksRequest
    ): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<any>(
                    `${environment.apiUrl}/solution/high-impact-initiatives/${solutionId}`,
                    request,
                    { headers }
                );
            })
        );
    }

    submitForm8(
        solutionId: number,
        request: any,
        isDraft?: boolean
    ): Observable<any> {
        let type = isDraft ? 'draft' : 'save-next';
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<any>(
                    `${environment.apiUrl}/solution/prioritize-immediate-action/${solutionId}`,
                    request,
                    { headers }
                );
            })
        );
    }

    submitForm3(
        solutionId: number,
        request: any,
        isDraft?: boolean
    ): Observable<any> {
        let type = isDraft ? 'draft' : 'save-next';
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<any>(
                    `${environment.apiUrl}/solution/weak-signal/${type}/${solutionId}`,
                    request,
                    { headers }
                );
            })
        );
    }

    submitForm4(solutionId: number): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<any>(
                    `${environment.apiUrl}/solution/solution-framework/save-next/${solutionId}`,
                    {},
                    { headers }
                );
            })
        );
    }

    submitStep4SolutionFrameworkDetails(
        solutionId: number,
        request: any,
        frameworkId?: number
    ): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                let queryParams = new HttpParams();
                if (frameworkId) {
                    queryParams = queryParams.set(
                        'frameworkId',
                        frameworkId.toString()
                    );
                }
                return this.http.patch<any>(
                    `${environment.apiUrl}/solution/solution-framework/${solutionId}`,
                    request,
                    { headers, params: queryParams }
                );
            })
        );
    }

    getSortedWeakSignals(solutionId: number): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<any>(
                    `${environment.apiUrl}/solution/weak-signal/sorted/${solutionId}`,
                    { headers }
                );
            })
        );
    }

    // step 10
    uploadFinalReport(
        solutionId: number,
        data: any,
        isDraft?: boolean
    ): Observable<any> {
        let type = isDraft ? 'draft' : 'submit';
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<any>(
                    `${environment.apiUrl}/solution/final-report/${type}/${solutionId}`,
                    data,
                    { headers }
                );
            })
        );
    }

    fetchFinalReport(solutionId: number): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<any>(
                    `${environment.apiUrl}/solution/final-report/${solutionId}`,
                    { headers }
                );
            })
        );
    }

    addWeakSignal(
        solutionId: number,
        data: any,
        weakSignalId?: number
    ): Observable<any> {
        let queryParams = new HttpParams();
        if (weakSignalId) {
            queryParams = queryParams.set(
                'weakSignalId',
                weakSignalId.toString()
            );
        }
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<any>(
                    `${environment.apiUrl}/solution/weak-signal/${solutionId}`,
                    data,
                    { headers, params: queryParams }
                );
            })
        );
    }

    deleteWeakSignal(
        solutionId: number,
        weakSignalId: number
    ): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.delete<any>(
                    `${environment.apiUrl}/solution/weak-signal/${solutionId}/${weakSignalId}`, // update the path when api is ready
                    { headers }
                );
            })
        );
    }

    submitWeakSignal(
        solutionId: number,
        data: any,
        isDraft?: boolean
    ): Observable<any> {
        let type = isDraft ? 'draft' : 'save-next';
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken();
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<any>(
                    `${environment.apiUrl}/solution/weak-signal/${type}/${solutionId}`,
                    data,
                    { headers }
                );
            })
        );
    }
}
