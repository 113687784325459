import {
    AbstractControl,
    FormArray,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

export class CommonValidators {
    public static minArrayItemValidator(min: number) {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const items = control as FormArray;

            if (items.length < min) {
                return { minArrayItems: { requiredLength: min } };
            }
            return null;
        };
    }

    public static maxArrayItemValidator(max: number) {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const items = control as FormArray;

            if (items.length > max) {
                return { maxArrayItems: { requiredLength: max } };
            }
            return null;
        };
    }
    public static duplicateArrayItemValidator(keyToCheck?: string) {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const items = control as FormArray;

            // Check for uniqueness of items
            const itemValues = items.controls.map((control) => control.value);
            const hasDuplicates = itemValues.some((value, index) => {
                if (typeof value === 'object') {
                    if (keyToCheck) {
                        return itemValues.some(
                            (val, i) =>
                                i !== index &&
                                val[keyToCheck] === value[keyToCheck]
                        );
                    } else {
                        return itemValues.some(
                            (val, i) =>
                                i !== index &&
                                JSON.stringify(val) === JSON.stringify(value)
                        );
                    }
                } else {
                    // For string or number, directly compare values
                    return itemValues.indexOf(value) !== index;
                }
            });

            if (hasDuplicates) {
                return { duplicateArrayItems: true };
            }
            return null;
        };
    }

    public static noWhitespaceValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value || '';

            if (value !== value.trim()) {
                return { noWhitespace: true };
            }

            return null; // Return null if validation passes
        };
    }

    public static atLeastOneRequired(
        controlNameA: string,
        controlNameB: string
    ): ValidatorFn {
        return (formGroup: AbstractControl) => {
            const fieldA = formGroup.get(controlNameA)?.value;
            const fieldB = formGroup.get(controlNameB)?.value;

            if (!fieldA && !fieldB) {
                return { atLeastOneRequired: true };
            }
            return null;
        };
    }
}
