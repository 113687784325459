<div class="how-it-works container text-center">
    <div class="header">
        <h2 class="title">{{ title2 }}</h2>
        <div class="row justify-content-center">
            <p class="description-font text-center col-md-10 offset-md-1 mx-auto mb-0">{{ description2 }}</p>
        </div>
    </div>
    <div class="text-center" style=" margin-bottom: 20px;">
        <h2>{{ title }}</h2>
    </div>

    <div class="row justify-content-center mx-auto col-md-12">
        <div class="col-md-4 mb-4 px-2.5" *ngFor="let step of steps; let i = index">
            <div class="step-container mx-auto h-100">
                <div class="d-flex justify-content-between align-items-start">
                    <div class=" step-number d-flex align-items-center justify-content-center rounded-end pe-1 ps-1"
                        [ngStyle]="{
            'background-color': stepNumberBgColor,
            'color': stepNumberColor
          }">
                        <h6 class="mb-0">STEP {{ i + 1 }}</h6>
                    </div>

                    <div class="step-icon">
                        <img [src]="step.icon" alt="{{ step.title }} Icon" class="img-fluid">
                    </div>
                </div>
                <div class="step-content text-start mt-5">
                    <h5 class="fs-15px fw-semibold">{{ step.title }}</h5>
                    <hr class="orange-line" [ngStyle]="{'border-color': stepLineColor}">
                    <p class="description-font1">{{ step.description }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
