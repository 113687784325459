import { Component, EventEmitter, Output, inject } from '@angular/core';
import { QuillEditorComponent } from '../quill-editor/quill-editor.component';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { LaunchChallengeState } from '../../core/store/launchChallengeForm/launchChallenge.state';
import { Store } from '@ngrx/store';
import { Guidelines } from '../../core/Model/guidelines-form.dto';
import { InputErrorMessageComponent } from '../input-error-message/input-error-message.component';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { selectChallengeId, selectGuidelinesError } from '../../core/store/launchChallengeForm/launchChallenge.selectors';
import { catchError, of, Subject, switchMap, take, tap, throwError, withLatestFrom } from 'rxjs';
import { updateGuidelines } from '../../core/store/launchChallengeForm/launchChallenge.actions';
import {ParticipantEligibility} from '../../core/enum/participantEligibility.enum';
import { TeamRequirements } from '../../core/enum/teamRequirements.enum';
import { ChallengeService } from '../../core/services/apis/challenge.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-guidelines-information-form',
    standalone: true,
    imports: [
        QuillEditorComponent,
        ReactiveFormsModule,
        InputErrorMessageComponent,
        CommonModule
    ],
    templateUrl: './guidelines-information-form.component.html',
    styleUrl: './guidelines-information-form.component.scss',
})
export class GuidelinesInformationFormComponent {
    guidelinesForm!: FormGroup;
    route:ActivatedRoute=inject(ActivatedRoute);
    router:Router=inject(Router);
    challengeId:string='';    @Output() successEvent = new EventEmitter<void>();

    constructor(
        private fb: FormBuilder,
        private store: Store<LaunchChallengeState>,
        protected validationMessageService: ValidationMessageService,
        private challengeService: ChallengeService,
        private alertService: SweetAlertService,
    ) {
        // Form initialization
       this.guideLinesForm();
    }

    guideLinesForm(){
        this.guidelinesForm = this.fb.group({
            openToAll: ['openToAll', Validators.required],
            ageChecked: [false],
            geographicChecked: [false],
            otherChecked: [false],
            ageRestrictions: [{ value: '', disabled: true }, Validators.required],
            geographicRestrictions: [{ value: '', disabled: true }, Validators.required],
            otherRestrictions: [{ value: '', disabled: true },Validators.required],
            teamRequirements: ['INDIVIDUAL', Validators.required],
            teamSizeLimitDetails: [{ value: '', disabled: true }, Validators.required],
            skillsOrExpertiseNeeded: ['', Validators.maxLength(5000)],
            intellectualPropertyTerms: ['', [Validators.required,Validators.maxLength(5000)]],
            partnershipAndCollaborations: ['', Validators.maxLength(5000)],
            dataSharingAndPrivacyPolicies: ['', Validators.maxLength(5000)],
            postChallengeSupport: ['', Validators.maxLength(5000)],
            marketingAndPromotionPlans: ['', Validators.maxLength(5000)],
            additionalCommentsOrInformation: ['', Validators.maxLength(5000)],
        });
    }

    ngOnInit(): void {
        this.guidelinesForm
            .get('teamRequirements')
            ?.valueChanges.subscribe((value) => {
                this.toggleTeamSizeLimitDetails(value);
            });

        this.guidelinesForm
            .get('openToAll')
            ?.valueChanges.subscribe((value) => {
                this.toggleRestrictionFields(value);
            });

        this.guidelinesForm
            .get('ageChecked')
            ?.valueChanges.subscribe((isChecked) => {
                this.toggleField('ageRestrictions', isChecked);
            });

        this.guidelinesForm
            .get('geographicChecked')
            ?.valueChanges.subscribe((isChecked) => {
                this.toggleField('geographicRestrictions', isChecked);
            });

        this.guidelinesForm
            .get('otherChecked')
            ?.valueChanges.subscribe((isChecked) => {
                this.toggleField('otherRestrictions', isChecked);
            });

        // Initialize form state based on current values
        this.toggleRestrictionFields(
            this.guidelinesForm.get('openToAll')?.value
        );
        this.challengeId=this.route.snapshot.queryParams['id'];
        if(this.challengeId){
            this.loadGuidelinesForm();
        }

    }

    toggleTeamSizeLimitDetails(value: string) {
        const teamSizeLimitDetailsControl = this.guidelinesForm.get('teamSizeLimitDetails');
        if (value === 'TEAM' || value === 'BOTH') {
            teamSizeLimitDetailsControl?.enable();
        } else {
            teamSizeLimitDetailsControl?.disable();
            teamSizeLimitDetailsControl?.setValue(''); // Clear the field when it's disabled
        }
    }

    toggleRestrictionFields(value: string) {
        const isOpenToAll = value === 'openToAll';
        if (isOpenToAll) {
            this.disableAllRestrictions();
        } else {
            this.guidelinesForm.get('ageChecked')?.setValue(true);
            this.guidelinesForm.get('geographicChecked')?.setValue(true);
            this.guidelinesForm.get('otherChecked')?.setValue(true);
            this.enableAllRestrictions();
        }
    }

    disableAllRestrictions() {
        this.guidelinesForm.get('ageChecked')?.setValue(false);
        this.guidelinesForm.get('geographicChecked')?.setValue(false);
        this.guidelinesForm.get('otherChecked')?.setValue(false);

        this.guidelinesForm.get('ageChecked')?.disable();
        this.guidelinesForm.get('geographicChecked')?.disable();
        this.guidelinesForm.get('otherChecked')?.disable();

        this.guidelinesForm.get('ageRestrictions')?.disable();
        this.guidelinesForm.get('geographicRestrictions')?.disable();
        this.guidelinesForm.get('otherRestrictions')?.disable();
    }

    enableAllRestrictions() {
        this.guidelinesForm.get('ageChecked')?.enable();
        this.guidelinesForm.get('geographicChecked')?.enable();
        this.guidelinesForm.get('otherChecked')?.enable();
    }

    toggleField(fieldName: string, isEnabled: boolean) {
        const control = this.guidelinesForm.get(fieldName);
        if (isEnabled) {
            control?.enable();
        } else {
            control?.disable();
            control?.setValue(''); // Clear the field when it's disabled
        }
    }

    // onSubmit() {
    //     this.guidelinesForm.markAllAsTouched();

    //     if (this.guidelinesForm.valid) {

    //         // const guidelinesData: Guidelines = this.guidelinesForm.value;
    //         // Map form value to the Guidelines DTO
    //         const formValue = this.guidelinesForm.value;

    //         const guidelinesData: Guidelines = {
    //             participantEligibility:
    //                 formValue.openToAll === 'openToAll'
    //                     ? ParticipantEligibility.OPEN
    //                     : ParticipantEligibility.CUSTOM,
    //             teamRequirements: formValue.teamRequirements,
    //             ageRestrictions: formValue.ageRestrictions,
    //             geographicRestrictions: formValue.geographicRestrictions,
    //             otherRestrictions: formValue.otherRestrictions,
    //             teamSizeLimit: formValue.teamRequirements === 'TEAM' ? formValue.teamSizeLimitDetails : '',
    //             skillsOrExpertiseNeeded: formValue.skillsOrExpertiseNeeded,
    //             intellectualPropertyTerms: formValue.intellectualPropertyTerms,
    //             partnershipAndCollaborations:
    //                 formValue.partnershipAndCollaborations,
    //             dataSharingAndPrivacyPolicies:
    //                 formValue.dataSharingAndPrivacyPolicies,
    //             postChallengeSupport: formValue.postChallengeSupport,
    //             marketingAndPromotionPlans:
    //                 formValue.marketingAndPromotionPlans,
    //             additionalCommentsOrInformation:
    //                 formValue.additionalCommentsOrInformation,
    //         };
    //         this.store
    //             .select(selectChallengeId)
    //             .pipe(
    //                 take(1)).subscribe((challengeId) => {
    //                     if (challengeId) {
    //                         this.store.dispatch(
    //                             updateGuidelines({
    //                                 challengeId,
    //                                 data: guidelinesData,
    //                             })
    //                         );
    //                         // Listen for any new errors after the API call
    //                      this.store
    //                      .pipe(
    //                          withLatestFrom(this.store.select(selectGuidelinesError)),
    //                          tap(([_, error]) => {
    //                              if (error) {
    //                                  this.validationMessageService.setServerErrors(
    //                                      this.guidelinesForm,
    //                                      error
    //                                  );
    //                              }
    //                          })
    //                      )
    //                      .subscribe();
    //                     }
    //                 });
    //     }
    // }
    loadGuidelinesForm() {
        const challengeId = this.challengeId; // Replace with the actual challengeId

        this.challengeService.getGuideline(challengeId)
            .subscribe({
                next: (data: any) => {
                    this.populateForm(data.data);
                },
                error: (error: HttpErrorResponse) => {
                    this.alertService.errorPopup(
                        error.error.message
                    );
                    console.error('Error loading guidelines:', error);
                }
            });
    }

    populateForm(data: any) {
        this.guidelinesForm.patchValue({
            openToAll: data.participantEligibility=== ParticipantEligibility.OPEN ? 'openToAll' : 'custom',
            ageRestrictions: data.metaData.ageRestrictions,
            geographicRestrictions: data.metaData.geographicRestrictions,
            otherRestrictions: data.metaData.otherRestrictions,
            teamRequirements: data.teamRequirements,
            teamSizeLimitDetails: data.metaData.teamSizeLimit,
            skillsOrExpertiseNeeded: data.metaData.skillsOrExpertiseNeeded,
            intellectualPropertyTerms: data.metaData.intellectualPropertyTerms,
            partnershipAndCollaborations: data.metaData.partnershipAndCollaborations,
            dataSharingAndPrivacyPolicies: data.metaData.dataSharingAndPrivacyPolicies,
            postChallengeSupport: data.metaData.postChallengeSupport,
            marketingAndPromotionPlans: data.metaData.marketingAndPromotionPlans,
            additionalCommentsOrInformation: data.metaData.additionalCommentsOrInformation,
            ageChecked: data.metaData.ageRestrictions?true:false,
            geographicChecked:data.metaData.geographicRestrictions?true:false,
            otherChecked: data.metaData.otherRestrictions?true:false,
        });

        // // Handle disabling or enabling of controls based on form data
        // this.toggleFields('ageChecked', this.ageRestrictionsControls);
        // this.toggleFields('geographicChecked', this.geographicRestrictionsControls);
        // this.toggleFields('otherChecked', this.otherRestrictionsControls);
    }

    onSubmit() {
        this.guidelinesForm.markAllAsTouched();

        if (this.guidelinesForm.valid) {
            // Clear existing server errors
            this.validationMessageService.clearServerErrors(this.guidelinesForm);

            // Build the request body
            const formValue = this.guidelinesForm.value;

            const guidelinesData: Guidelines = {
                participantEligibility:
                    formValue.openToAll === 'openToAll'
                        ? ParticipantEligibility.OPEN
                        : ParticipantEligibility.CUSTOM,
                teamRequirements: formValue.teamRequirements,
                ageRestrictions: formValue.ageRestrictions,
                geographicRestrictions: formValue.geographicRestrictions,
                otherRestrictions: formValue.otherRestrictions,
                teamSizeLimit: formValue.teamRequirements === 'TEAM' || 'BOTH' ? formValue.teamSizeLimitDetails : '',
                skillsOrExpertiseNeeded: formValue.skillsOrExpertiseNeeded,
                intellectualPropertyTerms: formValue.intellectualPropertyTerms,
                partnershipAndCollaborations: formValue.partnershipAndCollaborations,
                dataSharingAndPrivacyPolicies: formValue.dataSharingAndPrivacyPolicies,
                postChallengeSupport: formValue.postChallengeSupport,
                marketingAndPromotionPlans: formValue.marketingAndPromotionPlans,
                additionalCommentsOrInformation: formValue.additionalCommentsOrInformation,
            };

            const challengeId = this.route.snapshot.queryParams['id']; // Replace with the actual challengeId

            // Make the API call to update the guidelines
            this.challengeService.updateGuideline(challengeId, guidelinesData)
                .subscribe({
                    next: (response: any) => {
                        // Handle successful response
                        this.alertService.successPopup(response.message);
                        this.successEvent.emit();
                        // Additional success handling if needed, such as refreshing the form
                    },
                    error: (error: HttpErrorResponse) => {
                        // Handle error response
                        if (error.status === 422) {
                            const errors = error?.error?.errors;
                            if (errors) {
                                this.validationMessageService.setServerErrors(
                                    this.guidelinesForm,
                                    errors
                                );
                            }
                        } else {
                            this.alertService.errorPopup(error.error.message);
                        }
                    },
                });
        }
    }


}
