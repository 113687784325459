<section>
    <div class="container">
        <form [formGroup]="faqsForm">
            <div class="row">
                <div class="col-12">
                    <div class="mb-3">
                        <label for="" class="form-label fw-semibold" style="margin-bottom: 0;">Add
                            New FAQ
                        </label>
                        <small class="form-text text-muted info-msg" style="margin-bottom: 8px; display: block;">
                            Help text comes here
                        </small>
                        <div>
                            <input type="text" class="form-control" formControlName="title"
                                placeholder="Type the FAQ question here" />
                            <app-input-error-message [formData]="faqsForm" [controlName]="'title'">
                            </app-input-error-message>

                        </div>
                        <div class="mt-3">
                            <textarea class="form-control" formControlName="answer"
                                placeholder="Type the answer here"></textarea>
                            <app-input-error-message [formData]="faqsForm" [controlName]="'answer'">
                            </app-input-error-message>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 text-end check-btn">
                    <button type="button" (click)="onAddFaq()"><img src="/images/common/check.png" /></button>
                </div>
            </div>
            <div class="col-md-12">
                <div class="accordion-wrapper" *ngFor="let faq of faqs; let i = index; trackBy: trackByIndex">
                    <div class="accordion custom-accordion" id="accordionExample">
                        <div class="accordion-item ">
                            <h2 class="accordion-header" [attr.id]="'heading' + i">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    [attr.data-bs-target]="'#collapse' + i" aria-expanded="false"
                                    [attr.aria-controls]="'collapse' + i">
                                    {{faq.title}}
                                </button>
                            </h2>
                            <div [attr.aria-controls]="'collapse' + i" id="collapse{{i}}"
                                class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + i"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    {{faq.answer}}
                                </div>

                            </div>

                        </div>
                    </div>
                    <div class="cancel" (click)="faq.id !== undefined && onDeleteFaq(faq.id)">
                        <span><img src="/images/common/cancel.svg" /></span>
                    </div>
                </div>
            </div>
        </form>

    </div>
    <!-- <div class="row mt-4">
        <div class="col-12 text-end">
            <button type="button" class="btn btn-gradient btn-bg-blue-gradient fw-semibold custom-btn me-4"
                (click)="redirecToReviewPage()">
                Review & Submit
                <span class="arrow-btn-wrapper">
                    <img src="/images/home/right-arrow.svg" alt="Arrow" height="20" class="arrow-icon1">
                </span>
            </button>
        </div>
    </div> -->
</section>
