import { createAction, props } from '@ngrx/store';
import { ChallengeRequestDto } from '../../Model/challenge-request.dto';
import { ProblemStatementDto } from '../../Model/problem-statement.dto';
import { PrizeRewardDto } from '../../Model/prize-reward.dto';
import { ChallengeDetailsDto } from '../../Model/challenge-details';
import { FormGroup } from '@angular/forms';
import { challengeBasicInformationDto } from '../../Model/challenges-basic-information.dto';
import { SdgsDto } from '../../Model/sdgs';
import { Guidelines } from '../../Model/guidelines-form.dto';
import { evaluationCriteria } from '../../Model/evaluation-criteria.dto';
import { timelines } from '../../Model/timelines.dto';
import { ChallengeResources } from '../../Model/challenge-resource.dto';
import { Faqs } from '../../Model/faqs.dto';

//Step 1 - Basic Information
export const loadChallengeBasicInformation = createAction(
    '[Challenge] Load Challenge Basic Information',
    props<{ id: string }>()
);

export const loadChallengeBasicInformationSuccess = createAction(
    '[Challenge] Load Challenge Basic Information Success',
    props<{ data: challengeBasicInformationDto }>()
);

export const loadChallengeBasicInformationFailure = createAction(
    '[Challenge] Load Challenge Basic Information Failure',
    props<{ error:  { message: string, [key: string]: any } }>()
);

export const postChallengeBasicInformation = createAction(
    '[Challenge] Post Challenge Basic Information',
    props<{ data: challengeBasicInformationDto }>()
);
// Store Challenge Id
export const storeChallengeId = createAction(
    '[Launch Challenge] Store Challenge ID',
    props<{ challengeId: string }>()
);

export const postChallengeBasicInformationSuccess = createAction(
    '[Challenge] Post Challenge Basic Information Success',
    props<{ message: string,  challengeId: number }>()
);

export const postChallengeBasicInformationFailure = createAction(
    '[Challenge] Post Challenge Basic Information Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

export const updateChallengeBasicInformation = createAction(
    '[Challenge] Update Challenge Basic Information',
    props<{ id: string; data: challengeBasicInformationDto }>()
);

export const updateChallengeBasicInformationSuccess = createAction(
    '[Challenge] Update Challenge Basic Information Success',
    props<{ message: string }>()
);

export const updateChallengeBasicInformationFailure = createAction(
    '[Challenge] Update Challenge Basic Information Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Step 2 - Problem Statement

// Actions for loading problem statement
export const loadProblemStatement = createAction(
    '[Launch Challenge] Load Problem Statement',
    props<{ id: string }>()
);

export const loadProblemStatementSuccess = createAction(
    '[Launch Challenge] Load Problem Statement Success',
    props<{ problemStatement: ProblemStatementDto }>()
);

export const loadProblemStatementFailure = createAction(
    '[Launch Challenge] Load Problem Statement Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Actions for updating problem statement
export const updateProblemStatement = createAction(
    '[Launch Challenge] Update Problem Statement',
    props<{ id: string; data: ProblemStatementDto }>()
);

export const updateProblemStatementSuccess = createAction(
    '[Launch Challenge] Update Problem Statement Success',
    props<{ message: string }>()
);

export const updateProblemStatementFailure = createAction(
    '[Launch Challenge] Update Problem Statement Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Step 3
// Actions for loading challenge details
export const loadChallengeDetails = createAction(
    '[Launch Challenge] Load Challenge Details',
    props<{ id: string }>()
);

export const loadChallengeDetailsSuccess = createAction(
    '[Launch Challenge] Load Challenge Details Success',
    props<{ challengeDetails: ChallengeDetailsDto }>()
);

export const loadChallengeDetailsFailure = createAction(
    '[Launch Challenge] Load Challenge Details Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Actions for updating challenge details
export const updateChallengeDetails = createAction(
    '[Launch Challenge] Update Challenge Details',
    props<{ id: string; data: ChallengeDetailsDto; }>()
);

export const updateChallengeDetailsSuccess = createAction(
    '[Launch Challenge] Update Challenge Details Success',
    props<{ message: string }>()
);

export const updateChallengeDetailsFailure = createAction(
    '[Launch Challenge] Update Challenge Details Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Step 5 - Prize Rewards
// Load Prize Rewards
export const loadPrizeRewards = createAction(
    '[Launch Challenge] Load Prize Rewards',
    props<{ id: string }>()
);

export const loadPrizeRewardsSuccess = createAction(
    '[Launch Challenge] Load Prize Rewards Success',
    props<{ prizeRewards: PrizeRewardDto }>()
);

export const loadPrizeRewardsFailure = createAction(
    '[Launch Challenge] Load Prize Rewards Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Update Prize Rewards
export const updatePrizeRewards = createAction(
    '[Launch Challenge] Update Prize Rewards',
    props<{ challengeId: string; data: PrizeRewardDto }>()
);

export const updatePrizeRewardsSuccess = createAction(
    '[Launch Challenge] Update Prize Rewards Success',
    props<{ message: string }>()
);

export const updatePrizeRewardsFailure = createAction(
    '[Launch Challenge] Update Prize Rewards Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Load SDGs
export const loadSdgs = createAction(
    '[Launch Challenge] Load SDGs',
    props<{ id: string }>()
);

export const loadSdgsSuccess = createAction(
    '[Launch Challenge] Load SDGs Success',
    props<{ sdgs: SdgsDto[] }>()
);

export const loadSdgsFailure = createAction(
    '[Launch Challenge] Load SDGs Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Update SDGs
export const updateSdgs = createAction(
    '[Launch Challenge] Update SDGs',
    props<{ challengeId: string; data: { sdgs: SdgsDto[] } }>()
);

export const updateSdgsSuccess = createAction(
    '[Launch Challenge] Update SDGs Success',
    props<{ sdgs: SdgsDto[] }>()
);

export const updateSdgsFailure = createAction(
    '[Launch Challenge] Update SDGs Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Step 6 - Guidelines
// Load Guidelines
export const loadGuidelines = createAction(
    '[Launch Challenge] Load Guidelines',
    props<{ id: string }>()
);

export const loadGuidelinesSuccess = createAction(
    '[Launch Challenge] Load Guidelines Success',
    props<{ guidelines: Guidelines }>()
);

export const loadGuidelinesFailure = createAction(
    '[Launch Challenge] Load Guidelines Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Update Guidelines
export const updateGuidelines = createAction(
    '[Launch Challenge] Update Guidelines',
    props<{ challengeId: string; data: Guidelines }>()
);

export const updateGuidelinesSuccess = createAction(
    '[Launch Challenge] Update Guidelines Success',
    props<{ message: string }>()
);

export const updateGuidelinesFailure = createAction(
    '[Launch Challenge] Update Guidelines Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Step 7
// Load Evaluation Criteria
export const loadEvaluationCriteria = createAction(
    '[Launch Challenge] Load Evaluation Criteria',
    props<{ id: string }>()
);

export const loadEvaluationCriteriaSuccess = createAction(
    '[Launch Challenge] Load Evaluation Criteria Success',
    props<{ evaluationCriteria: evaluationCriteria }>()
);

export const loadEvaluationCriteriaFailure = createAction(
    '[Launch Challenge] Load Evaluation Criteria Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Update Evaluation Criteria
export const updateEvaluationCriteria = createAction(
    '[Launch Challenge] Update Evaluation Criteria',
    props<{ challengeId: string; data: evaluationCriteria }>()
);

export const updateEvaluationCriteriaSuccess = createAction(
    '[Launch Challenge] Update Evaluation Criteria Success',
    props<{ message: string }>()
);

export const updateEvaluationCriteriaFailure = createAction(
    '[Launch Challenge] Update Evaluation Criteria Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Step 8
// Load Timelines
export const loadTimelines = createAction(
    '[Launch Challenge] Load Timelines',
    props<{ id: string }>()
);

export const loadTimelinesSuccess = createAction(
    '[Launch Challenge] Load Timelines Success',
    props<{ timelines: timelines }>()
);

export const loadTimelinesFailure = createAction(
    '[Launch Challenge] Load Timelines Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Update Timelines
export const updateTimelines = createAction(
    '[Launch Challenge] Update Timelines',
    props<{ challengeId: string; data: timelines }>()
);

export const updateTimelinesSuccess = createAction(
    '[Launch Challenge] Update Timelines Success',
    props<{ message: string }>()
);

export const updateTimelinesFailure = createAction(
    '[Launch Challenge] Update Timelines Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Step 9 
// Load Resources
export const loadResources = createAction(
    '[Launch Challenge] Load Resources',
    props<{ id: string }>()
);

export const loadResourcesSuccess = createAction(
    '[Launch Challenge] Load Resources Success',
    props<{ resources: ChallengeResources }>()
);

export const loadResourcesFailure = createAction(
    '[Launch Challenge] Load Resources Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Update Resources
export const updateResources = createAction(
    '[Launch Challenge] Update Resources',
    props<{ challengeId: string; data: ChallengeResources }>()
);

export const updateResourcesSuccess = createAction(
    '[Launch Challenge] Update Resources Success',
    props<{ message: string }>()
);

export const updateResourcesFailure = createAction(
    '[Launch Challenge] Update Resources Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Step 10
// Load FAQs
export const loadFaqs = createAction(
    '[Launch Challenge] Load FAQs',
    props<{ id: string }>()
);

export const loadFaqsSuccess = createAction(
    '[Launch Challenge] Load FAQs Success',
    props<{ faqs: Faqs[] }>()
);

export const loadFaqsFailure = createAction(
    '[Launch Challenge] Load FAQs Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Update FAQs
export const updateFaqs = createAction(
    '[Launch Challenge] Update FAQs',
    props<{ challengeId: string; data: Faqs }>()
);

export const updateFaqsSuccess = createAction(
    '[Launch Challenge] Update FAQs Success',
    props<{ faqs: Faqs[] }>()
);

export const updateFaqsFailure = createAction(
    '[Launch Challenge] Update FAQs Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);

// Delete FAQ
export const deleteFaq = createAction(
    '[Launch Challenge] Delete FAQ',
    props<{ challengeId: string; faqsId: number }>()
);

export const deleteFaqSuccess = createAction(
    '[Launch Challenge] Delete FAQ Success',
    props<{ faqsId: number }>()
);

export const deleteFaqFailure = createAction(
    '[Launch Challenge] Delete FAQ Failure',
    props<{ error: { message: string, [key: string]: any } }>()
);
