<div class="card  custom-card">
    <div class="card-body d-flex justify-content-between align-items-center">
        <div class="d-flex flex-column align-items-start">
            <div class="d-flex align-items-center">
                <h5 class="card-title m-0">{{ leftNumber }}</h5>
                <span class="mx-2">|</span>
                <h5 class="card-title m-0">{{ rightNumber }}</h5>
            </div>
            <p class="card-text ">{{ leftText }} | {{ rightText }}</p>
        </div>
        <div>

            <a href="#" class="stretched-link text-decoration-none">
                <i class="bi bi-box-arrow-up-right"></i>
            </a>
        </div>
    </div>
</div>
