import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthState } from '../../core/store/auth/auth.state';
import { Store } from '@ngrx/store';
import * as AuthActions from '../../core/store/auth/auth.actions';
import { AuthService } from '../../core/services/apis/auth.service';

@Component({
    selector: 'app-login-with-google',
    standalone: true,
    imports: [],
    templateUrl: './login-with-google.component.html',
    styleUrl: './login-with-google.component.scss',
})
export class LoginWithGoogleComponent {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private store: Store<{ auth: AuthState }>
    ) {}
    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            const code = params['code'];
            const scope = params['scope'];

            if (code && scope) {
                this.store.dispatch(AuthActions.loginGoogle({ code, scope }));
            } else {
                this.router.navigate(['/login']);
            }
        });
    }
}
