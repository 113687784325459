import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { QuillEditorComponent } from '../../shared/quill-editor/quill-editor.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SolverFlowService } from '../../core/services/apis/solver-flow.service';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { InputErrorMessageComponent } from '../../shared/input-error-message/input-error-message.component';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ReferenceHandlerComponent } from '../../shared/input-solution-references/reference-handler/reference-handler.component';
import { ReferenceHandlerUploadsComponent } from '../../shared/input-solution-references/reference-handler-uploads/reference-handler-uploads.component';
import { InputSolutionReferencesComponent } from '../../shared/input-solution-references/input-solution-references.component';
import { CommonValidators } from '../../core/utilities/common-validators';
import { SolverFormStoreService } from '../store/solver-form-store.service';
import { firstValueFrom } from 'rxjs';
import { SolutionForm9UpdateRequest } from '../types/solution-form-9-types';
import { SolutionReferences } from '../../shared/input-solution-references/types/input-solution-references-types';

@Component({
    selector: 'app-solver-flow-step-9',
    standalone: true,
    imports: [
        QuillEditorComponent,
        ReactiveFormsModule,
        InputErrorMessageComponent,
        ReferenceHandlerComponent,
        ReferenceHandlerUploadsComponent,
        CommonModule,
        InputSolutionReferencesComponent,
    ],
    templateUrl: './solver-flow-step-9.component.html',
    styleUrl: './solver-flow-step-9.component.scss',
})
export class SolverFlowStep9Component implements OnInit {
    @Output() handleSuccess = new EventEmitter();
    solverFormStep9!: FormGroup;
    solutionReferences: SolutionReferences = {} as SolutionReferences;

    constructor(
        private fb: FormBuilder,
        private solverFlowService: SolverFlowService,
        private alertService: SweetAlertService,
        protected validationMessageService: ValidationMessageService,
        private solverFormStoreService: SolverFormStoreService
    ) {
        this.solverFormStep9 = this.fb.group(
            {
                executiveSummary: [''],
                futurePressRelease: [''],
            },
            {
                validators: CommonValidators.atLeastOneRequired(
                    'executiveSummary',
                    'futurePressRelease'
                ),
            }
        );
    }

    ngOnInit(): void {
        this.solverFormStoreService.selectForm9$.subscribe((form9) => {
            if (form9) {
                this.patchForm(form9.solution);
                this.solutionReferences = form9.solutionReferences || {};
            }
        });
    }

    patchForm(data: any) {
        this.solverFormStep9.patchValue({
            executiveSummary: data.executiveSummary || '',
            futurePressRelease: data.futurePressRelease || '',
        });
    }

    async onSubmit(isDraft?: boolean) {
        if (!isDraft) {
            this.solverFormStep9.markAllAsTouched();
        }
        if (this.solverFormStep9.valid || isDraft) {
            const formValues = this.solverFormStep9.value;
            const solverStep9Data: SolutionForm9UpdateRequest = {
                executiveSummary: formValues.executiveSummary,
                futurePressRelease: formValues.futurePressRelease,
                solutionReferences: formValues.solutionReferences,
            };
            const solutionId = await firstValueFrom(
                this.solverFormStoreService.selectSolutionId$
            );
            this.solverFlowService
                .updateproblemExplorerStep9(
                    solutionId as number,
                    solverStep9Data,
                    isDraft
                )
                .subscribe({
                    next: (response) => {
                        // handle success response
                        this.solverFormStoreService.getSolutionDetails();
                        this.handleSuccess.emit();
                    },
                    error: (error: HttpErrorResponse) => {
                        // Hnadle the error response
                        if (error.status === 422) {
                            const errors = error?.error?.errors;
                            if (errors) {
                                this.validationMessageService.setServerErrors(
                                    this.solverFormStep9,
                                    errors
                                );
                            }
                        } else {
                            this.alertService.errorPopup(
                                error.error.message ||
                                    'Error submitting form, please try again'
                            );
                        }
                    },
                });
        }
    }
}
