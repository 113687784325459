import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { AuthService } from '../../core/services/apis/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PasswordInputComponent } from '../../shared/input/password-input/password-input.component';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { CommonModule } from '@angular/common';
import { response } from 'express';
import { error } from 'console';
import { AuthState } from '../../core/store/auth/auth.state';
import { Store } from '@ngrx/store';
import * as AuthActions from '../../core/store/auth/auth.actions';
import { selectUserRoles } from '../../core/store/auth/auth.selectors';
import { distinctUntilChanged, first, map, switchMap, tap } from 'rxjs';

@Component({
    selector: 'app-signup',
    standalone: true,
    imports: [
        CommonModule,
        RouterLink,
        ReactiveFormsModule,
        PasswordInputComponent,
    ],
    templateUrl: './signup.component.html',
    styleUrl: './signup.component.scss',
})
export class SignupComponent {
    signupForm: FormGroup;
    useSameContactDetails: boolean = true;
    title: string = 'Sign Up';
    showEmailAndPassword: boolean = true;
    isSSOLogin: boolean = false;
    userRoles$ = this.store.select(selectUserRoles);
    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private router: Router,
        protected validationMessageService: ValidationMessageService,
        private alertService: SweetAlertService,
        private route: ActivatedRoute,
        private store: Store<{ auth: AuthState }>
    ) {
        this.signupForm = this.fb.group({
            role: [this.activeRole.name, Validators.required],
            firstName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            lastName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            email: [
                '',
                Validators.compose([Validators.required, Validators.email]),
            ],
            password: ['', Validators.required],
            organizationUrl: [''],
            organizationName: [''],
            contactFirstName: [''],
            contactLastName: [''],
            contactEmail: [''],
        });
    }

    ngOnInit(): void {
        // Check if the user is coming from an SSO login
        this.isSSOLogin =
            this.route.snapshot.queryParamMap.get('sso') === 'true';

        if (this.isSSOLogin) {
            this.showEmailAndPassword = false;
            this.title = 'Select Role';
            this.signupForm.removeControl('email');
            this.signupForm.removeControl('password');
        }
         // Set validators based on the selected role
        if (
            this.activeRole.slug === 'Organization' ||
            this.activeRole.slug === 'Startup'
        ){
            this.toggleContactValidators();
        }
    }
    roles = [
        { name: 'Intern', path: 'intern.png', slug: 'Intern' },
        {
            name: 'Organization',
            path: 'organization.png',
            slug: 'Organization',
        },
        {
            name: 'An Innovator',
            path: 'an_innovator.png',
            slug: 'Innovator',
        },
        { name: 'A Startup', path: 'a_startup.png', slug: 'Startup' },
        { name: 'A Mentor', path: 'a_mentor.png', slug: 'Mentor' },
    ];

    activeRole: any = this.roles[0];

    onRoleChange(role: any) {
        this.activeRole = role;
        this.signupForm.patchValue({ role: role.slug });
        this.toggleContactValidators();
    }

    toggleContactValidators() {
        if (
            this.activeRole.slug === 'Organization' ||
            this.activeRole.slug === 'Startup'
        ) {
            this.signupForm
                .get('organizationUrl')
                ?.setValidators([Validators.required]);
            this.signupForm
                .get('organizationName')
                ?.setValidators([Validators.required]);

            if (this.useSameContactDetails) {
                this.signupForm.get('contactFirstName')?.clearValidators();
                this.signupForm.get('contactLastName')?.clearValidators();
                this.signupForm.get('contactEmail')?.clearValidators();
            } else {
                this.signupForm
                    .get('contactFirstName')
                    ?.setValidators([
                        Validators.required,
                        Validators.maxLength(50),
                    ]);
                this.signupForm
                    .get('contactLastName')
                    ?.setValidators([
                        Validators.required,
                        Validators.maxLength(50),
                    ]);
                this.signupForm
                    .get('contactEmail')
                    ?.setValidators([Validators.required, Validators.email]);
            }
        }
        // Update the form controls with the new validator settings
        this.signupForm.get('organizationUrl')?.updateValueAndValidity();
        this.signupForm.get('organizationName')?.updateValueAndValidity();
        this.signupForm.get('contactFirstName')?.updateValueAndValidity();
        this.signupForm.get('contactLastName')?.updateValueAndValidity();
        this.signupForm.get('contactEmail')?.updateValueAndValidity();
    }

    onCheckboxChange() {
        this.useSameContactDetails = !this.useSameContactDetails;
        this.toggleContactValidators();
    }

    onSubmit() {
        this.signupForm.markAllAsTouched();

        // Debug: Log form validity
        if (this.signupForm.valid) {
            const isSSOLogin =
                this.route.snapshot.queryParamMap.get('sso') === 'true';
            // Debug: Log SSO login status
            if (isSSOLogin) {
                this.authService.updateRoleSSO(this.signupForm.value).subscribe(
                    (response) => {
                        const newRoles = response.roles; // Get the roles from response
                        const organization = response.organization
                        // Dispatch the action to update the roles in the store only if no roles exist
                        this.userRoles$
                            .pipe(first())
                            .subscribe((currentRoles) => {
                                // Only dispatch if there are no roles in the state
                                if (currentRoles.length === 0) {
                                    this.store.dispatch(
                                        AuthActions.updateUserRoles({
                                            roles: newRoles,
                                            organization: organization
                                        })
                                    );
                                }
                            });
                        this.alertService.successPopup(response.message);
                        this.router.navigate(['/']);
                    },
                    (error: HttpErrorResponse) => {
                        // Debug: Log error status and message
                        if (error.status == 422) {
                            const errors = error?.error?.errors;
                            if (errors) {
                                this.validationMessageService.setServerErrors(
                                    this.signupForm,
                                    errors
                                );
                            }
                        } else {
                            // this.alertService.errorPopup(error.error.message);
                        }
                    }
                );
            } else {
                this.authService.signup(this.signupForm.value).subscribe(
                    (response) => {
                        this.alertService.successPopup(response.message);

                        this.router.navigate(['/']);
                    },
                    (error: HttpErrorResponse) => {
                        if (error.status == 422) {
                            const errors = error?.error?.errors;
                            if (errors) {
                                this.validationMessageService.setServerErrors(
                                    this.signupForm,
                                    errors
                                );
                            }
                        } else {
                            this.alertService.errorPopup(error.error.message);
                        }
                    }
                );
            }
        }
    }
}
