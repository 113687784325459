<div class="static-page container">
    <div class="image-container position-relative">
        <div class="light-strap" [ngStyle]="{'background': lightColor}"></div>
        <div class="dark-strap" [ngStyle]="{'background': darkColor}"></div>
        <img [src]="imageSrc" alt="Image" class="main-image position-relative">
        <div class="overlay-text-container">
            <div class="overlay-text"
                [ngStyle]="{'background': overlayBgColor, 'width': overlayWidth, 'height': overlayHeight}">
                <h2 [ngStyle]="{'color': overlayTextColor}" class="text-center mt-1">{{ overlayText }}</h2>
            </div>
        </div>
    </div>
    <div class="content ">
        <h2 class="title-font">{{ title }}</h2>
        <div class="row justify-content-center">
            <p class="description-font text-center my-0 col-md-9 fw-semibold">{{ description }}</p>
            <a [href]="buttonLink">
                <button
                    class="btn btn-gradient rounded-start-0 get-challenge-btn align-items-center fw-semibold  custom-btn "
                    [ngStyle]="{'background': buttonBgColor}" style="margin-top: 20px; margin-bottom: 90px;">
                    {{ buttonText }}
                    <span class="arrow-btn-wrapper">
                        <img [src]="arrowIconSrc" alt="Arrow" height="20" class="arrow-icon1">
                    </span>
                </button>
            </a>
        </div>
    </div>
</div>
