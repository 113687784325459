<div class="preview-header navbar navbar-expand-lg bg-white py-3" *ngIf="selectedFramework">
    <div class="container">
        <button class="btn" style="color: #000; font-size: 15px; font-weight: 600" (click)="backToFrameworkSelection()">
            < Back to Framework Selection </button> <div>
    </div>
</div>
</div>

<section>
    <ng-container *ngIf="!selectedFramework">
        <div class="step-4-sub-heading">
            <h3 class="heading-mon-22">
                SELECT A FRAMEWORK TO DEVELOP SCENARIOS FOR
            </h3>
            <p>
                You can either choose any one of the two models or create
                scenarios for both
            </p>
        </div>
        <div class="row framework-container">
            <div class="col-md-6 framework-wrapper">
                @if (addedFrameWorks.has(frameworkType.gbn)) {
                <div class="data-filled-status"><img src="/images/solver-flow/selected.svg" /><span>Data
                        Filled In</span>
                </div>
                }
                <img src="/images/solver-flow/gbn-img.svg" height="102px" width="102px"
                    style="background-color: #cce3f3" />
                <h4>The GBN (Global Business Network) Framework</h4>
                <p class="framework-subtitle">
                    Imagining A Future Which Can Happen
                </p>
                <p>
                    Scenarios are divergent stories about the future. The story
                    format allows people and organizations to envision possible
                    future consequences of complex interactions among economic,
                    political, social, and environmental forces. Scenarios are
                    not predictions of the future, and in most cases the
                    scenarios are not likely to occur exactly as written, but
                    they do need to be plausible. They describe events which
                    could happen, which might happen. They are designed to take
                    an outside-in focus, beginning with external forces and
                    ending with internal actions that the organization might
                    take to prepare for and cope with the eventualities depicted
                    in the scenarios. The idea is to focus on the issues most
                    relevant to the missions of the organization.
                </p>
                <p>- U.S. Army Corps of Engineers</p>
                <button class="add-new-btn" (click)="setSelectedFramework(frameworkType.gbn)">
                    Select This Framework
                </button>
            </div>
            <div class="col-md-6 framework-wrapper">
                @if (addedFrameWorks.has(frameworkType.fourSteps)) {
                    <div class="data-filled-status"><img src="/images/solver-flow/selected.svg" /><span>Data
                        Filled In</span>
                </div>
                }
                <img src="/images/solver-flow/4step.svg" height="102px" width="102px"
                    style="background-color: #cce3f3" />
                <h4>The Four Steps Framework</h4>
                <p class="framework-subtitle">
                    Assessing A Future In Comparison To The Present
                </p>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla.
                </p>
                <p>- Lorem</p>
                <button class="add-new-btn" (click)="setSelectedFramework(frameworkType.fourSteps)">
                    Select This Framework
                </button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="selectedFramework">
        <!-- after framework selection -->
        <div class="gbn-framework-form-wrapper">
            @if (selectedFramework === frameworkType.gbn) {
            <h2>The GBN Framework</h2>
            } @else if (selectedFramework === frameworkType.fourSteps) {
            <h2>The Four Steps Framework</h2>
            }
            <div class="gbn-img-container">
                <ng-container *ngIf="selectedFramework === frameworkType.gbn">
                    <img src="/images/solver-flow/gbn-img.svg" /></ng-container>
                <ng-container *ngIf="selectedFramework === frameworkType.fourSteps">
                    <img src="/images/solver-flow/4step.svg" /></ng-container>
            </div>
            <p class="gbn-detail">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>

            <div class="gbn-table-wrapper">
                <table>
                    <thead class="gbn-header">
                        <th class="weak-signal">Weak Signals Title</th>
                        <th class="impact">Impact</th>
                        <th class="certainty">Certainty</th>
                        <th class="probability">Probability</th>
                        <th class="time">Time</th>
                    </thead>
                    <tbody>
                        @for (item of signalList; track $index) {
                        <tr class="gbn-data-row">
                            <td class="weak-signal">
                                {{ item.title }}
                            </td>
                            <td class="impact">{{ item.impact }}</td>
                            <td class="certainty">{{ item.certainty }}</td>
                            <td class="probability">{{ item.probability }}</td>
                            <td class="time">
                                {{
                                    weakSignalAssessmentTimeTitle[item.time] ||
                                        "< Years"
                                }}
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>

            <div [formGroup]="formGroup">
                <div class="row form-field-margin">
                    <div class="col-md-6">

                        <div class="custom-ng-select-dropdown-list">
                            <label for="title" class="form-label fw-semibold weak-signal-1-title">Select Weak Signal 1
                            </label>
                            <ng-select [items]="filteredweakSignal1List" bindValue="id" bindLabel="title"
                                [closeOnSelect]="true" [multiple]="false" [searchable]="false"
                                formControlName="weakSignal1" class="form-control">
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    {{ item?.title }}
                                </ng-template>
                            </ng-select>
                        </div>
                        <app-input-error-message [formData]="formGroup" controlName="weakSignal1">
                        </app-input-error-message>
                    </div>

                    <div class="col-md-6" *ngIf="selectedFramework === frameworkType.gbn">
                        <label for="challengeOwnerId" class="form-label fw-semibold weak-signal-2-title">Select Weak
                            Signal 2
                        </label>
                        <div class="custom-ng-select-dropdown-list">
                            <ng-select [items]="filteredweakSignal2List" bindValue="id" bindLabel="title"
                                [closeOnSelect]="true" [multiple]="false" [searchable]="false"
                                formControlName="weakSignal2" class="form-control">
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    {{ item?.title }}
                                </ng-template>
                            </ng-select>
                        </div>
                        <app-input-error-message [formData]="formGroup" controlName="weakSignal2">
                        </app-input-error-message>
                    </div>
                </div>
                <div class="col-md-12 mb-3">
                    <label class="form-label fw-semibold">SCENARIO A</label>
                    <small class="form-text text-muted info-msg">
                        Help text comes here
                    </small>

                    <textarea class="form-control" formControlName="scenarioA" rows="4"></textarea>
                </div>
                <div class="col-md-12 mb-3">
                    <label class="form-label fw-semibold">SCENARIO B</label>
                    <small class="form-text text-muted info-msg">
                        Help text comes here
                    </small>

                    <textarea class="form-control" formControlName="scenarioB" rows="4"></textarea>
                </div>
                <div class="col-md-12 mb-3">
                    <label class="form-label fw-semibold">SCENARIO C</label>
                    <small class="form-text text-muted info-msg">
                        Help text comes here
                    </small>

                    <textarea class="form-control" formControlName="scenarioC" rows="4"></textarea>
                </div>
                <div class="col-md-12 mb-3">
                    <label class="form-label fw-semibold">SCENARIO D</label>
                    <small class="form-text text-muted info-msg">
                        Help text comes here
                    </small>

                    <textarea class="form-control" formControlName="scenarioD" rows="4"></textarea>
                </div>
                <!-- Please add the more fields as per xd -->

                <app-input-solution-references #solutionReferencesComponentRef [formGroup]="formGroup"
                    [solutionReferences]="solutionReferences"></app-input-solution-references>

                <button class="add-new-btn" type="submit" (click)="saveSelectedFramework()">
                    Save
                </button>
            </div>
        </div>
    </ng-container>
</section>
