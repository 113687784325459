import { ChallengeDetailsDto } from "../../Model/challenge-details";
import { ChallengeResources } from "../../Model/challenge-resource.dto";
import { challengeBasicInformationDto } from "../../Model/challenges-basic-information.dto";
import { evaluationCriteria } from "../../Model/evaluation-criteria.dto";
import { Faqs } from "../../Model/faqs.dto";
import { Guidelines } from "../../Model/guidelines-form.dto";
import { PrizeRewardDto } from "../../Model/prize-reward.dto";
import { ProblemStatementDto } from "../../Model/problem-statement.dto";
import { SdgsDto } from "../../Model/sdgs";
import { timelines } from "../../Model/timelines.dto";

export interface LaunchChallengeState {
    basicInformation: challengeBasicInformationDto | null;
    challengeId?: string;
    problemStatement: ProblemStatementDto | null;
    problemStatementSubmitted: boolean;
    challengeDetails: ChallengeDetailsDto | null;
    challengeDetailsSubmitted: boolean;
    sdgs: SdgsDto[]; 
    sdgsSubmitted: boolean; 
    prizeRewards: PrizeRewardDto | null;
    prizeRewardsSubmitted: boolean;
    guidelines: Guidelines | null; 
    guidelinesSubmitted: boolean;
    evaluationCriteria: evaluationCriteria | null;
    evaluationCriteriaSubmitted: boolean; 
    timelines: timelines | null;
    timelinesSubmitted: boolean;
    resources: ChallengeResources | null;
    resourcesSubmitted: boolean; 
    faqs: Faqs[],
    faqsSubmitted: boolean;
    error: { message: string, [key: string]: any } | null;
    loading: boolean;
}

export const initialLaunchChallengeState: LaunchChallengeState = {
    basicInformation: null,
    problemStatement: null,
    challengeDetails: null,
    sdgs: [],
    prizeRewards: null,
    guidelines: null,
    evaluationCriteria: null,
    timelines: null,
    resources: null, 
    faqs: [],
    error: null,
    loading: false,
    problemStatementSubmitted: false,
    challengeDetailsSubmitted: false,
    sdgsSubmitted: false,
    prizeRewardsSubmitted: false,
    guidelinesSubmitted: false,
    evaluationCriteriaSubmitted: false,
    timelinesSubmitted: false,
    resourcesSubmitted: false,
    faqsSubmitted: false,
};