import { CommonModule } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { SolverFlowService } from '../../core/services/apis/solver-flow.service';
import { WeakSignalAssessmentTimeTitle } from '../types/solution-form-3-types';
import { NgSelectModule } from '@ng-select/ng-select';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { InputErrorMessageComponent } from '../../shared/input-error-message/input-error-message.component';
import { InputSolutionReferencesComponent } from '../../shared/input-solution-references/input-solution-references.component';
import { SolutionReferences } from '../../shared/input-solution-references/types/input-solution-references-types';
import { SolverFormStoreService } from '../store/solver-form-store.service';
import { firstValueFrom } from 'rxjs';
import {
    SolutionForm4FrameworkType,
    SolutionForm4SolutionFrameworkType,
} from '../types/solution-form-4-types';
import { SweetAlertService } from '../../core/services/sweet-alert.service';

@Component({
    selector: 'app-solver-flow-step-4-scenario-planning',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        InputErrorMessageComponent,
        MatInputModule,
        NgSelectModule,
        InputSolutionReferencesComponent,
    ],
    templateUrl: './solver-flow-step-4-scenario-planning.component.html',
    styleUrl: './solver-flow-step-4-scenario-planning.component.scss',
})
export class SolverFlowStep4ScenarioPlanningComponent implements OnInit {
    public formGroup: FormGroup;
    public frameworkType = SolutionForm4FrameworkType;
    public selectedFramework: SolutionForm4FrameworkType | null = null;
    public signalList: any[] = [];
    public filteredweakSignal1List: any[] = [];
    public filteredweakSignal2List: any[] = [];
    public weakSignalAssessmentTimeTitle: any = WeakSignalAssessmentTimeTitle;
    @Output() successEvent: EventEmitter<void> = new EventEmitter<void>();
    public frameworkList: SolutionForm4SolutionFrameworkType[] = [];
    public addedFrameWorks: Map<
        SolutionForm4FrameworkType,
        SolutionForm4SolutionFrameworkType
    > = new Map();
    public solutionReferences!: SolutionReferences;

    @ViewChild('solutionReferencesComponentRef')
    solutionReferencesComponentRef!: InputSolutionReferencesComponent;

    constructor(
        private readonly solverFlowService: SolverFlowService,
        private readonly formBuilder: FormBuilder,
        private readonly solverFormStoreService: SolverFormStoreService,
        private readonly alertService: SweetAlertService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        this.formGroup = this.formBuilder.group({
            weakSignal1: new FormControl('', Validators.required),
            weakSignal2: new FormControl('', Validators.required),
            scenarioA: new FormControl(''),
            scenarioB: new FormControl(''),
            scenarioC: new FormControl(''),
            scenarioD: new FormControl(''),
        });
    }

    ngOnInit(): void {
        this.fetchWeakSignals();

        this.solverFormStoreService.selectForm4$.subscribe((form4) => {
            this.frameworkList = form4 || [];
            this.frameworkList.forEach((framework) => {
                this.addedFrameWorks.set(framework.type, framework);
            });
        });

        this.formGroup.controls['weakSignal1'].valueChanges.subscribe(
            (event) => {
                console.log(event);
                this.filteredweakSignal2List = this.signalList.filter(
                    (item) => item.id !== event
                );
            }
        );
        this.formGroup.controls['weakSignal2'].valueChanges.subscribe(
            (event) => {
                this.filteredweakSignal1List = this.signalList.filter(
                    (item) => item.id !== event
                );
            }
        );
    }

    backToFrameworkSelection(): void {
        this.selectedFramework = null;
    }

    patchValues(): void {
        const data = this.addedFrameWorks.get(
            this.selectedFramework as SolutionForm4FrameworkType
        );
        this.solutionReferencesComponentRef?.clearFormValues();
        if (data) {
            this.formGroup.patchValue({
                weakSignal1: data.weakSignal1?.id,
                weakSignal2: data.weakSignal2?.id,
                scenarioA: data.scenarioA,
                scenarioB: data.scenarioB,
                scenarioC: data.scenarioC,
                scenarioD: data.scenarioD,
            });
            this.solutionReferences = data.solutionReferences || null;
        } else {
            this.formGroup.reset();
        }
    }

    setSelectedFramework(framwork: SolutionForm4FrameworkType): void {
        this.selectedFramework = framwork;
        this.changeDetectorRef.detectChanges();
        if (this.selectedFramework === SolutionForm4FrameworkType.gbn) {
            this.formGroup.controls['weakSignal2'].enable();
        } else {
            this.formGroup.controls['weakSignal2'].disable();
        }
        this.patchValues();
    }

    async fetchWeakSignals(): Promise<void> {
        const solutionId = await firstValueFrom(
            this.solverFormStoreService.selectSolutionId$
        );
        this.solverFlowService
            .getSortedWeakSignals(solutionId as number)
            .subscribe({
                next: (response) => {
                    this.signalList = response;
                    this.filteredweakSignal1List = response;
                    this.filteredweakSignal2List = response;
                },
            });
    }

    async onSubmit(isDraft?: boolean): Promise<void> {
        if (this.frameworkList.length >= 1) {
            const solutionId = await firstValueFrom(
                this.solverFormStoreService.selectSolutionId$
            );
            this.solverFlowService.submitForm4(solutionId as number).subscribe({
                next: () => this.successEvent.emit(),
                error: (error) => {
                    this.alertService.errorToaster(
                        error.error.message ||
                            'Error submitting form, please try again'
                    );
                },
            });
        } else {
            this.alertService.errorPopup('Please select a framework');
        }
    }

    async saveSelectedFramework(): Promise<void> {
        this.formGroup.markAllAsTouched();
        if (this.formGroup.valid) {
            const solutionId = await firstValueFrom(
                this.solverFormStoreService.selectSolutionId$
            );
            const values = this.formGroup.value;
            values.type = this.selectedFramework;
            this.solverFlowService
                .submitStep4SolutionFrameworkDetails(
                    solutionId as number,
                    values,
                    this.addedFrameWorks.get(
                        this.selectedFramework as SolutionForm4FrameworkType
                    )?.id
                )
                .subscribe({
                    next: () => {
                        this.solverFormStoreService.getSolutionDetails();
                        this.backToFrameworkSelection();
                    },
                });
        }
    }
}
