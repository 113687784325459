<app-banner-section 
  [imageSrc]="'/images/innovator/innovs-main.png'"
  [title]="'Join the Movement: Contribute Solutions to Global Challenges'"
  [description]="'Collaborate with a community of innovators, entrepreneurs, and organizations to develop solutions to pressing global challenges'"
  [buttonText]="'JUMP INTO THE CHALLENGES'"
  [buttonLink]="'#'"
  [lightColor]="'#BEE5AB'"
  [darkColor]="'#97D679'"
  [buttonBgColor]="'linear-gradient(270deg, #5EBD2D, #2E6D0D)'"
  [iconColor]="'#FF7C09'"
  [overlayText]="'Innovators'"
  [overlayBgColor]="'rgba(0, 0, 0, 0.5)'"
  [overlayTextColor]="'#FFFFFF'"
  [overlayWidth]="'240px'"
  [overlayHeight]="'70px'"
  [arrowIconSrc]="'/images/common/right-arrow-green.svg'">
</app-banner-section>

<app-how-it-works
  [title2]="'What is Open Innovation?'"
  [description2]="'Open innovation is a collaborative approach to solving complex problems by sharing knowledge, resources, and expertise across organizations and industries.'"
  [steps]="stepsData"
  [stepNumberColor]="'#5EBD2D'"
  [stepNumberBgColor]="'#DFF2D5'"
  [stepLineColor]="'#5EBD2D'">
</app-how-it-works>

<app-benefits-section
[title]="'Benefits of Open Innovation'"
[description]="'Open innovation offers a unique opportunity to collaborate with a diverse community of innovators, entrepreneurs, and organizations to develop solutions to pressing global challenges.'"
[additionalLine]="' The benefits of open innovation include:'"
[points]="[
  'Access to diverse perspectives and expertise',
  'Opportunities for collaboration and co-creation',
  'Increased visibility and recognition for your work',
  'Potential for funding and resources to support your solution'
]"
[bulletColor]="'#5EBD2D'"
[lightStrapColor]="'#97D679'"
[buttonBgColor]="'linear-gradient(270deg, #5EBD2D, #2E6D0D)'"
[buttonText]="'READY TO MAKE A DIFFERENCE?'"
[buttonLink]="'#'"
[imageSrc]="'/images/innovator/innovs-benefits.png'"
[arrowIconSrc]="'/images/common/right-arrow-green.svg'"
></app-benefits-section>