<h3 mat-dialog-title class="heading-mon-22">ADD A BEST PRACTICE</h3>
<mat-dialog-content class="mat-typography" [formGroup]="formGroup">
    <div class="row">
        <div class="col-md-9 form-field-margin">
            <label class="form-label fw-semibold"> Best Practice Title </label>

            <input formControlName="title" type="text" class="form-control" placeholder="" />
            <app-input-error-message [formData]="formGroup" controlName="title"></app-input-error-message>
        </div>
        <div class="col-md-3">
            <label for="" class="form-label fw-semibold">Date</label>

            <div class="custom-calender">
                <mat-form-field appearance="fill">
                    <input matInput [matDatepicker]="datePickerRef" formControlName="date" readonly />
                    <mat-datepicker-toggle matSuffix [for]="datePickerRef">
                    </mat-datepicker-toggle>
                    <mat-datepicker #datePickerRef></mat-datepicker>
                </mat-form-field>
            </div>
            <app-input-error-message [formData]="formGroup" controlName="date"></app-input-error-message>
        </div>
        <div class="col-md-12 form-field-margin">
            <label class="form-label fw-semibold">Name of the Organization
            </label>

            <small class="form-text text-muted info-msg" style="margin-bottom: 10px; display: block">
                Subtitle comes here
            </small>
            <textarea formControlName="organizationName" class="form-control" placeholder=""></textarea>
            <app-input-error-message [formData]="formGroup" [controlName]="'organizationName'">
            </app-input-error-message>
            <app-input-error-message [formData]="formGroup" [controlName]="'evidence'">
            </app-input-error-message>
        </div>
        <div class="col-md-12 form-field-margin">
            <label class="form-label fw-semibold">The Challenge</label>
            <small class="form-text text-muted info-msg" style="margin-bottom: 10px; display: block">
                Subtitle comes here
            </small>
            <textarea formControlName="challenge" type="text" class="form-control" placeholder=""></textarea>
            <app-input-error-message [formData]="formGroup" [controlName]="'challenge'">
            </app-input-error-message>
        </div>

        <div class="col-md-12 form-field-margin">
            <label class="form-label fw-semibold">The Solution </label>
            <small class="form-text text-muted info-msg" style="margin-bottom: 10px; display: block">
                Any additional context, insights, or questions related to the
                weak signal
            </small>
            <textarea formControlName="solutionText" type="text" class="form-control" placeholder=""></textarea>
            <app-input-error-message [formData]="formGroup" [controlName]="'solutionText'">
            </app-input-error-message>
        </div>

        <div class="col-md-12 form-field-margin">
            <label class="form-label fw-semibold">Implementation </label>
            <textarea formControlName="implementation" type="text" class="form-control" placeholder=""></textarea>
            <app-input-error-message [formData]="formGroup" [controlName]="'implementation'">
            </app-input-error-message>
        </div>
        <div class="col-md-12 form-field-margin">
            <label class="form-label fw-semibold">Output/Result </label>
            <textarea formControlName="outcome" type="text" class="form-control" placeholder=""></textarea>
            <app-input-error-message [formData]="formGroup" [controlName]="'outcome'">
            </app-input-error-message>
        </div>
        <div class="col-md-12 form-field-margin">
            <label class="form-label fw-semibold">Tags </label>
            <div>
                <input placeholder="New keyword..." [matChipInputFor]="reactiveChipGrid"
                    (matChipInputTokenEnd)="addTagList($event)" />
                <mat-chip-grid #reactiveChipGrid formControlName="tags">
                    @for (tag of tagList(); track $index) {
                    <mat-chip-row (removed)="removeTagList(tag)">
                        {{ tag.title }}
                        <button matChipRemove aria-label="'remove reactive form' + keyword">
                            <span>x</span>
                        </button>
                    </mat-chip-row>
                    }
                </mat-chip-grid>
            </div>
        </div>
    </div>

    <div class="row">
        <!-- Reference Handler for Document Uploads -->
        <app-input-solution-references [formGroup]="formGroup" [showPromptBox]="false"
            [solutionReferences]="solutionReferences"></app-input-solution-references>
    </div>

    <div class="col-md-12 form-field-margin">
        <label class="form-label fw-semibold">Citations </label>
        <textarea formControlName="citations" type="text" class="form-control" placeholder=""></textarea>
        <app-input-error-message [formData]="formGroup" [controlName]="'citations'">
        </app-input-error-message>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="add-new-btn solver-dialog-btn" type="submit" (click)="onSubmitClick()">
        Add Best Practice
    </button>
</mat-dialog-actions>
