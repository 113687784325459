import { Component } from '@angular/core';

@Component({
    selector: 'app-challenge-listing',
    standalone: true,
    imports: [],
    templateUrl: './challenge-listing.component.html',
    styleUrl: './challenge-listing.component.scss',
})
export class ChallengeListingComponent {}
