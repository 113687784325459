<div class="container p-0">
    <h2>
        Challenge Solver Form
    </h2>
        <div class="row">
            <div class="col-md-12" style="margin-bottom: 20px; margin-top: 40px;">
                <h3 class="heading-mon-22" style="margin-bottom: 20px;">SOLUTION OVERVIEW</h3>
                <label class="form-label fw-semibold">Solution Title</label>
                        <input type="text" class="form-control"   >
            </div>
            <div class="col-md-12" style="margin-bottom: 60px;">
                <label class="form-label fw-semibold">Brief Summary</label>
                        <input type="text" class="form-control"   >
            </div>
            <div class="col-md-12" style="margin-bottom: 60px;">
                <h3 class="heading-mon-22">DETAILED SOLUTION</h3>
                <small class="form-text text-muted info-msg">Please provide a comprehensive explanation of your solution. Include your methodology, approach, and any supporting data or calculations.</small>
                        <input type="text" class="form-control"   >
            </div>   
            <div class="col-md-12" style="margin-bottom: 60px;" >
                <h3 class="heading-mon-22">INNOVATION AND UNIQUENESS</h3>
                <small class="form-text text-muted info-msg">Explain what makes your solution innovative or unique compared to existing approaches.</small>
                        <input type="text" class="form-control"   >
            </div>   
            <div class="col-md-12" style="margin-bottom: 60px;">
                <h3 class="heading-mon-22">IMPLEMENTATION PLAN</h3>
                <small class="form-text text-muted info-msg">Outline the steps required to implement your solution. Include timeline, key milestones, and potential challenges</small>  
                <input type="text" class="form-control"   >
            </div>   
            <div class="col-md-12 " style="margin-bottom: 60px;" >
                <h3 class="heading-mon-22">EXPECTED IMPACT</h3>
                <small class="form-text text-muted info-msg">Describe the potential impact of your solution. Consider environmental, social, and economic aspects if applicable</small>
                        <input type="text" class="form-control"   >
            </div> 
            <div class="col-md-12" style="margin-bottom: 60px;">
                <h3 class="heading-mon-22">RESOURCES REQUIRED</h3>
                <small class="form-text text-muted info-msg">Indicate the estimated budget and timeframe for Implementation. Include any other key resources needed</small>
                        <input type="text" class="form-control"   >
            </div> 
            <div class="col-md-12" style="margin-bottom: 60px;">
                <h3 class="heading-mon-22">RESOURCES REQUIRED</h3>
                <small class="form-text text-muted info-msg">Indicate the estimated budget and timeframe for Implementation. Include any other key resources needed</small>
                        <input type="text" class="form-control"   >
            </div> 
        </div>
</div>