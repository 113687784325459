import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';

@Component({
    selector: 'app-quill-editor',
    standalone: true,
    imports: [QuillModule, ReactiveFormsModule, CommonModule],
    templateUrl: './quill-editor.component.html',
    styleUrl: './quill-editor.component.scss',
})
export class QuillEditorComponent {
    @Input() label: string = '';
    @Input() infoText: string = '';
    @Input() controlName: string = '';
    @Input() placeholder: string = '';
    @Input() parentForm!: FormGroup;
    @Input() required: boolean = false;
    @Input() infoTextWord: boolean = false;
    @Input() customClass?: string;

    editorModules = {
        toolbar: [
            // Group - 1
            ['bold', 'italic', 'underline', 'strike'],

            // Group - 2
            ['blockquote', 'code-block', 'link'],

            // Group - 3
            [{ list: 'ordered' }, { list: 'bullet' }],
        ],
    };
}
