<div class="container mt-5">
    <div class="col-md-12 border-bottom ">
        <h6 class="text-start text-color-overview">
            <a (click)="redirectToOverview()" class="cursor-pointer">
                < Back to Overview </a> </h6> </div> <div
                    class="card shadow rounded-start-0 custom-container border-0 container my-5">
                    <div class="card-body" style="padding: 0;">

                        <form class="row" style="padding: 50px; padding-bottom: 40px;">
                            <!-- <div *ngIf="showIntroText" class="mb-5">
                <h1 class="text-start fw-semibold fs-33px text-color">Get In Touch</h1>
                <h1 class="text-start fw-semibold fs-22px">Launch a Challenge or Use Our Crowdsourced Consulting
                    Services</h1
            </div> -->

            <div class="col-md-6 mb-3">
                <label for="firstName" class="form-label fw-semibold fs-15px">First Name<span
                    style="color: #B21818;">*</span></label>
                <input type="text" disabled  class="form-control" id="firstName"  [value]="postInterestId.firstName" />
            </div>
            <div class="col-md-6 mb-3">
                <label for="lastName" class="form-label fw-semibold fs-15px">Last Name<span
                    style="color: #B21818;">*</span></label>
                <input type="text" disabled class="form-control" id="lastName" [value]="postInterestId.lastName" />
            </div>
            <div class="col-md-6 mb-3">
                <label for="email" class="form-label fw-semibold fs-15px">Email<span
                    style="color: #B21818;">*</span></label>
                <input type="email" disabled class="form-control" id="email"  [value]="postInterestId.email" />
            </div>
            <div class="col-md-6 mb-3">
                <label for="designation" class="form-label fw-semibold fs-15px">Designation<span
                    style="color: #B21818;">*</span></label>
                <input type="text" disabled class="form-control" id="designation"  [value]="postInterestId?.designation" />
            </div>
            <div class="col-md-6 mb-3">
                <label for="organizationName" class="form-label fw-semibold fs-15px">Organization<span
                    style="color: #B21818;">*</span></label>
                <input type="text" disabled class="form-control" id="organizationName"  [value]="postInterestId?.organizationName" />
               
            </div>
            <div class="col-md-6 mb-3">
                <label for="organizationUrl" class="form-label fw-semibold fs-15px">Website<span
                    style="color: #B21818;">*</span></label>
                <input type="url" disabled class="form-control" id="organizationUrl"  [value]="postInterestId?.organizationUrl" />
            </div>
            <div class="col-md-12 mb-3">
                <label for="subject" class="form-label fw-semibold fs-15px">Subject<span
                    style="color: #B21818;">*</span></label>
                <input type="text" disabled class="form-control" id="subject"  [value]="postInterestId?.subject"/>
            </div>
            <div class="col-md-12 mb-3">
                <label for="message" class="form-label fw-semibold fs-15px">Message<span
                    style="color: #B21818;">*</span></label>
                <textarea class="form-control" disabled id="message"  rows="3" [value]="postInterestId?.message"  ></textarea>
            </div>
          
        </form>
    </div>
</div>
</div>
