import { Component } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { PasswordInputComponent } from '../../shared/input/password-input/password-input.component';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { AuthService } from '../../core/services/apis/auth.service';
import { InputErrorMessageComponent } from '../../shared/input-error-message/input-error-message.component';

@Component({
    selector: 'app-reset-password',
    standalone: true,
    imports: [ReactiveFormsModule, RouterLink, PasswordInputComponent, InputErrorMessageComponent],
    templateUrl: './reset-password.component.html',
    styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent {
    resetPasswordForm: FormGroup;
    token: string | undefined;

    constructor(
        private fb: FormBuilder,
        protected validationMessageService: ValidationMessageService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private alertService: SweetAlertService,
        private router: Router
    ) {
        this.resetPasswordForm = this.fb.group({
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmNewPassword: [
                '',
                [Validators.required, Validators.minLength(6)],
            ],
        });
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.token = params['token'];
        });
    }

    onResetPassword() {
        this.resetPasswordForm.markAllAsTouched();

        if (this.resetPasswordForm.valid) {
            const newPassword =
                this.resetPasswordForm.get('password')?.value;
            const confirmNewPassword =
                this.resetPasswordForm.get('confirmNewPassword')?.value;

            if (newPassword === confirmNewPassword) {
                if (this.token) {
                    this.authService
                        .resetPassword(this.token, newPassword)
                        .subscribe({
                            next: (response) => {
                                this.alertService.successToaster(
                                    response.message
                                );
                                this.router.navigate(['/login']);
                            },
                            error: (error) => {
                                if (error.status === 422) {
                                    const errors = error?.error?.errors;
                                    if (errors) {
                                        this.validationMessageService.setServerErrors(
                                            this.resetPasswordForm,
                                            errors
                                        );
                                    }
                                } else {
                                    this.alertService.errorToaster(
                                        error.error.message
                                    );
                                }
                            },
                        });
                }
            } else {
                this.alertService.errorToaster(
                    'The new password and re-enter new password do not match. Please try again.'
                );
            }
        }
    }
}
