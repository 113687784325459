<div class="row" style="margin-top: 44px;">
    <div class="col-md-4">
        <app-card [leftText]="'Challenges: Multiverz'" [rightText]="'Others'" [leftNumber]="12" [rightNumber]="2">
        </app-card>
    </div>
    <div class="col-md-4">
        <app-card [leftText]="'Certificates issued'" [rightText]="'Received'" [leftNumber]="300" [rightNumber]="2">
        </app-card>
    </div>
    <div class="col-md-4">
        <app-card [leftText]="'Total Received Questions'" [rightText]="'Asked'" [leftNumber]="2.1"
            [rightNumber]="52">
        </app-card>
    </div>
</div>

<div class="row mb-5">
    <div class="col-md-4">
        <app-card [leftText]="'Total Participants for your Challenges'" [leftNumber]="'23.5M'">
        </app-card>
    </div>
    <div class="col-md-4">
        <app-card [leftText]="'Challenges Participated'" [rightText]="'Submitted'" [leftNumber]="0"
            [rightNumber]="0">
        </app-card>
    </div>
    <div class="col-md-4">
        <app-card [leftText]="'Total Interest Requests Received'" [leftNumber]="9">
        </app-card>
    </div>
</div>