import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BannerSectionComponent } from '../../shared/banner-section/banner-section.component';

@Component({
  selector: 'app-organizations',
  standalone: true,
  imports: [CommonModule, BannerSectionComponent],
  templateUrl: './organizations.component.html',
  styleUrl: './organizations.component.scss'
})
export class OrganizationsComponent {

}
