<!-- Talk to us Page -->
<div class="talk-to-us-wrapper">
    <div class="row">
        <div class="col-md-12 " style="margin-bottom: 42px;">
            <form (submit)="handleSubmit($event)" > 
            <input type="text" class="form-control " placeholder="Ask your question here"
                style="display: inline-block; width: calc(80% - 20px); margin-right: 20px;" [(ngModel)]="qna" name="qna"/>
            <button  class="btn btn-primary rounded-start-0 get-challenge-btn btn-bg-blue-gradient text-start fs-18px fw-bold
                                custom-btn" style="padding-top: 10px !important; padding-bottom: 10px !important;">
                SUBMIT
                <span class="arrow-btn-wrapper"> <img src="/images/home/right-arrow.svg" alt="Arrow" height="20"
                        class="arrow-icon1"></span>
            </button>
        </form>
        <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
    </div>
        
        <!-- <div class="col-md-12 talk-to-us-questions">
            <div class="talk-to-us-que-wrapper">
                <div class="que-number"><span>1</span></div>
                <div>
                    <label class="question">Question comes here as a single line or a multiline
                        text?</label>
                    <p class="ans">Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque
                        inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                        Nemo
                        enim
                        ipsam, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi
                        nesciunt.
                        Neque porro, consectetur, adipisci velit, sed quia non numquam eius modi
                        tempora
                        incidunt ut labore et minima veniam, quis nostrum exercitationem ullam
                        corporis
                        suscipit
                        laboriosam, nisi ut aliquid iure reprehenderit qui in ea voluptate velit
                        esse
                        quam
                        nihil
                        molestiae consequatur, vel illum.</p>
                </div>
            </div>
            <div class="talk-to-us-que-wrapper">
                <div class="que-number"><span>2</span></div>
                <div>
                    <label class="question">Question comes here as a single line or a multiline
                        text?</label>
                    <p class="ans">Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque
                        inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                        Nemo
                        enim
                        ipsam, sed quia consequuntur magni dolores eos qui ratione voluptatem
                        sequi
                        nesciunt.
                        Neque porro, consectetur, adipisci velit, sed quia non numquam eius modi
                        tempora
                        incidunt ut labore et minima veniam, quis nostrum exercitationem ullam
                        corporis
                        suscipit
                        laboriosam, nisi ut aliquid iure reprehenderit qui in ea voluptate velit
                        esse
                        quam
                        nihil
                        molestiae consequatur, vel illum.</p>
                </div>
            </div>
        </div> -->
    </div>
</div>
