import {
    Component,
    EventEmitter,
    inject,
    Output,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReferenceHandlerUploadsComponent } from '../../shared/input-solution-references/reference-handler-uploads/reference-handler-uploads.component';
import { SolverFlowService } from '../../core/services/apis/solver-flow.service';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { solverStep5Dto } from '../../core/Model/solver-step5.dto';
import { QuillEditorComponent } from '../../shared/quill-editor/quill-editor.component';
import { InputErrorMessageComponent } from '../../shared/input-error-message/input-error-message.component';
import { firstValueFrom } from 'rxjs';
import { SolverFormStoreService } from '../store/solver-form-store.service';
import { ReferenceHandlerComponent } from '../../shared/input-solution-references/reference-handler/reference-handler.component';
import { InputSolutionReferencesComponent } from '../../shared/input-solution-references/input-solution-references.component';
import { SolutionReferences } from '../../shared/input-solution-references/types/input-solution-references-types';

@Component({
    selector: 'app-solver-flow-step-5',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        QuillEditorComponent,
        InputErrorMessageComponent,
        ReferenceHandlerComponent,
        ReferenceHandlerUploadsComponent,
        InputSolutionReferencesComponent,
    ],
    templateUrl: './solver-flow-step-5.component.html',
    styleUrl: './solver-flow-step-5.component.scss',
})
export class SolverFlowStep5Component {
    solverFormStep5!: FormGroup;
    solutionReferences: SolutionReferences = {} as SolutionReferences;
    @Output() successEvent = new EventEmitter<void>();
    route: ActivatedRoute = inject(ActivatedRoute);
    @ViewChild(ReferenceHandlerComponent)
    referenceHandlerComponent!: ReferenceHandlerComponent;
    @ViewChild(ReferenceHandlerUploadsComponent)
    referenceHandlerUploadsComponent!: ReferenceHandlerUploadsComponent;

    constructor(
        private fb: FormBuilder,
        private solverFlowService: SolverFlowService,
        private alertService: SweetAlertService,
        protected validationMessageService: ValidationMessageService,
        public readonly solverFormStoreService: SolverFormStoreService
    ) {
        this.step5Form();
    }

    step5Form() {
        this.solverFormStep5 = this.fb.group({
            keyInsights: ['', Validators.required],
            opportunitySpaces: ['', Validators.required],
            riskResilience: ['', Validators.required],
            innovationPathways: ['', Validators.required],
            quickLongTermStrategies: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.loadSoutionAndData();
    }
    private async loadSoutionAndData(): Promise<void> {
        try {
            const solutionId = await firstValueFrom(
                this.solverFormStoreService.selectSolutionId$
            );
            if (solutionId) {
                this.loadStep5Data(solutionId);
            } else {
                console.error('Solution ID is missing.');
            }
        } catch (error) {
            console.error('Error retrieving solution ID:', error);
        }
    }

    loadStep5Data(solutionId: number): void {
        if (solutionId) {
            this.solverFlowService
                .getProblemExplorerStep5(solutionId)
                .subscribe({
                    next: (data) => {
                        this.patchForm(data);
                    },
                    error: (error: HttpErrorResponse) => {
                        this.alertService.errorPopup(error.error.message);
                        console.error(
                            'Error loading problem explorer data:',
                            error
                        );
                    },
                });
        } else {
            console.error('Challenge ID is missing.');
        }
    }

    patchForm(data: any) {
        this.solverFormStep5.patchValue({
            keyInsights: data.keyInsights || '',
            opportunitySpaces: data.opportunitySpaces || '',
            riskResilience: data.riskResilience || '',
            innovationPathways: data.innovationPathways || '',
            quickLongTermStrategies: data.quickLongTermStrategies || '',
        });
        this.solutionReferences = data.solutionReferences;

        // Update reference URLs
        if (data.referenceUrls) {
            const referenceHandler = this.referenceHandlerComponent; // Reference to your shared component
            // TODO: need to check this
            // referenceHandler.referenceUrls = data.referenceUrls;
            // Make sure to trigger change detection or any necessary updates in the component
        }

        // Update uploaded files
        if (data.media) {
            // const referenceHandlerUploads =
            //     this.referenceHandlerUploadsComponent; // Reference to your shared component
            // referenceHandlerUploads.uploadedFiles = data.media.map(
            //     (fileName: string) => {
            //         // Simulate file objects or fetch actual file objects if possible
            //         return { name: fileName } as File;
            //     }
            // );
            // Make sure to trigger change detection or any necessary updates in the component
        }
    }

    async onSubmit(isDraft?: boolean) {
        if (!isDraft) {
            this.solverFormStep5.markAllAsTouched();
        }
        const challengeId = await firstValueFrom(
            this.solverFormStoreService.selectChallengeId$
        );
        const solutionId = await firstValueFrom(
            this.solverFormStoreService.selectSolutionId$
        );
        if (this.solverFormStep5.valid || isDraft) {
            const formValues = this.solverFormStep5.value;
            // TODO: need to check this
            // const referenceUrls = this.referenceHandlerComponent.referenceUrls;
            // const referenceUploads =
            //     this.referenceHandlerUploadsComponent.uploadedFileNames;
            const solverStep5Data: solverStep5Dto = {
                keyInsights: formValues.keyInsights,
                opportunitySpaces: formValues.opportunitySpaces,
                riskResilience: formValues.riskResilience,
                innovationPathways: formValues.innovationPathways,
                quickLongTermStrategies: formValues.quickLongTermStrategies,
                solutionReferences: formValues.solutionReferences,
                // referenceUrl: referenceUrls,
                // referenceUpload: referenceUploads,
            };
            this.solverFlowService
                .updateproblemExplorerStep5(
                    solverStep5Data,
                    challengeId,
                    solutionId,
                    isDraft
                )
                .subscribe({
                    next: (response) => {
                        this.successEvent.emit();
                    },
                    error: (error: HttpErrorResponse) => {
                        // Hnadle the error response
                        if (error.status === 422) {
                            const errors = error?.error?.errors;
                            if (errors) {
                                this.validationMessageService.setServerErrors(
                                    this.solverFormStep5,
                                    errors
                                );
                            }
                        } else {
                            this.alertService.errorPopup(
                                error.error.message ||
                                    'Error submitting form, please try again'
                            );
                        }
                    },
                });
        }
    }
}
