<div class="container">
    <form [formGroup]="solverFormStep9">
        <div style="margin-bottom: 50px">
            <h3 class="heading-mon-22">WRITE EXECUTIVE SUMMARY HERE</h3>
            <!-- Add quill editor here -->
            <app-quill-editor controlName="executiveSummary" [parentForm]="solverFormStep9">
            </app-quill-editor>
            <app-input-error-message [formData]="solverFormStep9" [controlName]="'executiveSummary'">
            </app-input-error-message>
        </div>
        <h3 class="add-or-heading">AND / OR</h3>
        <div style="margin-bottom: 70px;">
            <h3 class="heading-mon-22">CREATE A FUTURE PRESS RELEASE</h3>
            <app-quill-editor controlName="futurePressRelease" [parentForm]="solverFormStep9">
            </app-quill-editor>
            <app-input-error-message [formData]="solverFormStep9" [controlName]="'futurePressRelease'">
            </app-input-error-message>
        </div>
        <!-- Error message for custom validator -->
        <div *ngIf="
                solverFormStep9.hasError('atLeastOneRequired') &&
                solverFormStep9.touched
            ">
            <span class="text-danger">At least one of Section 1 or Section 2 must be filled.</span>
        </div>
        <app-input-solution-references [formGroup]="solverFormStep9" [solutionReferences]="solutionReferences">
        </app-input-solution-references>
    </form>
</div>
