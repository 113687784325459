import { Component, inject, Input } from '@angular/core';
import { ChallengeService } from '../../../core/services/apis/challenge.service';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Route } from '@angular/router';
import { SweetAlertService } from '../../../core/services/sweet-alert.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-challenge-details-talktous',
  standalone: true,
  imports: [FormsModule,CommonModule],
  templateUrl: './challenge-details-talktous.component.html',
  styleUrl: './challenge-details-talktous.component.scss'
})
export class ChallengeDetailsTalktousComponent {
  @Input() challenge:any;
  route:ActivatedRoute=inject(ActivatedRoute);
  challengeService=inject(ChallengeService)
  SweetAlertService:SweetAlertService = inject(SweetAlertService);
  challengeId:any
  constructor(){

  }


  qna:string=''
  errorMessage:string=''
 
  handleSubmit(event: Event): void {
    event.preventDefault();

    if (!this.qna.trim()) {
      this.errorMessage = 'Please enter a question before submitting.';
    } else {
      this.errorMessage = '';
      // Proceed with form submission
      console.log('qna', this.qna);
    this.challengeService.postQuestion(this.challenge.id, { title: this.qna })
      .subscribe({
        next:(data: any) => {
          console.log('qna posted', data);
          this.qna = '';
          this.SweetAlertService.successToaster(data.message);
        },
        error:(error: any) => {
          console.error('Error posting question', error);
          this.SweetAlertService.errorToaster(error.error.message);
        }
      }
        
      );
    }
    event.preventDefault(); // Prevent default form submission
    
  }



}
