import { Component } from '@angular/core';
import { PasswordInputComponent } from '../../shared/input/password-input/password-input.component';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { ProfileService } from '../../core/services/apis/profile.service';
import { InputErrorMessageComponent } from '../../shared/input-error-message/input-error-message.component';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { SweetAlertService } from '../../core/services/sweet-alert.service';

@Component({
    selector: 'app-change-password',
    standalone: true,
    imports: [
        PasswordInputComponent,
        ReactiveFormsModule,
        InputErrorMessageComponent,
    ],
    templateUrl: './change-password.component.html',
    styleUrl: './change-password.component.scss',
})
export class ChangePasswordComponent {
    changePasswordForm: FormGroup;

    constructor(
        private fb: FormBuilder,
        private profileService: ProfileService,
        private validationMessageService: ValidationMessageService,
        private alertService: SweetAlertService
    ) {
        this.changePasswordForm = this.fb.group({
            currentPassword: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            newPassword: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            confirmNewPassword: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
        });
    }

    onSubmit() {
        this.changePasswordForm.markAllAsTouched();

        if (this.changePasswordForm.valid) {
            this.profileService
                .changePassword(this.changePasswordForm.value)
                .subscribe({
                    next: (response) => {
                    this.alertService.successPopup(response.message);
                    },
                    error: (error) => {
                        if (error.status == 422) {
                            const errors = error?.error?.errors;
                            if (errors) {
                                this.validationMessageService.setServerErrors(
                                    this.changePasswordForm,
                                    errors
                                );
                            }
                        } else {
                            this.alertService.errorPopup(error.error.message);
                        }
                    },
                });
        }
    }
}
