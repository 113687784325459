import { Component, EventEmitter, inject, Output } from '@angular/core';
import { QuillEditorComponent } from '../quill-editor/quill-editor.component';
import { InputErrorMessageComponent } from '../input-error-message/input-error-message.component';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LaunchChallengeState } from '../../core/store/launchChallengeForm/launchChallenge.state';
import { Store } from '@ngrx/store';
import { ChallengeResources } from '../../core/Model/challenge-resource.dto';
import {
    selectChallengeId,
    selectResourcesError,
} from '../../core/store/launchChallengeForm/launchChallenge.selectors';
import { of, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { updateResources } from '../../core/store/launchChallengeForm/launchChallenge.actions';
import { S3UploadService } from '../../core/services/apis/s3-upload.service';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { ChallengeService } from '../../core/services/apis/challenge.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-resources-form',
    standalone: true,
    imports: [
        QuillEditorComponent,
        InputErrorMessageComponent,
        ReactiveFormsModule,
        CommonModule,
    ],
    templateUrl: './resources-form.component.html',
    styleUrl: './resources-form.component.scss',
})
export class ResourcesFormComponent {
    resourcesForm!: FormGroup;
    referenceUrls: string[] = [];
    uploadedFiles: File[] = [];
    challengeId: any;
    maxUrlsReached: boolean = false;
    route: ActivatedRoute = inject(ActivatedRoute);
    @Output() successEvent = new EventEmitter<void>();

    constructor(
        private fb: FormBuilder,
        private store: Store<{ launchChallenge: LaunchChallengeState }>,
        private imageUploadService: S3UploadService,
        private alertService: SweetAlertService,
        private validationMessageService: ValidationMessageService,
        private challengeService: ChallengeService
    ) {
        this.resourcesForm = this.fb.group({
            note: [''],
            referenceUrl: [
                '',
                [
                    Validators.pattern(/^(https?:\/\/|www\.)[\w\-]+(\.[\w\-]+)+([#?&].*)?$/),
                    Validators.maxLength(2048)
                    // Validators.pattern(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/),
                ],
            ],
            media: ['', this.maxFilesValidator.bind(this)],
        });
        // this.challengeId =this.route.snapshot.queryParams['id'];
    }

    ngOnInit() {
        this.challengeId = this.route.snapshot.queryParams['id'];
        if (this.challengeId) {
            this.loadResources();
        }
    }

    addReferenceUrl() {
        const referenceUrlControl = this.resourcesForm.get('referenceUrl');

        if (referenceUrlControl && referenceUrlControl.valid) {
            const url = referenceUrlControl.value;

            if (this.referenceUrls.length >= 10) {
                // this.alertService.errorToaster('You can only add up to 10 URLs.');
                this.maxUrlsReached = true;
                return;
            } else {
                this.maxUrlsReached = false;
                if (url && !this.referenceUrls.includes(url)) {
                    this.referenceUrls = [...this.referenceUrls, url];
                    referenceUrlControl.reset();
                } else {
                    // this.alertService.errorToaster(
                    //     'Cannot add the same reference URL twice.'
                    // );
                }
            }
        } else {
            referenceUrlControl?.markAsTouched();
        }
    }

    removeReferenceUrl(index: number) {
        this.referenceUrls = this.referenceUrls.filter((_, i) => i !== index);
    }

    maxFilesValidator(
        control: AbstractControl
    ): { [key: string]: boolean } | null {
        if (this.uploadedFiles.length > 10) {
            return { maxFilesExceeded: true };
        }
        return null;
    }

    onFileChange(event: any) {
        const files = event.target.files;
        const maxFileSize = 10 * 1024 * 1024; // 10 MB in bytes

        const totalFiles = this.uploadedFiles.length + files.length;

        if (totalFiles > 10) {
            // Set the validation error on the form control
            this.resourcesForm
                .get('media')
                ?.setErrors({ maxFilesExceeded: true });
            return;
        } else {
            // Clear the validation error if under the limit
            this.resourcesForm.get('media')?.setErrors(null);
        }
        // if (this.uploadedFiles.length + files.length > 10) {
        //     // Show an error message if more than 10 files are selected
        //     this.alertService.errorToaster('You can upload a maximum of 10 documents.');
        //     return;
        // }
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            // Check if the file exceeds 10mb
            if (file.size > maxFileSize) {
                // Set the validation error on the form control
                this.resourcesForm
                    .get('media')
                    ?.setErrors({ maxFileSizeExceeded: true });
                return;
            }

            // Check if the file is a PDF
            if (file.type !== 'application/pdf') {
                // Set the validation error for file type
                this.resourcesForm
                    .get('media')
                    ?.setErrors({ invalidFileType: true });
                return;
            }
            if (!this.uploadedFiles.some((f) => f.name === file.name)) {
                this.uploadedFiles.push(file);
                // Automatically upload files upon selection
                this.uploadFileToS3(file);
            }
        }
    }

    uploadFileToS3(file: File) {
        const folderPath = 'challenge-resources';
        const fileName = file.name;
        const fileType = file.type;

        this.imageUploadService
            .generateSignedUrl({ folderPath, fileName, fileType })
            .subscribe((response) => {
                const signedUrl = response.signedUrl;
                fetch(signedUrl, {
                    method: 'PUT',
                    headers: { 'Content-Type': fileType },
                    body: file,
                }).then((updateResources) => {
                    if (updateResources.ok) {
                        this.alertService.successToaster(
                            'Document upload Successfully'
                        );
                        this.resourcesForm.get('media')?.setValue(signedUrl);
                    } else {
                        this.alertService.errorToaster(
                            'Document upload failed'
                        );
                    }
                });
            });
    }

    removeFile(index: number) {
        this.uploadedFiles = this.uploadedFiles.filter((_, i) => i !== index);
        // Check if the total files are within the limit after removal

        if (
            this.uploadedFiles.length <= 10 &&
            this.uploadedFiles.every((file) => file.size <= 10 * 1024 * 1024)
        ) {
            this.resourcesForm.get('media')?.setErrors(null);
        }
    }

    // onSubmit() {
    //     this.resourcesForm.markAllAsTouched();
    //     if (this.resourcesForm.valid) {
    //       const formValue = this.resourcesForm.value;

    //       // Map form values to the DTO
    //       const challengeResources: ChallengeResources = {
    //           note: formValue.note,
    //           referenceUrls: this.referenceUrls,
    //           media: this.uploadedFiles.map(file => file.name),
    //       };

    //       this.store
    //             .select(selectChallengeId)
    //             .pipe(
    //                 take(1)).subscribe((challengeId) => {
    //                     if (challengeId) {
    //                         this.store.dispatch(
    //                             updateResources({
    //                                 challengeId,
    //                                 data: challengeResources,
    //                             })
    //                         );
    //                         this.store
    //                         .pipe(
    //                             withLatestFrom(
    //                                 this.store.select(selectResourcesError)
    //                             ),
    //                             tap(([_, error]) => {
    //                                 if (error) {
    //                                     this.validationMessageService.setServerErrors(
    //                                         this.resourcesForm,
    //                                         error
    //                                     );
    //                                 }
    //                             })
    //                         )
    //                         .subscribe();
    //                     }

    //                 });

    //     }
    // }

    loadResources(): void {
        const challengeId = this.challengeId; // Replace with the actual challengeId

        this.challengeService.getResources(challengeId).subscribe({
            next: (data: any) => {
                this.populateResourcesForm(data);
            },
            error: (error: HttpErrorResponse) => {
                // this.alertService.errorPopup('Error loading resources.');
                console.error('Error fetching resources:', error);
            },
        });
    }
    populateResourcesForm(data: any): void {
        // Set form values
        this.resourcesForm.patchValue({
            note: data.note,
            // referenceUrl: data.referenceUrls
            //     ? data.referenceUrls.join(', ')
            //     : '',
            media: data.media ? data.media.join(', ') : '',
        });

        // Update referenceUrls and file names
        this.referenceUrls = data.referenceUrls || [];

        // Update uploadedFiles array
        // Assume `data.media` contains file names, but we need to re-fetch the files if necessary
        if (data.media && data.media.length > 0) {
            this.uploadedFiles = data.media.map((fileName: string) => {
                // If you have a way to retrieve the file objects, use it. Otherwise, this is just for display.
                // If files are not available on the client-side, you might need to re-fetch or simulate file objects.
                return { name: fileName } as File; // Placeholder for demonstration
            });
        }
    }

    onSubmit(): void {
        this.resourcesForm.markAllAsTouched();
        if (this.resourcesForm.valid) {
            const formValue = this.resourcesForm.value;
            // Map form values to the DTO
            const challengeResources: ChallengeResources = {
                note: formValue.note,
                referenceUrls: this.referenceUrls,
                media: this.uploadedFiles.map((file) => file.name),
            };

            const challengeId = this.route.snapshot.queryParams['id']; // Replace with the actual challengeId

            this.challengeService
                .updateResources(challengeId, challengeResources)
                .subscribe({
                    next: (response: any) => {
                        this.alertService.successPopup(response.message);
                        this.successEvent.emit();
                    },
                    error: (error: HttpErrorResponse) => {
                        if (error.status === 422) {
                            const errors = error.error.errors;
                            if (errors) {
                                this.validationMessageService.setServerErrors(
                                    this.resourcesForm,
                                    errors
                                );
                            }
                        } else {
                            this.alertService.errorPopup(error.error.message);
                        }
                    },
                });
        }
    }
}
