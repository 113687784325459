export const guideLineContents = [
    {
        step1: {
            beforeYouStart: [
                'Take a few minutes to familiarize yourself with the form and its categories.',
                'Identify the problem you want to analyze and gain a clear understanding of its context.',
                'Gather any relevant information, data, or resources that may help complete the form.',
            ],
            fillingOutTheForm: [
                'Start by answering the questions in each category in sequence. However, feel free to jump back and forth between categories as needed.',
                'Be as specific and detailed as possible in your answers. Avoid vague or general responses which lead to a unsuccessful submission.',
                'Use the questions as prompts to stimulate your thinking and exploration of the problem. Feel free to add notes or comments if they are relevant.',
                `If you're unsure or don't have an answer to a question, write it as "Unknown" or "To Be Determined" and you can come back to it later.`,
            ],
            someTipsForYou: [
                'Take your time and be thorough. The Phoenix Checklist is a comprehensive tool; rushing through it may lead to incomplete or superficial analysis.',
                'Use diagrams, flowcharts, or mind maps to visualize the problem and its components, especially for Problem Analysis and Solution Development.',
                `Don't be afraid to challenge your assumptions and biases. The Phoenix Checklist is designed to help you think outside the box and alternative perspectives.`,
                'Consider involving a team or colleagues in filling out the form. Collaborative analysis can lead to more comprehensive and innovative solutions.',
                'Review and revise your answers as needed. The Phoenix Checklist is an iterative process, and your understanding of the problem may evolve.',
            ],
            aftrYouAreDone: [
                'Take a step back and review your completed form. Look for patterns, connections, and insights that may have emerged during the analysis.',
                'Identify key areas that require further exploration or research.',
                `Use the form's outputs to develop a plan of action, prioritize the next steps, and allocate resources accordingly.`,
                'Consider revisiting the form periodically to reassess the problem and track progress toward a solution.',
                'You can effectively use the Phoenix Checklist Form to analyze and understand complex problems, identify innovative solutions, and develop a roadmap for implementation.',
            ],
        },
    },
    {
        step2: {
            beforYouStart: [
                'Clearly define what aspects of the project statement require best practice research.',
                `List keywords that are specific to the project to use in your searches.`,
                'Choose databases and sources known for their credibility and thorough reporting.',
            ],
            fillingOutTheForm: [''],
            someTipsForYou: [
                'Focus on the most relevant and recent best practices to ensure up-to-date information.',
                `Evaluate the relevance and adaptability of each practice to the project scenario.`,
                `Use a digital tool or spreadsheet to systematically organize the information you collect for easy reference and comparison.`,
                `Identify common themes, compare and contrast approaches, and discuss how the practices could be adapted or combined for the project's challenges.`,
            ],
            aftrYouAreDone: [
                'Review the compiled best practices to ensure completeness and accuracy.',
                'Reflect on how these practices can be tailored or combined to address specific points in the project.',
                `Reflect on how these practices can be tailored or combined to address specific points in the project.`,
            ],
        },
    },
    {
        step3: {
            beforYouStart: [
                'Consider signals that may seem unusual or unrelated to your current focus.',
                `Don't just focus on prominent news headlines or popular trends. Explore niche sources, academic research, and social media platforms.`,
                `Distinguish between genuine weak signals and mere noise or distractions.`,
                `Include relevant background information, such as the source of the signal, the date, and any relevant quotes or images.`,
                `Evaluate the signal's probability, impact, and uncertainty and consider its potential implications for your organization or domain.`,
                `Look for relationships between weak signals and consider how they might interact or converge in the future.`,
            ],

            someTipsForYou: [
                'Monitor social media, online forums, and specialized news sources',
                'Engage with experts, researchers, and innovators in various fields',
                'Attend conferences, workshops, and seminars to stay informed about emerging trends',
                'Conduct regular horizon scanning exercises to stay alert to potential weak signals',
                'Encourage a culture of curiosity and open-mindedness within your organization',
            ],
            domain: [
                {
                    title: 'POLITICAL AND ECONOMIC SYSTEMS',
                    subDomain: [
                        {
                            title: 'Geo-politics',
                            content:
                                'Shifting global power dynamics, alliances, and conflicts shaping the world order.',
                        },
                        {
                            title: 'Economics',
                            content:
                                'Macroeconomic trends, financial systems, and economic policies influencing global markets',
                        },
                        {
                            title: 'Governance',
                            content:
                                'Evolution of political systems, laws and regulations, decision-making processes',
                        },
                    ],
                },
                {
                    title: 'SOCIAL AND CULTURAL TRENDS',
                    subDomain: [
                        {
                            title: 'Society',
                            content:
                                'Changing social structures, norms, and behaviors affecting human interactions and relationships',
                        },
                        {
                            title: 'Culture',
                            content:
                                'Evolving cultural expressions, traditions, and identities across diverse communities',
                        },
                        {
                            title: 'Values and Ethics',
                            content:
                                'Shifting moral, ethical, and philosophical beliefs guiding individual and collective actions',
                        },
                        {
                            title: 'Demographics',
                            content:
                                'Population dynamics, age structures, migration patterns, and their societal implications',
                        },
                    ],
                },
                {
                    title: 'TECHNOLOGICAL ADVANCEMENTS',
                    subDomain: [
                        {
                            title: 'Technology',
                            content:
                                'Rapid development and adoption of emerging technologies transforming various sectors',
                        },
                        {
                            title: 'Science',
                            content:
                                'Scientific discoveries, research breakthroughs, and their potential applications and impacts',
                        },
                    ],
                },
                {
                    title: 'ENVIRONMENTAL AND PHYSICAL SYSTEMS',
                    subDomain: [
                        {
                            title: 'Environment',
                            content:
                                'Climate change, natural resource management, and ecological sustainability challenges',
                        },
                        {
                            title: 'Infrastructure',
                            content:
                                'Development and maintenance of digital infrastructure, transportation, energy, water, and other critical systems that underpin modern society',
                        },
                    ],
                },
                {
                    title: 'HUMAN SYSTEMS',
                    subDomain: [
                        {
                            title: 'Health and Wellness',
                            content:
                                'Climate change, natural resource management, and ecological sustainability challenges',
                        },
                        {
                            title: 'Infrastructure',
                            content:
                                'Development and maintenance of digital infrastructure, transportation, energy, water, and other critical systems that underpin modern society',
                        },
                        {
                            title: 'Future of Work',
                            content:
                                'Evolving nature of jobs, workforce dynamics, organizational structures, and the impact of automation and AI',
                        },
                    ],
                },
                {
                    title: 'BUSINESS AND INDUSTRY',
                    subDomain: [
                        {
                            title: 'Innovation and Entrepreneurship',
                            content:
                                'Emergence of new business ideas and models, startups, and entrepreneurial ecosystems',
                        },
                        {
                            title: 'Industry 4.0',
                            content:
                                'Integration of advanced technologies in manufacturing and industrial processes',
                        },
                        {
                            title: 'Sustainable Business Practices',
                            content:
                                'Adoption of environmentally friendly and socially responsible business models',
                        },
                    ],
                },
                {
                    title: 'MEDIA',
                    subDomain: [
                        {
                            title: 'Digital Media and Platforms',
                            content:
                                'Growth of online media, social networks, and content distribution channels',
                        },
                        {
                            title: 'Misinformation and Fact-checking',
                            content:
                                'Challenges in combating fake news, propaganda, and ensuring information accuracy',
                        },
                        {
                            title: 'Immersive Media',
                            content:
                                'Advancements in virtual reality, augmented reality, and mixed reality technologies',
                        },
                    ],
                },
            ],
        },
    },
];
