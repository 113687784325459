import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Step7ActionableEditDialogComponent } from '../components/step-7-actionable-edit-dialog/step-7-actionable-edit-dialog.component';
import { CommonModule } from '@angular/common';
import { CommonUtilities } from '../../core/utilities/common-utilities';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { SolverFormStoreService } from '../store/solver-form-store.service';
import { firstValueFrom, Observable } from 'rxjs';
import { InputSolutionReferencesComponent } from '../../shared/input-solution-references/input-solution-references.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SolutionReferences } from '../../shared/input-solution-references/types/input-solution-references-types';
import {
    HighImpactInitiative,
    SolutionTermOpportunity,
    TermActionable,
} from '../types/solution-form-6-type';
import { SolverFlowService } from '../../core/services/apis/solver-flow.service';

@Component({
    selector: 'app-solver-flow-step-7-impact-initiatives',
    standalone: true,
    imports: [CommonModule, MatDialogModule, InputSolutionReferencesComponent],
    templateUrl: './solver-flow-step-7-impact-initiatives.component.html',
    styleUrl: './solver-flow-step-7-impact-initiatives.component.scss',
})
export class SolverFlowStep7ImpactInitiativesComponent implements OnInit {
    @Output() successEvent = new EventEmitter<void>();
    solutionReferences: SolutionReferences = {} as SolutionReferences;

    formGroup!: FormGroup;
    public impactInitiatives: any[] = [
        {
            id: '1',
            title: 'Actionable title comes here as a single line such that a long title can be accommodated here with a character limit of 120 characters',
            tasks: [],
        },
    ];
    public initiativeDetails = [
        {
            id: 'nearTerm',
            idea: 'idea',
            termType: 'NEAR',
            actionableList: [...this.impactInitiatives],
        },
        {
            id: 'nextTerm',
            idea: 'idea',
            termType: 'MEDIUM',
            actionableList: [...this.impactInitiatives],
        },
        {
            id: 'longTerm',
            idea: 'idea',
            termType: 'LONG',
            actionableList: [...this.impactInitiatives],
        },
    ];

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly matDialog: MatDialog,
        private readonly sweetAlertService: SweetAlertService,
        public readonly solverFormStoreService: SolverFormStoreService,
        private readonly solverFlowService: SolverFlowService
    ) {
        this.formGroup = this.formBuilder.group({});
    }

    ngOnInit(): void {
        this.solverFormStoreService.selectForm7$.subscribe((form7) => {
            if (form7?.solutionReferences) {
                this.solutionReferences = form7.solutionReferences;
            }
        });
    }

    async onItemClick(
        initiative: SolutionTermOpportunity,
        actionable: TermActionable
    ) {
        const solutionId = await firstValueFrom(
            this.solverFormStoreService.selectSolutionId$
        );
        this.matDialog
            .open(
                Step7ActionableEditDialogComponent,
                CommonUtilities.getDialogConfig({
                    solutionId,
                    data: actionable,
                })
            )
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    const findIndex = initiative.termActionables.findIndex(
                        (item: any) => item.id === actionable.id
                    );
                    if (findIndex > -1) {
                        initiative.termActionables[findIndex] = {
                            ...actionable,
                            highImpactInitiative: result,
                        };
                    }
                }
            });
    }

    async onSubmit(isDraft?: boolean) {
        const initiativeDetails = await firstValueFrom(
            this.solverFormStoreService.selectForm7$
        );
        const isTaskEmpty = initiativeDetails?.highPriorityTermActionable.some(
            (item) =>
                item.termActionables.some(
                    (actionable: any) =>
                        !(
                            actionable.highImpactInitiative as HighImpactInitiative
                        )?.immediateActions?.length
                )
        );
        if (isTaskEmpty && !isDraft) {
            this.sweetAlertService.errorToaster(
                'Please fill all high priority actionable fields'
            );
        } else {
            const request = this.formGroup.value;
            const solutionId = await firstValueFrom(
                this.solverFormStoreService.selectSolutionId$
            );
            this.solverFlowService
                .updateSolutionForm7(solutionId as number, request, isDraft)
                .subscribe({
                    next: () => {
                        this.solverFormStoreService.getSolutionDetails();
                        this.successEvent.emit();
                    },
                    error: (error) => {
                        this.sweetAlertService.errorToaster(
                            error.error.message ||
                                'Error submitting form, please try again'
                        );
                    },
                });
        }
    }

    hasHighPriorityActionables(item: any): boolean {
        return item.termActionables.some(
            (actionable: any) => actionable.priority === 'HIGH'
        );
    }
}
