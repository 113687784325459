import { NgClass,DatePipe } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { time } from 'console';

@Component({
  selector: 'app-challenge-details-timeline',
  standalone: true,
  imports: [NgClass,DatePipe],
  providers: [DatePipe],
  templateUrl: './challenge-details-timeline.component.html',
  styleUrl: './challenge-details-timeline.component.scss'
})
export class ChallengeDetailsTimelineComponent {
 timeline:any = [];
@Input() challenge :any= {};
constructor(private datePipe: DatePipe) {
  // this.timeline = this.challenge.timeline;

}
upcomingEvents: any[] = [];
upcomingEventDate: string | undefined;
updatedTimeline: any[] = [];
ngOnChanges(changes: SimpleChanges): void {
  if (changes['challenge'] && this.challenge) {
    this.timeline = this.challenge.timeline;
  }
  this.upcomingEvents = this.timeline.filter((event: any) => new Date(event.date) > new Date());

  // Set upcoming event date
  this.upcomingEventDate = this.upcomingEvents[0]?.date;

  // Update timeline with upcomingEvent property
   // Update timeline with upcomingEvent property
   this.updatedTimeline = this.timeline.map((event: any) => ({
    ...event,
    formattedDate: this.datePipe.transform(event.date, 'MMM d yyyy, h:mm a z', 'Asia/Kolkata'),
    upcomingEvent: event.date === this.upcomingEventDate
  }));

}
// filter past date from timeline
// upcomingEvents=this.timeline.filter((event:any)=>new Date(event.date)>new Date())
// set upcoming event date
// upcomingEventDate=this.upcomingEvents[0]?.date
// set property upcomingEvent in timeline array of objects
// updatedTimeline=this.timeline.map((event:any)=>({...event,upcomingEvent:event.date===this.upcomingEventDate}))
ngOnInit(): void {

}

}
