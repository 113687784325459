import { CommonModule } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    input,
    Input,
    Output,
} from '@angular/core';
import {
    FormGroup,
    FormBuilder,
    FormControl,
    ReactiveFormsModule,
    Validators,
    ValidatorFn,
    AbstractControl,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { QuillModule } from 'ngx-quill';
import { ChallengeService } from '../../core/services/apis/challenge.service';
import {
    ValidationMessageService,
    wordCountValidator,
} from '../../core/services/validation-message.service';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { InputErrorMessageComponent } from '../input-error-message/input-error-message.component';
import {
    catchError,
    filter,
    map,
    Observable,
    of,
    Subject,
    take,
    tap,
} from 'rxjs';
import { ChallengeRequestDto } from '../../core/Model/challenge-request.dto';
import { provideStore, Store } from '@ngrx/store';
import * as LaunchChallengeActions from '../../core/store/launchChallengeForm/launchChallenge.actions';
import { LaunchChallengeState } from '../../core/store/launchChallengeForm/launchChallenge.state';
import { launchChallengeReducer } from '../../core/store/launchChallengeForm/launchChallenge.reducer';
import { provideEffects } from '@ngrx/effects';
import { LaunchChallengeEffects } from '../../core/store/launchChallengeForm/launchChallenge.effects';
import { Category } from '../../core/Model/category';
import { challengeOwner } from '../../core/Model/challenge-owner';
import { challengeBasicInformationDto } from '../../core/Model/challenges-basic-information.dto';
import { title } from 'process';
import { OrganizationTypes } from '../../core/Model/organization-type.dto';
import { QuillEditorComponent } from '../quill-editor/quill-editor.component';
import { S3UploadService } from '../../core/services/apis/s3-upload.service';
import { response } from 'express';
import { HttpErrorResponse } from '@angular/common/http';
import {
    selectBasicInformationError,
    selectBasicInformationSuccess,
    selectChallengeId,
} from '../../core/store/launchChallengeForm/launchChallenge.selectors';
import { withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-basic-information-form',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        CommonModule,
        NgSelectModule,
        QuillModule,
        InputErrorMessageComponent,
        QuillEditorComponent,
    ],
    templateUrl: './basic-information-form.component.html',
    styleUrl: './basic-information-form.component.scss',
})
export class BasicInformationFormComponent {
    basicInformationForm!: FormGroup;
    showOtherOrganizationInput = false;
    multiverzOrgId: any;
    multiverzOrgIdSubject = new Subject<number | null>();
    isInputDisabled = false;
    challengeOwners: challengeOwner[] = [];
    categories: Category[] = [];
    organisationsType: OrganizationTypes[] = [];
    loading$: Observable<boolean>;
    error$: Observable<any>;
    basicInformation$: Observable<challengeBasicInformationDto | null | any>;
    challengeId$: Observable<string | null>;
    selectedImage: File | null = null;
    isChallengeOwnerDisabled: boolean = false;
    route: ActivatedRoute = inject(ActivatedRoute);
    @Output() idGenerated = new EventEmitter<string>();
    @Output() successEvent = new EventEmitter<void>();
    @Output() statusFetched = new EventEmitter<any>();
    @Input() queryParams: any = {};
    selectedCategories: any[] = [];
    constructor(
        private fb: FormBuilder,
        private challengeService: ChallengeService,
        protected validationMessageService: ValidationMessageService,
        private alertService: SweetAlertService,
        private store: Store<{ launchChallenge: LaunchChallengeState }>,
        private imageUploadService: S3UploadService,
        private router: Router
    ) {
        this.informationForm();

        this.loading$ = this.store.select(
            (state) => state.launchChallenge.loading
        );

        this.error$ = this.store.select((state) => state.launchChallenge.error);

        this.basicInformation$ = this.store.select(
            (state) => state.launchChallenge.basicInformation
        );
        // this.isEditable = this.route.snapshot.params['id'] ? true : false;

        this.challengeId$ = this.store
            .select((state) => state.launchChallenge.challengeId)
            .pipe(
                map((challengeId) => challengeId ?? null) // Transform undefined to null
            );
    }

    informationForm() {
        this.basicInformationForm = this.fb.group({
            imageName: ['', Validators.required],
            fileType: [''],
            title: ['', Validators.required],
            challengeOwnerId: [[], Validators.required],
            managedById: [''],
            category: [[], Validators.required],
            hasActionableFuture: ['', Validators.required],
            orgType: [[]],
            geographicScopeType: [[], Validators.required],
            geographicScopeValue: ['', Validators.required],
            // challengeType: ['', Validators.required],
            otherOrganizationType: [''],
            shortDescription: [
                '',
                [Validators.required, wordCountValidator(150)],
            ],
            challengeManagementAuthority: ['', Validators.required],
        });
        this.setupFormValueChanges();
        // Manually trigger change to handle initial form values
        const initialGeographicScopeType = this.basicInformationForm.get(
            'geographicScopeType'
        )?.value;
        if (initialGeographicScopeType === 'Global') {
            // Manually trigger change to handle initial form values
            // this.onGeographicScopeChange({
            //     target: {
            //         value: initialGeographicScopeType,
            //     },
            // });
            this.onGeographicScopeChange(initialGeographicScopeType);
        }
    }

    setupFormValueChanges() {
        this.basicInformationForm
            .get('challengeManagementAuthority')
            ?.valueChanges.subscribe((authority) => {
                if (authority === 'ChallengeOwner') {
                    const challengeOwnerId =
                        this.basicInformationForm.get(
                            'challengeOwnerId'
                        )?.value;
                    this.basicInformationForm.patchValue({
                        managedById: challengeOwnerId
                            ? Number(challengeOwnerId)
                            : '',
                    });
                } else if (authority === 'Multiverz') {
                    this.basicInformationForm.patchValue({
                        managedById: this.multiverzOrgId, // Use dynamic Multiverz ID
                    });
                }
            });

        // //listen to challengeOwnerId changes if "Challenge Owner" is selected
        // this.basicInformationForm
        //     .get('challengeOwnerId')
        //     ?.valueChanges.subscribe((challengeOwnerId) => {
        //         const authority = this.basicInformationForm.get(
        //             'challengeManagementAuthority'
        //         )?.value;
        //         if (authority === 'ChallengeOwner') {
        //             this.basicInformationForm.patchValue({
        //                 managedById: challengeOwnerId
        //                     ? Number(challengeOwnerId.id)
        //                     : '',
        //             });
        //         }
        //     });
    }

    geographicScopes = [
        { label: 'Global', value: 'Global' },
        { label: 'Multi Country', value: 'Multi Country' },
        { label: 'National', value: 'National' },
        { label: 'Local', value: 'Local' },
    ];

    challengeTypes = [
        { label: 'Actionable Futures', value: 'Actionable Futures' },
        { label: 'Regular', value: 'Regular' },
    ];
    loadCategories(): void {
        this.challengeService.listAllCategories().subscribe(
            (data: Category[]) => {
                this.categories = data.map((category) => ({
                    id: category.id,
                    title: category.title,
                }));
            },
            (error) => {
                this.alertService.errorPopup(error.error.message);
            }
        );
    }

    onSelectionChange(event: any) {
        this.selectedCategories.push(event);
        console.log('after selection = ', this.selectedCategories);
    }

    onRemoveChange(event: any) {
        console.log('event = ', event);
        this.selectedCategories = this.selectedCategories.filter(
            (category) => category.id !== event.id
        );
        console.log('after removal = ', this.selectedCategories);
    }

    isSelected(item: any): boolean {
        return this.selectedCategories.some(
            (selectedItem) =>
                selectedItem?.title === item?.title &&
                selectedItem?.id === item?.id
        );
    }
    //     // check if the category is already in the selected list
    //     if(!this.isCategorySelected(category)){
    //         this.selectedCategories.push(category);
    //     }
    // }
    // removeCategory(category: Category){
    //     // Remove the category from the selectedCategories array
    //     this.selectedCategories = this.selectedCategories.filter(c => c.id !== category.id);
    // }

    // isCategorySelected(category: Category){
    //     return this.selectedCategories.some(selected => selected.id === category.id);
    // }
    compareWith(item: any, selected: any) {
        return item.id === selected.id;
    }

    listAllOraganizationsType(): void {
        this.challengeService.listAllOraganizationsType().subscribe(
            (data: OrganizationTypes[]) => {
                this.organisationsType = data.map((organsation) => ({
                    id: organsation.id,
                    title: organsation.title,
                }));
            },
            (error) => {
                this.alertService.errorPopup(error.error.message);
            }
        );
    }

    loadChallengeOwners(): void {
        this.challengeService.listALLOrganizations().subscribe(
            (data: challengeOwner[]) => {
                this.challengeOwners = data;
                // Find and store the Multiverz organization ID
                const multiverzOrg = data.find((org) => org.id === 1);

                this.multiverzOrgId = multiverzOrg;
            },
            (error) => {
                this.alertService.errorPopup(error.error.message);
            }
        );
    }

    ngOnInit() {
        this.loadCategories();
        this.loadChallengeOwners();
        this.listAllOraganizationsType();
        const id = this.route.snapshot.queryParams['id'];
        if (id) {
            this.challengeService
                .getChallengeBasicInformation(id)
                .subscribe((data) => {
                    if (data) {
                        this.statusFetched.emit(data.status);
                        this.populateForm(data);
                        this.selectedCategories = [...data?.categories]; // This will trigger visibility settings
                    }
                });
        }

        // Watch for changes in challengeOwnerId to disable the "Challenge Owner" radio button if needed
        this.basicInformationForm.get('challengeOwnerId')?.valueChanges.subscribe((ownerId: number) => {
            // Find the selected challenge owner by its ID
            const selectedOwner = this.challengeOwners.find(owner => owner.id === ownerId);

            // If the selected owner's title is 'Multiverz Organization',  disable "Challenge Owner" radio button
            if(selectedOwner && selectedOwner.title === 'Multiverz Organization')
            {
                console.log('owner is multiverz');
                this.basicInformationForm.get('challengeManagementAuthority')?.patchValue('Multiverz');
                this.isChallengeOwnerDisabled = true; // Disable "Challenge Owner" radio button
            }
            else{
                this.isChallengeOwnerDisabled = false; // Enable "Challenge Owner" radio button
            }
        })

        // this.basicInformationForm.patchValue({
        //     geographicScopeType: 'Global', // Set default selection to Global
        //     geographicScopeValue: 'Global', // Set default value for the text field
        // });

        // // Disable the text field by default since the default selection is Global
        // this.isInputDisabled = true;

        // if (this.route.snapshot.params['id']) {
        //     let id = this.route.snapshot.queryParams['id'];
        //     this.challengeService
        //         .getChallengeBasicInformation(id)
        //         .subscribe((data) => {
        //             if (data) {
        //                 let fetchedData = {
        //                     imageName: data.imageName,
        //                     fileType: data.fileType,
        //                     title: data.title,
        //                     challengeOwnerId: data?.managedBy?.id,
        //                     managedById: data.managedBy?.id,
        //                     category: [...data.categories],
        //                     hasActionableFuture: [data.hasActionableFuture],
        //                     orgType: data.orgType.title,
        //                     geographicScopeType: data?.geographicScope.value,
        //                     geographicScopeValue: [data?.geographicScopeValue],
        //                     challengeType: data.hasActionableFuture
        //                         ? 'Actionable Future'
        //                         : 'Regular',
        //                     otherOrganizationType: '',
        //                     shortDescription: data.shortDescription,
        //                     challengeManagementAuthority: [data.managedById],
        //                 };

        //                 this.basicInformationForm.patchValue(fetchedData);
        //                 this.updateFormVisibility(data);
        //             }
        //         });
        // } else if (this.route.snapshot.queryParams['id']) {
        //     let id = this.route.snapshot.queryParams['id'];
        //     // this.store.dispatch(
        //     //     LaunchChallengeActions.loadChallengeBasicInformation({id}  )
        //     // );
        //     this.challengeService
        //         .getChallengeBasicInformation(id)
        //         .subscribe((data) => {
        //             if (data) {
        //                 let fetchedData = {
        //                     imageName: data.imageName,
        //                     fileType: data.fileType || this.basicInformationForm.get('fileType')?.value,
        //                     title: data.title,
        //                     challengeOwnerId: data?.managedBy?.id,
        //                     managedById: data.managedBy?.id,
        //                     category: [...data.categories],
        //                     hasActionableFuture: [data.hasActionableFuture],
        //                     orgType: data.orgType.title,
        //                     geographicScopeType: data?.geographicScope.type,
        //                     geographicScopeValue: [data?.geographicScope.value],
        //                     challengeType: data.hasActionableFuture
        //                         ? 'Actionable Future'
        //                         : 'Regular',
        //                     otherOrganizationType: '',
        //                     shortDescription: data.shortDescription,
        //                     challengeManagementAuthority: [data.managedById],
        //                 };
        //                 // Manually trigger the geographic scope input visibility
        //                 // Handle image display
        //                 if (data.imageName) {
        //                     this.selectedImage = {
        //                         name: data.imageName,
        //                     } as File; // Placeholder for demonstration
        //                 } else {
        //                     this.selectedImage = null;
        //                 }

        //                 this.basicInformationForm.patchValue(fetchedData);
        //                 this.updateFormVisibility(data);
        //             }
        //         });
        // }

        // else {
        //     this.challengeId$.pipe(take(1)).subscribe((id) => {
        //         if (id) {
        //             this.store.dispatch(
        //                 LaunchChallengeActions.loadChallengeBasicInformation({ id })
        //             );
        //             this.basicInformation$.pipe(take(1)).subscribe((data) => {
        //                 if (data) {
        //                     this.basicInformationForm.patchValue(data);
        //                     this.updateFormVisibility(data);
        //                 }
        //             });
        //         }
        //     });
        // }

        // Fetch existing data if available
    }
    // const multiverzId = 1;
    populateForm(data: any): void {
        const isMultiverz =
            data.managedBy?.id === 1 ? 'Multiverz' : 'ChallengeOwner';
        // Adjust your fetchedData object as needed
        let fetchedData = {
            imageName: data.imageName,
            fileType: data.fileType,
            title: data.title,
            challengeOwnerId: data?.challengeOwnerId.id,
            managedById: data.managedBy?.id,
            category: [...data.categories],
            hasActionableFuture: data.hasActionableFuture
                ? 'Actionable Futures'
                : 'Regular',
            orgType: data.orgType.title,
            geographicScopeType: data.geographicScope.type,
            geographicScopeValue: [data.geographicScope.value],
            // challengeType: data.hasActionableFuture,
            otherOrganizationType: '',
            shortDescription: data.shortDescription,
            challengeManagementAuthority: isMultiverz,
        };

        if (data.imageName) {
            this.selectedImage = {
                name: data.imageName,
            } as File; // Placeholder for demonstration
        } else {
            this.selectedImage = null;
        }
        this.basicInformationForm.patchValue(fetchedData);
        // Manually trigger the onGeographicScopeChange based on the patched value
        const geographicScopeTypeValue = this.basicInformationForm.get(
            'geographicScopeType'
        )?.value;
        if (geographicScopeTypeValue === 'Global') {
            // Manually disable the input field if "Global" is selected
            // this.onGeographicScopeChange({
            //     target: {
            //         value: geographicScopeTypeValue,
            //     },
            // });
            this.onGeographicScopeChange(geographicScopeTypeValue);
        }
    }

    onFileChange(event: any) {
        const file = event.target.files[0];
        const allowedTypes = [
            'image/png',
            'image/jpeg',
            'image/jpg',
            'image/bmp',
        ];
        const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes

        if (file) {
            // Check if the file type is valid
            if (!allowedTypes.includes(file.type)) {
                // Set the image name and file type if valid
                this.basicInformationForm
                    .get('imageName')
                    ?.setErrors({ invalidFileType: true });
                this.basicInformationForm.get('imageName')?.markAllAsTouched();
                console.log('Invalid File Type');

                //Reset the selected image and filetype
                this.selectedImage = null;
                this.basicInformationForm.get('fileType')?.setValue('');
                return;
            }

            // Check if the file size is within the limit
            if (file.size > maxFileSize) {
                this.basicInformationForm
                    .get('imageName')
                    ?.setErrors({ fileSizeExceeded: true });
                this.basicInformationForm.get('imageName')?.markAsTouched();

                // Reset the selected image and fileType
                this.selectedImage = null;
                this.basicInformationForm.get('fileType')?.setValue('');
                return;
            }
            // If valid proceed with setting the values and uploading
            this.selectedImage = file;
            this.basicInformationForm.get('imageName')?.setValue(file.name);
            this.basicInformationForm.get('fileType')?.setValue(file.type);

            // Clear any previous errors
            this.basicInformationForm.get('imageName')?.setErrors(null);

            // call the upload function
            this.uploadImage();
        }
    }

    removeImage() {
        this.selectedImage = null;
        this.basicInformationForm.get('imageName')?.setValue('');
        this.basicInformationForm.get('fileType')?.setValue('');
    }

    uploadImage() {
        if (this.selectedImage) {
            const fileName = this.selectedImage.name;
            const fileType = this.selectedImage.type;
            const folderPath = 'hero-images';

            this.imageUploadService
                .generateSignedUrl({
                    folderPath,
                    fileName,
                    fileType,
                })
                .subscribe((response) => {
                    const signedUrl = response.signedUrl;
                    this.uploadFileToS3(signedUrl, fileName, fileType);
                });
        }
    }

    uploadFileToS3(signedUrl: string, fileName: string, fileType: string) {
        const formData = new FormData();
        formData.append('file', this.selectedImage!);

        fetch(signedUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': fileType,
            },
            body: this.selectedImage,
        }).then((response) => {
            if (response.ok) {
                this.alertService.successToaster('Image Upload Successfully');
                this.basicInformationForm
                    .get('challengeHeroImage')
                    ?.setValue(signedUrl);
            } else {
                this.alertService.errorToaster('Image Upload Failed');
            }
        });
    }

    // updateFormVisibility(data: any) {
    //     this.showOtherOrganizationInput = data.orgType === 'Other';
    //     this.showRegionInput = data.geographicScope === 'Multi Country';
    //     this.showCountryInput = data.geographicScope === 'National';
    //     this.showLocationInput = data.geographicScope === 'Local';
    // }

    // onChallengeTypeChange(event: Event): void {
    //     const selectedValue = (event.target as HTMLSelectElement).value;

    //     if (selectedValue === 'ActionableFutures') {
    //         this.basicInformationForm.patchValue({ hasActionableFuture: 'Yes' });
    //     } else if (selectedValue === 'Regular') {
    //         this.basicInformationForm.patchValue({ hasActionableFuture: 'No' });
    //     }
    // }

    onGeographicScopeChange(selectedScope: any): void {
        // const selectedScope = event.target.value;
        const geographicScopeValueControl = this.basicInformationForm.get(
            'geographicScopeValue'
        );

        if (selectedScope === 'Global') {
            // Disable the input field and set its value to 'Global'
            geographicScopeValueControl?.setValue('Global');
            geographicScopeValueControl?.disable();
        } else {
            // Enable the input field and clear its value
            geographicScopeValueControl?.enable();
            geographicScopeValueControl?.setValue('');
            geographicScopeValueControl?.setValidators(Validators.required);
        }

        // Update form validity
        geographicScopeValueControl?.updateValueAndValidity();
    }

    onSubmit() {
        this.basicInformationForm.markAllAsTouched();
        if (this.basicInformationForm.valid) {
            // Convert challengeOwnerId to number
            const formValue = this.basicInformationForm.value;
            const hasActionableFutureValue =
                formValue.hasActionableFuture === 'Actionable Futures';
            // Multiver Managed By Id is null
            const managedById =
                formValue.challengeManagementAuthority === 'ChallengeOwner'
                    ? Number(formValue.challengeOwnerId) // Use challengeOwnerId if authority is 'Challenge Owner'
                    : 1;
            const categories = this.selectedCategories.map(
                (category: { title: string }) => category?.title
            );
            // Ensure immutability by using spread operators and mapping to DTO
            const updatedFormValue: challengeBasicInformationDto = {
                title: formValue.title,
                challengeOwnerId: Number(formValue.challengeOwnerId),
                managedById: managedById,
                // category: Array.isArray(formValue.category)
                //     ? formValue.category.map((cat: any) =>
                //           typeof cat === 'object' && cat?.title
                //               ? cat.title
                //               : cat
                //       )
                //     : [],
                category: categories,
                geographicScopeType: formValue.geographicScopeType ?? '',
                geographicScopeValue:
                    this.basicInformationForm
                        ?.get('geographicScopeValue')
                        ?.getRawValue() ?? '',
                hasActionableFuture: hasActionableFutureValue,
                orgType: formValue.orgType ?? '',
                shortDescription: formValue.shortDescription ?? '',
                challengeHeroImage: formValue.challengeHeroImage,
                imageName: formValue.imageName,
                fileType: formValue.fileType,
            };

            const challengeId = this.route.snapshot.queryParams['id'];
            if (challengeId) {
                this.challengeService
                    .updateChallengeBasicInformation(
                        challengeId,
                        updatedFormValue
                    )
                    .subscribe({
                        next: (response) => {
                            this.alertService.successPopup(response.message);
                            this.successEvent.emit();
                            // this.router.navigate(['/launch-a-challenge'], {queryParams: this.queryParams})
                        },
                        error: (error) => {
                            if (error.status == 422) {
                                const errors = error?.error?.errors;
                                if (errors) {
                                    this.validationMessageService.setServerErrors(
                                        this.basicInformationForm,
                                        errors
                                    );
                                }
                            } else {
                                this.alertService.errorPopup(
                                    error.error.message
                                );
                            }
                        },
                    });
            } else {
                this.challengeService
                    .postChallengeBasicInformation(updatedFormValue)
                    .subscribe({
                        next: (response) => {
                            this.alertService.successPopup(response.message);
                            this.idGenerated.emit(response.id);
                            this.successEvent.emit();
                        },
                        error: (error) => {
                            if (error.status == 422) {
                                const errors = error?.error?.errors;
                                if (errors) {
                                    this.validationMessageService.setServerErrors(
                                        this.basicInformationForm,
                                        errors
                                    );
                                }
                            } else {
                                this.alertService.errorPopup(
                                    error.error.message
                                );
                            }
                        },
                    });
            }
        }

        //     this.challengeId$.pipe(take(1)).subscribe((challengeId) => {

        //         if (challengeId) {
        //             this.store.dispatch(
        //                 LaunchChallengeActions.updateChallengeBasicInformation({
        //                     id: challengeId,
        //                     data: updatedFormValue,
        //                 })
        //             );
        //         } else {
        //             this.store.dispatch(
        //                 LaunchChallengeActions.postChallengeBasicInformation({
        //                     data: updatedFormValue,
        //                 })
        //             );
        //         }
        //     });
        //     // Wait for the error state to be updated
        //     this.store
        //     .pipe(
        //         withLatestFrom(this.store.select(selectBasicInformationError)),
        //         tap(([_, error]) => {
        //             if (error) {
        //                 this.validationMessageService.setServerErrors(
        //                     this.basicInformationForm,
        //                     error
        //                 );
        //             }
        //         })
        //     )
        //     .subscribe();
        // }
    }
}
