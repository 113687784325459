<div class="container">
    <form [formGroup]="problemStatementForm" (ngSubmit)="onSubmit()">
        <div class="row ">
            <div class="col-12 form-field-margin">
                <app-quill-editor label="Detailed Problem Description"
                infoText="Provide a comprehensive explanation of the social or environmental issue your challenge
                aims to address. Include relevant statistics, background information, and the current state of
                the
                problem."
                controlName="detailedProblemStatement"
                placeholder="Enter the detailed problem description here..."
                [parentForm]="problemStatementForm" [required]="true" [infoTextWord]="true">
            </app-quill-editor>

                <!-- <label for="detailedProblemDescription" class="form-label fw-semibold">Detailed Problem Description
                    <span style="color: #B21818;">*</span>
                    <span class="info-msg"> (max
                        500 words)</span></label>
                <small class="form-text text-muted info-msg">
                    Provide a comprehensive explanation of the social or environmental issue your challenge
                    aims to address. Include relevant statistics, background information, and the current state of
                    the
                    problem.
                </small>
                <div>
                    <quill-editor formControlName="detailedProblemStatement"
                        [ngStyle]="{'height': '200px','width':'100%'}"
                        placeholder="Enter the detailed problem description here..." [modules]="editorModules">
                    </quill-editor>
                </div> -->
                <app-input-error-message [formData]="problemStatementForm" [controlName]="'detailedProblemStatement'">
                </app-input-error-message>

            </div>

            <div class="col-12 form-field-margin">
                <app-quill-editor label="Root Causes"
                    infoText="Identify and explain the underlying factors contributing to the problem."
                    controlName="rootCauses"
                    placeholder="Identify and explain the underlying factors contributing to the problem."
                    [parentForm]="problemStatementForm">
                </app-quill-editor>

                <app-input-error-message [formData]="problemStatementForm" [controlName]="'rootCauses'">
                </app-input-error-message>
            </div>
        </div>

        <div class="col-12 form-field-margin">
            <app-quill-editor label="Previous Attempts to Solve" infoText=" Describe any notable past or ongoing efforts to address this issue, including their
            outcomes and limitations." controlName="previousAttempts"
                placeholder="Describe any notable past or ongoing efforts to address this issue..."
                [parentForm]="problemStatementForm">
            </app-quill-editor>

            <app-input-error-message [formData]="problemStatementForm" [controlName]="'previousAttempts'">
            </app-input-error-message>
        </div>

        <div class="col-12 form-field-margin">
            <app-quill-editor label="Urgency and Importance"
                infoText=" Explain why solving this challenge is crucial and time-sensitive."
                controlName="urgencyImportance"
                placeholder="Explain why solving this challenge is crucial and time-sensitive."
                [parentForm]="problemStatementForm">
            </app-quill-editor>
            <app-input-error-message [formData]="problemStatementForm" [controlName]="'urgencyImportance'">
            </app-input-error-message>
        </div>

        <div class="col-12 form-field-margin">
            <app-quill-editor label="Target Beneficiaries"
                infoText="Clearly define the primary group(s) that will benefit from the solution to this challenge."
                controlName="targetBeneficiaries"
                placeholder="Clearly define the primary group(s) that will benefit from the solution to this challenge."
                [parentForm]="problemStatementForm">
            </app-quill-editor>

            <app-input-error-message [formData]="problemStatementForm" [controlName]="'targetBeneficiaries'">
            </app-input-error-message>
        </div>

    </form>

</div>
