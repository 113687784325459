import { createAction, props } from '@ngrx/store';

export const login = createAction(
    '[Auth] Login',
    props<{ email: string; password: string }>()
);


export const loginGoogle = createAction(
    '[Auth] Login Google',
    props<{ code: string; scope: string }>()
);

export const loginLinkedin = createAction(
    '[Auth] Login Linkedin',
    props<{ code: string }>()
);
export const loginSuccess = createAction(
    '[Auth] Login Success',
    props<{ token: string; user: any }>()
);

export const loginSSOSuccessWithoutRole = createAction(
    '[Auth] Login SSO Success Without Role',
    props<{ token: string; user: any }>()
);

export const updateUserRoles = createAction(
    '[Auth] Update User Roles',
    props<{ roles: any[], organization: any | null }>()  // Pass the new roles as payload
  );

export const loginFailure = createAction(
    '[Auth] Login Failure',
    props<{ error: any }>()
);

export const logout = createAction('[Auth] Logout');

export const updateUser = createAction(
    '[Auth] Update User',
    props<{ user: any }>()
);

export const forgotPassword = createAction(
    '[Auth] Forgot Password',
    props<{ email: string }>()
);

export const forgotPasswordSuccess = createAction(
    '[Auth] Forgot Password Success',
    props<{ message: string }>()
);

export const forgotPasswordFailure = createAction(
    '[Auth] Forgot Password Failure',
    props<{ error: any }>()
);

