    <div class="container">

        <div class="name-date-wrapper">
            <h2>Hello {{name}}</h2>
            <p>{{date|date:'mediumDate'}}</p>
        </div>
        <div class="tabs-wrapper">
            <div class="the-challenges-tab-wrapper">
                <mat-tab-group [selectedIndex]='selectTabIndex'>
                    <mat-tab label="Overview">
                        <app-organisation-overview-dashboard>
                        </app-organisation-overview-dashboard>
                    </mat-tab>
                    <mat-tab label="Challenges">
                        <app-organisation-challenge>
                        </app-organisation-challenge>
                    </mat-tab>
                    @if (role===ROLE_SLUG.ORGANIZATION||role===ROLE_SLUG.SUPERADMIN||role===ROLE_SLUG.SUPERORG) {
                        <mat-tab label="Evaluation">
                            <app-evaluation>
                            </app-evaluation>
                        </mat-tab>
                    }
                    @if (role===ROLE_SLUG.SUPERADMIN||role===ROLE_SLUG.SUPERORG) {

                    <mat-tab label="Interests">
                        <app-organisation-interests-list>
                        </app-organisation-interests-list>
                    </mat-tab>
                    
                    } 
                   
                    <!-- <mat-tab label="Guidelines">
                <app-challenge-details-guidelines [challenge]="challenge" [guidelines]="guidelines">
                </app-challenge-details-guidelines>
            </mat-tab>
            <mat-tab label="Evaluation">
                <app-challenge-details-evaluation [evaluation]="evaluation" [challenge]="challenge">
                </app-challenge-details-evaluation>
            </mat-tab>
            <mat-tab label="TimeLine">
                <app-challenge-details-timeline [challenge]="challenge"></app-challenge-details-timeline>
            </mat-tab>
            <mat-tab label="Resources">
                <app-challenge-details-resources [challenge]="challenge" [resources]="resources">
                </app-challenge-details-resources>
            </mat-tab>
            <mat-tab label="FAQs">
                <app-challenge-details-faq [challenge]="challenge" [FAQ]="FAQ"></app-challenge-details-faq>
            </mat-tab>
            <mat-tab label="Talk to Us">
                <app-challenge-details-talktous [talkToUs]="talkToUs"></app-challenge-details-talktous>
            </mat-tab> -->

                </mat-tab-group>
                <div class="launch-challenge-btn">
                    @if (role===ROLE_SLUG.SUPERADMIN||role===ROLE_SLUG.SUPERORG) {
                    <button class="btn btn-primary rounded-start-0 btn-bg-blue-gradient fw-semibold custom-btn"
                        routerLink="/launch-a-challenge">Launch a Challenge <span class="arrow-btn-wrapper"> <img
                                src="/images/home/right-arrow.svg" alt="Arrow" height="20"
                                class="arrow-icon1 "></span></button>
                    } @else {
                    <button class="btn btn-primary rounded-start-0 btn-bg-blue-gradient fw-semibold custom-btn"
                        routerLink="/request-a-challenge">Request a Challenge <span class="arrow-btn-wrapper"> <img
                                src="/images/home/right-arrow.svg" alt="Arrow" height="20"
                                class="arrow-icon1 "></span></button>
                    }
                </div>
            </div>


        </div>

    </div>
