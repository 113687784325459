<div class="container-fluid mt-5 pt-2">
    <div class="row justify-content-center">
        <div class="col-md-10 border-bottom ">
            <h6 class="text-start text-color-overview" href="">
                <a href="">
                    < Back to Overview </a> </h6> </div> <h2 class="text-center text-color mt-3">Change Password</h2>
        </div>

        <div class="row mt-5 justify-content-center mb-5 pb-4 ">
            <div class="col-md-6">
                <div class="card shadow rounded-start-0 custom-container border-0">
                    <div class="card-body">
                        <form class="row" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
                            <div class="col-md-11 mb-3 mt-3 mx-auto">
                                <label for="inputCurrentPassword" class="form-label fw-semibold">Type Old
                                    Password</label>
                                <app-password-input id="inputCurrentPassword" formControlName="currentPassword">
                                </app-password-input>

                                <app-input-error-message [formData]="changePasswordForm"
                                    [controlName]="'currentPassword'">
                                </app-input-error-message>
                            </div>

                            <div class="col-md-11 mb-3 mx-auto">
                                <label for="inputNewPassword" class="form-label fw-semibold">New Password</label>
                                <app-password-input id="inputNewPassword" formControlName="newPassword">
                                </app-password-input>

                                <app-input-error-message [formData]="changePasswordForm" [controlName]="'newPassword'">
                                </app-input-error-message>
                            </div>

                            <div class="col-md-11 mb-3 mx-auto">
                                <label for="inputConfirmNewPassword" class="form-label fw-semibold">Confirm New
                                    Password</label>
                                <app-password-input id="inputConfirmNewPassword" formControlName="confirmNewPassword">
                                </app-password-input>

                                <app-input-error-message [formData]="changePasswordForm"
                                    [controlName]="'confirmNewPassword'"></app-input-error-message>
                            </div>

                            <div class="col-md-11 mt-3 text-end mx-auto">
                                <button type="submit" class="btn btn-gradient btn-bg-blue-gradient mb-5 custom-btn">
                                    Set Password
                                    <span class="arrow-btn-wrapper"> <img src="/images/home/right-arrow.svg" alt="Arrow"
                                            height="20" class="arrow-icon1"></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
