import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthState } from '../store/auth/auth.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtService } from '../services/jwt.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    constructor(
        private router: Router,
        private store: Store<{ auth: AuthState }>,
        private jwtService: JwtService
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const requiredRoleSlugs = route.data['roles'];
        return this.store.select('auth').pipe(
            map((authState) => {
                // checks token expiry
                if (this.jwtService.isTokenExpired(authState.token ?? '')) {
                    this.router.navigate(['/login']);
                    return false;
                }

                if (requiredRoleSlugs) {
                    const hasRequiredRole = requiredRoleSlugs?.some(
                        (role: string) =>
                            authState.user?.roles[0]?.['slug'] === role
                    );
                    if (!hasRequiredRole) {
                        if (authState?.user?.roles?.length === 0) {
                            this.router.navigate(['signup/sso'], {
                                queryParams: { sso: 'true' },
                            });
                            return false;
                        }
                        // checks logged in user via SSO ie without role and redirect to signup for assigning role
                        this.router.navigate(['/login']);
                        return false;
                    }
                }
                return true;
            })
        );
    }

    // access user throughout the application
    getUserState(): Observable<AuthState> {
        return this.store.select('auth');
    }
}
