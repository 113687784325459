<div class="custom-container-fluid">
    <div class="container">
        <div class="row my-4">
            <div class="col-md-5">
                <a routerLink="/">
                    <img src="/logo.svg" alt="Main Logo" height="40px" />
                </a>

                <div class="form-container">
                    <h2 class="fw-semibold">Login</h2>
                    <p class="text-secondary fs-15px">
                        Start creating positive social impact around the world
                    </p>

                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="mb-3">
                            <label for="inputEmail" class="form-label fw-semibold fs-15px">Email ID<span style="color: #B21818;">*</span></label>
                            <input type="email" formControlName="email" class="form-control" id="inputEmail" />

                            <app-input-error-message [formData]="loginForm" [controlName]="'email'">
                            </app-input-error-message>
                        </div>
                        <div class="mb-3">
                            <label for="inputPassword" class="form-label fw-semibold fs-15px">Password<span style="color: #B21818;">*</span></label>
                            <app-password-input id="inputPassword" formControlName="password"></app-password-input>

                            <app-input-error-message [formData]="loginForm" [controlName]="'password'">
                            </app-input-error-message>
                        </div>
                        <div class="mb-3 text-end">
                            <a class="fs-15px text-decoration-none text-dark" (click)="onForgotPassword()"
                                style="cursor: pointer">
                                Forgot Password?
                            </a>
                        </div>

                        <button type="submit" class="btn btn-gradient btn-bg-blue-gradient custom-btn">
                            LOGIN
                            <span class="arrow-btn-wrapper"> <img src="/images/home/right-arrow.svg" alt="Arrow"
                                    height="20" class="arrow-icon1"></span>
                        </button>
                    </form>

                    <div class="container mt-4">
                        <div class="row d-flex align-items-end">
                            <button type="button" class=" d-flex justify-content-center social-login-btn"
                                (click)="onLoginGoogle()">
                                <img src="/google.svg" alt="Google Logo" class="me-2" />
                                <span>Login using Google</span>
                            </button>
                        </div>

                        <div class="row mt-3">
                            <button type="button" class=" d-flex justify-content-center social-login-btn"
                                (click)="onLoginLinkedin()">
                                <img src="/linkedin.svg" alt="Linkedin Logo" class="me-2" />
                                <span>Login using Linkedin</span>
                            </button>
                        </div>
                    </div>

                    <p class="mt-3 fw-semibold fs-15px">
                        Don't have an account? Sign up
                        <a routerLink="/signup">here</a>
                    </p>
                </div>
            </div>
            <div class="col-md-7">
                <div class="right-img-container"> <img src="/images/login/main.png" class="img-fluid"
                        alt="Login Main" />
                </div>

            </div>
        </div>
    </div>
</div>
