import { Component } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import {  Router, RouterLink } from '@angular/router';
import { AuthState } from '../../core/store/auth/auth.state';
import { Store } from '@ngrx/store';
import * as AuthActions from '../../core/store/auth/auth.actions';

@Component({
    selector: 'app-forgot-password-email',
    standalone: true,
    imports: [ReactiveFormsModule, RouterLink],
    templateUrl: './forgot-password-email.component.html',
    styleUrl: './forgot-password-email.component.scss',
})
export class ForgotPasswordEmailComponent {
    emailVerificationForm: FormGroup;

    constructor(
        private fb: FormBuilder,
        protected validationMessageService: ValidationMessageService,
        private router: Router,
        private store: Store<{ auth: AuthState }>
    ) {
        this.emailVerificationForm = this.fb.group({
            email: [
                '',
                Validators.compose([Validators.required, Validators.email]),
            ],
        });
    }

    onForgotPassword() {
      const email = this.emailVerificationForm.get('email')?.value;
      if (email) {
        this.store.dispatch(AuthActions.forgotPassword({ email }));
      }
    }
}
