import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputErrorMessageComponent } from '../input-error-message/input-error-message.component';

@Component({
  selector: 'app-request-launch-challenge-form',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, InputErrorMessageComponent],
  templateUrl: './request-launch-challenge-form.component.html',
  styleUrl: './request-launch-challenge-form.component.scss'
})
export class RequestLaunchChallengeFormComponent {
  @Input() formGroup!: FormGroup;
  @Output() formSubmit = new EventEmitter<void>();
  @Input() showIntroText: boolean = false;

  onFormSubmit(): void {
    this.formSubmit.emit();
  }}
