import { Component, Inject, inject } from '@angular/core';
import { InputErrorMessageComponent } from "../shared/input-error-message/input-error-message.component";
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ChallengeService } from '../core/services/apis/challenge.service';

@Component({
  selector: 'app-view-post-interest-form',
  standalone: true,
  imports: [InputErrorMessageComponent,ReactiveFormsModule,CommonModule],
  templateUrl: './view-post-interest-form.component.html',
  styleUrl: './view-post-interest-form.component.scss'
})
export class ViewPostInterestFormComponent {
  router:Router=inject(Router);
  challengeService=inject(ChallengeService);
  postInterestId:any
route:ActivatedRoute=inject(ActivatedRoute);
  constructor() {
    // this.route.params.subscribe(params => {
    //   this.postInterestId = params['id'];
    // })

  };
  ngOnInit(): void {
    this.postInterestId = this.route.snapshot.params['id'];

    this.challengeService.getPostInterestById(this.postInterestId).subscribe((data: any) => {
      // this.formGroup.patchValue(data);
      this.postInterestId = data.data;
    }, (error: any) => {
      console.error('Error fetching post interest', error);
    })
  }
  redirectToOverview(): void {
    this.router.navigate(['/the-challenges',{tab:2}])
  }

}
