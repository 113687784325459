import { Component, inject } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { OrganizationType } from '../../core/Model/organizationType';
import { Incentives } from '../../core/Model/Incentives';
import { Category } from '../../core/Model/category';
import { ChallengeService } from '../../core/services/apis/challenge.service';
import { SweetAlertService } from '../../core/services/sweet-alert.service';

@Component({
  selector: 'app-filters',
  standalone: true,
  imports: [NgSelectModule],
  template: `
    <p>
      <label for="stageOfChallenge" class="form-label fw-semibold">Stage of challenge</label>
      <ng-select
        [items]="orgType"
        [multiple]="true"
        bindLabel="type"
        [selectableGroupAsModel]="false"
        [closeOnSelect]="false"
        bindValue="id"
        (add)="onSelectionChange($event)"
        (remove)="onRemoveChange($event)"

      >
        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
          <!-- Optional: Add content for optgroup template if needed -->
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <input id="item-{{ index }}" type="checkbox" [checked]="isSelected(item)" /> {{ item.type }}
        </ng-template>
      </ng-select>
    </p>
    <p>
      <label for="stageOfChallenge" class="form-label fw-semibold">Incentive Involved</label>
      <ng-select
        [items]="incentiveAvailable"
        [multiple]="true"
        bindLabel="option"
        [selectableGroupAsModel]="false"
        [closeOnSelect]="false"
        bindValue="id"
        (add)="onSelectionChange($event)"
        (remove)="onRemoveChange($event)"
      >
        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
          <!-- Optional: Add content for optgroup template if needed -->
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <input id="item-{{ index }}" type="checkbox" [checked]="isSelected(item)" /> {{ item.option }}
        </ng-template>
      </ng-select>
    </p>
    <p>
      <label for="stageOfChallenge" class="form-label fw-semibold">Category</label>
      <ng-select
        [items]="categories"
        [multiple]="true"
        bindLabel="title"
        [selectableGroupAsModel]="false"
        [closeOnSelect]="false"
        bindValue="id"
        (add)="onSelectionChange($event)"
        (remove)="onRemoveChange($event)"
      >
        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
          <!-- Optional: Add content for optgroup template if needed -->
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <input id="item-{{ index }}" type="checkbox" [checked]="isSelected(item)" /> {{ item.title }}
        </ng-template>
      </ng-select>
    </p>
  `,
  styleUrls: ['./filters.component.scss'] // Corrected to styleUrls
})
export class FiltersComponent {
  orgType: OrganizationType[] = [
    { id: 1, type: 'Upcoming' },
    { id: 2, type: 'Open For Submission' },
    { id: 3, type: 'Closed' }
  ];

  incentiveAvailable:Incentives[]=[
    {
      id:12,
      option:'yes'
    },
    {
      id:13,
      option:'no'
    }
  ]
  categories:Category[]=[]
  challengeService:ChallengeService=inject(ChallengeService)
  alertService:SweetAlertService=inject(SweetAlertService)
  selectedItems: (OrganizationType | Incentives)[] = [];
  loadCategories(): void{
    this.challengeService.listAllCategoryOptions().subscribe(
        (data: Category[]) => {
            this.categories = data.map(category => ({
                id: category.id,
                title: category.title
            }));
        },
        error => {
            this.alertService.errorPopup(error.error.message);
        }
    )
}
  constructor(){
    this.loadCategories()
  }

  onRemoveChange(selectedItem: OrganizationType | Incentives):void {
   let index=this.selectedItems.findIndex(selected=>selected.id===selectedItem.id)
    this.selectedItems.splice(index,1)

  }
  onSelectionChange(selectedItem: OrganizationType | Incentives): void {
    this.selectedItems.push(selectedItem)
  }

  isSelected(item: OrganizationType|Incentives): boolean {
    return this.selectedItems.some(selectedItem => selectedItem.id === item.id);
  }


}
