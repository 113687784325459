import { Component, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { AuthService } from '../../core/services/apis/auth.service';
import * as AuthActions from '../../core/store/auth/auth.actions';
import { AuthState } from '../../core/store/auth/auth.state';
import { Store } from '@ngrx/store';
import { PasswordInputComponent } from '../../shared/input/password-input/password-input.component';
import { InputErrorMessageComponent } from '../../shared/input-error-message/input-error-message.component';

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [RouterLink, ReactiveFormsModule, PasswordInputComponent, InputErrorMessageComponent],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    emailError: string | null = null;

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private router: Router,
        protected validationMessageService: ValidationMessageService,
        private store: Store<{ auth: AuthState }>
    ) {
        this.loginForm = this.fb.group({
            email: [
                '',
                Validators.compose([Validators.required, Validators.email]),
            ],
            password: ['', Validators.required],
        });
    }

    ngOnInit() {}

    onSubmit() {
        this.loginForm.markAllAsTouched();

        if (this.loginForm.valid) {
            this.store.dispatch(AuthActions.login(this.loginForm.value));
        }
    }

    onLoginGoogle(): void {
        this.authService.loginGoogle();
    }

    onLoginLinkedin() {
        this.authService.loginLinkedin();
    }

    onForgotPassword() {
        // const emailControl = this.loginForm.get('email');
        // if (emailControl) {
        //     emailControl.markAsTouched();
        //     emailControl.updateValueAndValidity();

        //     if (emailControl.valid) {
        //         const email = emailControl.value;
        //         this.store.dispatch(AuthActions.forgotPassword({ email }));
        //         this.router.navigate(['/forgot-password']);
        //     }
        // }
        this.router.navigate(['/forgot-password']);
    }
}
