import { Component, EventEmitter, inject, Output } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { InputErrorMessageComponent } from '../input-error-message/input-error-message.component';
import { QuillEditorComponent } from '../quill-editor/quill-editor.component';
import { Store } from '@ngrx/store';
import { LaunchChallengeState } from '../../core/store/launchChallengeForm/launchChallenge.state';
import {
    selectChallengeId,
    selectGuidelinesError,
} from '../../core/store/launchChallengeForm/launchChallenge.selectors';
import { of, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { updateEvaluationCriteria } from '../../core/store/launchChallengeForm/launchChallenge.actions';
import { evaluationCriteria } from '../../core/Model/evaluation-criteria.dto';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { ChallengeService } from '../../core/services/apis/challenge.service';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-evaluation-criteria-form',
    standalone: true,
    imports: [
        QuillEditorComponent,
        ReactiveFormsModule,
        InputErrorMessageComponent,
    ],
    templateUrl: './evaluation-criteria-form.component.html',
    styleUrl: './evaluation-criteria-form.component.scss',
})
export class EvaluationCriteriaFormComponent {
    evaluationCriteriaForm!: FormGroup;
    @Output() successEvent = new EventEmitter<void>();
    route: ActivatedRoute=inject(ActivatedRoute);
    challengeId: any;
    constructor(
        private fb: FormBuilder,
        private store: Store<{ launchChallenge: LaunchChallengeState }>,
        protected validationMessageService: ValidationMessageService,
        private challengeService: ChallengeService,
        private alertService: SweetAlertService
    ) {
       this.evaluationForm();
    }

    ngOnInit() {
        this.challengeId =this.route.snapshot.queryParams['id'];
        if(this.challengeId){
            this.loadEvaluationCriteria();
        }
    }
    evaluationForm(){
        this.evaluationCriteriaForm = this.fb.group({
            judgingProcess: ['', Validators.maxLength(5000)],
            evaluationCriteria: [
                '',
                [Validators.required, Validators.maxLength(5000)],
            ],
            judgesAndExpertise: ['', Validators.maxLength(5000)],
        });
    }

    loadEvaluationCriteria() {
        const challengeId = this.challengeId; // Replace with the actual challengeId

        this.challengeService.getEvaluationCriteria(challengeId).subscribe({
            next: (data: any) => {
                this.populateForm(data);
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorPopup(
                    error.error.message
                );
                console.error('Error fetching evaluation criteria:', error);
            },
        });
    }

    populateForm(data: any) {
        this.evaluationCriteriaForm.patchValue({
            judgingProcess: data.data.judgingProcess,
            evaluationCriteria: data.data.evaluationCriteria,
            judgesAndExpertise: data.data.judgesAndExpertise,
        });
    }

    onSubmit() {
        this.evaluationCriteriaForm.markAllAsTouched();

        if (this.evaluationCriteriaForm.valid) {
            // Clear existing server errors
            this.validationMessageService.clearServerErrors(
                this.evaluationCriteriaForm
            );

            // Build the request body
            const formValue = this.evaluationCriteriaForm.value;

            const evaluationCriteriaData: evaluationCriteria = {
                judgingProcess: formValue.judgingProcess,
                evaluationCriteria: formValue.evaluationCriteria,
                judgesAndExpertise: formValue.judgesAndExpertise,
            };

            const challengeId = this.route.snapshot.queryParams['id']; // Replace with the actual challengeId

            // Make the API call to update the evaluation criteria
            this.challengeService
                .updateEvaluationCriteria(challengeId, evaluationCriteriaData)
                .subscribe({
                    next: (response: any) => {
                        // Handle successful response
                        this.alertService.successPopup(response.message);
                        this.successEvent.emit();
                    },
                    error: (error: HttpErrorResponse) => {
                        // Handle error response
                        if (error.status === 422) {
                            const errors = error?.error?.errors;
                            if (errors) {
                                this.validationMessageService.setServerErrors(
                                    this.evaluationCriteriaForm,
                                    errors
                                );
                            }
                        } else {
                            this.alertService.errorPopup(error.error.message);
                        }
                    },
                });
        }
    }
}
