import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { guideLineContents } from './guideline-content';
@Component({
    selector: 'app-weak-signal-dialog',
    standalone: true,
    imports: [CommonModule, MatDialogModule],
    templateUrl: './guidelines-component.component.html',
    styleUrl: './guidelines-component.component.scss',
})
export class GuidelinesComponentComponent {
    challengeId: any;
    queryParams: any;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.route.queryParams.subscribe((params) => {
            console.log(params)
            this.queryParams = params
        });

        this.route.params.subscribe((params) => {
            console.log(params)
            this.challengeId = params['challengeId'];
        });
    }

    ngOnInIt(){

    }
    guidelines = guideLineContents;

    redirectToSolverFlow() {
        console.log('query params', this.queryParams)
        this.router.navigate(['/solver-flow', this.challengeId],{
            queryParams: this.queryParams
        });
    }
}
