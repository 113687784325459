import { Component, EventEmitter, inject, Output } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { Faqs } from '../../core/Model/faqs.dto';
import { LaunchChallengeState } from '../../core/store/launchChallengeForm/launchChallenge.state';
import { select, Store } from '@ngrx/store';
import {
    selectChallengeId,
    selectFaqIds,
    selectFaqs,
} from '../../core/store/launchChallengeForm/launchChallenge.selectors';
import { Observable, of, switchMap, take } from 'rxjs';
import {
    deleteFaq,
    loadFaqs,
    updateFaqs,
} from '../../core/store/launchChallengeForm/launchChallenge.actions';
import { CommonModule } from '@angular/common';
// import { Router } from 'express';
import { ActivatedRoute, Router } from '@angular/router';
import { InputErrorMessageComponent } from '../input-error-message/input-error-message.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ChallengeService } from '../../core/services/apis/challenge.service';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { response } from 'express';

@Component({
    selector: 'app-faqs-form',
    standalone: true,
    imports: [ReactiveFormsModule, CommonModule, InputErrorMessageComponent],
    templateUrl: './faqs-form.component.html',
    styleUrl: './faqs-form.component.scss',
})
export class FaqsFormComponent {
    faqsForm!: FormGroup;
    faqs: Faqs[] = [];
    faqIdCounter: number = 0;
    faqIds$: Observable<number[]>;
    @Output() successEvent = new EventEmitter<void>();
    challengeId: any;
    route: ActivatedRoute=inject(ActivatedRoute);
    constructor(
        private fb: FormBuilder,
        private store: Store<{ launchChallenge: LaunchChallengeState }>,
        private router: Router,
        private challengeService: ChallengeService,
        protected validationMessageService: ValidationMessageService,
        private alertService: SweetAlertService
    ) {
        this.createForm();
        this.faqIds$ = this.store.select(selectFaqIds);


    }
    ngOnInit() {
        this.challengeId =this.route.snapshot.queryParams['id'];
        if(this.challengeId){
            this.loadFaqs();
        }

    }

    createForm() {
        this.faqsForm = this.fb.group({
            title: ['', Validators.required],
            answer: ['', Validators.required],
        });
    }

    trackByIndex(index: number, faq: Faqs): number {
        return index;
    }

    onDeleteFaq(faqId: number) {
        const challengeId = this.route.snapshot.queryParams['id'];
        if (!challengeId) {
            this.alertService.successPopup('Internal Server Error');
            return;
        }

        this.challengeService.deleteFaqs(challengeId, faqId).subscribe({
            next: (response:any) => {
                this.alertService.successPopup(response.message);

                // Remove the deleted FAQ from the local array
                this.faqs = this.faqs.filter(faq => faq.id !==faqId);
            },
            error: (error: HttpErrorResponse) => {
                // Handle error response
                if (error.status === 422) {
                    const errors = error?.error?.errors;
                    if (errors) {
                        this.validationMessageService.setServerErrors(this.faqsForm, errors);
                    }
                } else {
                    this.alertService.errorPopup(error.error.message);
                }
            }
        })
        // if (faqId === undefined) {
        //     // Optionally log a warning or handle the case when faqId is undefined
        //     return;
        // }
        // this.store
        //     .select(selectChallengeId)
        //     .pipe(
        //         take(1),
        //         switchMap((challengeId) => {
        //             if (challengeId) {
        //                 // Dispatch the delete action with the FAQ ID
        //                 this.store.dispatch(
        //                     deleteFaq({ challengeId, faqsId: faqId })
        //                 );

        //                 // Update the local FAQ list by removing the deleted FAQ
        //                 this.faqs = this.faqs.filter((faq) => faq.id !== faqId);
        //                 return of(null);
        //             }
        //             return of(null);
        //         })
        //     )
        //     .subscribe();
    }
    // onAddFaq() {
    //     this.faqsForm.markAllAsTouched();
    //     if (this.faqsForm.valid) {
    //         const newFaq = {
    //             title: this.faqsForm.value.title,
    //             answer: this.faqsForm.value.answer,
    //         } as Faqs;

    //         this.store
    //             .select(selectChallengeId)
    //             .pipe(
    //                 take(1),
    //                 switchMap((challengeId) => {
    //                     if (challengeId) {
    //                         this.store.dispatch(
    //                             updateFaqs({
    //                                 challengeId,
    //                                 data: newFaq,
    //                             })
    //                         );
    //                         // Immediately add the new FAQ to the local list to update the UI
    //                         this.faqs.push(newFaq);
    //                         // Reset the form
    //                         this.faqsForm.reset();

    //                         // Ensure the accordion functionality works
    //                         setTimeout(() => {
    //                             const lastFaqIndex = this.faqs.length - 1;
    //                             const collapseElement = document.getElementById(
    //                                 'collapse' + lastFaqIndex
    //                             );
    //                             if (collapseElement) {
    //                                 collapseElement.classList.add('show');
    //                             }
    //                         }, 0);

    //                         return of(newFaq);
    //                     }
    //                     return of(null);
    //                 })
    //             )
    //             .subscribe();
    //     }
    // }

    loadFaqs(): void {
        const challengeId = this.challengeId;

        if (!challengeId) {
            console.error('Challenge ID is not set. Cannot load FAQs.');
            return;
        }

        this.challengeService.getFaqs(challengeId).subscribe({
            next: (faqs: any) => {
                this.faqs = faqs.data;
                // // Optional: Initialize or update the form with the first FAQ or handle as needed
                if (this.faqs.length > 0) {
                    this.faqsForm.patchValue({
                        title: this.faqs[0].title,
                        answer: this.faqs[0].answer,
                    });
                }
            },
            error: (error: HttpErrorResponse) => {
                console.error('Error loading FAQs:', error.message);
                // this.alertService.errorPopup('Failed to load FAQs. Please try again later.');
            }
        });
    }


    onAddFaq(): void {
        this.faqsForm.markAllAsTouched();
        if (this.faqsForm.valid) {
            const newFaq: Faqs = {
                title: this.faqsForm.value.title,
                answer: this.faqsForm.value.answer,
            };

            // Assuming you have a way to obtain the challenge ID
            const challengeId = this.route.snapshot.queryParams['id']; // Replace with the actual challengeId or retrieve dynamically

            this.challengeService.updateFaqs(challengeId, newFaq).subscribe({
                next: (response: any) => {
                    // Successfully added FAQ, update the UI
                    this.alertService.successToaster(response.message);

                    const newFaqData = response.data;

                    this.faqs.push(newFaqData); // Use response to ensure it includes any server-side modifications

                    // Reset the form
                    this.faqsForm.reset();

                    // Ensure the accordion functionality works
                    setTimeout(() => {
                        const lastFaqIndex = this.faqs.length - 1;
                        const collapseElement = document.getElementById(
                            'collapse' + lastFaqIndex
                        );
                        if (collapseElement) {
                            collapseElement.classList.add('show');
                        }
                    }, 0);
                },
                error: (error: HttpErrorResponse) => {
                   // Handle error response
                   if (error.status === 422) {
                    const errors = error?.error?.errors;
                    if (errors) {
                        this.validationMessageService.setServerErrors(
                            this.faqsForm,
                            errors
                        );
                    }
                } else {
                    this.alertService.errorPopup(error.error.message);
                }
                }
            });
        }
    }

    // redirecToReviewPage() {
    //     this.store
    //         .select(selectChallengeId)
    //         .pipe(
    //             take(1),
    //             switchMap((challengeId) => {
    //                 if (challengeId) {
    //                     // Dispatch the review id

    //                     this.router.navigate([
    //                         '/challenge-details',
    //                         challengeId,
    //                     ]);
    //                 } else {
    //                     console.error('Challenge ID is not available');
    //                 }

    //                 return of(null);
    //             })
    //         )
    //         .subscribe();
    // }
}
