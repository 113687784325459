import {
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { QuillEditorComponent } from '../../shared/quill-editor/quill-editor.component';
import { InputErrorMessageComponent } from '../../shared/input-error-message/input-error-message.component';
import { MatStepper } from '@angular/material/stepper';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SolverFlowService } from '../../core/services/apis/solver-flow.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { solverStep1Dto } from '../../core/Model/solver-step1.dto';
import { ActivatedRoute } from '@angular/router';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { ReferenceHandlerUploadsComponent } from '../../shared/input-solution-references/reference-handler-uploads/reference-handler-uploads.component';
import { SolverFormStoreService } from '../store/solver-form-store.service';
import { CommonModule } from '@angular/common';
import { firstValueFrom } from 'rxjs';
import { InputSolutionReferencesComponent } from '../../shared/input-solution-references/input-solution-references.component';
import { SolutionReferences } from '../../shared/input-solution-references/types/input-solution-references-types';
@Component({
    selector: 'app-solver-flow-step-1',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        QuillEditorComponent,
        InputErrorMessageComponent,
        MatStepper,
        MatStepperModule,
        MatFormFieldModule,
        MatInputModule,
        InputSolutionReferencesComponent,
    ],
    templateUrl: './solver-flow-step-1.component.html',
    styleUrl: './solver-flow-step-1.component.scss',
})
export class SolverFlowStep1Component implements OnInit {
    solverFormStep1!: FormGroup;
    referenceUrls: string[] = [];
    @Output() successEvent = new EventEmitter<number>();
    @ViewChild(ReferenceHandlerUploadsComponent)
    referenceHandlerUploadsComponent!: ReferenceHandlerUploadsComponent;
    solutionReferences: SolutionReferences = {} as SolutionReferences;

    constructor(
        private fb: FormBuilder,
        private solverFlowService: SolverFlowService,
        private alertService: SweetAlertService,
        protected validationMessageService: ValidationMessageService,
        public readonly solverFormStoreService: SolverFormStoreService
    ) {
        this.step1Form();
    }

    step1Form() {
        this.solverFormStep1 = this.fb.group({
            defineProblem: ['', Validators.required],
            problemBreakdown: ['', Validators.required],
            infoAssessment: ['', Validators.required],
            solutionExplorationInnovation: ['', Validators.required],
            solutionImplementation: ['', Validators.required],
        });
    }

    async ngOnInit(): Promise<void> {
        this.solverFormStoreService.selectForm1$.subscribe((data) => {
            this.patchForm(data);
        });
    }

    patchForm(data: any) {
        this.solverFormStep1.patchValue({
            defineProblem: data?.defineProblem || '',
            problemBreakdown: data?.problemBreakdown || '',
            infoAssessment: data?.infoAssessment || '',
            solutionExplorationInnovation:
                data?.solutionExplorationInnovation || '',
            solutionImplementation: data?.solutionImplementation || '',
        });
        this.solutionReferences = data?.solutionReferences;
    }

    async onSubmit(isDraft: boolean = false) {
        this.solverFormStep1.markAllAsTouched();
        const challengeId = await firstValueFrom(
            this.solverFormStoreService.selectChallengeId$
        );
        const solutionId = await firstValueFrom(
            this.solverFormStoreService.selectSolutionId$
        );

        if (this.solverFormStep1.valid || isDraft) {
            const formValues = this.solverFormStep1.value;
            const solverStep1Data: solverStep1Dto = {
                defineProblem: formValues.defineProblem,
                problemBreakdown: formValues.problemBreakdown,
                infoAssessment: formValues.infoAssessment,
                solutionExplorationInnovation:
                    formValues.solutionExplorationInnovation,
                solutionImplementation: formValues.solutionImplementation,
                solutionReferences: formValues.solutionReferences,
            };

            // First check for the challengeId
            if (challengeId) {
                // If challengeId exists, check if we have solutionId
                if (solutionId) {
                    // If solutionId exists, make API call with both challengeId and solutionId
                    this.solverFlowService
                        .solutionExplorer(
                            solverStep1Data,
                            challengeId,
                            solutionId
                        )
                        .subscribe({
                            next: async (response) => {
                                const solutionId = await firstValueFrom(
                                    this.solverFormStoreService
                                        .selectSolutionId$
                                );
                                this.successEvent.emit(solutionId as number);
                            },
                            error: (error: HttpErrorResponse) => {
                                if (error.status === 422) {
                                    const errors = error?.error?.errors;
                                    if (errors) {
                                        this.validationMessageService.setServerErrors(
                                            this.solverFormStep1,
                                            errors
                                        );
                                    }
                                } else {
                                    this.alertService.errorPopup(
                                        error.error.message
                                    );
                                }
                            },
                        });
                } else {
                    // Call the API with only challengeId for the first submission
                    this.solverFlowService
                        .solutionExplorer(solverStep1Data, challengeId)
                        .subscribe({
                            next: (response) => {
                                this.solverFormStoreService.updateSolutionId(
                                    response.id
                                );
                                // On successful submission, save the solutionId for future requests
                                this.alertService.successPopup(
                                    response.message
                                );
                                this.successEvent.emit(response.id); // Emitting the solution id for subsequent calls
                            },
                            error: (error: HttpErrorResponse) => {
                                if (error.status === 422) {
                                    const errors = error?.error?.errors;
                                    if (errors) {
                                        this.validationMessageService.setServerErrors(
                                            this.solverFormStep1,
                                            errors
                                        );
                                    }
                                } else {
                                    this.alertService.errorPopup(
                                        error.error.message
                                    );
                                }
                            },
                        });
                }
            } else {
                // If no challengeId exists, handle the error or redirect logic here
                this.alertService.errorPopup('Challenge ID is missing.');
            }
        }
    }
}
