import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Output } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { InputErrorMessageComponent } from '../input-error-message/input-error-message.component';
import { Store } from '@ngrx/store';
import { LaunchChallengeState } from '../../core/store/launchChallengeForm/launchChallenge.state';
import { SdgsDto } from '../../core/Model/sdgs';
import * as LaunchChallengeActions from '../../core/store/launchChallengeForm/launchChallenge.actions';
import { selectChallengeId, selectSdgsError } from '../../core/store/launchChallengeForm/launchChallenge.selectors';
import { of, Subject, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { ChallengeService } from '../../core/services/apis/challenge.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-sdgs-form',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, InputErrorMessageComponent],
    templateUrl: './sdgs-form.component.html',
    styleUrl: './sdgs-form.component.scss',
})
export class SdgsFormComponent {
    route:ActivatedRoute=inject(ActivatedRoute);
    router:Router=inject(Router);
    sdgs = [
        { id: 1, name: 'No Poverty', img: '/images/SDG/no-poverty.svg' },
        { id: 2, name: 'Zero Hunger', img: '/images/SDG/zero-hunger.svg' },
        {
            id: 3,
            name: 'Good Health and Well-being',
            img: '/images/SDG/good-health.svg',
        },
        {
            id: 4,
            name: 'Quailty Education',
            img: '/images/SDG/quailty-education.svg',
        },
        {
            id: 5,
            name: 'Gender Equality',
            img: '/images/SDG/gender-equailty.svg',
        },
        {
            id: 6,
            name: 'CLEAN WATER AND SANITATION',
            img: '/images/SDG/clean-water.svg',
        },
        {
            id: 7,
            name: 'AFFORDABLE AND CLEAN ENERGY',
            img: '/images/SDG/affordable-clean-energy.svg',
        },
        {
            id: 8,
            name: 'DECENT WORK AND ECONOMIC GROWTH',
            img: '/images/SDG/decent-work.svg',
        },
        {
            id: 9,
            name: 'INDUSTRY, INNOVATION AND INFRASTRUCTURE',
            img: '/images/SDG/industory-innovation.svg',
        },
        {
            id: 10,
            name: 'REDUCED INEQUAILITIES',
            img: '/images/SDG/reduced-inequailties.svg',
        },
        {
            id: 11,
            name: 'SUSTANABLE CITIES AND COMMUNITIES',
            img: '/images/SDG/sustainable-cities.svg',
        },
        {
            id: 12,
            name: 'RESPONSIBLE CONSUMPTION AND PRODUCTION',
            img: '/images/SDG/responsible-consumption.svg',
        },
        {
            id: 13,
            name: 'CLIMATE ACTIONS',
            img: '/images/SDG/climate-action.svg',
        },
        {
            id: 14,
            name: 'LIFE BELOW WATER',
            img: '/images/SDG/life-below-water.svg',
        },
        { id: 15, name: 'LIFE ON LAND', img: '/images/SDG/life-on-land.svg' },
        {
            id: 16,
            name: 'PEACE, JUSTICE AND STRONG INSTITUTIONS',
            img: '/images/SDG/peace-justice.svg',
        },
        {
            id: 17,
            name: 'PARTNERSHIPS FOR THE GOALS',
            img: '/images/SDG/partnership-for-goals.svg',
        },
    ];

    selectedSdgs: number[] = [];
    sdgForm!: FormGroup;
    private unsubscribe$ = new Subject<void>();
    challengeId: any;
    @Output() successEvent = new EventEmitter<void>();


    constructor(
        private fb: FormBuilder,
        private store: Store<LaunchChallengeState>,
        protected validationMessageService: ValidationMessageService,
        private challengeService: ChallengeService,
        private alertService: SweetAlertService
    ) {
        this.sdgsForm();
    }

    sdgsForm(){
        this.sdgForm = this.fb.group({
            sdgExplanations: this.fb.array([]),
        });
    }

    ngOnInit(): void {
        this.challengeId=this.route.snapshot.queryParams['id'];
        if(this.challengeId){
            this.loadSdgs();
        }
    }

    get sdgExplanations(): FormArray {
        return this.sdgForm.get('sdgExplanations') as FormArray;
    }

    getFormGroup(control: AbstractControl): FormGroup {
        return control as FormGroup;
    }

    onSdgToggle(sdgId: number): void {
        const index = this.selectedSdgs.indexOf(sdgId);

        if (index === -1) {
            // SDG selected
            this.selectedSdgs.push(sdgId);
            this.sdgExplanations.push(
                this.fb.group({
                    sdgId: [sdgId],
                    explanation: ['', Validators.required],
                })
            );
        } else {
            // SDG deslected
            this.selectedSdgs.splice(index, 1);
            this.sdgExplanations.removeAt(index);
        }
    }

    isSdgSelected(sdgId: number): boolean {
        return this.selectedSdgs.includes(sdgId);
    }

    loadSdgs(): void {
        const challengeId = this.challengeId; // Replace this with the actual challengeId obtained in step 1

        this.challengeService.getSdgs(challengeId).subscribe({
            next: (sdgs:any) => {
                if (sdgs) {
                    sdgs.data.forEach((sdg:any) => {
                        // Select the SDG and add it to the form array
                        this.selectedSdgs.push(sdg.id);
                        this.sdgExplanations.push(
                            this.fb.group({
                                sdgId: [sdg.id],
                                explanation: [sdg.explanation, Validators.required],
                            })
                        );
                    });
                }
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorPopup(
                    error.error.message
                );
                console.error('Failed to load SDGs:', error);
                // You can also display an error popup if necessary
                // this.alertService.errorPopup(error.error.message);
            }
        });
    }



    // onSubmit() {
    //     this.sdgForm.markAllAsTouched();

    //     if (this.sdgForm.valid) {
    //         // Clear existing server errors
    //         this.validationMessageService.clearServerErrors(this.sdgForm);

    //         const sdgsData: SdgsDto[] = this.sdgExplanations.value.map(
    //             (explanationGroup: any) => ({
    //                 id: explanationGroup.sdgId,
    //                 explanation: explanationGroup.explanation,
    //             })
    //         );

    //         this.store
    //             .select(selectChallengeId)
    //             .pipe(take(1))
    //             .subscribe((challengeId) => {
    //                 if (challengeId) {
    //                     // Dispatch the update action
    //                     this.store.dispatch(
    //                         LaunchChallengeActions.updateSdgs({
    //                             challengeId,
    //                             data: { sdgs: sdgsData },
    //                         })
    //                     );

    //                     // Wait for the error state to be updated
    //                     this.store
    //                         .pipe(
    //                             withLatestFrom(this.store.select(selectSdgsError)),
    //                             tap(([_, error]) => {
    //                                 if (error) {
    //                                     this.validationMessageService.setServerErrors(
    //                                         this.sdgForm,
    //                                         error
    //                                     );
    //                                 }else {
    //                                     // Clear server errors if submission is successful
    //                                     this.validationMessageService.clearServerErrors(
    //                                         this.sdgForm
    //                                     );
    //                                 }
    //                             })
    //                         )
    //                         .subscribe();
    //                 }
    //             });
    //     }
    // }
    onSubmit() {
        this.sdgForm.markAllAsTouched();

        if (this.sdgForm.valid) {
            const challengeId = this.route.snapshot.queryParams['id']; // Replace this with the actual challengeId obtained in step 1
            const sdgsData: SdgsDto[] = this.sdgExplanations.value.map(
                (explanationGroup: any) => ({
                    id: explanationGroup.sdgId,
                    explanation: explanationGroup.explanation,
                })
            );

            // Clear existing server errors
            this.validationMessageService.clearServerErrors(this.sdgForm);

            // Make the API call to update the SDGs
            this.challengeService.updateSdgs(challengeId, { sdgs: sdgsData })
                .subscribe({
                    next: (response: any) => {
                        // Handle successful response
                        this.alertService.successPopup(response.message);
                        this.successEvent.emit();
                        // Additional success handling if needed
                    },
                    error: (error: HttpErrorResponse) => {
                        // Handle error response
                        if (error.status === 422) {
                            const errors = error?.error?.errors;
                            if (errors) {
                                this.validationMessageService.setServerErrors(
                                    this.sdgForm,
                                    errors
                                );
                            }
                        } else {
                            this.alertService.errorPopup(
                                error.error.message
                            );
                        }
                    },
                });
        }
    }

}
