<div class="container">
    <form [formGroup]="rewardForm" (ngSubmit)="onSubmit()">
        <label class="form-label fw-semibold">Select Reward Type<br>
            <span class="info-msg">Describe any additional benefits for winners, such as mentorship, incubation support,
                or networking opportunities</span></label>
        <div class="prize-container" [ngClass]="{'disabled': !rewardForm.get('prizeMoney')?.value}">
            <input type="checkbox" id="prizeMoney" formControlName="prizeMoney"
                [checked]="rewardForm.get('prizeMoney')?.value" (change)="toggleDiv('prizeMoney')">
            <label for="prizeMoney">Prize money</label>

            <div id="prizeMoneyDiv">
                <!-- First Prize -->
                <div class="form-group row">
                    <label for="firstPrize" class="col-md-4 col-form-label">First Prize <span
                            style="color: #B21818;">*</span></label>
                    <div class="col-md-5" style="padding-left: 0;">
                        <div class="input-select-wrapper">
                            <input id="firstPrize" formControlName="firstPrize" placeholder="Please enter amount"
                                class="form-control" type="number" [disabled]="!rewardForm.get('prizeMoney')?.value">
                            <select class="form-select" formControlName="firstPrizeCurrencyCode"
                                [disabled]="!rewardForm.get('prizeMoney')?.value">
                                <option *ngFor="let currency of currencyOptions" [value]="currency">{{ currency }}
                                </option>
                            </select>
                        </div>
                        <div
                            *ngIf="rewardForm.get('firstPrizeCurrencyCode')?.touched && rewardForm.get('firstPrizeCurrencyCode')?.invalid">
                            <small class="text-danger">Currency code is required.</small>
                        </div>
                        <app-input-error-message [formData]="rewardForm" [controlName]="'firstPrize'">
                        </app-input-error-message>
                        <!-- <app-input-error-message [formData]="rewardForm" [controlName]="'firstPrizeCurrencyCode'">
                        </app-input-error-message> -->
                    </div>
                </div>

                <!-- Second Prize -->
                <div class="form-group row">
                    <label for="secondPrize" class="col-md-4 col-form-label">Second Prize</label>
                    <div class="col-md-5" style="padding-left: 0;">
                        <div class="input-select-wrapper">
                            <input id="secondPrize" formControlName="secondPrize"
                                placeholder="(Optional) Please enter amount" class="form-control" type="number"
                                [disabled]="!rewardForm.get('prizeMoney')?.value">
                            <select class="form-select" formControlName="secondPrizeCurrencyCode" [disabled]="true">
                                <!-- Keep disabled as it will be synced with firstPrizeCurrencyCode -->
                                <option *ngFor="let currency of currencyOptions" [value]="currency">{{currency}}
                                </option>
                            </select>
                        </div>
                        <app-input-error-message [formData]="rewardForm" [controlName]="'secondPrize'">
                        </app-input-error-message>
                    </div>
                </div>
                <!-- Third Prize -->
                <div class="form-group row">
                    <label for="thirdPrize" class="col-md-4 col-form-label">Third Prize</label>
                    <div class="col-md-5" style="padding-left: 0;">
                        <div class="input-select-wrapper">
                            <input id="thirdPrize" formControlName="thirdPrize"
                                placeholder="(Optional) Please enter amount" class="form-control" type="number"
                                [disabled]="!rewardForm.get('prizeMoney')?.value">
                            <select class="form-select" formControlName="thirdPrizeCurrencyCode" [disabled]="true">
                                <!-- Keep disabled as it will be synced with firstPrizeCurrencyCode -->
                                <option *ngFor="let currency of currencyOptions" [value]="currency">{{currency}}
                                </option>
                            </select>
                        </div>
                        <app-input-error-message [formData]="rewardForm" [controlName]="'thirdPrize'">
                        </app-input-error-message>
                        <app-input-error-message [formData]="rewardForm" [controlName]="'currencyCode'">
                        </app-input-error-message>
                    </div>
                </div>
                <!-- Challenges.one Commission -->
                <div class="form-group row">
                    <label for="commission" class="col-md-4 col-form-label">Challenges.one Commission
                        <span style="color: #B21818;">*</span>
                    </label>
                    <div class="col-md-5" style="padding-left: 0;">
                        <input id="commission" formControlName="commission" placeholder="Please enter amount"
                            class="form-control" type="number" [disabled]="!rewardForm.get('prizeMoney')?.value">
                        <app-input-error-message [formData]="rewardForm" [controlName]="'commission'">
                        </app-input-error-message>
                    </div>

                </div>
                <!-- Total Amount -->
                <div class="form-group row">
                    <label for="totalAmount" class="col-md-4 col-form-label">Total Amount</label>
                    <div class="col-md-5" style="padding-left: 0;">
                        <input id="totalAmount" formControlName="totalAmount"
                            class="form-control" [disabled]="!rewardForm.get('prizeMoney')?.value">
                        <app-input-error-message [formData]="rewardForm" [controlName]="'totalAmount'">
                        </app-input-error-message>
                    </div>

                </div>
                <!-- Details -->
                <div class="form-group row">
                    <label for="details" class="col-md-4 col-form-label">Details</label>
                    <div class="col-md-8" style="padding-left: 0;">
                        <input id="details" formControlName="details"
                            placeholder="(Optional) Please enter details about the prize money here"
                            class="form-control" [disabled]="!rewardForm.get('prizeMoney')?.value">
                        <app-input-error-message [formData]="rewardForm" [controlName]="'details'">
                        </app-input-error-message>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" style="margin-bottom: 20px;">
            <div class="col-md-4">
                <input type="checkbox" id="virtualInternship" formControlName="virtualInternship"
                    (change)="toggleFields('virtualInternship', ['virtualInternshipInput'])">
                <label for="virtualInternship">Virtual Internship</label>
            </div>

            <div class="col-md-8">
                <div id="virtualInternshipDiv">
                    <div class="form-group">
                        <input id="virtualInternshipInput" formControlName="virtualInternshipInput"
                            placeholder="Please specify details here" class="form-control">
                        <app-input-error-message [formData]="rewardForm" [controlName]="'virtualInternshipInput'">
                        </app-input-error-message>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-bottom: 20px;">
            <div class="col-md-4">
                <input type="checkbox" id="microCredential" formControlName="microCredential"
                    (change)="toggleFields('microCredential', ['microCredentialDetailsInput'])">
                <label for="microCredential">Micro Credential</label>
            </div>
            <div class="col-md-8">
                <div id="microCredentialDiv">
                    <div class="form-group">
                        <input id="microCredentialDetailsInput" formControlName="microCredentialDetailsInput"
                            placeholder="Please specify details here" class="form-control">
                        <app-input-error-message [formData]="rewardForm" [controlName]="'microCredentialDetailsInput'">
                        </app-input-error-message>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-bottom: 20px;">
            <div class="col-md-4">
                <input type="checkbox" id="potentialHiring" formControlName="potentialHiring"
                    (change)="toggleFields('potentialHiring', ['potentialHiringDetailsInput'])">
                <label for="potentialHiring">Potential Hiring</label>
            </div>
            <div class="col-md-8">
                <div id="potentialHiringDiv">
                    <div class="form-group">
                        <input id="potentialHiringDetailsInput" formControlName="potentialHiringDetailsInput"
                            placeholder="Please specify details here" class="form-control">
                        <app-input-error-message [formData]="rewardForm" [controlName]="'potentialHiringDetailsInput'">
                        </app-input-error-message>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-bottom: 20px;">
            <div class="col-md-4">
                <input type="checkbox" id="publicationOpportunity" formControlName="publicationOpportunity"
                    (change)="toggleFields('publicationOpportunity', ['publicationOpportunityDetailsInput'])">
                <label for="publicationOpportunity">Publication Opportunity</label>
            </div>
            <div class="col-md-8">
                <div id="publicationOpportunityDiv">
                    <div class="form-group">
                        <input id="publicationOpportunityDetailsInput"
                            formControlName="publicationOpportunityDetailsInput"
                            placeholder="Please specify details here" class="form-control">
                        <app-input-error-message [formData]="rewardForm"
                            [controlName]="'publicationOpportunityDetailsInput'">
                        </app-input-error-message>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-bottom: 20px;">
            <div class="col-md-4">
                <input type="checkbox" id="equityOrRoyalties" formControlName="equityOrRoyalties"
                    (change)="toggleFields('equityOrRoyalties', ['equaityRoyaltiesInput'])">
                <label for="equaityRoyalties">Equity or Royalties</label>
            </div>
            <div class="col-md-8">
                <div id="equaityRoyaltiesDiv">
                    <div class="form-group">
                        <input id="equaityRoyaltiesInput" formControlName="equaityRoyaltiesInput"
                            placeholder="Please specify details here" class="form-control">
                        <app-input-error-message [formData]="rewardForm" [controlName]="'equaityRoyaltiesInput'">
                        </app-input-error-message>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-bottom: 20px;">
            <div class="col-md-4">
                <input type="checkbox" id="fundingProposedSoultions" formControlName="fundingProposedSoultions"
                    (change)="toggleFields('fundingProposedSoultions', ['fundingProposedSoultionsInput'])">
                <label for="fundingProposedSoultions">Funding for Proposed Solutions</label>
            </div>
            <div class="col-md-8">
                <div id="fundingProposedSoultionsDiv">
                    <div class="form-group">
                        <input id="fundingProposedSoultionsInput" formControlName="fundingProposedSoultionsInput"
                            placeholder="Please specify details here" class="form-control">
                        <app-input-error-message [formData]="rewardForm"
                            [controlName]="'fundingProposedSoultionsInput'">
                        </app-input-error-message>
                    </div>
                </div>
            </div>

        </div>
        <div class="row" style="margin-bottom: 20px;">
            <div class="col-md-4">
                <input type="checkbox" id="others" formControlName="others"
                    (change)="toggleFields('others', ['othersInput'])">
                <label for="others">Other Incentives</label>
            </div>
            <div class="col-md-8">
                <div id="othersDiv">
                    <div class="form-group">
                        <input id="othersInput" formControlName="othersInput" placeholder="Please specify details here" class="form-control">
                        <app-input-error-message [formData]="rewardForm" [controlName]="'othersInput'">
                        </app-input-error-message>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
