import { CommonModule } from '@angular/common';
import {
    Component,
    importProvidersFrom,
    inject,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';

import { ProblemStatementFormComponent } from '../../shared/problem-statement-form/problem-statement-form.component';
import { ChallengeDetailsFormComponent } from '../../shared/challenge-details-form/challenge-details-form.component';
import { PrizesRewardFormComponent } from '../../shared/prizes-reward-form/prizes-reward-form.component';
import { SdgsFormComponent } from '../../shared/sdgs-form/sdgs-form.component';
import { BasicInformationFormComponent } from '../../shared/basic-information-form/basic-information-form.component';
import { DotWrapperComponent } from '../../shared/dot-wrapper/dot-wrapper.component';
import {
    catchError,
    EMPTY,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs';
import { LaunchChallengeState } from '../../core/store/launchChallengeForm/launchChallenge.state';
import { MemoizedSelector, select, Store } from '@ngrx/store';
import { MatStepper } from '@angular/material/stepper';
import { MatStepperModule } from '@angular/material/stepper';
import {
    selectBasicInformationError,
    selectBasicInformationSuccess,
    selectChallengeId,
    selectLaunchChallengeState,
} from '../../core/store/launchChallengeForm/launchChallenge.selectors';
import { GuidelinesInformationFormComponent } from '../../shared/guidelines-information-form/guidelines-information-form.component';
import { TimelinesFormComponent } from '../../shared/timelines-form/timelines-form.component';
import { EvaluationCriteriaFormComponent } from '../../shared/evaluation-criteria-form/evaluation-criteria-form.component';
import * as LaunchChallengeActions from '../../core/store/launchChallengeForm/launchChallenge.actions';
import { ResourcesFormComponent } from '../../shared/resources-form/resources-form.component';
import { FaqsFormComponent } from '../../shared/faqs-form/faqs-form.component';
import { AbstractControl, FormGroup } from '@angular/forms';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { ChallengeService } from '../../core/services/apis/challenge.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Route, Router, RouterModule } from '@angular/router';
import { showStatus } from '../../shared/utility/checkChallengeStatus';
@Component({
    selector: 'app-launch-a-challenge',
    standalone: true,
    imports: [
        CommonModule,
        ProblemStatementFormComponent,
        ChallengeDetailsFormComponent,
        PrizesRewardFormComponent,
        SdgsFormComponent,
        BasicInformationFormComponent,
        DotWrapperComponent,
        GuidelinesInformationFormComponent,
        TimelinesFormComponent,
        EvaluationCriteriaFormComponent,
        MatStepperModule,
        ResourcesFormComponent,
        FaqsFormComponent,
        RouterModule,
    ],
    templateUrl: './launch-a-challenge.component.html',
    styleUrl: './launch-a-challenge.component.scss',
})
export class LaunchAChallengeComponent implements OnInit, OnDestroy {
    @ViewChild(MatStepper) stepper!: MatStepper;
    @ViewChild(BasicInformationFormComponent)
    basicInformationFormComponent?: BasicInformationFormComponent;
    @ViewChild(ProblemStatementFormComponent)
    problemStatementFormComponent?: ProblemStatementFormComponent;
    @ViewChild(ChallengeDetailsFormComponent)
    challengeDetailsFormComponent?: ChallengeDetailsFormComponent;
    @ViewChild(SdgsFormComponent)
    SdgsFormComponent?: SdgsFormComponent;
    @ViewChild(PrizesRewardFormComponent)
    prizesRewardFormComponent?: PrizesRewardFormComponent;
    @ViewChild(GuidelinesInformationFormComponent)
    GuidelinesInformationFormComponent?: GuidelinesInformationFormComponent;
    @ViewChild(EvaluationCriteriaFormComponent)
    EvaluationCriteriaFormComponent?: EvaluationCriteriaFormComponent;
    @ViewChild(TimelinesFormComponent)
    TimelinesFormComponent?: TimelinesFormComponent;
    @ViewChild(ResourcesFormComponent)
    ResourcesFormComponent?: ResourcesFormComponent;
    @ViewChild(FaqsFormComponent)
    FaqsFormComponent?: FaqsFormComponent;
    challengeId: any;
    isPreviewMode: boolean = false;
    route: ActivatedRoute = inject(ActivatedRoute);
    router: Router = inject(Router);
    receivedStatus: any;
    receivedTimeline: any;
    isEditMode: boolean = false;
    queryParams: any = {};
    currentStep: number = 1;
    showStatus = showStatus;
    // stepsCompleted: boolean[] = Array(10).fill(false);
    private unsubscribe$ = new Subject<void>();
    steps = [
        { title: 'Basic Information' },
        { title: 'Problem Statement' },
        { title: 'Challenge Details' },
        { title: 'SDGs' },
        { title: 'Prizes & Rewards' },
        { title: 'Guidelines & Information' },
        { title: 'Evaluation Criteria' },
        { title: 'Timelines' },
        { title: 'Resources' },
        { title: 'FAQs' },
    ];
    stepsCompleted: boolean[] = Array(this.steps.length).fill(false);

    constructor(
        private store: Store<{ launchChallenge: LaunchChallengeState }>,
        protected validationMessageService: ValidationMessageService,
        private alertService: SweetAlertService,
        private challengeService: ChallengeService,
        private renderer: Renderer2
    ) {
        this.challengeId = this.route.snapshot.queryParams['id'];
        this.route.queryParams.subscribe((params) => {
            this.isEditMode = !!params['id'];
        });
    }

    // fetch status from child
    handleStatusFetched(status: any) {
        console.log('status in handleStatusFetched = ', status);
        this.receivedStatus = status;
        // this.receivedStatus=this.showStatus(this.receivedTimeline.startDate,this.receivedTimeline.endDate,status)
        console.log('status in handleStatusFetched = ', this.receivedStatus);
    }

    handleTimelineFetched(timeline:any){
        console.log("timeline in handleTimelinefetched - ",timeline)
        this.receivedTimeline=timeline
    }
    
    // showStatus(startDate: string, endDate: string, status: string,): string {
    //     if (status === 'DRAFT') {
          
    //       return 'Draft';
    
    //     } else if (status === 'PUBLISH' && new Date(startDate).getTime() > new Date().getTime()) {
          
          
    //       return 'Upcoming';
    
    //     }
    
    
    
    
    
    //     else if (status === 'PUBLISH' && new Date(startDate).getTime() < new Date().getTime() && new Date().getTime() < new Date(endDate).getTime()) {
          
          
    //       return 'Open';
    
    
    //     } else if (status === 'PUBLISH' && new Date(endDate).getTime() < new Date().getTime()) {
          
          
    //       return 'Closed';
    
    //     } {
         
    //       return 'NA';
    //     }
    //   }
    ngOnInit() {
        this.handleStepChanges();
        this.route.url.subscribe((segments) => {
            this.isPreviewMode = segments.some(
                (segment) => segment.path === 'preview-challenge-details'
            );
        });

        // const stepParam = this.route.snapshot.queryParamMap.get('step');
        // this.currentStep = stepParam ? +stepParam : 1;
        // this.queryParams['step'] = this.currentStep;

        // this.stepper.selectedIndex = this.currentStep - 1;
    }

    ngAfterViewInit() {
        // Ensure `stepper` is initialized
        this.handleStepChanges();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    handleStepChange(event: any): void {
        const previousStep = this.currentStep;
        this.currentStep = event.selectedIndex + 1;

        // Clear validation messages when navigating back as well as  when navigating forward
        if (this.currentStep < previousStep || this.currentStep > previousStep) {
            this.clearFormValidation();
        }
        this.updateStepperLines();
      }
      private updateStepperLines(): void {
        const stepHeaders = document.querySelectorAll('.mat-horizontal-stepper-header');
        stepHeaders.forEach((header, index) => {
            if (this.stepsCompleted[index]) {
                this.renderer.addClass(header, 'completed-stepper');
            } else {
                this.renderer.removeClass(header, 'completed-stepper');
            }
        });
    }

    // Method to clear form validation for the current step
    clearFormValidation(): void {
        switch (this.currentStep) {
            case 1:
                this.basicInformationFormComponent?.basicInformationForm.reset();
                break;
            case 2:
                this.problemStatementFormComponent?.problemStatementForm.reset();
                break;
            case 3:
                this.challengeDetailsFormComponent?.challengeDetailsForm.reset();
                break;
            case 4:
                this.SdgsFormComponent?.sdgForm.reset();
                break;
            case 5:
                this.prizesRewardFormComponent?.rewardForm.reset();
                break;
            case 6:
                this.GuidelinesInformationFormComponent?.guidelinesForm.reset();
                break;
            case 7:
                this.EvaluationCriteriaFormComponent?.evaluationCriteriaForm.reset();
                break;
            case 8:
                this.TimelinesFormComponent?.timelineForm.reset();
                break;
            case 9:
                this.ResourcesFormComponent?.resourcesForm.reset();
                break;
            case 10:
                this.FaqsFormComponent?.faqsForm.reset();
                break;
        }
    }

    getStepControl(stepIndex: number): AbstractControl {
        if (this.isEditMode) {
            return new FormGroup({});
        }

        switch (stepIndex) {
            case 0:
                return (
                    this.basicInformationFormComponent?.basicInformationForm ??
                    new FormGroup({})
                );
            // case 1:
            //     return (
            //         this.problemStatementFormComponent?.problemStatementForm ??
            //         new FormGroup({})
            //     );
            // case 2:
            //     return (
            //         this.challengeDetailsFormComponent?.challengeDetailsForm ??
            //         new FormGroup({})
            //     );
            // case 3:
            //     return this.SdgsFormComponent?.sdgForm ?? new FormGroup({});
            // case 4:
            //     return (
            //         this.prizesRewardFormComponent?.rewardForm ??
            //         new FormGroup({})
            //     );
            // case 5:
            //     return (
            //         this.GuidelinesInformationFormComponent?.guidelinesForm ??
            //         new FormGroup({})
            //     );
            // case 6:
            //     return (
            //         this.EvaluationCriteriaFormComponent
            //             ?.evaluationCriteriaForm ?? new FormGroup({})
            //     );
            // case 7:
            //     return (
            //         this.TimelinesFormComponent?.timelineForm ??
            //         new FormGroup({})
            //     );
            // case 8:
            //     return (
            //         this.ResourcesFormComponent?.resourcesForm ??
            //         new FormGroup({})
            //     );
            // case 9:
            //     return this.FaqsFormComponent?.faqsForm ?? new FormGroup({});
            default:
                return new FormGroup({});
        }
    }
    handleIdGenerated(id: string) {
        this.queryParams['id'] = id;
        this.router.navigate(['/launch-a-challenge'], {
            queryParams: { id: id, step: this.queryParams['step'] },
        });
    }

    onSaveAndNext(): void {
        // let formValid = false;
        switch (this.currentStep) {
            case 1:
                this.basicInformationFormComponent?.basicInformationForm.markAllAsTouched();
                // formValid =
                //     this.basicInformationFormComponent?.basicInformationForm
                //         .valid ?? false;
                // if (formValid) {
                //     this.basicInformationFormComponent?.onSubmit();
                // }
                this.basicInformationFormComponent?.onSubmit();
                break;
            case 2:
                this.problemStatementFormComponent?.problemStatementForm.markAllAsTouched();
                // formValid =
                //     this.problemStatementFormComponent?.problemStatementForm
                //         .valid ?? false;
                // if (formValid) {
                //     this.problemStatementFormComponent?.onSubmit();
                // }
                this.problemStatementFormComponent?.onSubmit();
                break;
            case 3:
                this.challengeDetailsFormComponent?.challengeDetailsForm.markAllAsTouched();
                // formValid =
                //     this.challengeDetailsFormComponent?.challengeDetailsForm
                //         .valid ?? false;
                // if (formValid) {
                //     this.challengeDetailsFormComponent?.onSubmit();
                // }
                this.challengeDetailsFormComponent?.onSubmit();
                break;
            case 4:
                this.SdgsFormComponent?.sdgForm.markAllAsTouched();
                // formValid = this.SdgsFormComponent?.sdgForm.valid ?? false;
                // if (formValid) {
                //     this.SdgsFormComponent?.onSubmit();
                // }
                this.SdgsFormComponent?.onSubmit();
                break;
            case 5:
                this.prizesRewardFormComponent?.rewardForm.markAllAsTouched();
                // formValid =
                //     this.prizesRewardFormComponent?.rewardForm.valid ?? false;
                // if (formValid) {
                //     this.prizesRewardFormComponent?.onSubmit();
                // }
                this.prizesRewardFormComponent?.onSubmit();
                break;
            case 6:
                this.GuidelinesInformationFormComponent?.guidelinesForm.markAllAsTouched();
                // formValid =
                //     this.GuidelinesInformationFormComponent?.guidelinesForm
                //         .valid ?? false;
                // if (formValid) {
                //     this.GuidelinesInformationFormComponent?.onSubmit();
                // }
                this.GuidelinesInformationFormComponent?.onSubmit();
                break;
            case 7:
                this.EvaluationCriteriaFormComponent?.evaluationCriteriaForm.markAllAsTouched();
                // formValid =
                //     this.EvaluationCriteriaFormComponent?.evaluationCriteriaForm
                //         .valid ?? false;
                // if (formValid) {
                //     this.EvaluationCriteriaFormComponent?.onSubmit();
                // }
                this.EvaluationCriteriaFormComponent?.onSubmit();
                break;
            case 8:
                this.TimelinesFormComponent?.timelineForm.markAllAsTouched();
                // formValid =
                //     this.TimelinesFormComponent?.timelineForm.valid ?? false;
                // if (formValid) {
                //     this.TimelinesFormComponent?.onSubmit();
                // }
                this.TimelinesFormComponent?.onSubmit();
                break;
            case 9:
                this.ResourcesFormComponent?.resourcesForm.markAllAsTouched();
                // formValid =
                //     this.ResourcesFormComponent?.resourcesForm.valid ?? false;
                // if (formValid) {
                //     this.ResourcesFormComponent?.onSubmit();
                // }
                this.ResourcesFormComponent?.onSubmit();
                break;
            case 10:
                this.FaqsFormComponent?.faqsForm.markAllAsTouched();
                // formValid = this.FaqsFormComponent?.faqsForm.valid ?? false;
                // if (formValid) {
                //     this.FaqsFormComponent?.onAddFaq();
                // }
                this.FaqsFormComponent?.onAddFaq();
                break;
        }
        // this.stepper.next();
        // // Check for errors after dispatching the form submission
        // this.store
        //     .pipe(
        //         withLatestFrom(
        //             this.store.select(selectBasicInformationError),
        //             this.store.select(selectBasicInformationSuccess)
        //         ),
        //         tap(([_, error, success]) => {
        //             if (error) {
        //                 // If there's an error, stay on the current step and display the error message
        //                 // this.alertService.errorPopup(
        //                 //     'Submission failed. Please correct the errors.'
        //                 // );
        //             } else if (success) {
        //                 this.stepper.next();
        //                 // If no errors, proceed to the next step
        //                 this.stepsCompleted[this.currentStep] = true;
        //                  // Proceed to the next step
        //             }
        //         })
        //     )
        //     .subscribe();
        // if (formValid) {
        //     this.stepsCompleted[this.currentStep] = true;
        // this.stepper.next(); // Proceed to the next step
        // }
        // this.currentStep += 0;
        // this.queryParams['step'] = this.currentStep;

        // let id = this.route.snapshot.queryParamMap.get('id');
        // // let step = this.route.snapshot.queryParamMap.get('step');
        // this.router.navigate(['/launch-a-challenge'], {
        //     queryParams: { id: id, step: this.queryParams['step'] },
        // });
        // this.stepper.selectedIndex = this.currentStep - 2;
        // this.stepper.next();
        // this.currentStep++;
        // this.queryParams['step'] = this.currentStep;

        // Only navigate if 'id' is already set
        // if (this.queryParams['id']) {
        //   this.router.navigate(['/launch-a-challenge'], {
        //     queryParams: { id: this.queryParams['id'], step: this.queryParams['step'] },
        //   });
        // }
    }

    handleStepSuccess() {
        // Mark current step as completed before navigating
        if (this.currentStep > 0) {
            this.stepsCompleted[this.currentStep - 1] = true;
        }

        this.currentStep++;
        this.queryParams['step'] = this.currentStep;

        if (this.queryParams['id']) {
            this.router.navigate(['/launch-a-challenge'], {
                queryParams: {
                    id: this.queryParams['id'],
                    step: this.queryParams['step'],
                },
            });
        }

        this.stepper.next(); // Proceed to the next step
    }

    redirectToReviewPage(): void {
        const challengeId = this?.route?.snapshot?.queryParams['id'];
        this.challengeService.postReview(challengeId).subscribe({
            next: (response: any) => {
                this.alertService.successPopup(response.message);
                // this.router.navigate([
                //     '/preview-challenge-details',
                //     {
                //         queryParams: {
                //             id: this.challengeId,

                //         }}

                // ]);
                this.router.navigate(['/preview-challenge-details'], {
                    queryParams: { id: challengeId },
                });
            },
            error: (error: HttpErrorResponse) => {
                if (error.status === 422) {
                    const errorMessage = error.error.message;
                    const errorDetails = error.error.errors;
                    this.handleValidationErrors(errorMessage, errorDetails);
                } else {
                    this.alertService.errorPopup(error.error.message);
                }
            },
        });
        // this.store
        //     .select(selectChallengeId)
        //     .pipe(
        //         take(1),
        //         switchMap((challengeId) => {
        //             if (challengeId) {
        //                 // Make the API call using the postReview method
        //                 return this.challengeService
        //                     .postReview(challengeId)
        //                     .pipe(
        //                         tap(() => {
        //                             // Navigate to the review page after successful API call
        //                             this.router.navigate([
        //                                 '/preview-challenge-details',
        //                                 challengeId,
        //                             ]);
        //                         }),
        //                         catchError((error: HttpErrorResponse) => {
        //                             // Handle error scenarios if the API call fails
        //                             if (error.status === 422) {
        //                                 const errorMessage =
        //                                     error.error.message ||
        //                                     'Validation error';
        //                                 const errorDetails = error.error.errors;
        //                                 this.handleValidationErrors(
        //                                     errorMessage,
        //                                     errorDetails
        //                                 );
        //                             } else {
        //                                 this.alertService.errorPopup(
        //                                     error.error.message ||
        //                                         'An error occurred'
        //                                 );
        //                             }
        //                             return of(null); // Return an observable to complete the stream
        //                         })
        //                     );
        //             } else {
        //                 this.alertService.errorPopup(
        //                     'Internal Server Occurred'
        //                 );
        //                 return of(null);
        //             }
        //         })
        //     )
        //     .subscribe();
    }

    // Handle detailed validation errors
    private handleValidationErrors(message: string, errors: any): void {
        let errorMessage = message;
        if (errors) {
            errorMessage += '\n' + Object.values(errors).join('\n');
        }

        if (errorMessage.includes('Validation error')) {
            errorMessage = errorMessage
                .replace(/Validation error[:]?/, '')
                .trim();
        }
        this.alertService.errorPopup(errorMessage);
    }

    onStepChange(event: StepperSelectionEvent): void {
        this.currentStep = event.selectedIndex;
    }

    private handleStepChanges() {
        if (this.stepper) {
            this.stepper.selectionChange
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((event) => {
                    const stepIndex = event.selectedIndex + 1;
                    // Prevent accessing any other
                    this.currentStep = stepIndex; // Update currentStep
                    this.loadDataForStep(stepIndex);
                });
        }
    }

    private loadDataForStep(step: number) {
        const challengeId$ = this.store.select(selectChallengeId).pipe(take(1));

        challengeId$
            .pipe(
                tap((challengeId) => {
                    if (challengeId) {
                        switch (step) {
                            case 1:
                                this.loadChallengeBasicInformation(challengeId);
                                break;
                            case 2:
                                this.loadProblemStatement(challengeId);
                                break;
                            case 3:
                                this.loadChallengeDetails(challengeId);
                                break;
                            case 4:
                                this.loadSdgs(challengeId);
                                break;
                            case 5:
                                this.loadPrizeRewards(challengeId);
                                break;
                            case 6:
                                this.loadGuidelines(challengeId);
                                break;
                            case 7:
                                this.loadEvaluationCriteria(challengeId);
                                break;
                            case 8:
                                this.loadTimelines(challengeId);
                                break;
                            case 9:
                                this.loadResources(challengeId);
                                break;
                            case 10:
                                this.loadFaqs(challengeId);
                                break;
                        }
                    }
                })
            )
            .subscribe();
    }

    private loadChallengeBasicInformation(challengeId: string) {
        this.store.dispatch(
            LaunchChallengeActions.loadChallengeBasicInformation({
                id: challengeId,
            })
        );
    }

    private loadProblemStatement(challengeId: string) {
        this.store.dispatch(
            LaunchChallengeActions.loadProblemStatement({ id: challengeId })
        );
    }

    private loadChallengeDetails(challengeId: string) {
        this.store.dispatch(
            LaunchChallengeActions.loadChallengeDetails({ id: challengeId })
        );
    }

    private loadSdgs(challengeId: string) {
        this.store.dispatch(
            LaunchChallengeActions.loadSdgs({ id: challengeId })
        );
    }

    private loadPrizeRewards(challengeId: string) {
        this.store.dispatch(
            LaunchChallengeActions.loadPrizeRewards({ id: challengeId })
        );
    }

    private loadGuidelines(challengeId: string) {
        this.store.dispatch(
            LaunchChallengeActions.loadGuidelines({ id: challengeId })
        );
    }

    private loadEvaluationCriteria(challengeId: string) {
        this.store.dispatch(
            LaunchChallengeActions.loadEvaluationCriteria({ id: challengeId })
        );
    }
    private loadTimelines(challengeId: string) {
        this.store.dispatch(
            LaunchChallengeActions.loadTimelines({ id: challengeId })
        );
    }
    private loadResources(challengeId: string) {
        this.store.dispatch(
            LaunchChallengeActions.loadResources({ id: challengeId })
        );
    }
    private loadFaqs(challengeId: string) {
        this.store.dispatch(
            LaunchChallengeActions.loadFaqs({ id: challengeId })
        );
    }
}
