<div>
    <!-- Overview Page -->
    <div class="overiew-wrapper">
        <h6 class="section-title">Detailed Problem Statement</h6>
        <p class="section-desc" [innerHTML]="challenge?.metaData?.detailedProblemStatement">
        </p>
        <h6 class="section-title">Root causes</h6>
        <p class="section-desc" [innerHTML]="challenge?.metaData?.rootCauses">
        </p>
        <h6 class="section-title">Previous Attempts to Solve</h6>
        <p class="section-desc" [innerHTML]="challenge?.metaData?.previousAttempts">
        </p>
        <h6 class="section-title">Urgency and Importance</h6>
        <p class="section-desc" [innerHTML]="challenge?.metaData?.urgencyImportance">
        </p>
        <h6 class="section-title">Target Beneficiaries</h6>
        <p class="section-desc" [innerHTML]="challenge?.metaData?.targetBeneficiaries">
        </p>


        <h6 class="section-title">Objectives</h6>
        <p class="section-desc">

            @for(objective of objectives;track objective){
            <li>{{objective}}</li>
            }


        </p>

        <h6 class="section-title">Desired Outcomes</h6>
        <p class="section-desc" [innerHTML]="challenge?.metaData?.desiredOutcomes">
        </p>
        <h6 class="section-title">Solution Requirements</h6>
        <p class="section-desc" [innerHTML]="challenge?.metaData?.solutionRequirements">
        </p>
        <h6 class="section-title">Constraints and Limitations</h6>
        <p class="section-desc" [innerHTML]="challenge?.metaData?.constraintsAndLimitations">
        </p>
        <div>
            <h6 class="section-title">SDG Alignment</h6>
            @for (sdg of challenge.challengeSDGs; track sdg.id) {
            <div class="overview-sdg-section">
                <img [src]="displaySDGImage(sdg?.sdg?.id)">
                <p>{{sdg?.explanation}}</p>
            </div>
            }
        </div>
    </div>
</div>
