import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [RouterLink, CommonModule],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
})
export class FooterComponent {
    currentYear: number;
    isPreviewPage: boolean = false;
    constructor(private router: Router) {
        this.currentYear = new Date().getFullYear();
    }

    ngOnInit() {
        this.router.events.subscribe(() => {
            this.isPreviewPage = this.router.url.includes('/preview-challenge-details');
        });
    }
}
