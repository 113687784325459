import { ResolveFn, Router } from '@angular/router';
import { AuthService } from '../services/apis/auth.service';
import { inject } from '@angular/core';
import { tap } from 'rxjs';
import { SweetAlertService } from '../services/sweet-alert.service';

export const emailVerificationResolver: ResolveFn<any> = (route, state) => {
    const token = route.queryParams['token'];
    const authService = inject(AuthService);
    const router = inject(Router);
    const alertService = inject(SweetAlertService);
    
    return authService.confirmEmail({ token: token }).pipe(
        tap({
            next: (response) => {
                // Handle success
                alertService.successToaster('Email verification successful, Please try to Login with the email!');
                router.navigate(['/login']);
            },
            error: (error) => {
                // Handle error
                alertService.errorToaster('Email verification failed. Please try again.');
                router.navigate(['/']);
            },
        })
    );
};
