<div>
    <!-- Overview Page -->
    <div class="overiew-wrapper">
        <div class="section-desc" *ngIf="this.eligbilityCriteria === 'Open to All'">
            <span class="section-title one-linear-title" *ngIf="this.eligbilityCriteria === 'Open to All'">PARTICIPANT
                ELIGIBILITY</span>

            <span class="one-linear-desc" *ngIf="this.eligbilityCriteria === 'Open to All'">
                {{this.eligbilityCriteria}}</span>

        </div>
        <h6 class="section-title"
            *ngIf="custom.ageRestriction || custom.geographicRestrictions || custom.otherRestrictions">
            PARTICIPANT ELIGIBILITY
        </h6>
        <ul class="section-desc">
            <li>
                <span class="section-title">Age restrictions</span>
                <span>{{custom.ageRestriction}}</span>
            </li>
            <li>
                <span class="section-title">Geographic restrictions</span>
                <span>{{custom.geographicRestrictions}}</span>
            </li>
            <li>
                <span class="section-title">Other restrictions</span>
                <span>{{custom.otherRestrictions}} </span>
            </li>
        </ul>
        <!-- <p class="section-desc"
            *ngIf="custom.ageRestriction || custom.geographicRestrictions || custom.otherRestrictions">
            <span *ngIf="custom.ageRestriction">
                <h6 class="section-title">Age restrictions</h6>
                {{custom.ageRestriction}} <br>
            </span>
            <span *ngIf="custom.geographicRestrictions">
                <h6 class="section-title">Geographic restrictions</h6>
                {{custom.geographicRestrictions}} <br>
            </span>
            <span *ngIf="custom.otherRestrictions">
                <h6 class="section-title">Other restrictions</h6>
                {{custom.otherRestrictions}} <br>
            </span>
        </p> -->
        <!-- <h6 class="section-title" *ngIf="custom.ageRestriction" >Age restrictions</h6>
                     <p class="section-desc" *ngIf="custom.ageRestriction"  >
                        {{custom.ageRestriction}}
                     </p>
                     <h6 class="section-title" *ngIf="custom.geographicRestrictions" >Geographic restrictions</h6>
                     <p class="section-desc" *ngIf="custom.geographicRestrictions"  >
                        {{custom.geographicRestrictions}}
                     </p>
                     <h6 class="section-title"*ngIf="custom.otherRestrictions" >Other restrictions</h6>
                     <p class="section-desc" *ngIf="custom.otherRestrictions" >
                        {{custom.otherRestrictions}}
                     </p> -->
        <h6 class="section-title">Team Requirements</h6>
        <p class="section-desc">
            {{this.teamRequirements}}
        </p>

        <h6 class="section-title">Skills Or Expertise Required</h6>
        <p class="section-desc" [innerHTML]="challenge?.challengeGuideline?.metaData?.skillsOrExpertiseNeeded">
        </p>
        <h6 class="section-title">Intellectual Property Terms</h6>
        <p class="section-desc" [innerHTML]="challenge?.challengeGuideline?.metaData?.intellectualPropertyTerms">
        </p>
        <h6 class="section-title">Partnership and Collaborations</h6>
        <p class="section-desc" [innerHTML]="challenge?.challengeGuideline?.metaData?.partnershipAndCollaborations">
        </p>
        <h6 class="section-title">Data Sharing and Privacy Policies</h6>
        <p class="section-desc" [innerHTML]="challenge?.challengeGuideline?.metaData?.dataSharingAndPrivacyPolicies">
        </p>
        <h6 class="section-title">Post Challenge support</h6>
        <p class="section-desc" [innerHTML]="challenge?.challengeGuideline?.metaData?.postChallengeSupport">
        </p>
        <h6 class="section-title">Marketing and Promotion Plans</h6>
        <p class="section-desc" [innerHTML]="challenge?.challengeGuideline?.metaData?.marketingAndPromotionPlans">
        </p>
        <h6 class="section-title">Additional Comments or Information</h6>
        <p class="section-desc" [innerHTML]="challenge?.challengeGuideline?.metaData?.additionalCommentsOrInformation">
        </p>
    </div>
</div>
