import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-challenge-details-evaluation',
  standalone: true,
  imports: [],
  templateUrl: './challenge-details-evaluation.component.html',
  styleUrl: './challenge-details-evaluation.component.scss'
})
export class ChallengeDetailsEvaluationComponent {
  @Input() evaluation = '';
  @Input() challenge: any={};
}
