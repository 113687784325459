import {
    Component,
    ElementRef,
    Input,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import {
    FormArray,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
} from '@angular/forms';
import { S3UploadService } from '../../../core/services/apis/s3-upload.service';
import { SweetAlertService } from '../../../core/services/sweet-alert.service';
import { CommonModule } from '@angular/common';
import { ReferenceDocument } from '../types/input-solution-references-types';
import { CommonUtilities } from '../../../core/utilities/common-utilities';

@Component({
    selector: 'app-reference-handler-uploads',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule],
    templateUrl: './reference-handler-uploads.component.html',
    styleUrl: './reference-handler-uploads.component.scss',
})
export class ReferenceHandlerUploadsComponent {
    @Input({ required: true }) formGroup!: FormGroup;
    @Input({ required: true }) controlName: string = ''; // Dynamic control name
    @Input() maxFiles: number = 10;
    @Input() maxFileSize: number = 10 * 1024 * 1024; // 10 MB

    @Input({ transform: (value: string[]) => value.join(',') })
    allowedFileTypes: string[] = ['.pdf'];

    @ViewChild('inputFileRef') inputFileRef!: ElementRef;
    public readonly inputName = CommonUtilities.generateUUID();

    constructor(
        private imageUploadService: S3UploadService,
        private alertService: SweetAlertService
    ) {}

    get uploadedFiles() {
        return this.formGroup.get(this.controlName) as FormArray;
    }

    clearItems() {
        this.uploadedFiles.clear();
    }

    onFileChange(event: any) {
        const files = event.target.files;
        const totalFiles = this.uploadedFiles.length + files.length;
        if (totalFiles > this.maxFiles) {
            this.uploadedFiles?.setErrors({ maxFilesExceeded: true });
            return;
        } else {
            this.uploadedFiles?.setErrors(null);
        }
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.size > this.maxFileSize) {
                this.uploadedFiles?.setErrors({ maxFileSizeExceeded: true });
                return;
            }
            if (file.type !== 'application/pdf') {
                this.uploadedFiles?.setErrors({ invalidFileType: true });
                return;
            }
            if (
                !this.uploadedFiles.value.some(
                    (f: ReferenceDocument) => f.fileName === file.fileName
                )
            ) {
                this.uploadFileToS3(file);
            }
        }
    }

    uploadFileToS3(file: File) {
        const folderPath = 'challenge-resources';
        const fileName = file.name;
        const fileType = file.type;
        const uniqueFileName = CommonUtilities.generateUUID();
        this.imageUploadService
            .generateSignedUrl({
                folderPath,
                fileName: uniqueFileName,
                fileType,
            })
            .subscribe({
                next: (response) => {
                    const signedUrl = response.signedUrl;
                    fetch(signedUrl, {
                        method: 'PUT',
                        headers: { 'Content-Type': fileType },
                        body: file,
                    }).then((updateResources) => {
                        if (updateResources) {
                            this.alertService.successToaster(
                                'Document uploaded successfully'
                            );
                            this.uploadedFiles.push(
                                new FormControl({
                                    fileName: uniqueFileName,
                                    displayName: fileName,
                                    fileType,
                                } as ReferenceDocument)
                            );
                            this.uploadedFiles.markAsTouched();
                        } else {
                            this.alertService.errorToaster(
                                'Document upload failed'
                            );
                        }
                    });
                },
                error: (error) => {
                    this.alertService.errorToaster('Document upload failed');
                },
                complete: () => {
                    this.inputFileRef.nativeElement.value = null;
                },
            });
    }

    removeFile(index: number, file: ReferenceDocument, event: Event) {
        event.stopPropagation();
        this.uploadedFiles.removeAt(index);
        this.uploadedFiles.markAllAsTouched();
        // TODO: Delete file from S3
    }
}
