import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { HowItWorksComponent } from '../../shared/how-it-works/how-it-works.component';
import { BenefitsSectionComponent } from '../../shared/benefits-section/benefits-section.component';
import { BannerSectionComponent } from '../../shared/banner-section/banner-section.component';

@Component({
  selector: 'app-intern',
  standalone: true,
  imports: [CommonModule, BannerSectionComponent, HowItWorksComponent, BenefitsSectionComponent],
  templateUrl: './intern.component.html',
  styleUrl: './intern.component.scss'
})
export class InternComponent {
  stepsData = [
    {
      icon: '/images/intern/interns_icon_1.svg',
      title: 'Browse Social Impact Challenges',
      description: 'Explore our available social impact challenges and find one that aligns with your values and goals.'
    },
    {
      icon: '/images/intern/interns_icon_2.svg', 
      title: 'Sign up and log in for an Internship',
      description: 'Register on the platform by providing your name, email, and password.'
    },
    {
      icon: '/images/intern/interns_icon_3.svg', 
      title: 'Enroll for the internship program',
      description: 'Pay a fee of $50 (per challenge) to enroll in our internship program and earn a micro-credential in Strategic Futures.'
    },
    {
      icon: '/images/intern/interns_icon_4.svg', 
      title: 'Complete the Internship',
      description: 'Work remotely on your selected challenge and complete the internship requirements, contributing to real social impact projects.'
    },
    {
      icon: '/images/intern/interns_icon_5.svg', 
      title: 'Learn Strategic Futures Skills',
      description: 'Learn skills in Strategic Futures, including problem exploration, horizon scanning, scenario planning, prioritization of opportunities and risks and actionable ideas for the near, medium and long term.'
    },
    {
      icon: '/images/intern/interns_icon_6.svg', 
      title: 'Micro-Credential in Strategic Futures',
      description: 'Upon completion of the internship, you\'ll receive a micro-credential in Strategic Futures, verifying your skills and knowledge in this area.'
    }
  ];
}
