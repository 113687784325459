export  function showStatus(startDate: string, endDate: string, status: string) {
    
    if (status === 'DRAFT') {

        return 'Draft';

    } else if (status === 'PUBLISH' && new Date(startDate).getTime() > new Date().getTime()) {


        return 'Upcoming';

    }





    else if (status === 'PUBLISH' && new Date(startDate).getTime() < new Date().getTime()) {


        return 'Open';


    } else if (status === 'PUBLISH' && endDate && new Date(endDate).getTime() < new Date().getTime()) {


        return 'Closed';

    } 
    else {

        return 'NA';
    }
}