import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthState } from '../../core/store/auth/auth.state';
import * as AuthActions from '../../core/store/auth/auth.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-login-with-linkedin',
  standalone: true,
  imports: [],
  templateUrl: './login-with-linkedin.component.html',
  styleUrl: './login-with-linkedin.component.scss'
})
export class LoginWithLinkedinComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<{ auth: AuthState }>
) {}
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
        const code = params['code'];

        if (code) {
            this.store.dispatch(AuthActions.loginLinkedin({ code }));
        } else {
            this.router.navigate(['/login']);
        }
    });
}
}
