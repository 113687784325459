<section class="challenge-detail-page">
    <div class="breadcrums-wrapper container">
        <a href="/">Home</a> <span>></span>
        @if (redirectedFromOverview) {
        <a [routerLink]="['/the-challenges']" [queryParams]="{tab:1}" >Overview</a> <span>></span>
        } @else {     
            <a href="/challenges-list">The Challenges</a> <span>></span>
        }
        <a class="active">Challenge detail</a>
    </div>
    <div class="custom-container-fluid">
        <div class="container">
            <div class="row my-5">
                <div class="col-xl-4 col-xxl-4 px-0">
                    <div class="left-straps-img-container">
                        <img [src]="challenge.downloadUrl" [alt]="challenge.imageName" class="img-benefits"
                            style="width: 100%; height:350px; max-height: 350px;border: 1px solid rgba(0, 0, 0, 0.5);">
                    </div>
                </div>
                <div class="col-xl-8 pe-0 col-xxl-8" style="padding-left: 50px;">
                    <label class="title">{{challenge.title}}</label>
                    <p class="desc" [innerHTML]="challenge.shortDescription"></p>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-4" style="margin-top: 50px;">
                @if(role?.length>0) {
                <button [disabled]="isPreviewMode" class="btn btn-primary rounded-start-0 get-challenge-btn text-start fs-18px fw-bold custom-btn"(click)="redirectToSolverForm()"   >
                    {{paymentStatus ? 'CONTINUE TO SOLVE' : 'PARTICIPATE NOW'}}
                    <span class="arrow-btn-wrapper"> <img src="/images/home/right-arrow.svg" alt="Arrow" height="20"
                            class="arrow-icon1"></span>
                </button>
            } @else {
                <button  [disabled]="isPreviewMode" class="btn btn-primary rounded-start-0 get-challenge-btn text-start fs-18px fw-bold custom-btn" (click)="redirectToLogin()" >
                    LOGIN TO PARTICIPATE
                    <span class="arrow-btn-wrapper"> <img src="/images/home/right-arrow.svg" alt="Arrow" height="20"
                            class="arrow-icon1"></span>
                </button>
            }
                @if (challenge?.challengePrize?.length>0||challenge?.challengeRewards?.length>0){
                <div class="incentives-wrapper">
                    <h1>INCENTIVES</h1>
                    <label class="section-title">Prize Money</label>
                    @if (challenge?.challengePrize[0]?.firstPrize) {
                    <p>1st place: ${{challenge?.challengePrize[0]?.firstPrize}}</p>
                    } @if (challenge?.challengePrize[0]?.secondPrize) {
                    <p>2nd place: ${{challenge?.challengePrize[0]?.secondPrize}}</p>
                    } @if (challenge?.challengePrize[0]?.thirdPrize) {
                    <p>3rd place: ${{challenge?.challengePrize[0]?.thirdPrize}}</p>
                    }
                    <p class="incentive-detail">{{challenge?.challengePrize[0]?.metaData?.details}}</p>
                    @for(reward of challenge.challengeRewards;track reward.id){
                    <label class="section-title">{{reward.title}}</label>
                    <p style="margin-bottom: 8px;">{{reward.description}}</p>
                    }
                </div>
                }
                <div class="info-container">
                    <div class="info-wrapper">
                        <label class="section-desc">Stage</label>
                        <label class="section-title">{{challenge?.status}}</label>
                    </div>
                    <div class="info-wrapper">
                        <label class="section-desc">Challenge Owner</label>
                        <label class="section-title">{{challenge?.organizedBy?.title}}</label>
                    </div>
                    <div class="info-wrapper">
                        <label class="section-desc">Geographic Scope</label>
                        <label class="section-title">{{challenge?.geographicScope?.value}}</label>
                    </div>
                    <div class="info-wrapper multiple-category">
                        <label class="section-desc">Challenge Category</label>
                        @for(category of challenge.categories;track category.id){
                        <label class="section-title">{{category.title}}</label>
                        }
                    </div>
                    <div class="info-wrapper">
                        <label class="section-desc">Challenge Type</label>
                        @if(challenge.hasActionableFuture){
                        <label class="section-title">Actionable Futures</label>
                        } @else {
                        <label class="section-title">Regular</label>
                        }
                    </div>
                </div>
            </div>
            <div class="col-md-8 tabs-wrapper" style="padding-left: 50px; padding-right: 0; margin-top: 30px;">
                <mat-tab-group>
                    <mat-tab label="Overview">
                        <app-challenge-details-overview [challenge]="challenge"
                            [objectives]="challenge.metaData.objectives">
                        </app-challenge-details-overview>
                    </mat-tab>
                    <mat-tab label="Guidelines">
                        <app-challenge-details-guidelines [challenge]="challenge">
                        </app-challenge-details-guidelines>
                    </mat-tab>
                    <mat-tab label="Evaluation">
                        <app-challenge-details-evaluation [challenge]="challenge">
                        </app-challenge-details-evaluation>
                    </mat-tab>
                    <mat-tab label="Timeline">
                        <app-challenge-details-timeline [challenge]="challenge"></app-challenge-details-timeline>
                    </mat-tab>
                    <mat-tab label="Resources">
                        <app-challenge-details-resources [challenge]="challenge">
                        </app-challenge-details-resources>
                    </mat-tab>
                    <mat-tab label="FAQs">
                        <app-challenge-details-faq [challenge]="challenge"></app-challenge-details-faq>
                    </mat-tab>
                    <mat-tab label="Talk to Us" [disabled]="isPreviewMode || !role?.length">
                        <app-challenge-details-talktous [challenge]="challenge"></app-challenge-details-talktous>
                    </mat-tab>

                </mat-tab-group>
            </div>
        </div>
    </div>
</section>
