<section>
    <form [formGroup]="solverFormStep1" (ngSubmit)="onSubmit()">
        <div class="row">
            <!-- TODO: Remove this -->
            <!-- <h2 *ngIf="!solverFormStoreService.isEdit">Create</h2> -->
            <!-- <div class="step-heading-wrapper">
                <h2>Step 1: The Problem Explorer's Checklist <sup>©</sup></h2>
                <h3>A FRAMEWORK FOR COMPREHENSIVE PROBLEM ANALYSIS</h3>
            </div> -->

            <div class="step-1-problem-details">
                <h3 class="heading-mon-22">SECTION 1: DEFINING THE PROBLEM</h3>
                <div class="section-with-detail-editor row">
                    <div class="col-md-3">
                        <div class="static-details-wrapper">
                            <h5 class="fs-15px">THINGS TO CONSIDER HERE</h5>
                            <ul>
                                <li>What is the core problem?</li>
                                <li>Why is addressing this problem important?</li>
                                <li>Who are the key stakeholders for this problem?</li>
                                <li>What is not the problem?</li>
                                <li>What is the current situation related to the problem?</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <app-quill-editor controlName="defineProblem" [parentForm]="solverFormStep1"
                            customClass="custom-height">
                        </app-quill-editor>
                        <mat-error>
                            <app-input-error-message [formData]="solverFormStep1" [controlName]="'defineProblem'">
                            </app-input-error-message>
                        </mat-error>
                        <!-- <mat-form-field appearance="outline">
                            <textarea matInput formControlName="section1" placeholder="Describe the problem"></textarea>
                        </mat-form-field> -->
                    </div>
                </div>
            </div>

            <div class="step-1-problem-details">
                <h3 class="heading-mon-22">SECTION 2: BREAKING DOWN THE PROBLEM</h3>
                <div class="section-with-detail-editor row">
                    <div class="col-md-3">
                        <div class="static-details-wrapper">
                            <h5 class="fs-15px">THINGS TO CONSIDER HERE</h5>
                            <ul>
                                <li>What are the different parts of the problem?</li>
                                <li>What are the relationships between different parts of the problem?</li>
                                <li>In what ways can you reframe the problem?</li>
                                <li>What are the underlying causes of this problem?</li>
                                <li>Have you come across similar problems that could be considered to solve the problem?
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <app-quill-editor customClass="custom-height" controlName="problemBreakdown"
                            [parentForm]="solverFormStep1">
                        </app-quill-editor>
                        <app-input-error-message [formData]="solverFormStep1" [controlName]="'problemBreakdown'">
                        </app-input-error-message>
                    </div>
                </div>
            </div>
            <div class="step-1-problem-details">
                <h3 class="heading-mon-22">SECTION 3: INFORMATION ASSESSMENT AND GATHERING</h3>
                <div class="section-with-detail-editor row">
                    <div class="col-md-3">
                        <div class="static-details-wrapper">
                            <h5 class="fs-15px">THINGS TO CONSIDER HERE</h5>
                            <ul>
                                <li>What information do you have on the problem?</li>
                                <li>Is the information sufficient to solve the problem?</li>
                                <li>What further information do you require to solve the problem?</li>
                                <li>What are you not yet understanding about the problem?</li>
                                <li>How do you plan to gather more information to address gaps in
                                    information/understanding of the problem?
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <app-quill-editor customClass="custom-height" controlName="infoAssessment"
                            [parentForm]="solverFormStep1">
                        </app-quill-editor>
                        <app-input-error-message [formData]="solverFormStep1" [controlName]="'infoAssessment'">
                        </app-input-error-message>
                    </div>
                </div>
            </div>
            <div class="step-1-problem-details">
                <h3 class="heading-mon-22">SECTION 4: SOLUTION EXPLORATION AND INNOVATION</h3>
                <div class="section-with-detail-editor row">
                    <div class="col-md-3">
                        <div class="static-details-wrapper">
                            <h5 class="fs-15px">THINGS TO CONSIDER HERE</h5>
                            <ul>
                                <li>Can you solve the whole problem or part of the problem?</li>
                                <li>Can you list out the different ways in which you can solve the problem?</li>
                                <li>How have others solved the problem or similar problems?</li>
                                <li>Can you describe what the final solution may look like and what innovation you could
                                    introduce?</li>
                                <li>What may be the result of solving the problem?
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <app-quill-editor customClass="custom-height" controlName="solutionExplorationInnovation"
                            [parentForm]="solverFormStep1">
                        </app-quill-editor>
                        <app-input-error-message [formData]="solverFormStep1"
                            [controlName]="'solutionExplorationInnovation'">
                        </app-input-error-message>
                    </div>
                </div>
            </div>
            <div class="step-1-problem-details">
                <h3 class="heading-mon-22">SECTION 5: IMPLEMENTING THE SOLUTION</h3>
                <div class="section-with-detail-editor row">
                    <div class="col-md-3">
                        <div class="static-details-wrapper">
                            <h5 class="fs-15px">THINGS TO CONSIDER HERE</h5>
                            <ul>
                                <li>What are key actions need to be taken to implement the solution?</li>
                                <li>Who must be involved in the implementation of the solution?</li>
                                <li>What is the timeline for implementation?</li>
                                <li>What resources are required to implement the solution?</li>
                                <li>What will success look like, and how will you measure it?
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <app-quill-editor customClass="custom-height" controlName="solutionImplementation"
                            [parentForm]="solverFormStep1">
                        </app-quill-editor>
                        <app-input-error-message [formData]="solverFormStep1" [controlName]="'solutionImplementation'">
                        </app-input-error-message>
                    </div>
                </div>
            </div>

            <app-input-solution-references [formGroup]="solverFormStep1" [solutionReferences]="solutionReferences">
            </app-input-solution-references>
        </div>
    </form>
</section>
