<section>
    <form [formGroup]="solverFormStep5" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="step-1-problem-details">
                <h3 class="heading-mon-22">SECTION 1: KEY INSIGHTS</h3>
                <div class="section-with-detail-editor row">
                    <div class="col-md-3">
                        <div class="static-details-wrapper">
                            <h5 class="fs-15px">THINGS TO CONSIDER HERE</h5>
                            <ul>
                                <li>What are the most critical insights we have gained from our analysis so far?</li>
                                <li>How can these insights be translated into concrete actions?</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <app-quill-editor controlName="keyInsights" customClass="custom-height"
                            [parentForm]="solverFormStep5">
                        </app-quill-editor>

                        <app-input-error-message [formData]="solverFormStep5" [controlName]="'keyInsights'">
                        </app-input-error-message>

                        <!-- <mat-form-field appearance="outline">
                            <textarea matInput formControlName="section1" placeholder="Describe the problem"></textarea>
                        </mat-form-field> -->
                    </div>
                </div>
            </div>

            <div class="step-1-problem-details">
                <h3 class="heading-mon-22">SECTION 2: OPPORTUNITY SPACES</h3>
                <div class="section-with-detail-editor row">
                    <div class="col-md-3">
                        <div class="static-details-wrapper">
                            <h5 class="fs-15px">THINGS TO CONSIDER HERE</h5>
                            <ul>
                                <li>Based on our research and scenarios, where are the biggest opportunity spaces for
                                    intervention or innovation?</li>
                                <li>Which areas offer the most potential for high-impact change?</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <app-quill-editor customClass="custom-height" controlName="opportunitySpaces"
                            [parentForm]="solverFormStep5">
                        </app-quill-editor>
                        <app-input-error-message [formData]="solverFormStep5" [controlName]="'opportunitySpaces'">
                        </app-input-error-message>
                    </div>
                </div>
            </div>
            <div class="step-1-problem-details">
                <h3 class="heading-mon-22">SECTION 3: RISK AND RESILIENCE</h3>
                <div class="section-with-detail-editor row">
                    <div class="col-md-3">
                        <div class="static-details-wrapper">
                            <h5 class="fs-15px">THINGS TO CONSIDER HERE</h5>
                            <ul>
                                <li>What actions can help mitigate the risks identified in our research and scenarios?
                                </li>
                                <li>How can we build resilience into our strategies to handle unexpected changes?</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <app-quill-editor customClass="custom-height" controlName="riskResilience"
                            [parentForm]="solverFormStep5">
                        </app-quill-editor>
                        <app-input-error-message [formData]="solverFormStep5" [controlName]="'riskResilience'">
                        </app-input-error-message>
                    </div>
                </div>
            </div>
            <div class="step-1-problem-details">
                <h3 class="heading-mon-22">SECTION 4: INNOVATION PATHWAYS</h3>
                <div class="section-with-detail-editor row">
                    <div class="col-md-3">
                        <div class="static-details-wrapper">
                            <h5 class="fs-15px">THINGS TO CONSIDER HERE</h5>
                            <ul>
                                <li>Where are the gaps in current approaches that new ideas could fill?</li>
                                <li>How can we push beyond incremental improvements to achieve transformative change?
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <app-quill-editor customClass="custom-height" controlName="innovationPathways"
                            [parentForm]="solverFormStep5">
                        </app-quill-editor>
                        <app-input-error-message [formData]="solverFormStep5" [controlName]="'innovationPathways'">
                        </app-input-error-message>
                    </div>
                </div>
            </div>
            <div class="step-1-problem-details">
                <h3 class="heading-mon-22">SECTION 5: QUICK WINS VS. LONG-TERM STRATEGIES</h3>
                <div class="section-with-detail-editor row">
                    <div class="col-md-3">
                        <div class="static-details-wrapper">
                            <h5 class="fs-15px">THINGS TO CONSIDER HERE</h5>
                            <ul>
                                <li>What immediate actions can we take to address urgent aspects of the challenge?</li>
                                <li>Which long-term strategies should we start laying the groundwork for now?</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <app-quill-editor customClass="custom-height" controlName="quickLongTermStrategies"
                            [parentForm]="solverFormStep5">
                        </app-quill-editor>
                        <app-input-error-message [formData]="solverFormStep5" [controlName]="'quickLongTermStrategies'">
                        </app-input-error-message>
                    </div>
                </div>
            </div>
            <!-- <app-reference-handler [controlName]="'referenceUrl'" [formGroup]="solverFormStep5" [maxUrls]="10">
            </app-reference-handler>
            <app-input-error-message [formData]="solverFormStep5" [controlName]="'referenceUrl'">
            </app-input-error-message>
            <div class="row">
                <app-reference-handler-uploads [controlName]="'referenceUpload'" [formGroup]="solverFormStep5"
                    [maxFiles]="10" [maxFileSize]="10 * 1024 * 1024">
                </app-reference-handler-uploads>
                <app-input-error-message [formData]="solverFormStep5" [controlName]="'referenceUpload'">
                </app-input-error-message>
                <div class="ai-model-wrapper">
                    <h3 class="heading-mon-22">PLEASE SHARE THE GEN AI MODEL(S) AND THE PROMPTS THAT YOU HAVE USED, IF
                        ANY
                    </h3>
                    <app-quill-editor controlName="quillText" [parentForm]="solverFormStep5">
                    </app-quill-editor>
                </div>
            </div> -->
            <app-input-solution-references [formGroup]="solverFormStep5" [solutionReferences]="solutionReferences"></app-input-solution-references>
        </div>
    </form>
</section>
