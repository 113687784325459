<div class="custom-container-fluid">
    <div class="container">
        <div class="row my-4">
            <div class="col-md-5">
                <a routerLink="/">
                    <img src="/logo.svg" alt="Main Logo" height="40px" />
                </a>

                <div class="form-container mt-5 pt-5">
                    <h2 class="fw-semibold">Reset Password</h2>
                    <form [formGroup]="resetPasswordForm">
                        <div class="mb-3">
                            <label for="password" class="form-label fw-semibold fs-15px mt-4">Enter new password</label>
                            <app-password-input id="password" formControlName="password"></app-password-input>
                            <app-input-error-message [formData]="resetPasswordForm" [controlName]="'password'">
                            </app-input-error-message>
                        </div>

                        <div class="mb-3">
                            <label for="confirmNewPassword" class="form-label fw-semibold fs-15px">Re-enter new
                                password</label>
                            <app-password-input id="confirmNewPassword" formControlName="confirmNewPassword">
                            </app-password-input>
                            <app-input-error-message [formData]="resetPasswordForm"
                                [controlName]="'confirmNewPassword'">
                            </app-input-error-message>
                        </div>

                        <button type="submit" (click)="onResetPassword()"
                            class="btn btn-gradient btn-bg-blue-gradient mt-4 custom-btn">
                            SET NEW PASSWORD
                            <span class="arrow-btn-wrapper"> <img src="/images/home/right-arrow.svg" alt="Arrow"
                                    height="20" class="arrow-icon1"></span>
                        </button>
                    </form>

                </div>
            </div>
            <div class="col-md-7">
                <div class="right-img-container">
                    <img src="/images/login/main.png" class="img-fluid" alt="Login Main" />
                </div>
            </div>
        </div>
    </div>
</div>
