import { createSelector } from '@ngrx/store';
import { AuthState } from './auth.state';

// Select the feature state
export const selectAuthState = (state: any): AuthState => state.auth;

export const selectIsAuthenticated = createSelector(
    selectAuthState,
    (authState: AuthState) => !!authState.token
);

// Selector to get user roles
export const selectUserRoles = createSelector(
    selectAuthState,
    (authState: AuthState) => authState.user?.roles || [] // Return roles if present, otherwise empty array
);

export const selectUserOrganization = createSelector(
    selectAuthState,
    (authState: AuthState) => authState.user?.organization || null // Return organization or null
);
// export const selectHasPermission = (permission: string) =>
//     createSelector(selectAuthState, (authState: AuthState) =>
//         authState.permissions.includes(permission)
//     );
