<nav class="navbar navbar-expand-lg bg-white shadow-sm sticky-top py-3" *ngIf="!isPreviewPage" style="z-index: 99;">
    <div class="container px-0">
        <a class="navbar-brand logo-size mb-2" routerLink="/" style="margin-right: 0;">
            <img src="/logo.svg" alt="Logo" />
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav me-auto">
                <li class="nav-item " style="margin-left: 40px;">
                    <a class="nav-link fw-bold fs-15px" routerLink="/challenges-list" routerLinkActive="active-link">The
                        Challenges</a>
                </li>
                <li class="nav-item dropdown " style="margin-left: 40px;">
                    <button class="nav-link dropdown-toggle fw-bold text-size" data-bs-toggle="dropdown"
                        aria-expanded="false" routerLinkActive="active-link">
                        The Changemakers
                    </button>
                    <ul class="dropdown-menu">
                        <li>
                            <a class="dropdown-item" routerLink="/intern" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Interns</a>
                            <a class="dropdown-item" routerLink="/organization" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Organizations</a>
                            <a class="dropdown-item" routerLink="/innovator" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Innovators</a>
                            <a class="dropdown-item" routerLink="/startups" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Startups</a>
                            <a class="dropdown-item" routerLink="/mentors" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Mentors</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item " style="margin-left: 40px;">
                    <a class="nav-link fw-bold text-size" href="" routerLinkActive="active-link">Pricing</a>
                </li>
                @if (role?.length>0) {
                <li class="nav-item " style="margin-left: 40px;">
                    <a class="nav-link fw-bold text-size" style=" color: #0074C1;" routerLink="/the-challenges"
                        routerLinkActive="active-link">Dashboard</a>
                </li>}
            </ul>
            <ul class="navbar-nav ms-auto align-items-center">
                <li class="nav-item me-3">
                    <div class="input-group search-color" style="max-width: 200px; max-height: 40px">
                        <input type="text" class="form-control bg-secondary-subtle search-color" />
                        <span class="input-group-text bg-secondary-subtle rounded-end-0 search-color"><img
                                src="/header/search.svg" alt="Search" height="20" /></span>
                    </div>
                </li>
                <li class="nav-item">
                    @if (isAuthenticated$ | async) {
                    <div class="dropdown">
                        <div data-bs-toggle="dropdown">
                            <button type="button" class="btn rounded-circle fw-semibold" style="
                                        background: #0074c1 0% 0% no-repeat
                                            padding-box;
                                        color: #fff;
                                        width: 40px;
                                        height: 40px;
                                        line-height: 27px;
                                    ">
                                <span>
                                    <ng-container *ngIf="user$ | async as user">
                                        <span>{{
                                                user.firstName
                                                .charAt(0)
                                                .toUpperCase()
                                                }}</span>
                                    </ng-container>
                                </span>
                            </button>
                            <span><img src="/images/common/down.svg" class="img-fluid ms-2" alt="Down arrow" /></span>
                        </div>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                                <a class="dropdown-item" type="button" routerLink="/my-profile"
                                    routerLinkActive="active">
                                    My Profile
                                </a>
                            </li>
                            <!-- <li>
                                <a class="dropdown-item" type="button" routerLink="/the-challenges"
                                    routerLinkActive="active">
                                    My Dashboard
                                </a>
                            </li> -->
                            <li>
                                <a class="dropdown-item" type="button" routerLink="/change-password"
                                    routerLinkActive="active">
                                    Change Password
                                </a>
                            </li>
                            <li>
                                <hr class="dropdown-divider" />
                            </li>
                            <li>
                                <a class="dropdown-item" type="button" (click)="logout()">
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </div>


                    } @else {
                    <a routerLink="/login" class="btn btn-gradient btn-bg-blue-gradient ms-3 custom-btn"
                        style="padding-top: 11px !important; padding-bottom: 10px !important;">
                        Login
                        <span class="arrow-btn-wrapper"> <img src="/images/home/right-arrow.svg" alt="Arrow" height="20"
                                class="arrow-icon1"></span>
                    </a>
                    }

                </li>
            </ul>
        </div>
    </div>
</nav>


<nav *ngIf="isPreviewPage" class="preview-header navbar navbar-expand-lg bg-white shadow-sm sticky-top py-3"
    style="height: 95px;">
    <div class="container">
        <button (click)="navigateBackToEditing()" class="btn" style="color: #000; font-size: 15px; font-weight: 600;">
            < Back to Editing </button> <div>
                <button (click)="publishChallenge()" class="btn btn-gradient btn-bg-blue-gradient custom-btn">
                    Submit
                    <span class="arrow-btn-wrapper">
                        <img src="/images/home/right-arrow.svg" alt="Arrow" height="20" class="arrow-icon1">
                    </span>
                </button>
    </div>
    </div>

</nav>
