import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-how-it-works',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './how-it-works.component.html',
  styleUrl: './how-it-works.component.scss'
})
export class HowItWorksComponent {
  @Input() title: string = 'How it works?';
  @Input() title2: string = '';
  @Input() description2: string = '';
  @Input() steps: { icon: string, title: string, description: string }[] = [];
  @Input() stepNumberColor: string = '';
  @Input() stepNumberBgColor: string = '';
  @Input() stepLineColor: string = '';
}
