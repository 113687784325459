import { Component, HostListener, inject } from '@angular/core';
import { ChallengeService } from '../core/services/apis/challenge.service';
import { CommonModule } from '@angular/common';
import { NgSelectComponent } from '@ng-select/ng-select';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { SweetAlertService } from '../core/services/sweet-alert.service';
import { ErrorMessages, SuccessMessages } from '../core/enum/ToasterMessages.enum';
@Component({
  selector: 'app-organisation-interests-list',
  standalone: true,
  imports: [CommonModule,NgSelectComponent,InfiniteScrollDirective,FormsModule],
  templateUrl: './organisation-interests-list.component.html',
  styleUrl: './organisation-interests-list.component.scss'
})
export class OrganisationInterestsListComponent {
  interests: any[] = [];
  challengeService =inject(ChallengeService);
  sortList = [{ id: 'DESC', title: 'Latest' }, { id: 'ASC', title: 'Oldest' }]
  showScrollToTopButton = false;
  route:Router=inject(Router);
  sweetAlert:SweetAlertService=inject(SweetAlertService)
  params = {
    pageLimit: 5,
    pageIndex: 1,
    searchKeyword: '',
    sortKey: 'createdAt',
    sortDirection: 'DESC',

  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showScrollToTopButton = window.scrollY > 300;
  }
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  handleSearchChange(searchValue: string): void {
    this.params.searchKeyword = searchValue;
    this.params.pageIndex = 1
    this.fetchInterests()


  }
  onSortChange(sortValue: any): void {
    this.params.sortDirection = sortValue
    this.params.pageIndex = 1
    this.fetchInterests()

  }
  redirectToViewInterest(id:any): void {
    this.route.navigate(['/interests/view',id])
    }


  onScroll(): void {
    this.params.pageIndex += 1;
    this.fetchInterests()
  }
  ngOnInit(): void {
    this.fetchInterests()
  }
  fetchInterests(): void {
    this.challengeService.getInterestsList(this.params).subscribe((response) => {
      if (this.params.pageIndex === 1) {
        this.interests = response.data.challengeRequests;
      } else {
        this.interests = [...this.interests, ...response.data.challengeRequests];
      }
    },
    (error) => {
      console.error(error, 'error from getInterestsList')
    })

  }
  deletePostInterest(postId:string):void{
    this.sweetAlert.confirmDeletion().subscribe((isConfirmed:boolean) => {
      if (isConfirmed) {
        this.challengeService.deletePostInterest(postId).subscribe(
          (data: any) => {
            this.params.pageIndex = 1;
            this.fetchInterests();
            this.sweetAlert.showDeletionSuccess(SuccessMessages.POST_INTEREST_DELETE);
          },
          (error: any) => {
            console.error('Error deleting challenge', error);
            this.sweetAlert.errorPopup(error?.error?.message??ErrorMessages.COMMON_ERROR);
          }
        );
      }
    });
  }

}
