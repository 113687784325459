<app-banner-section [imageSrc]="'/images/mentors/main.png'"
    [title]="'Empower the Next Generation of Social Impact Leaders'" [description]="
        'Share your expertise and experience to guide interns, startups, and innovators in solving social impact challenges and contributing innovative ideas.'
    " [buttonText]="'JUMP INTO THE CHALLENGES'" [buttonLink]="'#'" [lightColor]="'#F5B9D8'" [darkColor]="'#EE88BD'"
    [buttonBgColor]="
        'transparent linear-gradient(90deg, #850D4B 0%, #DF187F 100%) 0% 0% no-repeat padding-box'
    " [overlayText]="'Mentors'" [overlayBgColor]="'rgba(0, 0, 0, 0.5)'" [overlayTextColor]="'#FFFFFF'"
    [overlayWidth]="'280px'" [overlayHeight]="'70px'" [arrowIconSrc]="'/images/common/right-arrow-pink.svg'">
</app-banner-section>

<app-benefits-section [title]="'What is Mentorship in Open Innovation Section?'" [description]="
        'Mentorship in open innovation is a collaborative approach to guiding and advising interns, startups, and innovators in solving social impact challenges and contributing innovative ideas. Typically, the duration of the mentorship will be the duration of the challenge.'
    " [additionalLine]="'The benefits of open innovation for startups include:'" [points]="[
        'Opportunity to give back and make a positive impact',
        'Chance to share expertise and experience',
        'Networking opportunities with like-minded individuals and organizations',
        'Personal and professional growth',
        'Increased visibility and recognition for your work and expertise'
    ]" [bulletColor]="'#DF187F'" [lightStrapColor]="'#EE88BD'" [buttonBgColor]="
        'transparent linear-gradient(90deg, #850D4B 0%, #DF187F 100%) 0% 0% no-repeat padding-box'
    " [buttonText]="'READY TO MAKE A DIFFERENCE?'" [buttonLink]="''" [imageSrc]="'/images/mentors/benefits.png'"
    [arrowIconSrc]="'/images/common/right-arrow-pink.svg'" [marginZero]="0">
</app-benefits-section>

<div class="container my-5" style="margin-bottom: 60px !important;">
    <div class="row">
        <h2 class="fw-semibold text-center mb-4">How it works?</h2>

        @for (card of cards; track card;) {
        <div class="col-md-6" style="margin-bottom: 30px;">
            <div class="card border-0 h-100 card-corners">
                <div class="card-body ">
                    <div class="d-flex justify-content-between align-items-start">
                        <h6 class="px-2 py-1 mb-0 mt-0 rounded-end"
                            style="color: #df187f; background-color: #fadcec; font-size: 12px;">
                            STEP {{ $index + 1 }}
                        </h6>
                        <img class="img-fluid" style="max-height: 52px;" src="{{ card.icon }}" alt="" />
                    </div>
                    <div class="mt-5">
                        <h6 class="fw-semibold mb-1">{{ card.title }}</h6>
                        <hr class="my-1" style="border-color: #df187f; border-width: 2px;" />
                        <p class="text-secondary mb-4" style="color: #AEAEAE;" class="fs-15px">{{ card.content }}</p>
                    </div>
                </div>
            </div>
        </div>
        }
    </div>
</div>
