<section>
    <div class="container">
        <form [formGroup]="resourcesForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-md-12 form-field-margin">
                    <app-quill-editor label="Notes for Resources"
                        infoText="Guidance: List any resources, data, or support that will be provided to participants"
                        controlName="note" placeholder="" [parentForm]="resourcesForm">
                    </app-quill-editor>
                    <app-input-error-message [formData]="resourcesForm" [controlName]="'note'">
                    </app-input-error-message>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label class="form-label fw-semibold">URLs for Reference </label>
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control" formControlName="referenceUrl">
                </div>
                <div
                    *ngIf="resourcesForm.get('referenceUrl')?.hasError('pattern') && resourcesForm.get('referenceUrl')?.touched">
                    <small class="text-danger">Please enter a valid URL.</small>
                </div>
                <div class="col-12">
                    <button type="button" class="add-new-btn" (click)="addReferenceUrl()">
                        + Add New URL
                    </button>
                </div>
                <div *ngIf="maxUrlsReached">
                    <small class="text-danger">You can only add up to 10 URLs.</small>
                </div>
            </div>
            <div class="row ">
                <div class="col-md-12">
                    <div *ngFor="let url of referenceUrls; let i = index" class="uploaded-files-wrapper">
                        {{ url}}
                        <span class="cancel" (click)="removeReferenceUrl(i)"><img
                                src="/images/common/cancel.svg" /></span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="margin-top: 20px;">
                    <div class="mb-3">
                        <label class="form-label fw-semibold">Documents for Reference</label>
                        <div class="upload-info-wrapper">
                            <label for="media" class="image-upload-wrapper">+</label>
                            <input type="file" (change)="onFileChange($event)" class="form-control"
                                style="display: none;" accept=".pdf" id="media" multiple>
                            <small class="form-text text-muted info-msg">Documents of .pdf for size not more than 10 MB
                                can be uploaded here</small>
                        </div>
                        <small *ngIf="resourcesForm.get('media')?.hasError('maxFilesExceeded')" class="text-danger">
                            You can upload a maximum of 10 documents.
                        </small>
                        <small *ngIf="resourcesForm.get('media')?.hasError('maxFileSizeExceeded')" class="text-danger">
                            Each document must be less than 10 MB.
                        </small>
                        <small *ngIf="resourcesForm.get('media')?.hasError('invalidFileType')" class="text-danger">
                            Each document must be in pdf format.
                        </small>
                    </div>
                </div>
                <div class="col-md-6">
                    <div *ngFor="let file  of uploadedFiles; let i = index" class="uploaded-files-wrapper">
                        <label>{{ file.name }}</label>
                        <!-- <label> {{ file.type.split('/').pop() }}</label> -->
                        <span class="cancel" (click)="removeFile(i)"><img src="/images/common/cancel.svg" /></span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>
