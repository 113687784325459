<div class="row">
    <!-- URLs Section -->
    <div class="col-md-12">
        <label class="form-label fw-semibold">URLs for Reference</label>
        <div class="row" [formGroup]="localFormGroup">
            <div class="col-md-6">
                <input type="text" class="form-control" formControlName="url" />
            </div>
            <app-input-error-message
                [formData]="localFormGroup"
                [controlName]="'url'"
            ></app-input-error-message>

            <app-input-error-message
                [formData]="formGroup"
                controlName="referenceUrls"
            >
            </app-input-error-message>
            <div class="col-12">
                <button
                    type="button"
                    class="add-new-btn"
                    (click)="localFormGroup.valid && addItem()"
                >
                    + Add New URL
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div
                    *ngFor="let url of urlList.controls; let i = index"
                    class="uploaded-files-wrapper cusror-pointer"
                    (click)="editItem(i)"
                >
                    {{ url.value }}
                    <span class="cancel" (click)="removeItem(i, $event)">
                        <img src="/images/common/cancel.svg" />
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
