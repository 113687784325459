<div
    class="container"
    *ngFor="
        let item of (solverFormStoreService.selectForm7$ | async)
            ?.highPriorityTermActionable
    "
>
    <h3 *ngIf="hasHighPriorityActionables(item)">
        HIGH PRIORITY ACTIONABLES FOR THE
        <span
            *ngIf="item.termType === 'NEAR' && hasHighPriorityActionables(item)"
            >NEAR TERM [ less than 2 years ]</span
        >
        <span
            *ngIf="
                item.termType === 'MEDIUM' && hasHighPriorityActionables(item)
            "
        >
            MEDIUM TERM [ 2 to 5 years ]
        </span>
        <span
            *ngIf="item.termType === 'LONG' && hasHighPriorityActionables(item)"
            >LONG TERM [ 5 to 10 years ]</span
        >
    </h3>
    <div class="listing-wrapper">
        <table>
            <tbody>
                @for (actionable of item.termActionables; track $index) { @if
                (actionable.priority==="HIGH") {
                <tr
                    class="list-item cursor-pointer"
                    (click)="onItemClick(item, actionable)"
                >
                    <td class="title">
                        {{ actionable?.title }}
                    </td>
                    <td class="action">
                        @if (actionable?.highImpactInitiative?.immediateActions?.length) {
                        <img src="/images/solver-flow/step7-edit.svg" />
                        } @else {
                        <img src="/images/solver-flow/step7-add.svg" />
                        }
                    </td>
                </tr>
                } }
            </tbody>
        </table>
    </div>
</div>

<div class="solver-document-url-ai-wrapper">
    <app-input-solution-references
        [formGroup]="formGroup"
        [solutionReferences]="solutionReferences"
    ></app-input-solution-references>
</div>
