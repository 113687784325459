<!-- guidance step 1 -->
@if (queryParams?.step==1) {
<div class="guidelines-modal-wrapper container-fluid">
    <div class="container p-0">
        <div class="guidance-heading">
            <h2>Guidance For Step 1</h2>
            <img src="/images/solver-flow/cross.svg" class="cursor-pointer" (click)="redirectToSolverFlow()" />
        </div>
        <div class="guidance-details">
            <div class="d-flex">
                <div class="left-wrapper">
                    <h2 class="fs-33px"> Actionable Futures</h2>
                </div>
                <div class="right-wrapper">
                    <div class="guidance-shadow single-guidance-wrapper">
                        <h3 class="heading-mon-22">A SIMPLIFIED PHOENIX CHECKLIST</h3>
                        <p class="light-text">Explore the problem from different angles using an adapted version of the
                            Phoenix
                            checklist for CIA analysis</p>
                        <p>The Phoenix Checklist Form is a tool designed to assist users in examining various
                            aspects of a problem from multiple perspectives as part of a strategic foresight
                            exercise. This guidance note provides tips and suggestions to help you fill out the
                            form effectively and get the most out of the Phoenix Checklist.</p>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="left-wrapper">
                    <h2 class="fs-33px"> Before You Start</h2>
                </div>
                <div class="right-wrapper three-col-wrapper">
                    @for (item of guidelines[0].step1?.beforeYouStart; track $index) {
                    <div class="guidance-shadow  ">
                        <img src="" width="50px" height="50px" style="background-color: rgba(0, 116, 193, 0.2);" />
                        <p>{{item}} </p>
                    </div>
                    }
                </div>
            </div>
            <div class="d-flex">
                <div class="left-wrapper">
                    <h2 class="fs-33px">Filling Out The Form</h2>
                </div>
                <div class="right-wrapper two-col-wrapper">
                    @for (item of guidelines[0].step1?.fillingOutTheForm; track $index) {
                    <div class="guidance-shadow">
                        <img src="" width="50px" height="50px" style="background-color: rgba(0, 116, 193, 0.2);" />
                        <p>{{item}} </p>
                    </div>
                    }
                </div>
            </div>
            <div class="d-flex">
                <div class="left-wrapper">
                    <h2 class="fs-33px">Some tips for you</h2>
                </div>
                <div class="right-wrapper two-col-wrapper">
                    @for (item of guidelines[0].step1?.someTipsForYou; track $index) {
                    <div class="guidance-shadow">
                        <img src="" width="50px" height="50px" style="background-color: rgba(0, 116, 193, 0.2);" />
                        <p>{{item}} </p>
                    </div>
                    }
                </div>
            </div>
            <div class="d-flex">
                <div class="left-wrapper">
                    <h2 class="fs-33px">After you are done</h2>
                </div>
                <div class="right-wrapper two-col-wrapper">
                    @for (item of guidelines[0].step1?.aftrYouAreDone; track $index) {
                    <div class="guidance-shadow">
                        <img src="" width="50px" height="50px" style="background-color: rgba(0, 116, 193, 0.2);" />
                        <p>{{item}} </p>
                    </div>
                    }
                </div>
            </div>
        </div>
        <div class="text-end" style="margin-bottom: 60px;">
            <button class="btn btn-gradient btn-bg-blue-gradient custom-btn">
                I UNDERSTAND
                <span class="arrow-btn-wrapper">
                    <img src="/images/home/right-arrow.svg" alt="Arrow" height="20" class="arrow-icon1">
                </span>
            </button>
        </div>
    </div>
</div>
} @else if (queryParams.step==2) {
<!-- guidance step 2 -->
<div class="guidelines-modal-wrapper container-fluid">
    <div class="container p-0">
        <div class="guidance-heading">
            <h2>Guidance For Step 2</h2>
            <img src="/images/solver-flow/cross.svg" class="cursor-pointer" (click)="redirectToSolverFlow()" />
        </div>
        <div class="guidance-details">
            <div class="d-flex">
                <div class="left-wrapper">
                    <h2 class="fs-33px">Best Practices</h2>
                </div>
                <div class="right-wrapper">
                    <div class="guidance-shadow single-guidance-wrapper single-guidance-wrapper-with-que">
                        <h3 class="heading-mon-22">ABOUT BEST PRACTICES</h3>
                        <p>This step is designed to gather insights from established best practices relevant to the
                            project's statement. By examining cases where strategies have been successfully implemented,
                            you can gain a better understanding of potential solutions, implementation methods, and
                            results that are relevant to the current problem. This research will provide a foundation
                            for developing innovative solutions.</p>
                        <p class="question">What are best practices?</p>
                        <p>Best practices are strategies, techniques, or methodologies that have consistently
                            demonstrated superior results compared to other approaches. They have been proven effective
                            through implementation in real-world scenarios and have been recognized by experts in the
                            field.</p>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="left-wrapper">
                    <h2 class="fs-33px"> Before You Start</h2>
                </div>
                <div class="right-wrapper three-col-wrapper" style="margin-bottom: 20px !important;">
                    @for (item of guidelines[1].step2?.beforYouStart; track $index) {
                    <div class="guidance-shadow ">
                        <img src="" width="50px" height="50px" style="background-color: rgba(0, 116, 193, 0.2);" />
                        <p>{{item}}</p>
                    </div>
                    }
                </div>
            </div>
            <div class="d-flex">
                <div class="left-wrapper"></div>
                <div class="right-wrapper">
                    <div class="guidance-shadow three-col-with-single-ul-wrapper single-guidance-wrapper-with-que">
                        <h3 class="heading-mon-22"> How to select resources?</h3>
                        <p>Focus on academic journals, industry reports, authoritative publications, case studies, and
                            interviews with experts. Consider the following criteria when evaluating the credibility of
                            sources:</p>
                        <ul>
                            <li>Author's credentials and expertise in the field</li>
                            <li>Publication's peer-review process and reputation</li>
                            <li>Timeliness and relevance of the information</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="left-wrapper">
                    <h2 class="fs-33px"> Some Tips For You</h2>
                </div>
                <div class="right-wrapper two-col-wrapper">
                    @for (item of guidelines[1].step2?.someTipsForYou; track $index) {
                    <div class="guidance-shadow ">
                        <img src="" width="50px" height="50px" style="background-color: rgba(0, 116, 193, 0.2);" />
                        <p>{{item}}</p>
                    </div>
                    }
                </div>
            </div>
            <div class="d-flex">
                <div class="left-wrapper">
                    <h2 class="fs-33px"> After You Are Done</h2>
                </div>
                <div class="right-wrapper two-col-wrapper">
                    @for (item of guidelines[1].step2?.aftrYouAreDone; track $index) {
                    <div class="guidance-shadow ">
                        <img src="" width="50px" height="50px" style="background-color: rgba(0, 116, 193, 0.2);" />
                        <p>{{item}}</p>
                    </div>
                    }
                </div>
            </div>
        </div>
        <div class="text-end" style="margin-bottom: 60px;">
            <button class="btn btn-gradient btn-bg-blue-gradient custom-btn">
                I UNDERSTAND
                <span class="arrow-btn-wrapper">
                    <img src="/images/home/right-arrow.svg" alt="Arrow" height="20" class="arrow-icon1">
                </span>
            </button>
        </div>
    </div>
</div>
} @else if(queryParams.step==3) {
<!-- guidance step 3 -->
<div class="guidelines-modal-wrapper container-fluid">
    <div class="container p-0">
        <div class="guidance-heading">
            <h2>Guidance For Step 3</h2>
            <img src="/images/solver-flow/cross.svg" class="cursor-pointer" (click)="redirectToSolverFlow()" />
        </div>
        <div class="guidance-details">
            <div class="d-flex">
                <div class="left-wrapper">
                    <h2 class="fs-33px">Horizon Scanning</h2>
                </div>
                <div class="right-wrapper">
                    <div class="guidance-shadow three-col-with-single-ul-wrapper single-guidance-wrapper-with-que">
                        <h3 class="heading-mon-22"> WHAT ARE WEAK SIGNALS?</h3>
                        <p>Weak signals are early, subtle, and often ambiguous indications of potential future changes,
                            trends, or disruptions. They can be faint whispers of emerging technologies, shifting
                            societal values, or nascent market opportunities. Weak signals are often overlooked or
                            underestimated but can provide valuable insights for strategic foresight, innovation, and
                            decision-making.</p>
                        <p class="question">Examples of Weak Signals</p>
                        <ul>
                            <li>A small startup developing a new material that could replace traditional plastics</li>
                            <li>A growing online community discussing alternative forms of education</li>
                            <li>A series of unconnected news articles about increasing wildfires in specific regions
                            </li>
                            <li>A new fashion trend emerging in a niche market</li>
                            <li>A patent filing for a novel application of artificial intelligence in healthcare</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="left-wrapper">
                    <h2 class="fs-33px"> Weak Signal Attributes</h2>
                </div>
                <div class="right-wrapper w-100">
                    <div class="guidance-shadow three-col-with-single-ul-wrapper">
                        <ul>
                            <li>Often ambiguous or unclear in their implications</li>
                            <li>May seem insignificant or unrelated to current trends</li>
                            <li>Can be easily overlooked or dismissed
                            </li>
                            <li>May be scattered across different sources or domains</li>
                            <li>Can be contradictory or conflicting with existing information</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="left-wrapper">
                    <h2 class="fs-33px"> Before You Start</h2>
                </div>
                <div class="right-wrapper two-col-wrapper simple-heading-line-p-wrapper">
                    <div class="guidance-shadow ">
                        <p class="question">Be open-minded</p>
                        <p>Consider signals that may seem unusual or unrelated to your current focus.</p>
                    </div>
                    <div class="guidance-shadow ">
                        <p class="question">Look beyond the obvious</p>
                        <p>Don't just focus on prominent news headlines or popular trends. Explore niche sources,
                            academic research, and social media platforms.</p>
                    </div>
                    <div class="guidance-shadow">
                        <p class="question">Capture the signal, not the noise</p>
                        <p>Distinguish between genuine weak signals and mere noise or distractions.</p>
                    </div>
                    <div class="guidance-shadow">
                        <p class="question">Provide context</p>
                        <p>Look for relationships between weak signals and consider how they might interact or converge
                            in the future.</p>
                    </div>
                    
                </div>
            </div>
            <div class="d-flex">
                <div class="left-wrapper">
                    <h2 class="fs-33px">Some tips for you</h2>
                </div>
                <div class="right-wrapper two-col-wrapper">
                    @for (item of guidelines[2].step3?.someTipsForYou; track $index) {
                    <div class="guidance-shadow">
                        <img src="" width="50px" height="50px" style="background-color: rgba(0, 116, 193, 0.2);" />
                        <p>{{item}} </p>
                    </div>
                }
                </div>
            </div>
            <div class="d-flex">
                <div class="left-wrapper">
                    <h2 class="fs-33px">Domains</h2>
                </div>
                <div class="right-wrapper two-col-wrapper simple-heading-line-p-wrapper">
                    @for (item of guidelines[2].step3?.domain; track $index; let index=$index) {
                    <h3 class="heading-mon-22" [ngStyle]="{'margin-top':index!==0?'60px':''}">{{item.title}} </h3>
                    @for(subDomain of item.subDomain; track $index){
                    <div class="guidance-shadow ">
                        <p class="question">{{subDomain.title}}</p>
                        <p>{{subDomain.content}}</p>
                    </div>}
                    }
                </div>
            </div>

        </div>
        <div class="text-end" style="margin-bottom: 60px;">
            <button class="btn btn-gradient btn-bg-blue-gradient custom-btn" (click)="redirectToSolverFlow()">
                I UNDERSTAND
                <span class="arrow-btn-wrapper">
                    <img src="/images/home/right-arrow.svg" alt="Arrow" height="20" class="arrow-icon1">
                </span>
            </button>
        </div>
    </div>
</div>
}
