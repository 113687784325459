<section>
    <div class="container">
        <form [formGroup]="sdgForm" (ngSubmit)="onSubmit()">
            <div class="row ">
                <div class="col-md-12 mb-3">
                    <label class="form-label fw-semibold">SDG Alignment</label>
                    <small class="form-text text-muted info-msg">
                        Select all the SDGs which align with your challenge and intended outcome
                    </small>

                </div>
            </div>
            <div class="row">
                <div *ngFor="let sdg of sdgs" class="col-md-2 px-0 text-center" style="margin-bottom: 30px;">
                    <div class="sdg-img" [class.active]="isSdgSelected(sdg.id)" (click)="onSdgToggle(sdg.id)">
                        <img [src]="sdg.img" [alt]="sdg.name" />
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div style="margin-bottom: 20px;">
                        <label for="desiredOutcomes" class="form-label fw-semibold">SDG Alignment Explanation</label>
                        <small class="form-text text-muted info-msg">
                            For each selected SDG, explain how your challenge and its desired outcomes align with and
                            contribute
                            to the goal's targets </small>

                    </div>
                </div>

                <div class="row ">
                    <div class="col-12">
                        <div *ngFor="let sdgExplanation of sdgExplanations.controls; let i = index"
                            [formGroup]="getFormGroup(sdgExplanation)" class="row mt-3">
                            <div class="col-md-1">
                                <label class="form-label fw-semibold">SDG {{
                                    getFormGroup(sdgExplanation).get('sdgId')?.value }} <span
                                        style="color: #B21818;">*</span></label>
                                <br>
                            </div>
                            <div class=" col-md-11" style="margin-bottom: 20px;">
                                <textarea class="form-control" formControlName="explanation" rows="4"></textarea>
                                <app-input-error-message [formData]="getFormGroup(sdgExplanation)"
                                    [controlName]="'explanation'">
                                </app-input-error-message>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </form>

    </div>
</section>
