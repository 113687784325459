<!-- <footer > -->


<footer class="text-center text-lg-start pt-4" *ngIf="!isPreviewPage">
    <div class="container custom-container ">
        <div class="row mb-5">
            <div class="col-md-2">
                <img src="/logo.svg" alt="Challenges.one Logo" class="img-fluid" />
            </div>
            <div class="col-md-10 border-start border-2" style="border-color: #0074c1 !important">
                <p class="mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
            </div>
        </div>
        <div class="row d-flex align-items-center">
            <div class="col-md-9">
                <ul class="list-inline mb-0">
                    <li class="list-inline-item px-4">
                        <a routerLink="/about-us" class="text-decoration-none text-dark">About Us</a>
                    </li>
                    <li class="list-inline-item px-4">
                        <a routerLink="/contact-us" class="text-decoration-none text-dark">Contact Us</a>
                    </li>
                    <li class="list-inline-item px-4">
                        <a routerLink="/terms-conditions" class="text-decoration-none text-dark">Terms & Conditions</a>
                    </li>
                    <li class="list-inline-item px-4">
                        <a routerLink="/legal" class="text-decoration-none text-dark">Legal</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-3">
                <!-- Social media icons placeholder, replace with actual icons -->
                <ul class="list-inline mb-0 text-end">
                    <li class="list-inline-item social-icon-container">
                        <img src="/images/social/facebook.svg" alt="Facebook" class="img-fluid" />
                    </li>
                    <li class="list-inline-item social-icon-container">
                        <img src="/images/social/instagram.svg" alt="Instagram" class="img-fluid" />
                    </li>
                    <li class="list-inline-item social-icon-container">
                        <img src="/images/social/linkedin.svg" alt="Linkedin" class="img-fluid" />
                    </li>
                    <li class="list-inline-item social-icon-container">
                        <img src="/images/social/x.svg" alt="X" class="img-fluid" />
                    </li>
                </ul>
            </div>
        </div>

        <hr class="border-3" style="color: #0074C1 !important" />

        <div class="row mt-3">
            <div class="col-md-12 text-center">
                <p style="padding-bottom: 20px;" class="mb-0">Multiverz © {{ currentYear }}</p>
            </div>
        </div>
    </div>
</footer>
<!-- </footer> -->
