<div class="container" style="padding: 0">
    <form [formGroup]="solverFormStep10" (ngSubmit)="onSubmit(true)">
        <!-- <div>
            <label class="heading-mon-22">FINAL REPORT FORMAT</label>
            <app-quill-editor controlName="quillText" [parentForm]="solverFormStep10">
            </app-quill-editor>
            <app-input-error-message [formData]="solverFormStep10" [controlName]="'quillText'">
            </app-input-error-message>
        </div> -->
        <button class="add-new-btn" style="margin-top: 40px !important;
" (click)="downloadReportTemplate()">
            DOWNLOAD REPORT TEMPLATE
        </button>
        <div class="final-report-wrapper">
            <label class="heading-mon-22">UPLOAD FINAL REPORT IN PDF FORMAT</label>
            <button class="add-new-btn" (click)="uploadFinalReport()">
                <img src="/images/solver-flow/upload.svg" /><span>UPLOAD FINAL REPORT</span>
            </button>

        </div>
        @if (finalReport?.name) {
        <div class="col-md-6" style="margin-bottom: 20px">

            <div class="uploaded-files-wrapper">
                <label>{{finalReport?.name  }}</label>
                <span class="cancel" (click)="removeFile('finalReport')">
                    <img src="/images/common/cancel.svg" />
                </span>
            </div>

        </div>
        }
        <app-input-error-message [formData]="solverFormStep10" [controlName]="'finalReport'" />
        <div class="supported-files-wrapper">
            <label class="heading-mon-22">UPLOAD SUPPORTING FILES</label>
            <button class="add-new-btn" (click)="uploadSupportingFiles()">
                <img src="/images/solver-flow/upload.svg" /><span>UPLOAD FILES</span>
            </button>

            <p>
                Documents of .pdf, .doc, .docx, .xls, .xlsx, .csv, .jpg, .jpeg,
                .bmp, .png, .mp4, .ppt, .pptx for size not more than 10 MB can
                be uploaded here
            </p>

        </div>
        @if (supportedFile?.name) {
        <div class="col-md-6" style="margin-bottom: 20px">
            <div class="uploaded-files-wrapper">
                <label>{{ supportedFile?.name }}</label>
                <span class="cancel" (click)="removeFile('supportedFile')">
                    <img src="/images/common/cancel.svg" />
                </span>
            </div>
        </div>
        }
        <app-input-error-message [formData]="solverFormStep10" [controlName]="'supportedFile'" />

    </form>
</div>
