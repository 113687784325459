<div class="container-fluid mt-5 pt-2">
    <div class="row justify-content-center">
        <div class="col-md-10 border-bottom">
            <h6 class="text-start text-color-overview">
                <a href="the-challenges" style="color: black;"> Back </a>
            </h6>
        </div>
        <h2 class="text-center text-color mt-3">Request to launch a challenge</h2>
    </div>

    <div class="row mt-5 justify-content-center mb-5 pb-4">
        <div class="col-md-10 p-0">
            <app-request-launch-challenge-form [formGroup]="requestLaunchChallengeForm" (formSubmit)="onSubmit()">
            </app-request-launch-challenge-form>
        </div>
    </div>

</div>
