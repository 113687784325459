import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as AuthActions from './auth.actions';
import { AuthService } from '../../services/apis/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SweetAlertService } from '../../services/sweet-alert.service';

@Injectable()
export class AuthEffects {
    route:ActivatedRoute=inject(ActivatedRoute)
    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.login),
            mergeMap((action) =>
                this.authService.login(action).pipe(
                    map((response) => {
                        this.alertService.successToaster(response.message);
                        return AuthActions.loginSuccess({
                            token: response.accessToken,
                            user: response.data,
                        });
                    }),
                    catchError((error) => {
                        this.alertService.errorToaster(error.error.message);
                        return of(AuthActions.loginFailure({ error }));
                    })
                )
            )
        )
    );

    loginGoogle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loginGoogle),
            mergeMap((action) =>
                this.authService
                    .handleRedirectGoogle(action.code, action.scope)
                    .pipe(
                        map((response) => {
                            this.alertService.successToaster(response.message);
                            // // Check if the role is missing
                            if (

                                response?.data?.roles?.length === 0
                            ) {
                                // Perform the redirection without dispatching an NgRx action
                               return AuthActions.loginSSOSuccessWithoutRole({
                                    token: response.accessToken,
                                    user: response.data,

                                });
                            }
                            // Proceed with login success only if roles exist
                            return AuthActions.loginSuccess({
                                token: response.accessToken,
                                user: response.data,
                            });

                        }),
                        catchError((error) => {
                            this.alertService.errorToaster(error.error.message);
                            return of(AuthActions.loginFailure({ error }));
                        })
                    )
            )
        )
    );
    loginSSOSuccessWithoutRole$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.loginSSOSuccessWithoutRole),
                tap(() => {
                    this.router.navigate(['/signup/sso'], {
                        queryParams: { sso: 'true' },
                    });
                })
            ),
        { dispatch: false }
    );

    loginLinkedin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loginLinkedin),
            mergeMap((action) =>
                this.authService.handleRedirectLinkedin(action.code).pipe(
                    map((response) => {
                        this.alertService.successToaster(response.message);
                        if (

                            response?.data?.roles?.length === 0
                        ) {
                            // Perform the redirection without dispatching an NgRx action
                           return AuthActions.loginSSOSuccessWithoutRole({
                                token: response.accessToken,
                                user: response.data,

                            });
                        }
                        return AuthActions.loginSuccess({
                            token: response.accessToken,
                            user: response.data,
                        });
                    }),
                    catchError((error) => {
                        this.alertService.errorToaster(error.error.message);
                        return of(AuthActions.loginFailure({ error }));
                    })
                )
            )
        )
    );

    forgotPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.forgotPassword),
            mergeMap((action) =>
                this.authService.forgotPassword(action.email).pipe(
                    map((response) => {
                        this.alertService.successToaster(response.message);
                        return AuthActions.forgotPasswordSuccess({
                            message: response.message,
                        });
                    }),
                    catchError((error) => {
                        this.alertService.errorToaster(error.error.message);
                        return of(AuthActions.forgotPasswordFailure({ error }));
                    })
                )
            )
        )
    );

    loginSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.loginSuccess),
                tap(() => this.router.navigate([this.route.snapshot.queryParams['redirectUrl']??'/the-challenges'])) // Redirect to home page
            ),
        { dispatch: false }
    );

    logout$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.logout),
                tap(() => {
                    this.alertService.successToaster(
                        'You have been logged out successfully.'
                    );
                    this.router.navigate(['/login']);
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router,
        private alertService: SweetAlertService
    ) {}
}
