<div class="col-md-12" style="margin: 20px 0">
    <label class="form-label fw-semibold">Documents for Reference</label>
    <div class="upload-info-wrapper">
        <label for="{{ inputName }}" class="image-upload-wrapper">+</label>
        <input
            type="file"
            [name]="inputName"
            #inputFileRef
            (change)="onFileChange($event)"
            class="form-control"
            [id]="inputName"
            style="display: none"
            [accept]="allowedFileTypes"
            multiple
        />
        <small class="form-text text-muted info-msg">
            Documents of .pdf format, not exceeding
            {{ maxFileSize / (1024 * 1024) }}
            MB can be uploaded here
        </small>
    </div>
    <small
        *ngIf="formGroup.get(controlName)?.hasError('maxFilesExceeded')"
        class="text-danger"
        >You can upload a maximum of {{ maxFiles }} documents.</small
    >
    <small
        *ngIf="formGroup.get(controlName)?.hasError('maxFileSizeExceeded')"
        class="text-danger"
        >Each document must be less than
        {{ maxFileSize / (1024 * 1024) }} MB.</small
    >
    <small
        *ngIf="formGroup.get(controlName)?.hasError('invalidFileType')"
        class="text-danger"
        >Each document must be in PDF format.</small
    >
</div>
<div class="col-md-6" style="margin-bottom: 20px">
    @for (file of uploadedFiles.controls; track $index; let i = $index) {
    <div class="uploaded-files-wrapper">
        <label>{{ file.value?.displayName }}</label>
        <span class="cancel" (click)="removeFile(i, file.value, $event)">
            <img src="/images/common/cancel.svg" />
        </span>
    </div>
    }
</div>
