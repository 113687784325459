import { Component } from '@angular/core';
import { CardComponent } from '../shared/card/card.component';

@Component({
  selector: 'app-organisation-overview-dashboard',
  standalone: true,
  imports: [CardComponent],
  templateUrl: './organisation-overview-dashboard.component.html',
  styleUrl: './organisation-overview-dashboard.component.scss'
})
export class OrganisationOverviewDashboardComponent {

}
