import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { title } from 'process';
import { timelines } from '../../core/Model/timelines.dto';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { LaunchChallengeState } from '../../core/store/launchChallengeForm/launchChallenge.state';
import { catchError, of, switchMap, take, tap, withLatestFrom } from 'rxjs';
import {
    selectChallengeId,
    selectTimelinesError,
} from '../../core/store/launchChallengeForm/launchChallenge.selectors';
import { updateTimelines } from '../../core/store/launchChallengeForm/launchChallenge.actions';
import { InputErrorMessageComponent } from '../input-error-message/input-error-message.component';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { error, group } from 'console';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ChallengeService } from '../../core/services/apis/challenge.service';
import { ActivatedRoute } from '@angular/router';
import { start } from 'repl';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-timelines-form',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        CommonModule,
        InputErrorMessageComponent,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatFormFieldModule,
        MatIcon
    ],
    templateUrl: './timelines-form.component.html',
    styleUrl: './timelines-form.component.scss',
})
export class TimelinesFormComponent {
    timelineForm!: FormGroup;
    today: Date = new Date();
    @Output() successEvent = new EventEmitter<void>();
    @Output() timelineDetails = new EventEmitter<any>();
    route: ActivatedRoute = inject(ActivatedRoute);

    challengeId: any;

    constructor(
        private fb: FormBuilder,
        private store: Store<{ launchChallenge: LaunchChallengeState }>,
        private validationMessageService: ValidationMessageService,
        private cdr: ChangeDetectorRef,
        private alertService: SweetAlertService,
        private challengeService: ChallengeService
    ) {
        this.timelinesForm();
    }
    timelinesForm() {
        this.timelineForm = this.fb.group(
            {
                timelineImplication: ['long-term', Validators.required],
                startDate: ['', Validators.required],
                endDate: [''],
                milestones: this.fb.array([]),
                winnerAnnouncementDate: [''],
            },
            { validators: this.dateRangeValidator }
        );
    }

    dateRangeValidator: ValidatorFn = (
        group: AbstractControl
    ): ValidationErrors | null => {
        const startDate = group.get('startDate')?.value;
        const endDate = group.get('endDate')?.value;
        const winnerAnnouncementDate = group.get(
            'winnerAnnouncementDate'
        )?.value;
        const milestones = group.get('milestones') as FormArray;

        // Check if start date is less than the present date
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Clear time part for accurate comparison
        if (startDate && new Date(startDate) < today) {
            return { startDateBeforeToday: true };
        }
        if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
            // const start = new Date(startDate);
            // const end = new Date(endDate);
            if (
                startDate &&
                endDate &&
                new Date(startDate) > new Date(endDate)
            ) {
                return { endDateBeforeStartDate: true };
            }
        }

        // Check for winner annoument Date
        if (
            endDate &&
            winnerAnnouncementDate &&
            new Date(winnerAnnouncementDate) < new Date(endDate)
        ) {
            // const end = new Date(endDate);
            // const winnerDate = new Date(winnerAnnouncementDate);

            if (
                endDate &&
                winnerAnnouncementDate &&
                new Date(winnerAnnouncementDate) < new Date(endDate)
            ) {
                return { winnerDateBeforeEndDate: true };
            }
        }

        if (
            startDate &&
            winnerAnnouncementDate &&
            new Date(winnerAnnouncementDate) < new Date(startDate)
        ) {
            if (
                startDate &&
                winnerAnnouncementDate &&
                new Date(winnerAnnouncementDate) < new Date(startDate)
            ) {
                return { winnerBeforeStartDate: true };
            }
        }

        // Winner Announcemnt start date
        // if ( startDate && winnerAnnouncementDate && new Date(winnerAnnouncementDate) >= new Date(startDate))
        //     {
        //         return { winnerBeforeStartDate: true};
        //     }

        // Check each milestone date against start and end dates
        if (milestones) {
            for (let i = 0; i < milestones.controls.length; i++) {
                const milestoneDate = milestones.at(i).get('date')?.value;
                if (milestoneDate) {
                    if (
                        startDate &&
                        new Date(milestoneDate) < new Date(startDate)
                    ) {
                        milestones
                            .at(i)
                            .setErrors({ milestoneBeforeStartDate: true });
                    }
                    if (
                        endDate &&
                        new Date(milestoneDate) > new Date(endDate)
                    ) {
                        milestones
                            .at(i)
                            .setErrors({ milestoneAfterEndDate: true });
                    }
                }
            }
        }
        return null;
    };

    ngOnInit(): void {
        const timelineImplicationValue = this.timelineForm.get(
            'timelineImplication'
        )?.value;

        this.addMilestones();
        // Explicitly trigger the initial validation state after form initialization
        this.updateEndDateValidation(timelineImplicationValue);
        this.handleTimelineImplicationChanges();

        this.challengeId = this.route.snapshot.queryParams['id'];
        if (this.challengeId) {
            this.loadTimelines();
        }

        this.timelineForm.get('startDate')?.valueChanges.subscribe(() => {
            this.timelineForm.updateValueAndValidity();
            this.updateRelatedDateFields();
        });

        // Listen to endDate changes and trigger validation for milestones
        this.timelineForm.get('endDate')?.valueChanges.subscribe(() => {
            this.updateRelatedDateFields();
        });
    }

    updateRelatedDateFields() {
        // Trigger validation for milestones and winner announcement dates
        this.milestones.controls.forEach((control) => {
            control.updateValueAndValidity();
        });
        this.timelineForm
            .get('winnerAnnouncementDate')
            ?.updateValueAndValidity();
    }

    // Helper function to adjust for the timezone offset
    private adjustForTimezone(date: Date): Date {
        return new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes() - date.getTimezoneOffset()
        );
    }

    createMilestoneGroup(): FormGroup {
        return this.fb.group(
            {
                title: [''],
                date: [''],
            },
            { validators: this.milestoneValidator }
        );
    }

    milestoneValidator: ValidatorFn = (
        group: AbstractControl
    ): ValidationErrors | null => {
        const title = group.get('title')?.value;
        const date = group.get('date')?.value;

        const startDate = this.timelineForm.get('startDate')?.value;
        const endDate = this.timelineForm.get('endDate')?.value;

        if ((title && !date) || (!title && date)) {
            return { milestoneIncomplete: true };
        }

        if (date) {
            const milestoneDate = new Date(date);
            if (startDate && milestoneDate < new Date(startDate)) {
                return { milestoneBeforeStartDate: true };
            }
            if (endDate && milestoneDate > new Date(endDate)) {
                return { milestoneBeforeEndDate: true };
            }
        }

        return null;
    };

    get milestones(): FormArray {
        return this.timelineForm.get('milestones') as FormArray;
    }

    addMilestones() {
        for (let i = 0; i < 5; i++) {
            this.milestones.push(this.createMilestoneGroup());
        }
    }

    clearDate(index: number) {
        // Get the specific milestone FormGroup from the FormArray
        const milestoneGroup = this.milestones.at(index) as FormGroup;

        // Clear the 'date' control for this milestone
        milestoneGroup.get('date')?.setValue(null);
    }

    handleTimelineImplicationChanges(): void {
        const timelineImplicationControl = this.timelineForm.get(
            'timelineImplication'
        );
        const endDateControl = this.timelineForm.get('endDate');

        if (timelineImplicationControl && endDateControl) {
            this.updateEndDateValidation(timelineImplicationControl.value);
            timelineImplicationControl.valueChanges.subscribe((value) => {
                this.updateEndDateValidation(value);
            });
        }
    }

    updateEndDateValidation(selectedValue?: string): void {
        const endDateControl = this.timelineForm.get('endDate');

        if (selectedValue === 'long-term') {
            // Clear validators and reset the value
            endDateControl?.clearValidators();
            endDateControl?.reset(); // Ensure no value is set that might trigger validation
            endDateControl?.updateValueAndValidity(); // Update validation state
            this.cdr.detectChanges();
        } else if (selectedValue === 'fixed-term') {
            // Set validators
            endDateControl?.setValidators([Validators.required]);
            endDateControl?.updateValueAndValidity(); // Update validation stat
        }
    }

    getMinDate(): Date {
        const startDate = this.timelineForm.get('startDate')?.value;
        return startDate ? new Date(startDate) : this.today;
    }

    getMaxDate(): Date | null {
        const endDate = this.timelineForm.get('endDate')?.value;
        return endDate ? new Date(endDate) : null;
    }

    // Method to get the minimum date for the endDate field
    getMinEndDate(): Date {
        const startDate = this.timelineForm.get('startDate')?.value;
        if (startDate) {
            const minEndDate = new Date(startDate);
            minEndDate.setDate(minEndDate.getDate() + 1); // Add one day to the start date
            return minEndDate;
        }
        return this.today; // Default to today if startDate is not set
    }

    getWinnerAnnouncementMinDate(): Date {
        const endDate = this.timelineForm.get('endDate')?.value;
        if (endDate) {
            return new Date(endDate); // Allow the same day as endDate
        }
        return this.today;
    }

    loadTimelines(): void {
        const challengeId = this.challengeId; // Replace with the actual challengeId

        this.challengeService.getTimelines(challengeId).subscribe({
            next: (data: any) => {
                let timeline = {
                    startDate: data.data.startDate,
                    endDate: data.data.endDate,
                };
                this.timelineDetails.emit(timeline);
                this.populateForm(data);
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorPopup(error.error.message);
            },
        });
    }

    populateForm(data: any): void {
        // Adjust the date values for timezone offset and format them if they are not empty
        const startDate = data.data.startDate
            ? this.adjustForTimezone(new Date(data.data.startDate))
            : '';
        const endDate = data.data.endDate
            ? this.adjustForTimezone(new Date(data.data.endDate))
            : '';
        const winnerAnnouncementDate = data.data.winnerAnnouncementDate
            ? this.adjustForTimezone(new Date(data.data.winnerAnnouncementDate))
            : '';

        const timelineImplication = data.data.endDate
            ? 'fixed-term'
            : 'long-term'; //If end date exist select ''fixed-date'
        this.timelineForm.patchValue({
            timelineImplication: timelineImplication,
            startDate:
                startDate instanceof Date ? this.formatDate(startDate) : '',
            endDate: endDate instanceof Date ? this.formatDate(endDate) : '',
            winnerAnnouncementDate:
                winnerAnnouncementDate instanceof Date
                    ? this.formatDate(winnerAnnouncementDate)
                    : '',
        });

        // Update validation state based on timelineImplication
        this.updateEndDateValidation(timelineImplication);
        // Patch milestones data to the form array
        const milestonesArray = this.milestones;
        data.data.milestones.forEach((milestone: any, index: number) => {
            if (milestonesArray.at(index)) {
                // Patch existing milestone if available
                milestonesArray.at(index).patchValue({
                    title: milestone.title || '',
                    date: milestone.date
                        ? this.formatDate(
                              this.adjustForTimezone(new Date(milestone.date))
                          )
                        : '',
                });
            } else {
                // Add a new milestone if the index doesn't exist
                const milestoneGroup = this.createMilestoneGroup();
                milestoneGroup.patchValue({
                    title: milestone.title || '',
                    date: milestone.date
                        ? this.formatDate(
                              this.adjustForTimezone(new Date(milestone.date))
                          )
                        : '',
                });
                milestonesArray.push(milestoneGroup);
            }
        });
    }

    formatDate(date: Date): string {
        return date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    }

    // onSubmit(): void {
    //     this.timelineForm.markAllAsTouched();
    //     if (this.timelineForm.valid) {
    //         const formValue = this.timelineForm.value;

    //         const filteredMilestones = formValue.milestones
    //             .filter((milestone: any) => {
    //                 const title = milestone.title ? milestone.title.trim() : '';
    //                 const date = milestone.date ? milestone.date : null;
    //                 return title !== '' || date !== null;
    //             })
    //             .map((milestone: any) => ({
    //                 ...milestone,
    //                 date: milestone.date
    //                     ? this.adjustForTimezone(new Date(milestone.date))
    //                     : null,
    //             }));

    //         const timelinesData: timelines = {
    //             startDate: this.adjustForTimezone(
    //                 new Date(formValue.startDate)
    //             ),
    //             endDate: this.adjustForTimezone(new Date(formValue.endDate)),
    //             milestones: filteredMilestones,
    //             winnerAnnouncementDate: this.adjustForTimezone(
    //                 new Date(formValue.winnerAnnouncementDate)
    //             ),
    //         };
    //         this.store
    //             .select(selectChallengeId)
    //             .pipe(take(1))
    //             .subscribe((challengeId) => {
    //                 if (challengeId) {
    //                     this.store.dispatch(
    //                         updateTimelines({
    //                             challengeId,
    //                             data: timelinesData,
    //                         })
    //                     );
    //                     this.store
    //                         .pipe(
    //                             withLatestFrom(
    //                                 this.store.select(selectTimelinesError)
    //                             ),
    //                             tap(([_, error]) => {
    //                                 if (error) {
    //                                     this.validationMessageService.setServerErrors(
    //                                         this.timelineForm,
    //                                         error
    //                                     );
    //                                 }
    //                             })
    //                         )
    //                         .subscribe();
    //                 }
    //             });
    //     }
    // }
    onSubmit(): void {
        this.timelineForm.markAllAsTouched();

        if (this.timelineForm.valid) {
            const formValue = this.timelineForm.value;

            const filteredMilestones = formValue.milestones
                .filter((milestone: any) => {
                    const title = milestone.title ? milestone.title.trim() : '';
                    const date = milestone.date ? milestone.date : null;
                    return title !== '' || date !== null;
                })
                .map((milestone: any) => ({
                    ...milestone,
                    date: milestone.date
                        ? this.adjustForTimezone(new Date(milestone.date))
                        : null,
                }));

            const timelinesData: timelines = {
                startDate: this.adjustForTimezone(
                    new Date(formValue.startDate)
                ),
                endDate: this.adjustForTimezone(new Date(formValue.endDate)),
                milestones: filteredMilestones,
                winnerAnnouncementDate: this.adjustForTimezone(
                    new Date(formValue.winnerAnnouncementDate)
                ),
            };

            if (formValue.timelineImplication === 'long-term') {
                delete timelinesData.endDate;
            }

            const challengeId = this.route.snapshot.queryParams['id']; // Replace with the actual challengeId
            this.challengeService
                .updateTimelines(challengeId, timelinesData)
                .subscribe({
                    next: (response: any) => {
                        this.alertService.successPopup(response.message);
                        this.successEvent.emit();
                    },
                    error: (error: HttpErrorResponse) => {
                        if (error.status === 422) {
                            const errors = error.error.errors;
                            if (errors) {
                                this.validationMessageService.setServerErrors(
                                    this.timelineForm,
                                    errors
                                );
                            }
                        } else {
                            this.alertService.errorPopup(error.error.message);
                        }
                    },
                });
        }
    }
}
