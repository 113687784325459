import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit, Output } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { InputErrorMessageComponent } from '../input-error-message/input-error-message.component';
import {
    Observable,
    of,
    Subject,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs';
import { ChallengeDetailsDto } from '../../core/Model/challenge-details';
import { LaunchChallengeState } from '../../core/store/launchChallengeForm/launchChallenge.state';
import { Store } from '@ngrx/store';
import * as LaunchChallengeActions from '../../core/store/launchChallengeForm/launchChallenge.actions';
import {
    selectChallengeDetails,
    selectChallengeDetailsError,
    selectChallengeId,
} from '../../core/store/launchChallengeForm/launchChallenge.selectors';
import { QuillEditorComponent } from '../quill-editor/quill-editor.component';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { ChallengeService } from '../../core/services/apis/challenge.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { ActivatedRoute,Route, Router } from '@angular/router';
@Component({
    selector: 'app-challenge-details-form',
    standalone: true,
    imports: [
        CommonModule,
        QuillModule,
        ReactiveFormsModule,
        InputErrorMessageComponent,
        QuillEditorComponent,
    ],
    templateUrl: './challenge-details-form.component.html',
    styleUrl: './challenge-details-form.component.scss',
})
export class ChallengeDetailsFormComponent implements OnInit, OnDestroy {
    challengeDetailsForm!: FormGroup;
    challengeDetails$: Observable<ChallengeDetailsDto | null>;
    route:ActivatedRoute=inject(ActivatedRoute);
    router:Router=inject(Router);
    challengeId:any;
    private unsubscribe$ = new Subject<void>();
    @Output() successEvent = new EventEmitter<void>();
    constructor(
        private fb: FormBuilder,
        private store: Store<{ launchChallenge: LaunchChallengeState }>,
        protected validationMessageService: ValidationMessageService,
        private challengeService: ChallengeService,
        private alertService: SweetAlertService
    ) {
        this.detailForm();
        this.challengeDetails$ = this.store.select(selectChallengeDetails);
    }

    ngOnInit() {
        this.challengeId = this.route.snapshot.queryParams['id'];
        if(this.challengeId){
            this.loadChallengeDetails();
        }
    }

    detailForm(){
        this.challengeDetailsForm = this.fb.group({
            objective1: ['', Validators.required],
            objective2: [''],
            objective3: [''],
            objective4: [''],
            objective5: [''],
            desiredOutcomes: [
                '',
                [Validators.required, Validators.maxLength(5000)],
            ],
            solutionRequirements: [
                '',
                [Validators.required, Validators.maxLength(5000)],
            ],
            constraintsAndLimitations: ['', Validators.maxLength(5000)],
        });
    }

    loadChallengeDetails() {
        const challengeId = this.challengeId; // Replace this with the actual challengeId obtained in step 1
        this.challengeService
            .getChallengeDetails(challengeId)
            .subscribe({
                next: (challengeDetails:any) => {
                    if (challengeDetails) {
                        // Patch the form with the retrieved data
                        this.challengeDetailsForm.patchValue({
                            objective1: challengeDetails.data.objectives[0] || '',
                            objective2: challengeDetails.data.objectives[1] || '',
                            objective3: challengeDetails.data.objectives[2] || '',
                            objective4: challengeDetails.data.objectives[3] || '',
                            objective5: challengeDetails.data.objectives[4] || '',
                            desiredOutcomes: challengeDetails.data.desiredOutcomes,
                            solutionRequirements:
                                challengeDetails.data.solutionRequirements,
                            constraintsAndLimitations:
                                challengeDetails.data.constraintsAndLimitations,
                        });
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.alertService.errorPopup(
                        error.error.message
                    );
                },
            });
    }

    // onSubmit() {
    //     this.challengeDetailsForm.markAllAsTouched();
    //     if (this.challengeDetailsForm.valid) {
    //         this.store
    //             .select(selectChallengeId)
    //             .pipe(take(1))
    //             .subscribe((challengeId) => {
    //                 if (challengeId) {
    //                     const formValues = this.challengeDetailsForm.value;
    //                     const objectives = [
    //                         formValues.objective1,
    //                         formValues.objective2,
    //                         formValues.objective3,
    //                         formValues.objective4,
    //                         formValues.objective5,
    //                     ].filter((obj) => !!obj);

    //                     // Map form values to the ChallengeDetailsDto
    //                     const challengeDetailsData: ChallengeDetailsDto = {
    //                         objectives,
    //                         desiredOutcomes: formValues.desiredOutcomes,
    //                         solutionRequirements:
    //                             formValues.solutionRequirements,
    //                         constraintsAndLimitations:
    //                             formValues.constraintsAndLimitations,
    //                     };

    //                     // Clear existing server errors
    //                     this.validationMessageService.clearServerErrors(
    //                         this.challengeDetailsForm
    //                     );
    //                     // NGRX
    //                     // // Dispatch the update action
    //                     // this.store.dispatch(
    //                     //     LaunchChallengeActions.updateChallengeDetails({
    //                     //         id: challengeId,
    //                     //         data: challengeDetailsData,
    //                     //     })
    //                     // );

    //                     // // Wait for the error state to be updated
    //                     // this.store
    //                     //     .pipe(
    //                     //         withLatestFrom(
    //                     //             this.store.select(
    //                     //                 selectChallengeDetailsError
    //                     //             )
    //                     //         ),
    //                     //         tap(([_, error]) => {
    //                     //             if (error) {
    //                     //                 this.validationMessageService.setServerErrors(
    //                     //                     this.challengeDetailsForm,
    //                     //                     error
    //                     //                 );
    //                     //             } else {
    //                     //                 // Clear server errors if submission is successful
    //                     //                 this.validationMessageService.clearServerErrors(
    //                     //                     this.challengeDetailsForm
    //                     //                 );
    //                     //             }
    //                     //         })
    //                     //     )
    //                     //     .subscribe();

    //                     // Make the API call to update the challenge details
    //                     this.challengeService
    //                         .updateChallengeDetails(
    //                             challengeId,
    //                             challengeDetailsData
    //                         )
    //                         .pipe(takeUntil(this.unsubscribe$))
    //                         .subscribe({
    //                             next: (response) => {
    //                                 // Handle successful response
    //                                 this.alertService.successPopup(
    //                                     response.message
    //                                 );
    //                             },
    //                             error: (error: HttpErrorResponse) => {
    //                                 // Handle error response
    //                                 if (error.status === 422) {
    //                                     const errors = error?.error?.errors;
    //                                     if (errors) {
    //                                         this.validationMessageService.setServerErrors(
    //                                             this.challengeDetailsForm,
    //                                             errors
    //                                         );
    //                                     }
    //                                 } else {
    //                                     this.alertService.errorPopup(
    //                                         error.error.message
    //                                     );
    //                                 }
    //                             },
    //                         });
    //                 }
    //             });
    //     }
    // }

    onSubmit() {
        this.challengeDetailsForm.markAllAsTouched();
        if (this.challengeDetailsForm.valid) {
            const challengeId = this.route.snapshot.queryParams['id'];
             // Replace this with the actual challengeId obtained in step 1
            const formValues = this.challengeDetailsForm.value;

            const objectives = [
                formValues.objective1,
                formValues.objective2,
                formValues.objective3,
                formValues.objective4,
                formValues.objective5,
            ].filter((obj) => !!obj);

            // Map form values to the ChallengeDetailsDto
            const challengeDetailsData: ChallengeDetailsDto = {
                objectives,
                desiredOutcomes: formValues.desiredOutcomes,
                solutionRequirements: formValues.solutionRequirements,
                constraintsAndLimitations: formValues.constraintsAndLimitations,
            };

            // Clear existing server errors
            this.validationMessageService.clearServerErrors(this.challengeDetailsForm);

            // Make the API call to update the challenge details
            this.challengeService.updateChallengeDetails(challengeId, challengeDetailsData)
                // .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: (response) => {
                        // Handle successful response
                        this.alertService.successPopup(response.message);
                         // Replace with your actual route
                         this.successEvent.emit();
                    },
                    error: (error: HttpErrorResponse) => {
                        // Handle error response
                        if (error.status === 422) {
                            const errors = error?.error?.errors;
                            if (errors) {
                                this.validationMessageService.setServerErrors(
                                    this.challengeDetailsForm,
                                    errors
                                );
                            }
                        } else {
                            this.alertService.errorPopup(
                                error.error.message
                            );
                        }
                    },
                });
        }
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
