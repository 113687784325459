import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-challenge-details-overview',
  standalone: true,
  imports: [],
  templateUrl: './challenge-details-overview.component.html',
  styleUrl: './challenge-details-overview.component.scss'
})
export class ChallengeDetailsOverviewComponent {
  @Input() overview = '';
  @Input() challenge:any = {};
  @Input() objectives:string[] = [];

 
displaySDGImage(id: number): string {    
  switch (id) {
    case 1:
      return '/images/SDG/no-poverty.svg';
    case 2:
      return '/images/SDG/zero-hunger.svg';
    case 3:
      return '/images/SDG/good-health.svg';
    case 4:
      return '/images/SDG/quailty-education.svg';
    case 5:
      return '/images/SDG/gender-equailty.svg';
    case 6:
      return '/images/SDG/clean-water.svg';
    case 7:
      return '/images/SDG/affordable-clean-energy.svg';
    case 8:
      return '/images/SDG/decent-work.svg';
    case 9:
      return '/images/SDG/industory-innovation.svg';
    case 10:
      return '/images/SDG/reduced-inequailties.svg';
    case 11:
      return '/images/SDG/sustainable-cities.svg';
    case 12:
      return '/images/SDG/responsible-consumption.svg';
    case 13:
      return '/images/SDG/climate-action.svg';
    case 14:
      return '/images/SDG/life-below-water.svg';
    case 15:
      return '/images/SDG/life-on-land.svg';
    case 16:
      return '/images/SDG/peace-justice.svg';
    case 17:
      return '/images/SDG/partnership-for-goals.svg';
    default:
      return '/images/SDG/no-poverty.svg';
  }
}
}