<form [formGroup]="formGroup">
  <section>
    <h3>THINKING FOR THE NEAR <span>TERM [ less than 2 years ]</span></h3>
    <div class="step-6-term-wrapper">
      <div formGroupName="NEAR">
        <app-quill-editor controlName="idea" [parentForm]="nearTermFormGroup" customClass="custom-height">
        </app-quill-editor>
        <app-input-error-message [formData]="nearTermFormGroup" controlName="idea">
        </app-input-error-message>
        <!-- Add Quill editor here -->
        <div class="prority-wrapper row">
          <div class="col-md-9">
            <h5>ADD ACTIONABLES AND PRIORITIZE THEM</h5>
          </div>
          <div class="col-md-3">
            <h6>PRIORITY</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9 justify-content-between">

          </div>
          <div class="col-md-3 row prority-label">
            <div class="col-md-4">Low</div>
            <div class="col-md-4">Mid</div>
            <div class="col-md-4">High</div>
          </div>


          <div formArrayName="termActionables">

            <div *ngFor="let item of NEAR.controls; let i = index" [formGroupName]="i" class="row">
              <div class="col-md-9 prority-input-wrapper">
                <input class="form-control" formControlName="title" placeholder="Add actionable item here" />
              </div>
              <div class="col-md-3 row prority-input-wrapper">
                <div class="col-md-4">
                  <input class="form-check-input" type="radio" formControlName="priority" value="LOW" />
                </div>
                <div class="col-md-4">
                  <input class="form-check-input" type="radio" formControlName="priority" value="MID" />
                </div>
                <div class="col-md-4">
                  <input class="form-check-input" type="radio" formControlName="priority" value="HIGH" />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="NEAR.errors?.['minLengthArrayWithPriority']" class="error-message">
            At least one actionable item is required.
          </div>
        </div>

      </div>

        </div>
    
  </section>
  <section>
    <h3>THINKING FOR THE MEDIUM <span>TERM [ 2 to 5 years ]</span></h3>
    <div class="step-6-term-wrapper">
      <div formGroupName="MEDIUM">
        <app-quill-editor controlName="idea" [parentForm]="nextTermFormGroup" customClass="custom-height">
        </app-quill-editor>
        <app-input-error-message [formData]="nextTermFormGroup" controlName="idea">
        </app-input-error-message>
        <!-- Add Quill editor here -->
        <div class="prority-wrapper row">
          <div class="col-md-9">
            <h5>ADD ACTIONABLES AND PRIORITIZE THEM</h5>
          </div>
          <div class="col-md-3">
            <h6>PRIORITY</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9 justify-content-between">

          </div>
          <div class="col-md-3 row prority-label">
            <div class="col-md-4">Low</div>
            <div class="col-md-4">Mid</div>
            <div class="col-md-4">High</div>
          </div>


          <div formArrayName="termActionables">

            <div *ngFor="let item of MEDIUM.controls; let i = index" [formGroupName]="i" class="row">
              <div class="col-md-9 prority-input-wrapper">
                <input class="form-control" formControlName="title" placeholder="Add actionable item here" />
              </div>
              <div class="col-md-3 row prority-input-wrapper">
                <div class="col-md-4">
                  <input class="form-check-input" type="radio" formControlName="priority" value="LOW" />
                </div>
                <div class="col-md-4">
                  <input class="form-check-input" type="radio" formControlName="priority" value="MID" />
                </div>
                <div class="col-md-4">
                  <input class="form-check-input" type="radio" formControlName="priority" value="HIGH" />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="MEDIUM.errors?.['minLengthArrayWithPriority']" class="error-message">
            At least one actionable item is required.
          </div>
          <app-input-error-message [formData]="nearTermFormGroup" controlName="termActionables">
          </app-input-error-message>
        </div>

      </div>
    </div>
  </section>
  <section>
    <h3>THINKING FOR THE LONG <span>TERM [ 5 to 10 years ]</span></h3>
    <div class="step-6-term-wrapper">
      <div formGroupName="LONG">
        <app-quill-editor controlName="idea" [parentForm]="longTermFormGroup" customClass="custom-height">
        </app-quill-editor>
        <app-input-error-message [formData]="longTermFormGroup" controlName="idea">
        </app-input-error-message>
        <!-- Add Quill editor here -->
        <div class="prority-wrapper row">
          <div class="col-md-9">
            <h5>ADD ACTIONABLES AND PRIORITIZE THEM</h5>
          </div>
          <div class="col-md-3">
            <h6>PRIORITY</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9 justify-content-between">

          </div>
          <div class="col-md-3 row prority-label">
            <div class="col-md-4">Low</div>
            <div class="col-md-4">Mid</div>
            <div class="col-md-4">High</div>
          </div>


          <div formArrayName="termActionables">

            <div *ngFor="let item of LONG.controls; let i = index" [formGroupName]="i" class="row">
              <div class="col-md-9 prority-input-wrapper">
                <input class="form-control" formControlName="title" placeholder="Add actionable item here" />
              </div>
              <div class="col-md-3 row prority-input-wrapper">
                <div class="col-md-4">
                  <input class="form-check-input" type="radio" formControlName="priority" value="LOW" />
                </div>
                <div class="col-md-4">
                  <input class="form-check-input" type="radio" formControlName="priority" value="MID" />
                </div>
                <div class="col-md-4">
                  <input class="form-check-input" type="radio" formControlName="priority" value="HIGH" />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="LONG.errors?.['minLengthArrayWithPriority']" class="error-message">
            At least one actionable item is required.
          </div>
        </div>

      </div>
    </div>
</section>
<div class="solver-document-url-ai-wrapper">
    
  <app-input-solution-references [formGroup]="formGroup" [solutionReferences]="solutionReferences"></app-input-solution-references>
  
    </div>
</form>
