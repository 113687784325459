import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
} from '@angular/forms';
import { CommonValidators } from '../../core/utilities/common-validators';
import { SolutionReferences } from './types/input-solution-references-types';
import { CommonModule } from '@angular/common';
import { ReferenceHandlerUploadsComponent } from './reference-handler-uploads/reference-handler-uploads.component';
import { InputErrorMessageComponent } from '../input-error-message/input-error-message.component';
import { QuillEditorComponent } from '../quill-editor/quill-editor.component';
import { ReferenceHandlerComponent } from './reference-handler/reference-handler.component';

@Component({
    selector: 'app-input-solution-references',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ReferenceHandlerComponent,
        ReferenceHandlerUploadsComponent,
        InputErrorMessageComponent,
        QuillEditorComponent,
    ],
    templateUrl: './input-solution-references.component.html',
    styleUrl: './input-solution-references.component.scss',
})
export class InputSolutionReferencesComponent implements OnChanges {
    @Input({ required: true }) formGroup!: FormGroup;
    @Input() solutionReferences!: SolutionReferences;
    @Input() maxReferenceUrls: number = 10;
    @Input() maxReferenceDocuments: number = 10;
    @Input() maxFileSize: number = 10 * 1024 * 1024;
    @Input() allowedFileTypes: string[] = ['.pdf'];
    @Input() showPromptBox: boolean = true;

    private localFormGroup!: FormGroup;
    @ViewChild('referenceUrlsControlRef')
    referenceUrlsControlRef!: ReferenceHandlerComponent;
    @ViewChild('referenceDocumentsControlRef')
    referenceDocumentsControlRef!: ReferenceHandlerUploadsComponent;

    constructor(private fb: FormBuilder) {
        this.localFormGroup = this.fb.group({
            referenceUrls: this.fb.array(
                this.solutionReferences?.referenceUrls || [],
                [
                    CommonValidators.maxArrayItemValidator(
                        this.maxReferenceUrls
                    ),
                    CommonValidators.duplicateArrayItemValidator(),
                ]
            ),
            prompts: this.fb.control(this.solutionReferences?.prompts ?? ''),
            referenceDocuments: this.fb.array(
                this.solutionReferences?.referenceDocuments || [],
                [
                    CommonValidators.maxArrayItemValidator(
                        this.maxReferenceDocuments
                    ),
                ]
            ),
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['solutionReferences'] &&
            changes['solutionReferences'].currentValue !==
                changes['solutionReferences'].previousValue &&
            this.solutionReferencesFormGroup
        ) {
            this.patchFormValues();
        }
    }

    ngOnInit(): void {
        if (!this.formGroup) {
            throw new Error('FormGroup is required');
        }
        this.formGroup.addControl('solutionReferences', this.localFormGroup);
        if (this.solutionReferences) {
            this.patchFormValues();
        }
    }

    patchFormValues() {
        if (this.solutionReferences?.prompts) {
            this.solutionReferencesFormGroup.patchValue({
                prompts: this.solutionReferences.prompts,
            });
        }
        if (this.solutionReferences?.referenceUrls) {
            this.referenceUrlsControlRef?.clearItems();
            this.solutionReferences.referenceUrls.forEach((url) => {
                (
                    this.solutionReferencesFormGroup.get(
                        'referenceUrls'
                    ) as FormArray
                ).push(this.fb.control(url));
            });
        }
        if (this.solutionReferences?.referenceDocuments) {
            this.referenceDocumentsControlRef?.clearItems();
            this.solutionReferences.referenceDocuments.forEach((doc) => {
                (
                    this.solutionReferencesFormGroup.get(
                        'referenceDocuments'
                    ) as FormArray
                ).push(this.fb.control(doc));
            });
        }
    }

    clearFormValues() {
        this.solutionReferencesFormGroup.reset();
        this.referenceUrlsControlRef?.clearItems();
        this.referenceDocumentsControlRef?.clearItems();
    }

    get solutionReferencesFormGroup() {
        return this.formGroup?.get('solutionReferences') as FormGroup;
    }
}
