<section class="custom-container-fluid ">
    <div class="container mt-5">
        <div class="row">
            <div class="col-md-6 px-0">
                <div>
                    <h5 class="mt-5 color-text text-start fw-bold p-text-size">THINK AHEAD. THINK ACROSS. THINK AGAIN.
                    </h5>
                    <div class="d-flex flex-column mb-3 text-start">
                        <h1 class="fw-bold text-size">Social Impact,</h1>
                        <h1 class="fw-bold text-size">Real World</h1>
                        <h1 class="fw-bold text-size">Challenges.</h1>
                    </div>
                    <p class="fw-semibold text-start p-text-size">We are an open-innovation platform empowering
                        <br>interns,
                        innovators, and startups to
                        develop
                        solutions <br> with deep social impact.</p>
                    <button
                        class="btn btn-primary mt-4 rounded-start-0 get-challenge-btn text-start fs-18px fw-bold custom-btn">
                        JUMP INTO THE CHALLENGES
                        <span class="arrow-btn-wrapper"> <img src="/images/home/right-arrow.svg" alt="Arrow" height="20"
                                class="arrow-icon1"></span>
                    </button>
                </div>
            </div>
            <div class="col-md-6 pe-0">
                <div class="right-straps-img-container">
                    <img src="/images/home/globe.png" alt="Globe in Hand" class="img-fluid w-100 ">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="custom-container-fluid ">
    <div class="container my-5 py-5">
        <div class="row">
            <div class="col-md-4 px-0">

                <h5 class="text-right color-text fw-bold p-text-size">CREATE IMPACT</h5>
                <h2 class="text-right fw-semibold fs-33px">
                    Challenges <br /> You Must See
                </h2>
                <div class="text-right mt-5">
                    <div class="scroll-buttons d-inline-flex gap-3 pt-5">
                        <div class="circle d-flex justify-content-center align-items-center" id="scroll-left"
                            (click)="prev()">
                            <img src="/images/home/left-arrow.svg" alt="Left Arrow">
                        </div>
                        <div class="circle d-flex justify-content-center align-items-center" id="scroll-right"
                            (click)="next()">
                            <img src="/images/home/right-arrow.svg" alt="Right Arrow">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 pe-0">
                <div class="right-img-container">
                    <div>
                        <div id="carouselExample" class="carousel slide">
                            <div class="carousel-inner" id="cards-container">
                                <div *ngFor="let card of visibleCards" class="carousel-item active">
                                    <div class="row">
                                        <div class="col-md-3" *ngFor="let card of visibleCards">
                                            <div class="card border-0 d-flex flex-column">
                                                <img [src]="card.image" alt="Card image" class="card-image-size">
                                                <div class="card-body d-flex flex-column justify-content-between px-0">
                                                    <div>
                                                        <h5 class="text-start fw-bold fs-15px text-truncate"
                                                            title="{{ card.title }}">{{ card.title }}
                                                        </h5>
                                                    </div>
                                                    <div class="mt-auto">
                                                        <p class="text-start card-text-color fs-12px">{{ card.text }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container mt-3">
    <div class="row">
        <div class="col-md-12">
            <h5 class="text-right color-text fw-bold">BLAZING NEW TRAILS</h5>
            <h2 class="text-right">The Changemakers</h2>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col p-0">
            <div class="card border-0">
                <div class="card-body">
                    <div class="d-flex justify-content-start gap-3 mb-3">
                        <div class="circle-bg circle-color2">
                            <img src="/images/home/changemaker/organization-light.svg" alt="Organization Icon 2"
                                class="icon-img no-hover">
                            <img src="/images/home/changemaker/organization-dark.svg" alt="Organization Icon 1"
                                class="icon-img hover">
                        </div>
                    </div>
                    <h5 class="vertical-line fw-bold text-start line-org">Organizations</h5>
                    <p class="card-text text-start">Connect with the most enthusiastic students and experts to source
                        ideas and
                        solutions to the challenges which really matter to you</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card border-0">
                <div class="card-body">
                    <div class="d-flex justify-content-start gap-3 mb-3">
                        <div class="circle-bg circle-color3">
                            <img src="/images/home/changemaker/intern-light.svg" alt="Interns Icon 1"
                                class="icon-img no-hover">
                            <img src="/images/home/changemaker/intern-dark.svg" alt="Interns Icon 2"
                                class="icon-img hover">
                        </div>
                        <div class="circle-bg circle-color4 icon-hidden">
                        </div>
                    </div>
                    <h5 class="vertical-line fw-bold text-start line-int">Interns</h5>
                    <p class="card-text text-start">Complete a virtual internship which is unique in the world, and earn
                        microcredentials in strategic futures</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card border-0">
                <div class="card-body">
                    <div class="d-flex justify-content-start gap-3 mb-3">
                        <div class="circle-bg circle-color5">
                            <img src="/images/home/changemaker/innovator-light.svg" alt="Innovators Icon 1"
                                class="icon-img no-hover">
                            <img src="/images/home/changemaker/innovator-dark.svg" alt="Innovators Icon 2"
                                class="icon-img hover">
                        </div>
                    </div>
                    <h5 class="vertical-line fw-bold text-start line-inn">Innovators</h5>
                    <p class="card-text text-start">Engage as an individual expert, an established business or academic
                        institution to real world challenges</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card border-0">
                <div class="card-body">
                    <div class="d-flex justify-content-start gap-3 mb-3">
                        <div class="circle-bg circle-color7 icon-visible">
                            <img src="/images/home/changemaker/startup-light.svg" alt="Startups Icon 1"
                                class="icon-img no-hover">
                            <img src="/images/home/changemaker/startup-dark.svg" alt="Startups Icon 2"
                                class="icon-img hover">
                        </div>
                    </div>
                    <h5 class="vertical-line fw-bold text-start line-sta">Startups</h5>
                    <p class="card-text text-start">Expand your business opportunities by contributing to social impact
                        with
                        innovative solutions</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card border-0">
                <div class="card-body">
                    <div class="d-flex justify-content-start gap-3 mb-3">
                        <div class="circle-bg circle-color9">
                            <img src="/images/home/changemaker/mentor-light.svg" alt="Mentors Icon 1"
                                class="icon-img no-hover">
                            <img src="/images/home/changemaker/mentor-dark.svg" alt="Mentors Icon 2"
                                class="icon-img hover">
                        </div>
                    </div>
                    <h5 class="vertical-line fw-bold text-start line-men">Mentors</h5>
                    <p class="card-text text-start">Share your wisdom by guiding students and open innovation teams to
                        make
                        solutions to positive social impact real and practical</p>
                </div>
            </div>
        </div>
    </div>
    <hr class="mt-4 mb-5">
    <div class="row mt-5 text-center">
        <div class="col-md-12">
            <div class="d-flex justify-content-center position-relative">
                <div class="step mx-2 text-center">
                    <div class="rect-bg">
                        <img src="/images/home/steps/step-1.svg" alt="Step 1" class="icon-img">
                    </div>
                    <p class="fw-bold">Step 1</p>
                    <p>Define Your Challenge</p>
                </div>
                <div class="step mx-2 text-center">
                    <div class="rect-bg">
                        <img src="/images/home/steps/step-2.svg" alt="Step 2" class="icon-img">
                    </div>
                    <p class="fw-bold">Step 2</p>
                    <p>Set Your Goals and Criteria</p>
                </div>
                <div class="step mx-2 text-center">
                    <div class="rect-bg">
                        <img src="/images/home/steps/step-3.svg" alt="Step 3" class="icon-img">
                    </div>
                    <p class="fw-bold">Step 3</p>
                    <p>Choose Your Challenge Category</p>
                </div>
                <div class="step mx-2 text-center">
                    <div class="rect-bg">
                        <img src="/images/home/steps/step-4.svg" alt="Step 4" class="icon-img">
                    </div>
                    <p class="fw-bold">Step 4</p>
                    <p>Craft Your Challenge Statement</p>
                </div>
                <div class="step mx-2 text-center">
                    <div class="rect-bg">
                        <img src="/images/home/steps/step-5.svg" alt="Step 5" class="icon-img">
                    </div>
                    <p class="fw-bold">Step 5</p>
                    <p>Set a Timeline and Budget</p>
                </div>
                <div class="step mx-2 text-center">
                    <div class="rect-bg">
                        <img src="/images/home/steps/step-6.svg" alt="Step 6" class="icon-img">
                    </div>
                    <p class="fw-bold">Step 6</p>
                    <p>Launch and Promote Your Challenge</p>
                </div>
                <div class="step mx-2 text-center">
                    <div class="rect-bg">
                        <img src="/images/home/steps/step-7.svg" alt="Step 7" class="icon-img">
                    </div>
                    <p class="fw-bold">Step 7</p>
                    <p>Evaluate and Select Winners</p>
                </div>
            </div>
            <button
                class="btn btn-gradient btn-bg-blue-gradient border-0 fs-18px mt-4 rounded-start-0 fw-semibold custom-btn">SEE
                DETAILS
                <span class="arrow-btn-wrapper"> <img src="/images/home/right-arrow.svg" alt="Arrow" height="20"
                        class="arrow-icon1"></span>
            </button>
        </div>
    </div>
</div>


<div class="container-fluid mt-5">
    <div class="row mt-5 justify-content-center" style="margin-bottom: 90px;">
        <div class="col-md-12 p-0 text-start">
            <app-request-launch-challenge-form [formGroup]="requestLaunchChallengeForm" [showIntroText]="true"
                (formSubmit)="onSubmit()"></app-request-launch-challenge-form>
        </div>
    </div>
