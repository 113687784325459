<app-banner-section [imageSrc]="'/images/intern/intern-main.png'"
    [title]="'Make a Difference with Virtual Social Impact Internship'"
    [description]="'Gain valuable experience, enhance your skills, and contribute to creating positive social change with our virtual internships, and earn a micro-credential in Strategic Futures to kick-start your career.'"
    [buttonText]="'JUMP INTO THE CHALLENGES'" [buttonLink]="'#'" [lightColor]="'#FFDEC0'" [darkColor]="'#FFB878'"
    [buttonBgColor]="'linear-gradient(270deg, #FF7C09, #AC5000)'" [iconColor]="'#FF7C09'" [overlayText]="'Interns'"
    [overlayBgColor]="'rgba(0, 0, 0, 0.5)'" [overlayTextColor]="'#FFFFFF'"
    [arrowIconSrc]="'/images/home/right-arrow-orange.svg'">

</app-banner-section>

<app-how-it-works [title2]="'What is a Virtual Social Impact Internship?'"
    [description2]="'A virtual social impact internship is a remote internship experience that allows you to contribute to projects and organizations making a positive difference in the world, while gaining skills and building your portfolio.'"
    [steps]="stepsData" [stepNumberColor]="'#FF7C09'" [stepNumberBgColor]="'#FFEBDA'" [stepLineColor]="'#FF7C09'">
</app-how-it-works>

<app-benefits-section [title]="'Benefits of Social Impact Experience and Strategic Futures Skills'"
    [description]="'In contrast to academic case studies, our challenges are real-world problems that large organizations are actively seeking to solve. Your solutions will be evaluated by decision-makers, simulating a genuine work environment.'"
    [additionalLine]="'The benefits of social impact experience and Strategic Futures skills are:'" [points]="[
  'Enhanced career prospects in strategic planning and leadership roles',
  'Ability to think critically about the future and anticipate challenges',
  'Developed skills in Strategic Futures including problem exploration, horizon scanning, scenario planning, prioritization of opportunities and risks and actionable ideas for the near, medium and long term',
  'Verifiable proof of expertise in Strategic Futures',
  'Increased earning potential in social impact careers',
  'Developed skills in leadership, teamwork, and problem-solving'
]" [bulletColor]="'#FF7C09'" [lightStrapColor]="'#FFBD83'"
    [buttonBgColor]="'linear-gradient(270deg, #FF7C09, #AC5000)'" [buttonText]="'READY TO MAKE A DIFFERENCE?'"
    [buttonLink]="'#'" [imageSrc]="'/images/intern/intern-benefits.png'"
    [arrowIconSrc]="'/images/home/right-arrow-orange.svg'"></app-benefits-section>
