import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
    HttpClient,
    HttpHeaderResponse,
    HttpHeaders,
    HttpParams,
} from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { ChallengeRequestDto } from '../../Model/challenge-request.dto';
import { ProblemStatementDto } from '../../Model/problem-statement.dto';
import { PrizeRewardDto } from '../../Model/prize-reward.dto';
import { Category } from '../../Model/category';
import { challengeOwner } from '../../Model/challenge-owner';
import { ChallengeDetailsDto } from '../../Model/challenge-details';
import { SdgsDto } from '../../Model/sdgs';
import { AuthService } from './auth.service';
import { challengeBasicInformationDto } from '../../Model/challenges-basic-information.dto';
import { OrganizationTypes } from '../../Model/organization-type.dto';
import { Guidelines } from '../../Model/guidelines-form.dto';
import { evaluationCriteria } from '../../Model/evaluation-criteria.dto';
import { timelines } from '../../Model/timelines.dto';
import { QueryParams } from '../../Model/QueryParams';
import { ChallengeDetails } from '../../Model/challeng-details-response';
import { ChallengeResources } from '../../Model/challenge-resource.dto';
import { Faqs } from '../../Model/faqs.dto';

@Injectable({
    providedIn: 'root',
})
export class ChallengeService {
    constructor(
        private http: HttpClient,
        private authService: AuthService // Inject AuthService
    ) {}

    postInterest(data: ChallengeRequestDto): Observable<any> {
        return this.http.post<any>(
            `${environment.apiUrl}/challenge/post-interest`,
            data
        );
    }
    // Step 1
    postChallengeBasicInformation(
        data: challengeBasicInformationDto
    ): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.post<any>(
                    `${environment.apiUrl}/challenge/launch-challenge/basic-information`,
                    data,
                    { headers }
                );
            })
        );
    }

    getChallengeBasicInformation(id: string): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<any>(
                    `${environment.apiUrl}/challenge/get-basic-information/${id}`,
                    { headers }
                );
            })
        );
        // return this.http.get<any>(
        //     `${environment.apiUrl}/challenge/get-basic-information/${id}`
        // );
    }

    proccedToPayment(data: any): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // To get token
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
                switchMap((token) => {
                    const headers = new HttpHeaders().set(
                        'Authorization',
                        `Bearer ${token}`
                    );
                    return this.http.post<any>(
                        `${environment.apiUrl}/stripe/create-checkout-session`,
                        data,
                        { headers }
                    );
                })
        )
   
    }

    checkChallengePaymentStatus(challengeId: any): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // To get token
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
                switchMap((token) => {
                    const headers = new HttpHeaders().set(
                        'Authorization',
                        `Bearer ${token}`
                    );
                    return this.http.get<any>(
                        `${environment.apiUrl}/stripe/challenge-payment-status/${challengeId}`,
                        
                        { headers }
                    );
                })
        )
    }

    agreeLegalTerms(challengeId:any,): Observable<any> { 
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // To get token
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
                switchMap((token) => {
                    const headers = new HttpHeaders().set(
                        'Authorization',
                        `Bearer ${token}`
                    );
                    return this.http.post<any>(
                        `${environment.apiUrl}/challenge/agree-challenge-legal/${challengeId}`,
                      {},
                        { headers }
                    );
                })
        )
     } 

     fetchExistingSolution(challengeId:any):Observable<any>{
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // To get token
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
                switchMap((token) => {
                    const headers = new HttpHeaders().set(
                        'Authorization',
                        `Bearer ${token}`
                    );
                    return this.http.get<any>(
                        `${environment.apiUrl}/solution/last-challenge-solution/${challengeId}`,
                      
                        { headers }
                    );
                })
        )
     }

     fetchLegalTermsStatus(challengeId:any): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // To get token
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
                switchMap((token) => {
                    const headers = new HttpHeaders().set(
                        'Authorization',
                        `Bearer ${token}`
                    );
                    return this.http.get<any>(
                        `${environment.apiUrl}/challenge/agreed-challenge-legal/${challengeId}`,
                        
                        { headers }
                    );
                })
        )
     }


    getSessionStatus(sessionId: string): Observable<any> {
        let httpParams = new HttpParams().set('session_id', sessionId.toString());
        return this.http.get<any>(
            `${environment.apiUrl}/stripe/session-status`,
            {
                params: httpParams,
            }


        );
    }

    updateChallengeBasicInformation(
        id: string,
        data: challengeBasicInformationDto
    ): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<any>(
                    `${environment.apiUrl}/challenge/update-basic-information/${id}`,
                    data,
                    { headers }
                );
            })
        );
        // return this.http.patch<any>(
        //     `${environment.apiUrl}/challenge/update-basic-information/${id}`,
        //     data
        // );
    }

    // Step 2
    updateProblemStatement(
        challengeId: string,
        data: ProblemStatementDto
    ): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<any>(
                    `${environment.apiUrl}/challenge/update-problem-statement/${challengeId}`,
                    data,
                    { headers }
                );
            })
        );
        // return this.http.patch<any>(
        //     `${environment.apiUrl}/challenge/update-problem-statement/${challengeId}`,
        //     data
        // );
    }

    getProblemStatement(id: string): Observable<ProblemStatementDto> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<any>(
                    `${environment.apiUrl}/challenge/get-problem-statement/${id}`,
                    { headers }
                );
            })
        );
        // return this.http.get<any>(
        //     `${environment.apiUrl}/challenge/get-problem-statement/${id}`
        // );
    }

    listAllCategories(): Observable<Category[]> {
        return this.http.get<Category[]>(
            `${environment.apiUrl}/challenge/list-all-categories`
        );
    }

    listAllOraganizationsType(): Observable<OrganizationTypes[]> {
        return this.http.get<OrganizationTypes[]>(
            `${environment.apiUrl}/challenge/get-challenge-organization-type`
        );
    }

    listALLOrganizations(): Observable<challengeOwner[]> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // To get token
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<challengeOwner[]>(
                    `${environment.apiUrl}/users/list-all-organizations`,
                    { headers }
                );
            })
        );
    }

    listAllCategoryOptions(): Observable<challengeOwner[]> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // To get token
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<challengeOwner[]>(
                    `${environment.apiUrl}/challenge/list-all-categories`,
                    { headers }
                );
            })
        );
    }
    listAllCategoryOptionsPublic(): Observable<challengeOwner[]> {
        return this.http.get<challengeOwner[]>(
            `${environment.apiUrl}/challenge/list-all-categories`
        );
    }

    listAllSDGs(): Observable<Category[]> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // To get token
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<Category[]>(
                    `${environment.apiUrl}/challenge/sdgs`,
                    { headers }
                );
            })
        );
    }

    listAllSDGsPublic(): Observable<Category[]> {
        return this.http.get<Category[]>(
            `${environment.apiUrl}/challenge/sdgs`
        );
    }

    listAllOrgTypes(): Observable<Category[]> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // To get token
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<Category[]>(
                    `${environment.apiUrl}/challenge/get-challenge-organization-type`,
                    { headers }
                );
            })
        );
    }
    listAllOrgTypesPublic(): Observable<Category[]> {
        return this.http.get<Category[]>(
            `${environment.apiUrl}/challenge/get-challenge-organization-type`
        );
    }

    // Step 3
    getChallengeDetails(challengeId: string): Observable<ChallengeDetailsDto> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<ChallengeDetailsDto>(
                    `${environment.apiUrl}/challenge/launch-challenge/challenge-details/${challengeId}`,
                    { headers }
                );
            })
        );
        // return this.http.get<ChallengeDetailsDto>(
        //     `${environment.apiUrl}/challenge/launch-challenge/challenge-details/${challengeId}`
        // );
    }
    getChallengeDetail(challengeId: string): Observable<ChallengeDetails> {
        return this.http.get<ChallengeDetails>(
            `${environment.apiUrl}/challenge/get-challenge-details/${challengeId}`
        );
    }

    deleteChallenge(challengeId: string): Observable<any> {
        return this.http.delete<any>(
            `${environment.apiUrl}/challenge/${challengeId}`
        );
    }
    deletePostInterest(postId: string): Observable<any> {
        return this.http.delete<any>(
            `${environment.apiUrl}/challenge/post-interest/${postId}`
        );
    }

    downloadResourceFile(file: {
        folderPath: string;
        fileName: string;
        // fileType: string
    }): Observable<any> {
        return this.http.post<any>(
            `${environment.apiUrl}/s3/generate-signed-url-for-get-object`,
            file
        );
    }

    //   getMyChallenges(params: QueryParams): Observable<any> {
    //     let httpParams = new HttpParams()
    //     .set('pageLimit', params.pageLimit.toString())
    //     .set('pageIndex', params.pageIndex.toString())
    //     .set('searchKeyword', params.searchKeyword)
    //     .set('sortKey', params.sortKey)
    //     .set('sortDirection', params.sortDirection);

    // const headers = new HttpHeaders().set(
    //     'Authorization',
    //     `Bearer YOUR_TOKEN_HERE`
    // );
    //     return this.http.get<any>(
    //         `${environment.apiUrl}/challenge/my-challenges`,{
    //             headers,
    //             params: httpParams,
    //         }
    //     );
    // }
    // Step 3
    updateChallengeDetails(
        challengeId: string,
        data: ChallengeDetailsDto
    ): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<any>(
                    `${environment.apiUrl}/challenge/launch-challenge/challenge-details/${challengeId}`,
                    data,
                    { headers }
                );
            })
        );
        // return this.http.patch<any>(
        //     `${environment.apiUrl}/challenge/launch-challenge/challenge-details/${challengeId}`,
        //     data
        // );
    }
    // Step 5
    getPrizeRewards(challengeId: string): Observable<PrizeRewardDto> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<PrizeRewardDto>(
                    `${environment.apiUrl}/challenge/launch-challenge/prize-rewards/${challengeId}`,
                    { headers }
                );
            })
        );
        // return this.http.get<PrizeRewardDto>(
        //     `${environment.apiUrl}/challenge/launch-challenge/prize-rewards/${challengeId}`
        // );
    }

    updatePrizeRewards(
        challengeId: string,
        data: PrizeRewardDto
    ): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<any>(
                    `${environment.apiUrl}/challenge/launch-challenge/prize-rewards/${challengeId}`,
                    data,
                    { headers }
                );
            })
        );
        // return this.http.patch<any>(
        //     `${environment.apiUrl}/challenge/launch-challenge/prize-rewards/${challengeId}`,
        //     data
        // );
    }

    // Step 4
    getSdgs(challengeId: string): Observable<SdgsDto[]> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<SdgsDto[]>(
                    `${environment.apiUrl}/challenge/launch-challenge/sdgs/${challengeId}`,
                    { headers }
                );
            })
        );
        // return this.http.get<SdgsDto[]>(
        //     `${environment.apiUrl}/challenge/launch-challenge/sdgs/${challengeId}`
        // );
    }

    updateSdgs(
        challengeId: string,
        data: { sdgs: SdgsDto[] }
    ): Observable<SdgsDto[]> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<SdgsDto[]>(
                    `${environment.apiUrl}/challenge/launch-challenge/sdgs/${challengeId}`,
                    data,
                    { headers }
                );
            })
        );
        // return this.http.patch<SdgsDto[]>(
        //     `${environment.apiUrl}/challenge/launch-challenge/sdgs/${challengeId}`,
        //     data
        // );
    }

    getChallenges(params: QueryParams): Observable<any> {
        let httpParams = new HttpParams()
            .set('pageLimit', params.pageLimit.toString())
            .set('pageIndex', params.pageIndex.toString())
            .set('searchKeyword', params.searchKeyword)
            .set('sortKey', params.sortKey)
            .set('sortDirection', params.sortDirection);

        params?.challengeStages?.forEach((stage, index) => {
            httpParams = httpParams.append(`challengeStages[${index}]`, stage);
        });

        params?.sdgs?.forEach((sdg, index) => {
            httpParams = httpParams.append(`sdgs[${index}]`, sdg);
        });

        params?.categories?.forEach((category, index) => {
            httpParams = httpParams.append(`categories[${index}]`, category);
        });

        params?.orgTypes?.forEach((orgType, index) => {
            httpParams = httpParams.append(`orgTypes[${index}]`, orgType);
        });

        params?.challengeTypes?.forEach((type, index) => {
            httpParams = httpParams.append(`challengeTypes[${index}]`, type);
        });

        params?.incentives?.forEach((incentive, index) => {
            httpParams = httpParams.append(`incentives[${index}]`, incentive);
        });

        const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer YOUR_TOKEN_HERE`
        );

        return this.http.get<any>(`${environment.apiUrl}/challenge`, {
            headers,
            params: httpParams,
        });
    }

    getMyChallenges(params: QueryParams): Observable<any> {
        let httpParams = new HttpParams()
            .set('pageLimit', params.pageLimit.toString())
            .set('pageIndex', params.pageIndex.toString())
            .set('searchKeyword', params.searchKeyword)
            .set('sortKey', params.sortKey)
            .set('sortDirection', params.sortDirection);

        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // To get token
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<any>(
                    `${environment.apiUrl}/challenge/my-challenges`,
                    {
                        headers,
                        params: httpParams,
                    }
                );
            })
        );
    }

    // step 6
    getGuideline(challengeId: string): Observable<Guidelines> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<Guidelines>(
                    `${environment.apiUrl}/challenge/launch-challenge/guideline/${challengeId}`,
                    { headers }
                );
            })
        );
        // return this.http.get<Guidelines>(
        //     `${environment.apiUrl}/challenge/launch-challenge/guideline/${challengeId}`
        // );
    }

    updateGuideline(
        challengeId: string,
        data: Guidelines
    ): Observable<Guidelines> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<Guidelines>(
                    `${environment.apiUrl}/challenge/launch-challenge/guideline/${challengeId}`,
                    data,
                    { headers }
                );
            })
        );
        // return this.http.patch<Guidelines>(
        //     `${environment.apiUrl}/challenge/launch-challenge/guideline/${challengeId}`,
        //     data
        // );
    }

    // Step 7
    getEvaluationCriteria(challengeId: string): Observable<evaluationCriteria> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<evaluationCriteria>(
                    `${environment.apiUrl}/challenge/launch-challenge/evaluation-criteria/${challengeId}`,
                    { headers }
                );
            })
        );
        // return this.http.get<evaluationCriteria>(
        //     `${environment.apiUrl}/challenge/launch-challenge/evaluation-criteria/${challengeId}`
        // );
    }

    updateEvaluationCriteria(
        challengeId: string,
        data: evaluationCriteria
    ): Observable<evaluationCriteria> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<evaluationCriteria>(
                    `${environment.apiUrl}/challenge/launch-challenge/evaluation-criteria/${challengeId}`,
                    data,
                    { headers }
                );
            })
        );
        // return this.http.patch<evaluationCriteria>(
        //     `${environment.apiUrl}/challenge/launch-challenge/evaluation-criteria/${challengeId}`,
        //     data
        // );
    }

    // Step 8
    getTimelines(challengeId: string): Observable<timelines> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<timelines>(
                    `${environment.apiUrl}/challenge/launch-challenge/timeline/${challengeId}`,
                    { headers }
                );
            })
        );
        // return this.http.get<timelines>(
        //     `${environment.apiUrl}/challenge/launch-challenge/timeline/${challengeId}`
        // );
    }

    updateTimelines(
        challengeId: string,
        data: timelines
    ): Observable<timelines> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<timelines>(
                    `${environment.apiUrl}/challenge/launch-challenge/timeline/${challengeId}`,
                    data,
                    { headers }
                );
            })
        );
        // return this.http.patch<timelines>(
        //     `${environment.apiUrl}/challenge/launch-challenge/timeline/${challengeId}`,
        //     data
        // );
    }

    // Step 9
    updateResources(
        challengeId: string,
        data: ChallengeResources
    ): Observable<ChallengeResources> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<ChallengeResources>(
                    `${environment.apiUrl}/challenge/launch-challenge/resources/${challengeId}`,
                    data,
                    { headers }
                );
            })
        );
        // return this.http.patch<ChallengeResources>(
        //     `${environment.apiUrl}/challenge/launch-challenge/resources/${challengeId}`,
        //     data
        // );
    }

    getResources(challengeId: string): Observable<ChallengeResources> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<ChallengeResources>(
                    `${environment.apiUrl}/challenge/launch-challenge/resources/${challengeId}`,
                    { headers }
                );
            })
        );
        // return this.http.get<ChallengeResources>(
        //     `${environment.apiUrl}/challenge/launch-challenge/resources/${challengeId}`
        // );
    }

    // Step 10
    // challengeService method
    updateFaqs(challengeId: string, faq: Faqs): Observable<Faqs> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch<Faqs>(
                    `${environment.apiUrl}/challenge/launch-challenge/faqs/${challengeId}`,
                    faq,
                    { headers }
                );
            })
        );
        // return this.http.patch<Faqs>(
        //     `${environment.apiUrl}/challenge/launch-challenge/faqs/${challengeId}`,
        //     faq
        // );
    }

    getFaqs(challengeId: string): Observable<Faqs[]> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<Faqs[]>(
                    `${environment.apiUrl}/challenge/launch-challenge/faqs/${challengeId}`,
                    { headers }
                );
            })
        );
        // return this.http.get<Faqs[]>(
        //     `${environment.apiUrl}/challenge/launch-challenge/faqs/${challengeId}`
        // );
    }

    deleteFaqs(challengeId: string, FaqsId: number): Observable<Faqs[]> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // Get the token if authenticated
                } else {
                    throw new Error('User is not authenticated'); // Handle the error if not authenticated
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.delete<Faqs[]>(
                    `${environment.apiUrl}/challenge/launch-challenge/faqs/${challengeId}/${FaqsId}`,
                    { headers }
                );
            })
        );
        // return this.http.delete<Faqs[]>(
        //     `${environment.apiUrl}/challenge/launch-challenge/faqs/${challengeId}/${FaqsId}`
        // );
    }

    // Review Api call
    postReview(challengeId: string): Observable<any> {
        // Make the POST request with no body
        return this.http.post<any>(
            `${environment.apiUrl}/challenge/launch-challenge/review/${challengeId}`,
            null
        );
    }

    publishLaunchChallenge(challengeId: string): Observable<any> {
        return this.http.post<any>(
            `${environment.apiUrl}/challenge/launch-challenge/publish/${challengeId}`,
            {}
        );
    }

    postQuestion(challengeId: string, data: any): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // To get token
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.post<any>(
                    `${environment.apiUrl}/challenge/ask-qna/${challengeId}`,
                    data,
                    {
                        headers: headers,
                    }
                );
            })
        );
    }

    getInterestsList(params: QueryParams): Observable<any> {
        let httpParams = new HttpParams()
            .set('pageLimit', params.pageLimit.toString())
            .set('pageIndex', params.pageIndex.toString())
            .set('searchKeyword', params.searchKeyword)
            .set('sortKey', params.sortKey)
            .set('sortDirection', params.sortDirection);
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // To get token
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<any>(
                    `${environment.apiUrl}/challenge/post-interest`,
                    {
                        headers,
                        params: httpParams,
                    }
                );
            })
        );
    }

    getPostInterestById(postId: string): Observable<any> {
        return this.authService.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.authService.getToken(); // To get token
                } else {
                    throw new Error('User is not authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.get<any>(
                    `${environment.apiUrl}/challenge/post-interest/${postId}`,
                    {
                        headers,
                    }
                );
            })
        );
    }
}
