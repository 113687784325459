import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom, map, Observable, switchMap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { select, Store } from '@ngrx/store';
import { JwtService } from '../jwt.service';
import { AuthState } from '../../store/auth/auth.state';
import { EditUserProfileDto } from '../../Model/edit-profile.dto';
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private userPermissions: string[] = [];
    private authUrl = `${environment.apiUrl}/auth/google`;
    private linkedinAuthUrl = `${environment.apiUrl}/auth/linkedin`;

    constructor(
        private http: HttpClient,
        private store: Store<{ auth: AuthState }>,
        private jwtService: JwtService
    ) {}

    login(credentials: { email: string; password: string }): Observable<any> {
        return this.http.post(`${environment.apiUrl}/auth/login`, credentials);
    }

    loginGoogle(): void {
        window.location.href = this.authUrl;
    }

    loginLinkedin(): void {
        window.location.href = this.linkedinAuthUrl;
    }

    handleRedirectGoogle(code: string, scope: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/auth/google/redirect`, {
            params: { code, scope },
        });
    }

    handleRedirectLinkedin(code: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/auth/linkedin/redirect`, {
            params: { code },
        });
    }

    signup(userDetails: {
        role: string;
        firstName: string;
        lastName: string;
        email: string;
        password: string;
    }): Observable<any> {
        return this.http.post(
            `${environment.apiUrl}/auth/register`,
            userDetails
        );
    }

    confirmEmail(data: { token: string }): Observable<any> {
        return this.http.patch(
            `${environment.apiUrl}/auth/confirm-email`,
            data
        );
    }

    isAuthenticated(): Observable<boolean> {
        return this.store.pipe(
            select('auth'),
            map((authState: AuthState) => {
                const token = authState.token ?? '';
                return !this.jwtService.isTokenExpired(token);
            })
        );
    }

    getUser(): Observable<any> {
        return this.store.pipe(
            select('auth'),
            map((authState: AuthState) => authState.user)
        );
    }

    getToken(): Observable<any> {
        return this.store.pipe(
            select('auth'),
            map((authState: AuthState) => authState.token)
        );
    }

    hasPermission(permission: string): boolean {
        return this.userPermissions.includes(permission);
    }

    forgotPassword(email: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/auth/forget-password`, {
            email,
        });
    }

    resetPassword(token: string, password: string): Observable<any> {
        return this.http.patch(
            `${environment.apiUrl}/auth/setup-new-password`,
            { token, password }
        );
    }

    updateRoleSSO(userDetails: EditUserProfileDto): Observable<any> {
        return this.isAuthenticated().pipe(
            switchMap((isAuth) => {
                if (isAuth) {
                    return this.getToken();
                } else {
                    throw new Error('User is not Authenticated');
                }
            }),
            switchMap((token) => {
                const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                );
                return this.http.patch(
                    `${environment.apiUrl}/users/update-user-after-sso`,
                    userDetails,
                    { headers }
                );
            })
        );
        // return this.http.patch(`${environment.apiUrl}/users/update-user-after-sso`, {role, userDetails});
    }

    async getHeaders(): Promise<HttpHeaders | undefined> {
        const isAuthenticated = await firstValueFrom(this.isAuthenticated());
        if (isAuthenticated) {
            const token = await firstValueFrom(this.getToken());
            return new HttpHeaders().set('Authorization', `Bearer ${token}`);
        } else {
            throw new Error('User is not Authenticated');
        }
    }
}
