import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { initialState } from './auth.state';

export const authReducer = createReducer(
    initialState,
    on(AuthActions.loginSuccess, (state, { token, user }) => ({
        ...state,
        token,
        user,
        error: null,
    })),
    on(AuthActions.loginSSOSuccessWithoutRole, (state, { token, user }) => ({
        ...state,
        token,
        user,
        error: null,
    })),
    // Handle the action to update user roles
    on(AuthActions.updateUserRoles, (state, { roles, organization  }) => ({
        ...state,
        user: {
            ...state.user,
            roles: [...state.user.roles, ...roles],// Append the new roles
            organization: organization !== undefined ? organization : state.user.organization
             
        },
    })),
    on(AuthActions.loginFailure, (state, { error }) => ({
        ...state,
        error,
    })),
    on(AuthActions.forgotPasswordSuccess, (state, { message }) => ({
        ...state,
        forgotPasswordMessage: message,
        error: null,
    })),
    on(AuthActions.forgotPasswordFailure, (state, { error }) => ({
        ...state,
        error,
    })),
    on(AuthActions.logout, () => ({
        ...initialState, // Reset to initial state on logout
    })),
    on(AuthActions.updateUser, (state, { user }) => ({ ...state, user }))
);
