<div class="container mt-5">
    <div class="row">
        <div class="col-md-6">
            <a  >
                Back to Evaluation list</a>
        </div>
      
        <hr>
    </div>

    <section>


        <div class="filters-wrapper">
            <div class="search-wrapper" style="max-width: 164px; max-height: 40px">
                <input type="text" class="form-control search-color" placeholder="Search" [(ngModel)]="params.searchKeyword"
                    (ngModelChange)="handleSearchChange($event)" />
                <img src="/images/common/search-black.svg" alt="Search" height="20" />
            </div>
            <div class=" list-sort-btn" style="margin-left: 20px;">
                <div class="custom-multi-select">
                    <label for="sort" class="form-label fw-semibold">Sort By</label>
                    <ng-select [items]="sortList" bindValue="id" bindLabel="title" [closeOnSelect]="true"
                        [ngModel]="params.sortDirection" [multiple]="false" (ngModelChange)="onSortChange($event)"
                        [searchable]=" false">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            {{ item?.title }}
                        </ng-template>
                    </ng-select>
                </div>
            </div>
        </div>
        <button class="top-nav" (click)="scrollToTop()" *ngIf="showScrollToTopButton">
            <img src="/images/common/top.svg" alt="Scroll to Top" />
        </button>
        <div class="listing-wrapper" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
            (scrolled)="onScroll()">
            <table>
                <tbody>
                    @if (challenges.length>0) {
                    @for (challenge of challenges; track challenge.id) {
                    <tr class="list-item">
                        <td class="title">{{challenge.title}}</td>
                        <td class="light-text">Last updated: {{challenge.updatedAt|date:'mediumDate'}}</td>
                        <td class="light-text org-name">{{challenge.organizedBy.title}}</td>
                        <td class="status">{{showStatus(challenge.startDate,challenge.endDate,challenge.status)}}</td>
                        <td class="action">
                          
                            <img src="/images/common/open-eye.svg"
                                (click)="redirectSpecificChallengeSolutionList(challenge.id)" alt="View" height="20"
                                style="margin-left: 15px;" />
                        </td>
                    </tr>}
                    } @else {
                    <div>
                        <img class="no-records" src="/images/common/no-records.svg" alt="No Data" height="100" />
                        <p class="no-records-p">No records found</p>
                    </div>
                    }
    
                    
                </tbody>
            </table>
        </div>
    </section>

    </div>
   
          
