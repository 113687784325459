<!-- interns listing -->
<section>
    <div class="container">

        <div class="filters-wrapper">
            <div class="search-wrapper" style="max-width: 164px; max-height: 40px">
                <input type="text" class="form-control search-color" placeholder="Search"
                    [(ngModel)]="params.searchKeyword" (ngModelChange)="handleSearchChange($event)" />
                <img src="/images/common/search-black.svg" alt="Search" height="20" />
            </div>
            <div class=" list-sort-btn" style="margin-left: 20px;">
                <div class="custom-multi-select">
                    <label for="sort" class="form-label fw-semibold">Sort By</label>
                    <ng-select [items]="sortList" bindValue="id" bindLabel="title" [closeOnSelect]="true"
                        [ngModel]="params.sortDirection" [multiple]="false" (ngModelChange)="onSortChange($event)"
                        [searchable]=" false">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            {{ item?.title }}
                        </ng-template>
                    </ng-select>
                </div>
            </div>
        </div>
        <button class="top-nav" (click)="scrollToTop()" *ngIf="showScrollToTopButton">
            <img src="/images/common/top.svg" alt="Scroll to Top" />
        </button>
        <div class="listing-wrapper" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
            (scrolled)="onScroll()" >
            <table>
                <tbody>
                    @if (interests.length>0) {
                        @for (interest of interests; track interest.id) {
                            <tr class="list-item">
                                <td class="title">{{interest.organizationName}}</td>
                                <td class="light-text">{{interest.email}} </td>
                                <td class="light-text org-name">{{interest.subject}}</td>
                                <td class="action">
                                    <img src="/images/common/open-eye.svg" (click)="redirectToViewInterest(interest.id)"
                                        />
                                        <img alt="delete" src="/images/common/delete.svg" 
                
                                            (click)="deletePostInterest(interest.id)" style="margin-left: 15px;" height="25px" />
                
                                </td>
                            </tr>}
                    } @else {
                        <div >
                        <img class="no-records" src="/images/common/no-records.svg" alt="No Data" height="100" />
                        <p class="no-records-p">No records found</p>
                    </div>
                    }
                  
                 
                </tbody>
            </table>
        </div>
    </div>
</section>
