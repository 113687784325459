import { createReducer, on } from '@ngrx/store';
import {
    LaunchChallengeState,
    initialLaunchChallengeState,
} from './launchChallenge.state';
import * as LaunchChallengeActions from './launchChallenge.actions';

export const launchChallengeReducer = createReducer(
    initialLaunchChallengeState,
    on(LaunchChallengeActions.postChallengeBasicInformation, (state) => ({
        ...state,
        error: null,
    })),
    on(LaunchChallengeActions.storeChallengeId, (state, { challengeId }) => ({
        ...state,
        challengeId,
    })),
    on(
        LaunchChallengeActions.postChallengeBasicInformationSuccess,
        (state) => ({
            ...state,
            error: null,
        })
    ),
    on(
        LaunchChallengeActions.postChallengeBasicInformationFailure,
        (state, { error }) => {
            return {
                ...state,
                error: error,
            };
        }
    ),
    on(
        LaunchChallengeActions.updateChallengeBasicInformationSuccess,
        (state) => ({
            ...state,
            error: null,
        })
    ),
    on(
        LaunchChallengeActions.updateChallengeBasicInformationFailure,
        (state, { error }) => ({
            ...state,
            error: error,
        })
    ),
    on(LaunchChallengeActions.loadChallengeBasicInformation, (state) => ({
        ...state,
        loading: true,
    })),
    on(
        LaunchChallengeActions.loadChallengeBasicInformationSuccess,
        (state, { data }) => ({
            ...state,
            basicInformation: data,
            loading: false,
            error: null,
        })
    ),
    on(
        LaunchChallengeActions.loadChallengeBasicInformationFailure,
        (state, { error }) => ({
            ...state,
            error: { ...error },
            loading: false,
        })
    ),
    // Handle loading problem statement
    on(LaunchChallengeActions.loadProblemStatement, (state) => ({
        ...state,
        loading: true,
    })),
    on(
        LaunchChallengeActions.loadProblemStatementSuccess,
        (state, { problemStatement }) => ({
            ...state,
            problemStatement,
            loading: false,
            error: null,
        })
    ),
    on(
        LaunchChallengeActions.loadProblemStatementFailure,
        (state, { error }) => ({
            ...state,
            error: { ...error },
            loading: false,
        })
    ),

    // Handle updating problem statement
    on(LaunchChallengeActions.updateProblemStatement, (state) => ({
        ...state,
        loading: true,
    })),
    on(
        LaunchChallengeActions.updateProblemStatementSuccess,
        (state, { message }) => ({
            ...state,
            error: null,
            loading: false,
        })
    ),
    on(
        LaunchChallengeActions.updateProblemStatementFailure,
        (state, { error }) => ({
            ...state,
            error: error,
            loading: false,
        })
    ),
    // Handle loading challenge details
    on(LaunchChallengeActions.loadChallengeDetails, (state) => ({
        ...state,
        loading: true,
    })),
    on(
        LaunchChallengeActions.loadChallengeDetailsSuccess,
        (state, { challengeDetails }) => ({
            ...state,
            challengeDetails,
            loading: false,
            error: null,
        })
    ),
    on(
        LaunchChallengeActions.loadChallengeDetailsFailure,
        (state, { error }) => ({
            ...state,
            error: { ...error },
            loading: false,
        })
    ),
    // Handle updating challenge details
    on(LaunchChallengeActions.updateChallengeDetails, (state) => ({
        ...state,
        loading: true,
    })),
    on(
        LaunchChallengeActions.updateChallengeDetailsSuccess,
        (state, { message }) => ({
            ...state,
            error: null,
            loading: false,
        })
    ),
    on(
        LaunchChallengeActions.updateChallengeDetailsFailure,
        (state, { error }) => ({
            ...state,
            error: error,
            loading: false,
        })
    ),
    // Step 4 SDGs
    on(LaunchChallengeActions.loadSdgs, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(LaunchChallengeActions.loadSdgsSuccess, (state, { sdgs }) => ({
        ...state,
        loading: false,
        sdgs,
    })),
    on(LaunchChallengeActions.loadSdgsFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: { ...error },
    })),
    // Update SDGs
    on(LaunchChallengeActions.updateSdgs, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(LaunchChallengeActions.updateSdgsSuccess, (state, { sdgs }) => ({
        ...state,
        loading: false,
        sdgsSubmitted: true, // Mark SDGs as submitted
    })),
    on(LaunchChallengeActions.updateSdgsFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: error,
    })),
    // Step - 5 Load Prize Rewards
    on(LaunchChallengeActions.loadPrizeRewards, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(
        LaunchChallengeActions.loadPrizeRewardsSuccess,
        (state, { prizeRewards }) => ({
            ...state,
            loading: false,
            prizeRewards,
        })
    ),
    on(LaunchChallengeActions.loadPrizeRewardsFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: { ...error },
    })),

    // Update Prize Rewards
    on(LaunchChallengeActions.updatePrizeRewards, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(
        LaunchChallengeActions.updatePrizeRewardsSuccess,
        (state, { message }) => ({
            ...state,
            loading: false,
            prizeRewardsSubmitted: true,
        })
    ),
    on(
        LaunchChallengeActions.updatePrizeRewardsFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error: error,
        })
    ),
    // Step 6 - Load Guidelines
    on(LaunchChallengeActions.loadGuidelines, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(
        LaunchChallengeActions.loadGuidelinesSuccess,
        (state, { guidelines }) => ({
            ...state,
            loading: false,
            guidelines,
        })
    ),
    on(LaunchChallengeActions.loadGuidelinesFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: { ...error },
    })),

    // Update Guidelines
    on(LaunchChallengeActions.updateGuidelines, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(
        LaunchChallengeActions.updateGuidelinesSuccess,
        (state, { message }) => ({
            ...state,
            loading: false,
            guidelinesSubmitted: true,
        })
    ),
    on(LaunchChallengeActions.updateGuidelinesFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: error,
    })),
    // Step 7 - Load Evaluation Criteria
    on(LaunchChallengeActions.loadEvaluationCriteria, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(
        LaunchChallengeActions.loadEvaluationCriteriaSuccess,
        (state, { evaluationCriteria }) => ({
            ...state,
            loading: false,
            evaluationCriteria,
        })
    ),
    on(
        LaunchChallengeActions.loadEvaluationCriteriaFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error: { ...error },
        })
    ),

    // Update Evaluation Criteria
    on(LaunchChallengeActions.updateEvaluationCriteria, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(
        LaunchChallengeActions.updateEvaluationCriteriaSuccess,
        (state, { message }) => ({
            ...state,
            loading: false,
            evaluationCriteriaSubmitted: true,
        })
    ),
    on(
        LaunchChallengeActions.updateEvaluationCriteriaFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error: error,
        })
    ),
    // Step 8
    // Load Timelines
    on(LaunchChallengeActions.loadTimelines, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(LaunchChallengeActions.loadTimelinesSuccess, (state, { timelines }) => ({
        ...state,
        loading: false,
        timelines,
    })),
    on(LaunchChallengeActions.loadTimelinesFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: { ...error },
    })),

    // Update Timelines
    on(LaunchChallengeActions.updateTimelines, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(
        LaunchChallengeActions.updateTimelinesSuccess,
        (state, { message }) => ({
            ...state,
            loading: false,
            timelinesSubmitted: true,
        })
    ),
    on(LaunchChallengeActions.updateTimelinesFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: error,
    })),
    // Step 9
    // Load Resources
    on(LaunchChallengeActions.loadResources, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(LaunchChallengeActions.loadResourcesSuccess, (state, { resources }) => ({
        ...state,
        loading: false,
        resources,
    })),
    on(LaunchChallengeActions.loadResourcesFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: { ...error },
    })),

    // Update Resources
    on(LaunchChallengeActions.updateResources, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(
        LaunchChallengeActions.updateResourcesSuccess,
        (state, { message }) => ({
            ...state,
            loading: false,
            resourcesSubmitted: true,
        })
    ),
    on(LaunchChallengeActions.updateResourcesFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: error,
    })),
    // Step 10
    // Load FAQs
    on(LaunchChallengeActions.loadFaqs, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(LaunchChallengeActions.loadFaqsSuccess, (state, { faqs }) => ({
        ...state,
        loading: false,
        faqs,
    })),
    on(LaunchChallengeActions.loadFaqsFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: { ...error },
    })),

    // Update FAQs
    on(LaunchChallengeActions.updateFaqs, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(LaunchChallengeActions.updateFaqsSuccess, (state, { faqs }) => ({
        ...state,
        loading: false,
        faqs,
        faqsSubmitted: true,
    })),
    on(LaunchChallengeActions.updateFaqsFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: error,
    })),

    // Delete FAQ
    on(LaunchChallengeActions.deleteFaq, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(LaunchChallengeActions.deleteFaqSuccess, (state, { faqsId }) => ({
        ...state,
        loading: false,
        faqs: state.faqs.filter((faq) => faq.id !== Number(faqsId)),
    })),
    on(LaunchChallengeActions.deleteFaqFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: { ...error },
    }))
);
