import { Component } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { InputErrorMessageComponent } from '../../shared/input-error-message/input-error-message.component';
import { RequestLaunchChallengeFormComponent } from '../../shared/request-launch-challenge-form/request-launch-challenge-form.component';
import { ChallengeService } from '../../core/services/apis/challenge.service';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { ChallengeRequestDto } from '../../core/Model/challenge-request.dto';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-request-launch-challenge',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        InputErrorMessageComponent,
        RequestLaunchChallengeFormComponent,
    ],
    templateUrl: './request-launch-challenge.component.html',
    styleUrl: './request-launch-challenge.component.scss',
})
export class RequestLaunchChallengeComponent {
    requestLaunchChallengeForm!: FormGroup;

    constructor(
        private fb: FormBuilder,
        private challengeService: ChallengeService,
        private validationMessageService: ValidationMessageService,
        private alertService: SweetAlertService,
        private route: Router
    ) {}

    ngOnInit(): void {
        this.requestLaunchChallengeForm = this.fb.group({
            firstName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            lastName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            email: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.email,
                    Validators.maxLength(50),
                ]),
            ],
            designation: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            organizationName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            organizationUrl: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            subject: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(255),
                ]),
            ],
            message: ['', Validators.compose([Validators.required])],
        });
    }

    onSubmit(): void {
        this.requestLaunchChallengeForm.markAllAsTouched();

        if (this.requestLaunchChallengeForm.valid) {
            const challengeRequest: ChallengeRequestDto =
                this.requestLaunchChallengeForm.value;

            this.challengeService.postInterest(challengeRequest).subscribe({
                next: (response) => {
                    this.alertService.successPopup(response.message);
                    this.requestLaunchChallengeForm.reset();
                    this.route.navigate(['/the-challenges']);
                },
                error: (error) => {
                    if (error.status == 422) {
                        const errors = error?.error?.errors;
                        if (errors) {
                            this.validationMessageService.setServerErrors(
                                this.requestLaunchChallengeForm,
                                errors
                            );
                        }
                    } else {
                        this.alertService.errorPopup(error.error.message);
                    }
                },
            });
        }
    }
}
