import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import {
    AbstractControl,
    Form,
    FormArray,
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { QuillEditorComponent } from '../../shared/quill-editor/quill-editor.component';
import { InputErrorMessageComponent } from '../../shared/input-error-message/input-error-message.component';
import { ReferenceHandlerUploadsComponent } from '../../shared/input-solution-references/reference-handler-uploads/reference-handler-uploads.component';
import { ReferenceHandlerComponent } from '../../shared/input-solution-references/reference-handler/reference-handler.component';
import { SolverFlowService } from '../../core/services/apis/solver-flow.service';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { SolverFormStoreService } from '../store/solver-form-store.service';
import { SolutionReferences } from '../../shared/input-solution-references/types/input-solution-references-types';
import { InputSolutionReferencesComponent } from '../../shared/input-solution-references/input-solution-references.component';
import { firstValueFrom } from 'rxjs';
@Component({
    selector: 'app-solver-flow-step-6',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        CommonModule,
        QuillEditorComponent,
        InputErrorMessageComponent,
        ReferenceHandlerUploadsComponent,
        ReferenceHandlerComponent,
        InputSolutionReferencesComponent,
    ],
    templateUrl: './solver-flow-step-6.component.html',
    styleUrl: './solver-flow-step-6.component.scss',
})
export class SolverFlowStep6Component {
    formGroup: FormGroup;
    solutionReferences: SolutionReferences = {} as SolutionReferences;
    @Output() successEvent = new EventEmitter();

    constructor(
        private fb: FormBuilder,
        private solverFlowService: SolverFlowService,
        private sweetAlertService: SweetAlertService,
        private solverFormStoreService: SolverFormStoreService
    ) {
        this.formGroup = this.fb.group({
            NEAR: this.fb.group({
                idea: ['Near term', Validators.required],
                termActionables: this.fb.array(
                    [],
                    this.minLengthArrayWithPriority(1)
                ),
            }),
            MEDIUM: this.fb.group({
                idea: ['Medium term', Validators.required],
                termActionables: this.fb.array(
                    [],
                    this.minLengthArrayWithPriority(1)
                ),
            }),
            LONG: this.fb.group({
                idea: ['Long term', Validators.required],
                termActionables: this.fb.array(
                    [],
                    this.minLengthArrayWithPriority(1)
                ),
            }),
        });
    }

    ngOnInit(): void {
        // this.addActionableItems('NEAR');
        // this.addActionableItems('MEDIUM');
        // this.addActionableItems('LONG');
        this.fetchTermActionables();
    }

    async fetchTermActionables(): Promise<void> {
        const solutionId = await firstValueFrom(
            this.solverFormStoreService.selectSolutionId$
        );
        this.solverFlowService
            .fetchTermActionables(solutionId as number)
            .subscribe({
                next: (response) => {
                    this.patchFormValues(
                        response.solutionTermOpportunities,
                        response.solutionReferences
                    );
                    // this.solverFormStoreService.updateSolutionTermOpportunities(response.solutionTermOpportunities);
                },
                error: (error) => {
                    console.error('Error - ', error);
                },
            });
    }
    get nearTermFormGroup(): FormGroup {
        return this.formGroup.get('NEAR') as FormGroup;
    }
    get nextTermFormGroup(): FormGroup {
        return this.formGroup.get('MEDIUM') as FormGroup;
    }
    get longTermFormGroup(): FormGroup {
        return this.formGroup.get('LONG') as FormGroup;
    }

    get NEAR(): FormArray {
        return (this.formGroup.get('NEAR') as FormGroup).get(
            'termActionables'
        ) as FormArray;
    }

    get MEDIUM(): FormArray {
        return (this.formGroup.get('MEDIUM') as FormGroup).get(
            'termActionables'
        ) as FormArray;
    }

    get LONG(): FormArray {
        return (this.formGroup.get('LONG') as FormGroup).get(
            'termActionables'
        ) as FormArray;
    }

    patchFormValues(
        solutionTermOpportunities: any[],
        solutionReferences: any
    ): void {
        this.solutionReferences = solutionReferences;
        solutionTermOpportunities.forEach((term) => {
            const termFormGroup = this.formGroup.get(
                term.termType
            ) as FormGroup;
            termFormGroup.patchValue({ idea: term.idea });

            const termFormArray = termFormGroup.get(
                'termActionables'
            ) as FormArray;
            term.termActionables.forEach((actionable: any) => {
                const item = this.fb.group({
                    title: [actionable.title, [Validators.maxLength(120)]],
                    priority: [{ value: actionable.priority, disabled: false }],
                    id: actionable.id,
                });

                // Listen to value changes on the title to enable or disable priority field
                item.get('title')!.valueChanges.subscribe(
                    (value: string | null) => {
                        if (value!.trim().length > 0) {
                            item.get('priority')!.enable(); // Enable the priority radio buttons when title has value
                            item.get('priority')!.setValue('MID'); // Set default to 'medium'
                        } else {
                            item.get('priority')!.disable(); // Disable the priority buttons when title is empty
                            item.get('priority')!.setValue(''); // Clear priority value
                        }
                    }
                );

                termFormArray.push(item);
            });

            // Ensure there are exactly 5 controls
            while (termFormArray.length < 5) {
                termFormArray.push(this.createDefaultActionable());
            }
        });

        // Ensure all sections have exactly 5 controls
        ['NEAR', 'MEDIUM', 'LONG'].forEach((termType) => {
            const termFormArray = (
                this.formGroup.get(termType) as FormGroup
            ).get('termActionables') as FormArray;
            while (termFormArray.length < 5) {
                termFormArray.push(this.createDefaultActionable());
            }
        });
    }
    createDefaultActionable(): FormGroup {
        const item = this.fb.group({
            title: ['', [Validators.maxLength(120)]],
            priority: [{ value: '', disabled: true }],
            id: null,
        });

        // Listen to value changes on the title to enable or disable priority field
        item.get('title')!.valueChanges.subscribe((value: string | null) => {
            if (value!.trim().length > 0) {
                item.get('priority')!.enable(); // Enable the priority radio buttons when title has value
                item.get('priority')!.setValue('MID'); // Set default to 'medium'
            } else {
                item.get('priority')!.disable(); // Disable the priority buttons when title is empty
                item.get('priority')!.setValue(''); // Clear priority value
            }
        });

        return item;
    }

    addActionableItems(term: string): void {
        const termFormArray = (this.formGroup.get(term) as FormGroup).get(
            'termActionables'
        ) as FormArray;

        for (let i = 0; i < 5; i++) {
            const item = this.fb.group({
                title: ['1', [Validators.maxLength(120)]], // Correct Validators array syntax
                priority: [{ value: 'MID', disabled: false }],
                id: null, // Disabled by default
            });

            // Add the item to the FormArray
            termFormArray.push(item);

            // Listen to value changes on the title to enable or disable priority field
            item.get('title')!.valueChanges.subscribe(
                (value: string | null) => {
                    if (value!.trim().length > 0) {
                        item.get('priority')!.enable(); // Enable the priority radio buttons when title has value
                        item.get('priority')!.setValue('MID'); // Set default to 'medium'
                    } else {
                        item.get('priority')!.disable(); // Disable the priority buttons when title is empty
                        item.get('priority')!.setValue(''); // Clear priority value
                    }
                }
            );
        }
    }

    minLengthArrayWithPriority(min: number) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control instanceof FormArray) {
                const validItems = control.controls.filter(
                    (group: AbstractControl) => {
                        const title = group.get('title')?.value;
                        const priority = group.get('priority')?.value;
                        return title && title.trim().length > 0 && priority;
                    }
                );
                return validItems.length >= min
                    ? null
                    : { minLengthArrayWithPriority: true };
            }
            return null;
        };
    }

    async submitTermActionables(data: any, isDraft?: boolean): Promise<void> {
        const solutionId = await firstValueFrom(
            this.solverFormStoreService.selectSolutionId$
        );
        // this is for save and next
        this.solverFlowService
            .createActionableItems(solutionId as number, data, isDraft)
            .subscribe({
                next: (response) => {
                    this.solverFormStoreService.getSolutionDetails();
                    this.sweetAlertService.successToaster(
                        'Actionable items created successfully'
                    );
                    this.successEvent.emit();
                },
                error: (error) => {
                    this.sweetAlertService.errorToaster(
                        error.error.message || 'Actionable have some errors'
                    );
                },
            });
    }

    onSubmit(isDraft?: boolean): void {
        if (!isDraft) {
            this.formGroup.markAllAsTouched();
        }
        if (this.formGroup.valid || isDraft) {
            const validNearTermItems = this.getValidItems(this.NEAR);
            const validNextTermItems = this.getValidItems(this.MEDIUM);
            const validLongTermItems = this.getValidItems(this.LONG);

            //data to be submitted to backend
            const submissionData = {
                ...this.formGroup.value,
                nearTermOpportunity: {
                    ...this.formGroup.value.NEAR,
                    termActionables: validNearTermItems,
                },
                mediumTermOpportunity: {
                    ...this.formGroup.value.MEDIUM,
                    termActionables: validNextTermItems,
                },
                longTermOpportunity: {
                    ...this.formGroup.value.LONG,
                    termActionables: validLongTermItems,
                },
            };
            this.submitTermActionables(submissionData, isDraft);

            // call updater and save the response from backend to ngrx state
        }
    }
    private getValidItems(formArray: FormArray): any[] {
        return formArray.controls
            .filter((group: AbstractControl) => {
                const title = group.get('title')?.value;
                const priority = group.get('priority')?.value;
                return title && title.trim().length > 0 && priority;
            })
            .map((group) => group.value);
    }
}
