import { Component, HostListener, inject, ViewChild } from '@angular/core';
import { Category } from '../../core/Model/category';
import { FiltersService } from '../../filters.service';
import { CommonModule } from '@angular/common';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import {
    challengeIncentiveOptions,
    challengeStages,
    challengeTypeOptions,
} from '../../core/Model/DroddownOptions';
import { FormsModule } from '@angular/forms';
import {
    InfiniteScrollDirective,
    InfiniteScrollModule,
} from 'ngx-infinite-scroll';
import { RouterModule } from '@angular/router';
@Component({
    selector: 'app-challenge-list',
    standalone: true,
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule,
        InfiniteScrollDirective,
        RouterModule,
    ],
    templateUrl: './challenge-list.component.html',
    styleUrl: './challenge-list.component.scss',
})
export class ChallengeListComponent {
    params: any = {
        challengeStages: [],
        sdgs: [],
        categories: [],
        orgTypes: [],
        challengeTypes: [],
        incentives: [],
        // search:'',
        pageLimit: 5,
        pageIndex: 1,
        searchKeyword: '',
        sortKey: 'createdAt',
        sortDirection: 'DESC',
    };
    sortList = [
        { id: 'DESC', title: 'Latest' },
        { id: 'ASC', title: 'Oldest' },
    ];
    categories: Category[] = [];
    sdgs: Category[] = [];
    orgTypes: Category[] = [];
    incentivesOptions = [...challengeIncentiveOptions];
    stagesOptions = [...challengeStages];
    typeOptions = [...challengeTypeOptions];
    filterService: FiltersService = inject(FiltersService);
    selectedItems: any[] = [];
    showScrollToTopButton = false;
    @ViewChild('categorySelect') categorySelect!: NgSelectComponent;

    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.showScrollToTopButton = window.scrollY > 300;
    }
    challenges: any[] = [];
    onSortChange(sortValue: { id: string; title: string }): void {
        this.params.sortDirection = sortValue?.id;
        this.params.pageIndex = 1;
        this.fetchChallenges();
    }

    ngAfterViewInit(): void {
        this.categorySelect.open();
    }
    onRemoveChange(selectedItem: any, key: string) {
        let indexForParamsObject;
        if (key === 'sdgs') {
            indexForParamsObject = this.params[key].findIndex(
                (selected: any) => selected == selectedItem.id
            );
        } else {
            indexForParamsObject = this.params[key].findIndex(
                (selected: any) => selected === selectedItem.title
            );
        }
        let indexForselectedItems = this.selectedItems.findIndex(
            (selected: any) => selected.title === selectedItem.title
        );

        // Remove item from selectedItems and params
        if (indexForselectedItems !== -1) {
            this.selectedItems.splice(indexForselectedItems, 1);
        } else {
            console.error('Item not found in selectedItems');
        }

        if (indexForParamsObject !== -1) {
            this.params[key].splice(indexForParamsObject, 1);
        } else {
            console.error('Item not found in params');
        }

        this.params.pageIndex = 1;
        this.fetchChallenges();
    }
    onSelectionChange(selectedItem: any, key: string): void {
        let itemObject = { ...selectedItem, key: key };

        this.selectedItems.push(itemObject);
        if (key === 'sdgs') {
            this.params[key].push(itemObject?.id);
        } else {
            this.params[key].push(itemObject?.title);
        }

        this.params.pageIndex = 1;
        this.fetchChallenges();
    }
    isEllipsisActive(text: string): boolean {
        const tempElement = document.createElement('span');
        tempElement.style.whiteSpace = 'nowrap';
        tempElement.style.visibility = 'hidden';
        tempElement.style.position = 'absolute';
        tempElement.innerText = text;
        document.body.appendChild(tempElement);

        const isOverflowing = tempElement.scrollWidth > tempElement.offsetWidth;
        document.body.removeChild(tempElement);

        return isOverflowing;
    }

    removeAllFilters() {
        this.selectedItems = [];
        this.params = {
            challengeStages: [],
            sdgs: [],
            categories: [],
            orgTypes: [],
            challengeTypes: [],
            incentives: [],
            // search:'',
            pageLimit: 5,
            pageIndex: 1,
            searchKeyword: '',
            sortKey: 'createdAt',
            sortDirection: 'DESC',
        };
        this.fetchChallenges();
    }

    isSelected(item: any, key: string): boolean {
        return this.selectedItems.some(
            (selectedItem) =>
                selectedItem?.title === item?.title &&
                selectedItem?.id === item?.id
        );
        // return this.params[key].some((selectedItem:any) =>selectedItem?.title === item?.title&&selectedItem?.id===item?.id);
    }

    onScroll(): void {
        this.params.pageIndex += 1;
        this.fetchChallenges();
    }
    // onScrollUp(): void {
    //   this.params.pageIndex -= 1;
    //   this.fetchChallenges()
    // }

    handleSearchChange(searchValue: string): void {
        this.params.searchKeyword = searchValue;
        this.params.pageIndex = 1;
        this.fetchChallenges();
    }
    fetchChallenges() {
        return this.filterService.getChallenges(this.params).subscribe(
            (data: any) => {
                if (this.params.pageIndex === 1) {
                    this.challenges = data.challenges;
                } else {
                    this.challenges = [...this.challenges, ...data.challenges];
                }
            },
            (error: any) => {
                console.error('Error fetching challenges', error);
            }
        );
    }
    ngOnInit(): void {
        this.filterService.getCategories().subscribe(
            (data: Category[]) => {
                this.categories = data;
            },
            (error: any) => {
                console.error('Error fetching categories', error);
            }
        );

        this.filterService.getSdgs().subscribe(
            (data: any[]) => {
                this.sdgs = data;
            },
            (error: any) => {
                console.error('Error fetching sdgs', error);
            }
        );

        this.filterService.getOrgTypes().subscribe(
            (data: any[]) => {
                this.orgTypes = data;
            },
            (error: any) => {
                console.error('Error fetching orgTypes', error);
            }
        );
        this.fetchChallenges();
    }
    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}
