import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RequestLaunchChallengeFormComponent } from '../../shared/request-launch-challenge-form/request-launch-challenge-form.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChallengeService } from '../../core/services/apis/challenge.service';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { ChallengeRequestDto } from '../../core/Model/challenge-request.dto';

interface Card {
    image: string;
    title: string;
    text: string;
}

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [CommonModule, RequestLaunchChallengeFormComponent],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
})
export class HomeComponent {
    startIndex: number = 0;
    cardsToShow: number = 4;
    requestLaunchChallengeForm!: FormGroup;

    constructor(
        private fb: FormBuilder,
        private challengeService: ChallengeService,
        private validationMessageService: ValidationMessageService,
        private alertService: SweetAlertService
    ) {}

    cards: Card[] = [
        {
            image: '/images/home/mask group 2.png',
            title: 'Training and Development Upskilling in India',
            text: 'SDG 9',
        },
        {
            image: '/images/home/mask group 3.png',
            title: 'Empowering Local Business Development in Vietnam',
            text: 'SDG 8',
        },
        {
            image: '/images/home/mask group 4.png',
            title: 'Cleaning Rivers In China And Treating Chemical Waste',
            text: 'SDG 13',
        },
        {
            image: '/images/home/mask group 5.png',
            title: 'Textbooks for Children in India',
            text: 'SDG 4',
        },
        {
            image: '/images/home/mask group 6.png',
            title: 'Processing Coesmetic Product Packaging Trash in Nicaragua',
            text: 'SDG 12',
        },
        {
            image: '/images/home/mask group 2.png',
            title: 'Empowering Local Business Development in Vietnam',
            text: 'SDG 8',
        },
        {
            image: '/images/home/mask group 4.png',
            title: 'Training and Development Upskilling in India',
            text: 'SDG 9',
        },
        {
            image: '/images/home/mask group 3.png',
            title: 'Cleaning Rivers In China And Treating Chemical Waste',
            text: 'SDG 13',
        },
    ];

    visibleCards: Card[] = [];

    ngOnInit() {
        this.updateVisibleCards();

        this.requestLaunchChallengeForm = this.fb.group({
            firstName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            lastName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            email: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.email,
                    Validators.maxLength(50),
                ]),
            ],
            designation: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            organizationName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            organizationUrl: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            subject: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(255),
                ]),
            ],
            message: ['', Validators.compose([Validators.required])],
        });
    }

    updateVisibleCards() {
        this.visibleCards = this.cards.slice(
            this.startIndex,
            this.startIndex + this.cardsToShow
        );
    }

    prev() {
        if (this.startIndex > 0) {
            this.startIndex -= 1;
            this.updateVisibleCards();
        }
    }

    next() {
        if (this.startIndex + this.cardsToShow < this.cards.length) {
            this.startIndex += 1;
            this.updateVisibleCards();
        }
    }

    onSubmit(): void {
        this.requestLaunchChallengeForm.markAllAsTouched();

        if (this.requestLaunchChallengeForm.valid) {
            const challengeRequest: ChallengeRequestDto =
                this.requestLaunchChallengeForm.value;

            this.challengeService.postInterest(challengeRequest).subscribe({
                next: (response) => {
                    this.alertService.successPopup(response.message);
                    this.requestLaunchChallengeForm.reset();
                },
                error: (error) => {
                    if (error.status == 422) {
                        const errors = error?.error?.errors;
                        if (errors) {
                            this.validationMessageService.setServerErrors(
                                this.requestLaunchChallengeForm,
                                errors
                            );
                        }
                    } else {
                        this.alertService.errorPopup(error.error.message);
                    }
                },
            });
        }
    }
}
