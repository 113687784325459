<section class="custom-container-fluid">
    <div class="benefits-section container " style="margin-top: 80px; margin-bottom: 90px;">
        <div class="row">
            <div class="col-xl-6 col-xxl-4 px-0">
                <div class="left-straps-img-container" [ngStyle]="{'--dynamic-strap-color': lightStrapColor}">
                    <img [src]="imageSrc" alt="Benefits Image" class="img-benefits w-100">
                </div>
            </div>
            <div class="col-xl-6 col-xxl-8 pe-0 mt-4" style="padding-left: 50px;">
                <h2 class="title-font col-md-11">{{ title }}</h2>
                <p class="description-font fw-semibold">{{ description }}
                    <span class="additional-line fw-semibold">{{ additionalLine }}</span>
                </p>
                <ul class="benefits-list fw-semibold">
                    <li *ngFor="let point of points">
                        <span class="dot mt-2" [ngStyle]="{'color': bulletColor}">•</span> {{ point }}
                    </li>
                </ul>
                <a [href]="buttonLink">
                    <button
                        class="btn btn-primary rounded-start-0 get-challenge-btn align-items-center mt-4 text-start fw-semibold custom-btn"
                        [ngStyle]="{'background': buttonBgColor}">
                        {{ buttonText }}
                        <span class="arrow-btn-wrapper">
                            <img [src]="arrowIconSrc" alt="Arrow" height="20" class="arrow-icon1">
                        </span>
                    </button>
                </a>
            </div>
        </div>
    </div>
</section>
