import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StripeService } from 'ngx-stripe';
import { ChallengeService } from '../core/services/apis/challenge.service';
import { switchMap } from 'rxjs';
import { StripeEmbeddedCheckout } from '@stripe/stripe-js';
import { ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-proceed-to-payment',
    standalone: true,
    imports: [],
    templateUrl: './proceed-to-payment.component.html',
    styleUrl: './proceed-to-payment.component.scss',
})
export class ProceedToPaymentComponent {
    checkoutInstance!: StripeEmbeddedCheckout;
    challengeId: any;
    constructor(
        private http: HttpClient,
        private stripeService: StripeService,
        private challengeService: ChallengeService,
        private route:ActivatedRoute,

    ) {
        this.challengeId = this.route.snapshot.queryParams['id']
            ? this?.route?.snapshot?.queryParams['id']
            : this?.route?.snapshot?.params['id'];
    }
    ngOnInit(): void {
        this.checkout();
    }
    ngOnDestroy(): void {
        if (this.checkoutInstance) {
            this.checkoutInstance.destroy();
        }
    }
    checkout() {
        this.challengeService
            .proccedToPayment({ challengeId: Number(this.challengeId) })
            .pipe(
                switchMap(async (session: any) => {
                    // this.options.clientSecret = session.clientSecret; // set clientSecret
                    return await this.stripeService.initEmbeddedCheckout({
                        clientSecret: session.clientSecret, // For embedded checkout
                    });
                })
            )
            .subscribe((result) => {
                // If `initEmbeddedCheckout` fails due to a browser or network
                // error, you should display the localized error message to your
                // customer using `error.message`.
                result.subscribe(
                    (checkout) => {
                        checkout.mount('#stripe-container');
                        this.checkoutInstance = checkout;
                    },
                    (error) => {
                        console.error('error in stripe', error);
                    }
                );
            });
    }
}
