import { Injectable,inject } from '@angular/core';
import Swal from 'sweetalert2';
import { FiltersService } from '../../filters.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {
  // challengeService= inject(FiltersService);
  constructor() { }


   // Display a success popup
   successPopup(message: string, title: string = 'Success') {
    Swal.fire({
      title: title,
      text: message,
      icon: 'success',
      confirmButtonText: 'OK'
    });
  }

  // Display an error popup
  errorPopup(message: string, title: string = 'Error') {
    Swal.fire({
      title: title,
      text: message,
      icon: 'error',
      confirmButtonText: 'OK'
    });
  }

  // Display a success toaster
  successToaster(message: string) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'success',
      title: message,
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true
    });
  }

  // Display an error toaster
  errorToaster(message: string) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'error',
      title: message,
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true
    });
  }

  confirmDeletion(): Observable<boolean> {
    return new Observable((observer) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        observer.next(result.isConfirmed);
        observer.complete();
      });
    });
  }
  confirmSubmission(): Observable<boolean> {
    return new Observable((observer) => {
      Swal.fire({
        title: "Final Submission",
        text: "You won't be able to edit your solution afterwards!",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, submit it!"
      }).then((result) => {
        observer.next(result.isConfirmed);
        observer.complete();
      });
    });
  }

  showDeletionSuccess(message:string='Your file has been deleted.'): void {
    Swal.fire({
      title: "Deleted!",
      text: message,
      icon: "success"
    });
  }
  
}
