<div class="container my-5">
    <div class="row">
        <div class="col-md-12 border-bottom">
            <h2 class="text-center">My Profile</h2>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-md-12">
            <form class="row" [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                <div *ngIf="showUserDetails">
                    <div class="row mt-3 mb-3">
                        <div class="col-md-12 fw-semibold" style="color: #0074C1; font-size: 22px;">
                            USER DETAILS
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label for="inputFirstName" class="form-label fw-semibold">First Name</label>
                            <input type="text" class="form-control" id="inputFirstName" formControlName="firstName" />

                            <app-input-error-message [formData]="profileForm" [controlName]="'firstName'">
                            </app-input-error-message>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="inputLastName" class="form-label fw-semibold">Last Name</label>
                            <input type="text" class="form-control" id="inputLastName" formControlName="lastName" />

                            <app-input-error-message [formData]="profileForm" [controlName]="'lastName'">
                            </app-input-error-message>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label for="inputEmail" class="form-label fw-semibold">Email</label>
                            <input type="email" class="form-control" id="inputEmail" disabled [value]="userEmail" />
                        </div>
                    </div>
                </div>

                <div *ngIf="showOrganizationDetails">
                    <div class="row mt-3">
                        <div class="col-md-12 fw-semibold" style="color: #0074C1; font-size: 22px;">
                            ORGANIZATION DETAILS
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6 mb-3">
                            <label for="organizationName" class="form-label fw-semibold">Organization Name</label>
                            <input type="text" class="form-control" id="organizationName"
                                formControlName="organizationName" />

                            <app-input-error-message [formData]="profileForm" [controlName]="'organizationName'">
                            </app-input-error-message>
                        </div>

                        <div class="col-md-6 mb-3">
                            <label for="organizationUrl" class="form-label fw-semibold">Website Url
                            </label>
                            <input type="text" class="form-control" id="organizationUrl"
                                formControlName="organizationUrl" />
                            <app-input-error-message [formData]="profileForm" [controlName]="'organizationUrl'">
                            </app-input-error-message>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-12 fw-semibold" style="color: #0074C1; font-size: 22px;">
                            CONTACT PERSON DETAILS
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6 mb-3">
                            <label for="contactFirstName" class="form-label fw-semibold">First Name</label>
                            <input type="text" class="form-control" id="contactFirstName"
                                formControlName="contactFirstName" />

                            <app-input-error-message [formData]="profileForm" [controlName]="'contactFirstName'">
                            </app-input-error-message>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="contactLastName" class="form-label fw-semibold">Last Name</label>
                            <input type="text" class="form-control" id="contactLastName"
                                formControlName="contactLastName" />

                            <app-input-error-message [formData]="profileForm" [controlName]="'contactLastName'">
                            </app-input-error-message>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="contactEmail" class="form-label fw-semibold">Email</label>
                            <input type="email" class="form-control" id="contactEmail" formControlName="contactEmail" />
                            <app-input-error-message [formData]="profileForm" [controlName]="'contactEmail'">
                            </app-input-error-message>
                        </div>
                    </div>
                </div>




                <!-- <div class="col-md-6 mb-3">
                    <div class="mb-3">
                        <label for="inputProfileImg" class="form-label"
                            >Profile Image</label
                        >
                        <input class="form-control" type="file" id="inputProfileImg" />
                    </div>
                </div> -->

                <div class="col-md-12 mt-3">
                    <button type="submit" class="btn btn-gradient btn-bg-blue-gradient custom-btn">
                        Update Profile <span class="arrow-btn-wrapper"> <img src="/images/home/right-arrow.svg"
                                alt="Arrow" height="20" class="arrow-icon1"></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>