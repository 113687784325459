export const initialState: AuthState = {
    token: null,
    user: null,
    error: null,
};


export interface AuthState {
    token: string | null;
    user: any;
    error: any;
}