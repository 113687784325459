<section>
    <div class="container">
        <form [formGroup]="guidelinesForm" (ngSubmit)="onSubmit()">
            <div class="row mt-3">
                <div class="col-md-12">
                    <label class="form-label fw-semibold">Participant Eligibility <span
                            style="color: #B21818;">*</span></label>
                    <small class="form-text text-muted info-msg">
                        Select the criteria for the participant eligibility
                    </small>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-3">
                    <div class="form-check form-check-inline" style="margin-right: 60px;">
                        <input class="form-check-input" type="radio" formControlName="openToAll" value="openToAll">
                        <label class="form-check-label checkbox-label">Open to All</label>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" formControlName="openToAll" value="custom">
                        <label class="form-check-label checkbox-label">Custom Criteria</label>
                    </div>
                </div>
            </div>

            <!-- Age restrictions -->
            <div class="row mt-4">
                <div class="col-md-3">
                    <input type="checkbox" formControlName="ageChecked">
                    <label for="age">Age restrictions</label>
                </div>
                <div class="col-md-9">
                    <input type="text" formControlName="ageRestrictions" placeholder="Please details here"
                        class="form-control">
                    <app-input-error-message [formData]="guidelinesForm" [controlName]="'ageRestrictions'">
                    </app-input-error-message>
                </div>
            </div>

            <!-- Geographic restrictions -->
            <div class="row mt-2">
                <div class="col-md-3">
                    <input type="checkbox" formControlName="geographicChecked">
                    <label for="Geographic">Geographic restrictions</label>
                </div>
                <div class="col-md-9">
                    <input type="text" formControlName="geographicRestrictions" placeholder="Please details here"
                        class="form-control">
                    <app-input-error-message [formData]="guidelinesForm" [controlName]="'geographicRestrictions'">
                    </app-input-error-message>
                </div>
            </div>

            <!-- Other restrictions -->
            <div class="row mt-2">
                <div class="col-md-3">
                    <input type="checkbox" formControlName="otherChecked">
                    <label for="other">Other restrictions</label>
                </div>
                <div class="col-md-9">
                    <input type="text" formControlName="otherRestrictions" placeholder="Please details here"
                        class="form-control">
                    <app-input-error-message [formData]="guidelinesForm" [controlName]="'otherRestrictions'">
                    </app-input-error-message>
                </div>
            </div>

            <div class="row " style="margin-top: 50px;">
                <div class="col-md-12">
                    <label class="form-label fw-semibold">Team Requirements <span
                            style="color: #B21818;">*</span></label>
                    <small class="form-text text-muted info-msg">
                        Subtitle text
                    </small>
                </div>
            </div>
            <div class="row" style="margin-top: 20px;">
                <div class="col-md-12 " style="margin-bottom: 20px;">
                    <div class="form-check form-check-inline" style="margin-right: 60px;">
                        <input class="form-check-input" type="radio" formControlName="teamRequirements"
                            value="INDIVIDUAL">
                        <label class="form-check-label checkbox-label" for="">Individual participation allowed</label>
                    </div>
                </div>
                <div class="col-md-12 row" style="margin-bottom: 20px; align-items: center;">
                    <div class="col-md-5">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" formControlName="teamRequirements"
                                value="TEAM">
                            <label class="form-check-label checkbox-label" for="teamSizeLimitRadio">Teams required
                                (specify size limits, if any)</label>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <input type="text" id="teamSizeLimitDetails" formControlName="teamSizeLimitDetails"
                            placeholder="Please details here" class="form-control"
                            [disabled]="guidelinesForm.get('teamRequirements')?.value !== 'TEAM'">
                        <app-input-error-message [formData]="guidelinesForm" [controlName]="'teamSizeLimitDetails'">
                        </app-input-error-message>
                    </div>
                </div>
                <div class="col-md-12 ">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" formControlName="teamRequirements" value="BOTH">
                        <label class="form-check-label checkbox-label" for="">Both individual and team involvement
                            allowed
                        </label>
                    </div>
                </div>
                <!-- <div *ngIf="guidelinesForm.get('teamRequirements')?.touched && guidelinesForm.get('teamRequirements')?.invalid" class="text-danger">
                    Please select any one team requirement option.
                </div> -->
                <app-input-error-message [formData]="guidelinesForm" [controlName]="'teamRequirements'">
                </app-input-error-message>
            </div>

            <div class="col-12 form-field-margin" style="margin-top: 50px;">
                <app-quill-editor label="Skills or Expertise Needed"
                    infoText=" List any specific skills, knowledge, or experience that would benefit participants"
                    controlName="skillsOrExpertiseNeeded" placeholder="" [parentForm]="guidelinesForm">
                </app-quill-editor>
                <app-input-error-message [formData]="guidelinesForm" [controlName]="'skillsOrExpertiseNeeded'">
                </app-input-error-message>
            </div>

            <div class="col-12 form-field-margin">
                <app-quill-editor label="Intellectual Property Terms" infoText="Clearly state the intellectual property rules for the challenge, 
                including ownership of submitted ideas and potential licensing agreements"
                    controlName="intellectualPropertyTerms" placeholder="" [parentForm]="guidelinesForm" skillsOrExpertiseNeeded [required]="true">
                </app-quill-editor>
                <app-input-error-message [formData]="guidelinesForm" [controlName]="'intellectualPropertyTerms'">
                </app-input-error-message>
            </div>

            <div class="col-12 form-field-margin">
                <app-quill-editor label="Partnerships and Collaborations"
                    infoText="List any partner organizations or institutions involved in the challenge and their roles"
                    controlName="partnershipAndCollaborations" placeholder="" [parentForm]="guidelinesForm">
                </app-quill-editor>
                <app-input-error-message [formData]="guidelinesForm" [controlName]="'partnershipAndCollaborations'">
                </app-input-error-message>
            </div>

            <div class="col-12 form-field-margin">
                <app-quill-editor label="Data Sharing and Privacy Policies"
                    infoText="Explain how participant data will be handled and any data-sharing agreements in place"
                    controlName="dataSharingAndPrivacyPolicies" placeholder="" [parentForm]="guidelinesForm">
                </app-quill-editor>
                <app-input-error-message [formData]="guidelinesForm" [controlName]="'dataSharingAndPrivacyPolicies'">
                </app-input-error-message>
            </div>

            <div class="col-12 form-field-margin">
                <app-quill-editor label="Post-Challenge Support"
                    infoText="Describe any support or resources that will be available to winners for implementing their solutions"
                    controlName="postChallengeSupport" placeholder="" [parentForm]="guidelinesForm">
                </app-quill-editor>
                <app-input-error-message [formData]="guidelinesForm" [controlName]="'postChallengeSupport'">
                </app-input-error-message>
            </div>

            <div class="col-12 form-field-margin">
                <app-quill-editor label="Marketing and Promotion Plans"
                    infoText="Outline your strategy for promoting the challenge and attracting diverse, qualified participants"
                    controlName="marketingAndPromotionPlans" placeholder="" [parentForm]="guidelinesForm">
                </app-quill-editor>
                <app-input-error-message [formData]="guidelinesForm" [controlName]="'marketingAndPromotionPlans'">
                </app-input-error-message>
            </div>

            <div class="col-12 form-field-margin">
                <app-quill-editor label="Additional Comments or Information"
                    infoText="Provide any other relevant information not covered in the previous sections"
                    controlName="additionalCommentsOrInformation" placeholder="" [parentForm]="guidelinesForm">
                </app-quill-editor>
                <app-input-error-message [formData]="guidelinesForm" [controlName]="'additionalCommentsOrInformation'">
                </app-input-error-message>
            </div>
        </form>
    </div>
</section>
