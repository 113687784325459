<section>
    <div class="container">
        <form [formGroup]="timelineForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-md-12">
                    <label class="form-label fw-semibold">Challenge Timeline Implication <span
                            style="color: #B21818;">*</span></label>
                    <small class="form-text text-muted info-msg">
                        Choose the nature of the timeline of the challenge
                    </small>
                </div>
            </div>
            <div class="row " style="margin-top: 20px;">
                <div class="col-md-12">
                    <div class="form-check form-check-inline" style="width: calc(100% - (50% - -30%));">
                        <input class="form-check-input" type="radio" id="longTerm" formControlName="timelineImplication"
                            value="long-term">
                        <label class="form-check-label" for="longTerm" style="font-weight: 500;">Long-term</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" id="fixedTerm"
                            formControlName="timelineImplication" value="fixed-term">
                        <label class="form-check-label" for="fixedTerm" style="font-weight: 500;">Fixed-term</label>
                    </div>
                </div>
                <div
                    *ngIf="timelineForm.get('timelineImplication')?.touched && timelineForm.get('timelineImplication')?.invalid">
                    <small class="text-danger">Time Implication Line is required.</small>
                </div>
            </div>

            <div class="row mt-4" *ngIf="timelineForm.get('timelineImplication')?.value">
                <!-- Challenge Launch Date -->
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-5">
                            <label class="form-label fw-semibold">Challenge Launch Date</label>
                        </div>
                        <div class="col-md-7 ">
                            <div class="custom-calender">
                                <mat-form-field appearance="fill">
                                    <input matInput [matDatepicker]="startPicker" [min]="today"
                                        formControlName="startDate" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="startPicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #startPicker></mat-datepicker>
                                </mat-form-field>
                                <!-- Built-in clear button using Material icon -->
                                <div class="cancel" *ngIf="timelineForm.get('startDate')?.value"
                                    (click)="timelineForm.get('startDate')?.setValue(null)">
                                    <span><img src="/images/common/cancel.svg" /></span>
                                </div>
                            </div>
                            <app-input-error-message [formData]="timelineForm" [controlName]="'startDate'">
                            </app-input-error-message>
                            <div *ngIf="timelineForm.hasError('startDateBeforeToday') && timelineForm.get('startDate')?.touched"
                                class="text-danger">
                                Start Date must be present date
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Submission Deadline (conditionally visible) -->
                <div class="col-md-6" *ngIf="timelineForm.get('timelineImplication')?.value === 'fixed-term'">
                    <div class="row">
                        <div class="col-md-5 text-center">
                            <label class="form-label fw-semibold">Submission Deadline</label>
                        </div>
                        <div class="col-md-7" style="padding: 0;">
                            <div class="custom-calender">
                                <mat-form-field appearance="fill"
                                    *ngIf="timelineForm.get('timelineImplication')?.value === 'fixed-term'">
                                    <input matInput [matDatepicker]="endPicker" [min]="getMinEndDate()"
                                        formControlName="endDate" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #endPicker></mat-datepicker>
                                </mat-form-field>
                                <div class="cancel" *ngIf="timelineForm.get('endDate')?.value"
                                    (click)="timelineForm.get('endDate')?.setValue(null)">
                                    <span><img src="/images/common/cancel.svg" /></span>
                                </div>
                                <!-- Error Message -->
                                <div *ngIf="timelineForm.hasError('endDateBeforeStartDate') && timelineForm.get('endDate')?.touched"
                                    class="text-danger">
                                    End Date must not be before start date
                                </div>
                                <div>
                                    <app-input-error-message [formData]="timelineForm" [controlName]="'endDate'">
                                    </app-input-error-message>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row" style="margin-top: 40px;">
                <div class="col-md-12 mb-3">
                    <label class="form-label fw-semibold">Key Milestones</label>
                    <small class="form-text text-muted info-msg">
                        Include important dates such as Q&A sessions, webinars, preliminary judging, finalist
                        announcements,
                        etc.
                    </small>
                </div>
            </div>
            <div formArrayName="milestones">
                <app-input-error-message [formData]="timelineForm" [controlName]="'milestones'">
                </app-input-error-message>
                <div class="row mb-2" *ngFor="let milestone of milestones.controls; let i = index" [formGroupName]="i">
                    <div class="milestone-inputs">
                        <input type="text" class="form-control" formControlName="title"
                            placeholder="Milestone event description">
                        <app-input-error-message [formData]="timelineForm" [controlName]="'title'">
                        </app-input-error-message>
                    </div>
                    <div class="milestone-date">
                        <div class="custom-calender">
                            <mat-form-field appearance="fill">
                                <input matInput [matDatepicker]="milestonePicker" [min]="getMinEndDate()"
                                    [max]="getMaxDate()" formControlName="date" readonly>
                                <mat-datepicker-toggle matSuffix [for]="milestonePicker"></mat-datepicker-toggle>
                                <mat-datepicker #milestonePicker></mat-datepicker>
                                <app-input-error-message [formData]="timelineForm" [controlName]="'date'">
                                </app-input-error-message>
                            </mat-form-field>
                            <!-- Cancel button to clear the date for the specific milestone -->
                            <div class="cancel" *ngIf="milestone.get('date')?.value" (click)="clearDate(i)">
                                <span><img src="/images/common/cancel.svg" /></span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="milestone.hasError('milestoneIncomplete')" class="text-danger">
                        Both title and date are required if one is filled.
                    </div>
                    <div *ngIf="milestone.hasError('milestoneBeforeStartDate')" class="text-danger">
                        Milestone date cannot be before launch date.
                    </div>
                    <div *ngIf="milestone.hasError('milestoneAfterEndDate')" class="text-danger">
                        Milestone date cannot be after submission end date.
                    </div>
                </div>
            </div>


            <div class="row " style="margin-top: 40px;">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-5">
                            <label class="form-label fw-semibold">Winner Announcement</label>
                        </div>
                        <div class="col-md-7">
                            <div class="custom-calender">
                                <mat-form-field appearance="fill">
                                    <input matInput [matDatepicker]="winnerAnnouncementPicker"
                                        [min]="getWinnerAnnouncementMinDate()" formControlName="winnerAnnouncementDate"
                                        readonly>
                                    <mat-datepicker-toggle matSuffix [for]="winnerAnnouncementPicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #winnerAnnouncementPicker></mat-datepicker>
                                </mat-form-field>
                                <div class="cancel" *ngIf="timelineForm.get('winnerAnnouncementDate')?.value"
                                    (click)="timelineForm.get('winnerAnnouncementDate')?.setValue(null)">
                                    <span><img src="/images/common/cancel.svg" /></span>
                                </div>
                            </div>
                            <div
                                *ngIf="timelineForm.hasError('winnerDateBeforeEndDate') && timelineForm.get('winnerAnnouncementDate')?.touched">
                                <small class="text-danger">Winner Announcement Date must not be before submission
                                    deadline date</small>
                            </div>
                            <div
                                *ngIf="timelineForm.hasError('winnerBeforeStartDate') && timelineForm.get('winnerAnnouncementDate')?.touched">
                                <small class="text-danger">Winner Announcement Date must not be before launch
                                    date</small>
                            </div>
                            <app-input-error-message [formData]="timelineForm" [controlName]="'winnerAnnouncementDate'">
                            </app-input-error-message>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>
</section>
