import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { ChallengeService } from '../../core/services/apis/challenge.service';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { PrizeRewardDto } from '../../core/Model/prize-reward.dto';
import { InputErrorMessageComponent } from '../input-error-message/input-error-message.component';
import { updatePrizeRewards } from '../../core/store/launchChallengeForm/launchChallenge.actions';
import { Store } from '@ngrx/store';
import { LaunchChallengeState } from '../../core/store/launchChallengeForm/launchChallenge.state';
import {
    selectChallengeId,
    selectPrizeRewardsError,
    selectProblemStatementError,
} from '../../core/store/launchChallengeForm/launchChallenge.selectors';
import {
    catchError,
    first,
    of,
    Subject,
    switchMap,
    take,
    takeUntil,
    tap,
    throwError,
    withLatestFrom,
} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { PrizeMoneyDto } from '../../core/Model/prize-money.dto';
import { NonMonetaryRewardDto } from '../../core/Model/non-monetory-reward.dto';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-prizes-reward-form',
    standalone: true,
    imports: [ReactiveFormsModule, CommonModule, InputErrorMessageComponent],
    templateUrl: './prizes-reward-form.component.html',
    styleUrl: './prizes-reward-form.component.scss',
})
export class PrizesRewardFormComponent implements OnInit {
    rewardForm!: FormGroup;
    // Define control names for each section
    challengeId: any
    route:ActivatedRoute=inject(ActivatedRoute);
    router:Router=inject(Router);
    virtualInternshipControls = ['virtualInternshipInput'];
    microCredentialControls = ['microCredentialDetailsInput'];
    potentialHiringControls = ['potentialHiringDetailsInput'];
    publicationOpportunityControls = ['publicationOpportunityDetailsInput'];
    equaityRoyaltiesControls = ['equaityRoyaltiesInput'];
    fundingProposedSoultionsControls = ['fundingProposedSoultionsInput'];
    othersControls = ['othersInput'];
    currencyOptions: string[] = [
        'USD',
        'AUD',
        'CAD',
        'CHF',
        'CNH',
        'EUR',
        'HKD',
        'GBP',
        'JPY',
        'NOK',
        'NZD',
        'SEK',
        'SDG',
    ];
    private unsubscribe$ = new Subject<void>();
    @Output() successEvent = new EventEmitter<void>();

    constructor(
        private fb: FormBuilder,
        private challengeService: ChallengeService,
        private alertService: SweetAlertService,
        private store: Store<LaunchChallengeState>,
        private validationMessageService: ValidationMessageService
    ) {
        this.prizeRewardForm();
    }

    prizeRewardForm(){
        this.rewardForm = this.fb.group({
            prizeMoney: [false],
            firstPrize: [
                { value: '', disabled: true },
                [Validators.required, Validators.pattern(/^\d+$/)],
            ],
            secondPrize: [{ value: '', disabled: true }],
            thirdPrize: [{ value: '', disabled: true }],
            commission: [{ value: '', disabled: true }, [Validators.required]],
            totalAmount: [{ value: '', disabled: true }],
            details: [{ value: '', disabled: true }],
            firstPrizeCurrencyCode: [
                { value: 'USD', disabled: true },
                [Validators.required],
            ],
            secondPrizeCurrencyCode: [{ value: '', disabled: true }],
            thirdPrizeCurrencyCode: [{ value: '', disabled: true }],
            virtualInternship: [false],
            virtualInternshipInput: [{ value: '', disabled: true }],

            microCredential: [false],
            microCredentialDetailsInput: [{ value: '', disabled: true }],

            potentialHiring: [false],
            potentialHiringDetailsInput: [{ value: '', disabled: true }],

            publicationOpportunity: [false],
            publicationOpportunityDetailsInput: [{ value: '', disabled: true }],

            equityOrRoyalties: [false],
            equaityRoyaltiesInput: [{ value: '', disabled: true }],

            fundingProposedSoultions: [false],
            fundingProposedSoultionsInput: [{ value: '', disabled: true }],

            others: [false],
            othersInput: [{ value: '', disabled: true }],
        });
    }

    ngOnInit(): void {
        // Subscribe to changes in the prize fields to recalculate total
        this.rewardForm
            .get('firstPrize')
            ?.valueChanges.subscribe(() => this.updateTotalAmount());
        this.rewardForm
            .get('secondPrize')
            ?.valueChanges.subscribe(() => this.updateTotalAmount());
        this.rewardForm
            .get('thirdPrize')
            ?.valueChanges.subscribe(() => this.updateTotalAmount());
        this.rewardForm
            .get('commission')
            ?.valueChanges.subscribe(() => this.updateTotalAmount());

        // // Initial calculation if needed
        this.updateTotalAmount();

        this.rewardForm
            .get('firstPrizeCurrencyCode')
            ?.valueChanges.subscribe((value) => {
                // Sync the currency code for second and third prize with the first prize
                this.rewardForm
                    .get('secondPrizeCurrencyCode')
                    ?.setValue(value, { emitEvent: false });
                this.rewardForm
                    .get('thirdPrizeCurrencyCode')
                    ?.setValue(value, { emitEvent: false });
            });
            this.challengeId=this.route.snapshot.queryParams['id'];
            if(this.challengeId){
                this.loadRewardForm();
            }
    }

    updateTotalAmount(): void {
        const firstPrize =
            parseFloat(this.rewardForm.get('firstPrize')?.value) || 0;
        const secondPrize =
            parseFloat(this.rewardForm.get('secondPrize')?.value) || 0;
        const thirdPrize =
            parseFloat(this.rewardForm.get('thirdPrize')?.value) || 0;
        const commission =
            parseFloat(this.rewardForm.get('commission')?.value) || 0;

        const total = firstPrize + secondPrize + thirdPrize + commission;
        this.rewardForm
            .get('totalAmount')
            ?.setValue(total, { emitEvent: false });
    }

    populateincentive(title: any, data: any) {
        let obj=data.filter((data:any)=>data.title===title);
         return obj[0]
    }

    populateForm(data: any) {
       let micorCred=this.populateincentive('Micro Credential', data.challengeRewards)
       let virtualInternship=this.populateincentive('Virtual Internship', data.challengeRewards)
       let potentialHiring=this.populateincentive('Potential Hiring', data.challengeRewards)
       let publicationOpportunity=this.populateincentive('Publication Opportunity', data.challengeRewards)
       let equityRoyalties=this.populateincentive('Equity Or Royalties', data.challengeRewards)
       let fundingProposedSoultions=this.populateincentive('Funding For Proposed Solutions', data.challengeRewards)
       let others=this.populateincentive('Other Incentives', data.challengeRewards)
        this.rewardForm.patchValue({
            prizeMoney: data?.challengePrize?.firstPrize > 0 ? true : false,
            firstPrize: data?.challengePrize?.firstPrize || null,
            secondPrize: data?.challengePrize?.secondPrize || null,
            thirdPrize: data?.challengePrize?.thirdPrize || null,
            commission: data?.challengePrize?.metaData?.platformFee || null,
            totalAmount:
                (data.challengePrize?.firstPrize || 0) +
                (data.challengePrize?.secondPrize || 0) +
                (data.challengePrize?.thirdPrize || 0) +
                (data.challengePrize?.platformFee || 0),
            details: data.prizeMoney?.details,
            firstPrizeCurrencyCode: data.prizeMoney?.currencyCode || 'USD',
            secondPrizeCurrencyCode: data.prizeMoney?.currencyCode || 'USD',
            thirdPrizeCurrencyCode: data.prizeMoney?.currencyCode || 'USD',

            virtualInternship: virtualInternship?.title!==undefined,
            virtualInternshipInput: virtualInternship?.description,

            microCredential: micorCred?.title!==undefined,
            microCredentialDetailsInput: micorCred?.description,

            potentialHiring: potentialHiring?.title!==undefined,
            potentialHiringDetailsInput: potentialHiring?.description,

            publicationOpportunity: publicationOpportunity?.title!==undefined,
            publicationOpportunityDetailsInput: publicationOpportunity?.description,

            equityOrRoyalties: equityRoyalties?.title!==undefined,
            equaityRoyaltiesInput: equityRoyalties?.description,

            fundingProposedSoultions: fundingProposedSoultions?.title!==undefined,
            fundingProposedSoultionsInput: fundingProposedSoultions?.description,

            others: others?.title!==undefined,
            othersInput: others?.description,
        });
        // Call toggleDiv to ensure the fields are enabled/disabled based on the checkbox value
        this.toggleDiv('prizeMoney');
        this.toggleFields('virtualInternship', this.virtualInternshipControls);
        this.toggleFields('microCredential', this.microCredentialControls);
        this.toggleFields('potentialHiring', this.potentialHiringControls);
        this.toggleFields('publicationOpportunity', this.publicationOpportunityControls);
        this.toggleFields('others', this.othersControls);
        this.toggleFields('fundingProposedSoultions', this.fundingProposedSoultionsControls);
        this.toggleFields('equityOrRoyalties', this.equaityRoyaltiesControls);

        // Ensure currency fields are enabled if prize money is checked
        if (data.prizeMoney && data.prizeMoney.firstPrize) {
            this.rewardForm.get('firstPrizeCurrencyCode')?.enable();
            this.rewardForm.get('secondPrizeCurrencyCode')?.enable();
            this.rewardForm.get('thirdPrizeCurrencyCode')?.enable();
        }
    }
    // populateForm(data: any) {
    //     this.rewardForm.patchValue({
    //         prizeMoney: data?.challengePrize?.firstPrize>0?true:false,
    //         firstPrize: data.prizeMoney?.firstPrize || null,
    //         secondPrize: data.prizeMoney?.secondPrize || null,
    //         thirdPrize: data.prizeMoney?.thirdPrize || null,
    //         commission: data.prizeMoney?.platformFee || null,
    //         totalAmount:
    //             (data.prizeMoney?.firstPrize || 0) +
    //             (data.prizeMoney?.secondPrize || 0) +
    //             (data.prizeMoney?.thirdPrize || 0) +
    //             (data.prizeMoney?.platformFee || 0),
    //         details: data.prizeMoney?.details,
    //         firstPrizeCurrencyCode: data.prizeMoney?.currencyCode || 'USD',
    //         secondPrizeCurrencyCode: data.prizeMoney?.currencyCode || 'USD',
    //         thirdPrizeCurrencyCode: data.prizeMoney?.currencyCode || 'USD',

    //         virtualInternship: !!data.nonMonetaryRewards.virtualInternship,
    //         virtualInternshipInput: this.populateincentive('Virtual Internship',data.challengeRewards),

    //         microCredential: !!data.nonMonetaryRewards.microCredential,
    //         microCredentialDetails: data.nonMonetaryRewards.microCredential,

    //         potentialHiring: !!data.nonMonetaryRewards.potentialHiring,
    //         potentialHiringDetails: data.nonMonetaryRewards.potentialHiring,

    //         publicationOpportunity:
    //             !!data.nonMonetaryRewards.publicationOpportunity,
    //         publicationOpportunityDetails:
    //             data.nonMonetaryRewards.publicationOpportunity,
    //     });

    //     this.toggleDiv('prizeMoney');
    //     this.toggleFields('virtualInternship', this.virtualInternshipControls);
    //     this.toggleFields('microCredential', this.microCredentialControls);
    //     this.toggleFields('potentialHiring', this.potentialHiringControls);
    //     this.toggleFields(
    //         'publicationOpportunity',
    //         this.publicationOpportunityControls
    //     );
    //     this.toggleFields('others', this.othersControls);
    //     this.toggleFields(
    //         'fundingProposedSoultions',
    //         this.fundingProposedSoultionsControls
    //     );
    //     this.toggleFields('equaityRoyalties', this.equaityRoyaltiesControls);

    //     // Ensure currency fields are enabled if prize money is checked
    //     if (data.prizeMoney && data.prizeMoney.firstPrize) {
    //         this.rewardForm.get('firstPrizeCurrencyCode')?.enable();
    //         this.rewardForm.get('secondPrizeCurrencyCode')?.enable();
    //         this.rewardForm.get('thirdPrizeCurrencyCode')?.enable();
    //     }
    // }

    // onSubmit() {
    //     this.rewardForm.markAllAsTouched();
    //     // Check if all fields are disabled
    //     const allDisabled = this.areAllSectionsDisabled();

    //     if (allDisabled) {
    //         this.alertService.errorPopup('All fields are disabled.');
    //         return;
    //     }

    //     if (this.rewardForm.valid) {
    //         const prizeRewards: PrizeRewardDto = this.buildRequestBody();

    //         this.store
    //             .select(selectChallengeId)
    //             .pipe(take(1))
    //             .subscribe((challengeId) => {
    //                 if (challengeId) {
    //                     this.store.dispatch(
    //                         updatePrizeRewards({
    //                             challengeId,
    //                             data: prizeRewards,
    //                         })
    //                     );
    //                      // Listen for any new errors after the API call
    //                      this.store
    //                      .pipe(
    //                          withLatestFrom(this.store.select(selectPrizeRewardsError)),
    //                          tap(([_, error]) => {
    //                              if (error) {
    //                                  this.validationMessageService.setServerErrors(
    //                                      this.rewardForm,
    //                                      error
    //                                  );
    //                              }
    //                          })
    //                      )
    //                      .subscribe();
    //                 }
    //             });
    //     }
    // }
    loadRewardForm() {
        const challengeId = this.challengeId ; // Replace with the actual challengeId

        this.challengeService
            .getPrizeRewards(challengeId)
            .subscribe({
                next: (data:any) => {
                    this.populateForm(data.data);
                },
                error: (error: HttpErrorResponse) => {
                    this.alertService.errorPopup(
                        error.error.message
                    );
                    // Additional error handling if needed
                    // this.alertService.errorPopup(error.error.message);
                },
            });
    }

    onSubmit() {
        this.rewardForm.markAllAsTouched();

        // Check if all fields are disabled
        const allDisabled = this.areAllSectionsDisabled();

        // if (allDisabled) {
        //     this.alertService.errorPopup('All fields are disabled.');
        //     return;
        // }

        if (this.rewardForm.valid) {
            // Clear existing server errors
            this.validationMessageService.clearServerErrors(this.rewardForm);

            // Build the request body
            const prizeRewards: PrizeRewardDto = this.buildRequestBody();
            const challengeId = this.route.snapshot.queryParams['id']; // Replace with the actual challengeId

            // Make the API call to update the prize rewards
            this.challengeService
                .updatePrizeRewards(challengeId, prizeRewards)
                .subscribe({
                    next: (response) => {
                        // Handle successful response
                        this.alertService.successPopup(response.message);
                        this.successEvent.emit();
                    },
                    error: (error: HttpErrorResponse) => {
                        // Handle error response
                        if (error.status === 422) {
                            const errors = error?.error?.errors;
                            if (errors) {
                                this.validationMessageService.setServerErrors(
                                    this.rewardForm,
                                    errors
                                );
                            }
                        } else {
                            this.alertService.errorPopup(error.error.message);
                        }
                    },
                });
        }
    }

    areAllSectionsDisabled(): boolean {
        const prizeMoneyDisabled = !this.rewardForm.get('prizeMoney')?.value;
        const otherSectionsDisabled =
            !this.rewardForm.get('virtualInternship')?.value &&
            !this.rewardForm.get('microCredential')?.value &&
            !this.rewardForm.get('potentialHiring')?.value &&
            !this.rewardForm.get('publicationOpportunity')?.value &&
            !this.rewardForm.get('equaityRoyalties')?.value &&
            !this.rewardForm.get('fundingProposedSoultions')?.value &&
            !this.rewardForm.get('others')?.value;

        return prizeMoneyDisabled && otherSectionsDisabled;
    }

    buildRequestBody(): PrizeRewardDto {
        const formValue = this.rewardForm.value;

        // Initialize the prizeMoney object
        let prizeMoney: PrizeMoneyDto | null = null;

        if (formValue.prizeMoney) {
            // Initialize the prizeMoney object
            prizeMoney = {};

            if (formValue.firstPrize) {
                prizeMoney.firstPrize = formValue.firstPrize;
            }
            if (formValue.secondPrize) {
                prizeMoney.secondPrize = formValue.secondPrize;
            }
            if (formValue.thirdPrize) {
                prizeMoney.thirdPrize = formValue.thirdPrize;
            }
            if (formValue.commission) {
                prizeMoney.platformFee = formValue.commission;
            }
            if (formValue.details) {
                prizeMoney.details = formValue.details;
            }
            if (formValue.firstPrizeCurrencyCode) {
                prizeMoney.currencyCode = formValue.firstPrizeCurrencyCode;
            }
        }

        const nonMonetaryRewards: NonMonetaryRewardDto = {};

        if (formValue.virtualInternship) {
            nonMonetaryRewards.virtualInternship =
                formValue.virtualInternshipInput;
        }
        if (formValue.microCredential) {
            nonMonetaryRewards.microCredential =
                formValue.microCredentialDetailsInput;
        }
        if (formValue.potentialHiring) {
            nonMonetaryRewards.potentialHiring =
                formValue.potentialHiringDetailsInput;
        }
        if (formValue.publicationOpportunity) {
            nonMonetaryRewards.publicationOpportunity =
                formValue.publicationOpportunityDetailsInput;
        }
        if (formValue.equityOrRoyalties) {
            nonMonetaryRewards.equityOrRoyalties =
                formValue.equaityRoyaltiesInput;
        }
        if (formValue.fundingProposedSoultions) {
            nonMonetaryRewards.fundingForProposedSolutions =
                formValue.fundingProposedSoultionsInput;
        }
        if (formValue.others) {
            nonMonetaryRewards.otherIncentives = formValue.othersInput;
        }

        return {
            prizeMoney,
            nonMonetaryRewards,
        };
    }

    toggleDiv(divName: string) {
        const control = this.rewardForm.get(divName);

        if (control) {
            const isChecked = control.value;

            // List of fields that correspond to each prize money section
            const controls = [
                'firstPrize',
                'secondPrize',
                'thirdPrize',
                'commission',
                'totalAmount',
                'details',
                'firstPrizeCurrencyCode',
                'secondPrizeCurrencyCode',
                'thirdPrizeCurrencyCode',
            ];

            controls.forEach((controlName) => {
                const formControl = this.rewardForm.get(controlName);

                if (formControl) {
                    if (isChecked) {
                        formControl.enable();
                    } else {
                        formControl.disable();
                        formControl.reset();
                    }
                }
            });
        }
    }

    toggleFields(controlName: string, fieldControls: string[]) {
        const isEnabled = this.rewardForm.get(controlName)?.value;

        fieldControls.forEach((field) => {
            const control = this.rewardForm.get(field);
            if (isEnabled) {
                control?.enable();
                control?.setValidators([Validators.required]);
            } else {
                control?.disable();
                control?.clearValidators();
                control?.setValue('');
            }
            control?.updateValueAndValidity();
        });
    }
}
