<div class="container">
    <form [formGroup]="challengeDetailsForm" (ngSubmit)="onSubmit()">
        <div class="row ">
            <div class="col-12">
                <div class="form-field-margin">
                    <label class="form-label fw-semibold">Challenge Objectives <span
                            style="color: #B21818;">*</span></label>
                    <small class="form-text text-muted info-msg" style="margin-bottom: 10px; display: block;">
                        Please list up to five objectives. If you have more than five, please list the top five of
                        highest
                        priority
                    </small>
                    <input type="text" class="form-control mb-2" formControlName="objective1"
                        placeholder="Enter objective 1">

                    <app-input-error-message [formData]="challengeDetailsForm" [controlName]="'objective1'">
                    </app-input-error-message>

                    <input type="text" class="form-control
                     mb-2" formControlName="objective2" placeholder="Enter objective 2">

                    <app-input-error-message [formData]="challengeDetailsForm" [controlName]="'objective2'">
                    </app-input-error-message>

                    <input type="text" class="form-control mb-2" formControlName="objective3"
                        placeholder="Enter objective 3">

                    <app-input-error-message [formData]="challengeDetailsForm" [controlName]="'objective3'">
                    </app-input-error-message>

                    <input type="text" class="form-control mb-2" formControlName="objective4"
                        placeholder="Enter objective 4">

                    <app-input-error-message [formData]="challengeDetailsForm" [controlName]="'objective4'">
                    </app-input-error-message>

                    <input type="text" class="form-control mb-2" formControlName="objective5"
                        placeholder="Enter objective 5">

                    <app-input-error-message [formData]="challengeDetailsForm" [controlName]="'objective5'">
                    </app-input-error-message>
                </div>
            </div>

            <div class="col-12 form-field-margin">
                <app-quill-editor label="Desired Outcomes"
                    infoText="Describe the specific, measurable results you hope to achieve through this challenge"
                    controlName="desiredOutcomes"
                    placeholder="Describe the specific, measurable results you hope to achieve through this challenge."
                    [parentForm]="challengeDetailsForm" [required]="true">
                </app-quill-editor>

                <app-input-error-message [formData]="challengeDetailsForm" [controlName]="'desiredOutcomes'">
                </app-input-error-message>
            </div>
        </div>

        <div class="col-12 form-field-margin">
            <app-quill-editor label="Solution Requirements"
                infoText="Outline any technical, practical, or ethical requirements that proposed solutions must meet."
                controlName="solutionRequirements"
                placeholder="Outline any technical, practical, or ethical requirements that proposed solutions must meet."
                [parentForm]="challengeDetailsForm" [required]="true">
            </app-quill-editor>
            <div>
                <app-input-error-message [formData]="challengeDetailsForm" [controlName]="'solutionRequirements'">
                </app-input-error-message>
            </div>

        </div>

        <div class="col-12 form-field-margin">
            <app-quill-editor label="Constraints and
                    Limitations" infoText=" Identify any restrictions or boundaries that innovators should be aware of (e.g., budget,
                        technology, and regulations)." controlName="constraintsAndLimitations"
                placeholder="Identify any restrictions or boundaries that innovators should be aware of..."
                [parentForm]="challengeDetailsForm">
            </app-quill-editor>
            <div>
                <app-input-error-message [formData]="challengeDetailsForm" [controlName]="'constraintsAndLimitations'">
                </app-input-error-message>
            </div>

        </div>

    </form>

</div>
