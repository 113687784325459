<div class="container">
    <div class="row" style="margin-top: 25px;">
        <div class="col-md-12">
            <a routerLink="/">
                <img src="/logo.svg" alt="Main Logo" height="40px" />
            </a>
        </div>
        <div class="col-md-12 border-bottom">
            <h2 class="text-center">{{ title }}</h2>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-6 pe-5">
            <form class="row" [formGroup]="signupForm" (ngSubmit)="onSubmit()">
                <div class="col-md-12">
                    <p class="form-label fw-semibold">I WANT TO JOIN AS...</p>

                    <div class="row mt-3">
                        @for (role of roles; track $index) {
                        <div class="col-md-4 mb-3">
                            <div class="form-check p-0">
                                <input class="form-check-input d-none" type="radio" formControlName="role"
                                    [value]="role.slug" id="inputRole-{{ $index }}"
                                    [checked]="role.name === activeRole.name" (change)="onRoleChange(role)" />
                                <label class="form-check-label border border-1 rounded w-100 text-center py-2 {{
                                        role.slug
                                    }}" for="inputRole-{{ $index }}">
                                    {{ role.name }}
                                </label>
                            </div>
                        </div>
                        }
                    </div>

                    <small class="text-danger">
                        {{
                        validationMessageService.getMessage(
                        signupForm,
                        "role"
                        )
                        }}
                    </small>
                </div>
                <hr />
                <div class="col-md-6 mb-3">
                    <label for="inputFirstName" class="form-label fw-semibold">First Name<span
                            style="color: #B21818;">*</span></label>
                    <input type="text" class="form-control" id="inputFirstName" formControlName="firstName" />

                    <small class="text-danger">
                        {{
                        validationMessageService.getMessage(
                        signupForm,
                        "firstName"
                        )
                        }}
                    </small>
                </div>
                <div class="col-md-6 mb-3">
                    <label for="inputLastName" class="form-label fw-semibold">Last Name<span
                            style="color: #B21818;">*</span></label>
                    <input type="text" class="form-control" id="inputLastName" formControlName="lastName" />

                    <small class="text-danger">
                        {{
                        validationMessageService.getMessage(
                        signupForm,
                        "lastName"
                        )
                        }}
                    </small>
                </div>


                <!-- Conditional Display of Email and Password -->
                <!-- *ngIf="showEmailAndPassword" -->
                <div *ngIf="showEmailAndPassword">
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label for="inputEmail" class="form-label fw-semibold">Email<span
                                    style="color: #B21818;">*</span></label>
                            <input type="email" class="form-control" id="inputEmail" formControlName="email" />

                            <small class="text-danger">
                                {{
                                validationMessageService.getMessage(
                                signupForm,
                                "email"
                                )
                                }}
                            </small>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="inputPassword" class="form-label fw-semibold">Password<span
                                    style="color: #B21818;">*</span></label>
                            <app-password-input id="inputPassword" formControlName="password"></app-password-input>

                            <small class="text-danger">
                                {{
                                validationMessageService.getMessage(
                                signupForm,
                                "password"
                                )
                                }}
                            </small>
                        </div>
                    </div>

                </div>
                <!-- Conditional Fields for Organization or Startup -->
                <div *ngIf="activeRole.slug === 'Organization' || activeRole.slug === 'Startup'" class="col-md-12 mb-3">

                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label for="inputOrganizationName" class="form-label fw-semibold">Organization Name<span
                                    style="color: #B21818;">*</span></label>
                            <input type="text" class="form-control" id="inputOrganizationName"
                                formControlName="organizationName" />
                            <small class="text-danger">
                                {{
                                validationMessageService.getMessage(signupForm, "organizationName")
                                }}
                            </small>
                        </div>

                        <div class="col-md-6 mb-3">
                            <label for="inputOrganizationUrl" class="form-label fw-semibold">Website Url<span
                                    style="color: #B21818;">*</span></label>
                            <input type="text" class="form-control" id="inputOrganizationUrl"
                                formControlName="organizationUrl" />
                            <small class="text-danger">
                                {{
                                validationMessageService.getMessage(signupForm, "organizationUrl")
                                }}
                            </small>
                        </div>
                    </div>

                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="contactPersonCheckbox"
                            (change)="onCheckboxChange()" [checked]="useSameContactDetails" />
                        <label class="form-check-label" for="contactPersonCheckbox">
                            Contact person details are the same as above
                        </label>
                    </div>
                </div>

                <div *ngIf="!useSameContactDetails && (activeRole.slug === 'Organization' || activeRole.slug === 'Startup')"
                    class="col-md-6 mb-3">
                    <label for="contactFirstName" class="form-label fw-semibold">First Name<span
                            style="color: #B21818;">*</span></label>
                    <input type="text" class="form-control" id="contactFirstName" formControlName="contactFirstName" />
                    <small class="text-danger">
                        {{
                        validationMessageService.getMessage(signupForm, 'contactFirstName')
                        }}
                    </small>
                </div>
                <div *ngIf="!useSameContactDetails && (activeRole.slug === 'Organization' || activeRole.slug === 'Startup')"
                    class="col-md-6 mb-3">
                    <label for="contactLastName" class="form-label fw-semibold">Last Name<span
                            style="color: #B21818;">*</span></label>
                    <input type="text" class="form-control" id="contactLastName" formControlName="contactLastName" />
                    <small class="text-danger">
                        {{
                        validationMessageService.getMessage(signupForm, 'contactLastName')
                        }}
                    </small>
                </div>
                <div *ngIf="!useSameContactDetails && (activeRole.slug === 'Organization' || activeRole.slug === 'Startup')"
                    class="col-md-6 mb-3">
                    <label for="contactEmail" class="form-label fw-semibold">Contact Email<span
                            style="color: #B21818;">*</span></label>
                    <input type="email" class="form-control" id="contactEmail" formControlName="contactEmail" />
                    <small class="text-danger">
                        {{
                        validationMessageService.getMessage(signupForm, 'contactEmail')
                        }}
                    </small>
                </div>
                <div class="col-md-12" style="margin-top: 54px;">
                    <button type="submit" class="btn btn-gradient btn-bg-blue-gradient custom-btn">
                        {{ isSSOLogin ? 'Select Role' : 'SIGN UP' }}
                        <span class="arrow-btn-wrapper"> <img src="/images/home/right-arrow.svg" alt="Arrow" height="20"
                                class="arrow-icon1"></span>
                    </button>
                </div>
            </form>

            <p class="mt-3 fw-semibold fs-15px" *ngIf="!isSSOLogin">
                Already have an account? Login
                <a routerLink="/login">here</a>
            </p>
        </div>
        <div class="col-md-6 p-5" style="background-color: #fafafa">
            <img src="/images/signup/{{ activeRole.path }}" class="img-fluid" alt="Role Based" />
            <p class="mt-4">
                If you sign up as an
                {{ activeRole.slug.toLowerCase() }} then....
            </p>
        </div>
    </div>
</div>