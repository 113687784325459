import { SolutionReferences } from '../../shared/input-solution-references/types/input-solution-references-types';

export enum SolutionForm4FrameworkType {
    gbn = 'GBN',
    fourSteps = 'Four Step',
}

export interface SolutionForm4SolutionFrameworkType {
    id: number;
    type: SolutionForm4FrameworkType;
    scenarioA: string;
    scenarioB: string;
    scenarioC: string;
    scenarioD: string;
    activated: boolean;
    createdAt: string;
    updatedAt: string;
    solutionReferences: SolutionReferences;
    weakSignal1: SolutionForm4SolutionFrameworkWeakSignalType;
    weakSignal2: SolutionForm4SolutionFrameworkWeakSignalType;
}

export interface SolutionForm4SolutionFrameworkWeakSignalType {
    id: number;
}

export interface SolutionForm4FrameworkRequestType {
    id?: number;
    type: SolutionForm4FrameworkType;
    weakSignal1: number;
    weakSignal2: number;
    scenarioA: string;
    scenarioB: string;
    scenarioC: string;
    scenarioD: string;
    solutionReferences: SolutionReferences;
}
