import { Component } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { AwsS3ClientService } from '../../core/services/aws-s3-client.service';
import { map, Observable, take } from 'rxjs';
import { AuthService } from '../../core/services/apis/auth.service';
import { ProfileService } from '../../core/services/apis/profile.service';
import { updateUser } from '../../core/store/auth/auth.actions';
import { Store } from '@ngrx/store';
import { AuthState } from '../../core/store/auth/auth.state';
import { InputErrorMessageComponent } from '../../shared/input-error-message/input-error-message.component';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { CommonModule } from '@angular/common';
import { EditUserProfileDto } from '../../core/Model/edit-profile.dto';
import { UserRoleSlug } from '../../core/enum/UserRole.enum';

@Component({
    selector: 'app-my-profile',
    standalone: true,
    imports: [ReactiveFormsModule, InputErrorMessageComponent, CommonModule],
    templateUrl: './my-profile.component.html',
    styleUrl: './my-profile.component.scss',
})
export class MyProfileComponent {
    profileForm: FormGroup;
    userEmail: string = '';
    user$: Observable<any>;
    contactEmail: string = '';
    showUserDetails: boolean = false;
    showOrganizationDetails: boolean = false;
    showContactDetails: boolean = false;
    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        protected validationMessageService: ValidationMessageService,
        private awsS3ClientService: AwsS3ClientService,
        private profileService: ProfileService,
        private store: Store<{ auth: AuthState }>,
        private alertService: SweetAlertService
    ) {
        this.user$ = this.authService.getUser();
        this.profileForm = this.fb.group({
            firstName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            lastName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            profileImageName: [''],
            organizationUrl: [
                '',
                Validators.compose([
                    Validators.required,
                ]),
            ],
            organizationName: [
                '',
                Validators.compose([
                    Validators.required,
                ]),
            ],
            contactFirstName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            contactLastName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            contactEmail: ['',  Validators.compose([
                Validators.required,
                Validators.email,
                ,
            ])],
        });

        this.user$.subscribe((user) => {
            if (user) {
                this.profileForm.patchValue({
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    organizationUrl: user?.organization?.url || '',
                    organizationName: user?.organization?.title || '',
                    contactFirstName:
                        user?.organization?.contactFirstName || '',
                    contactLastName: user?.organization?.contactLastName || '',
                    contactEmail: user?.organization?.contactEmail || '',
                });

                this.userEmail = user.email;
                // this.contactEmail = user.organization.contactEmail;

                // Determine which section to display based on the user role
                const userRoleSlug = user.roles?.[0]?.slug || '';

                if (
                    userRoleSlug === 'INTERN' ||
                    userRoleSlug === 'SUPER_ADMIN'
                ) {
                    this.showUserDetails = true;
                    this.showOrganizationDetails = false;
                } else if (
                    userRoleSlug === 'ORGANIZATION' ||
                    UserRoleSlug.SUPERORG === 'SUPER_ORGANIZATION'
                ) {
                    // this.contactEmail = user?.organization?.contactEmail;
                    this.showUserDetails = true;
                    this.showOrganizationDetails = true;
                } else {
                    // console.erro('Unrecognized role:', userRoleSlug);
                }
            }
        });
    }

    ngOnInit() {
        this.user$.subscribe((user) => {
            if (user) {
                const userRoleSlug = user.roles?.[0]?.slug || '';

                if (
                    userRoleSlug === 'INTERN' ||
                    userRoleSlug === 'SUPER_ADMIN'
                ) {
                    this.profileForm.get('organizationUrl')?.clearValidators();
                    this.profileForm.get('organizationName')?.clearValidators();
                    this.profileForm.get('contactFirstName')?.clearValidators();
                    this.profileForm.get('contactLastName')?.clearValidators();
                    this.profileForm.get('contactEmail')?.clearValidators();
                } else if (
                    userRoleSlug === 'ORGANIZATION' ||
                    userRoleSlug === 'SUPER_ORGANIZATION'
                ) {
                    this.profileForm
                        .get('organizationUrl')
                        ?.setValidators([
                            Validators.required,
                            Validators.maxLength(50),
                        ]);
                    this.profileForm
                        .get('organizationName')
                        ?.setValidators([
                            Validators.required,
                            Validators.maxLength(50),
                        ]);
                    this.profileForm
                        .get('contactFirstName')
                        ?.setValidators([Validators.required]);
                    this.profileForm
                        .get('contactLastName')
                        ?.setValidators([Validators.required]);
                }
                this.profileForm.updateValueAndValidity(); // Update form validation
            }
        });
    }

    onSubmit() {
        this.profileForm.markAllAsTouched();

        if (this.profileForm.valid) {
            this.user$.pipe(take(1)).subscribe((user) => {
                const userRoleSlug = user?.roles?.[0]?.slug || '';

                let updateData: EditUserProfileDto = {
                    firstName: this.profileForm.value.firstName,
                    lastName: this.profileForm.value.lastName,
                    profileImageName: this.profileForm.value.profileImageName,
                    fileType: this.profileForm.value.fileType,
                };

                if (
                    userRoleSlug === 'ORGANIZATION' ||
                    userRoleSlug === 'SUPER_ORGANIZATION'
                ) {
                    updateData = {
                        ...updateData,
                        organizationName:
                            this.profileForm.value.organizationName,
                        organizationUrl: this.profileForm.value.organizationUrl,
                        contactFirstName:
                            this.profileForm.value.contactFirstName,
                        contactLastName: this.profileForm.value.contactLastName,
                        contactEmail: this.profileForm.value.contactEmail
                    };
                }

                this.profileService.update(updateData).subscribe({
                    next: (response) => {
                        this.alertService.successPopup(response.message);
                        this.store.dispatch(
                            updateUser({ user: response.data })
                        );
                    },
                    error: (error) => {
                        if (error.status == 422) {
                            const errors = error?.error?.errors;
                            if (errors) {
                                this.validationMessageService.setServerErrors(
                                    this.profileForm,
                                    errors
                                );
                            }
                        } else {
                            this.alertService.errorPopup(error.error.message);
                        }
                    },
                });
            });
        }
    }

    async onProfileImgChange(event: any): Promise<void> {
        if (event.target.files.length) {
            const file = event.target.files[0];

            try {
                const imageUrl = await this.awsS3ClientService.upload(file);

                // Update the profileImage form control with the URL
                this.profileForm.patchValue({
                    profileImageName: imageUrl,
                });
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    }
}
