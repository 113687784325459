import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginComponent } from './pages/login/login.component';
import { MainLayoutComponent } from './layouts/main/main.component';
import { AuthLayoutComponent } from './layouts/auth/auth.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { LegalComponent } from './pages/legal/legal.component';
import { InternComponent } from './pages/intern/intern.component';
import { OrganizationsComponent } from './pages/organizations/organizations.component';
import { InnovatorsComponent } from './pages/innovators/innovators.component';
import { MentorsComponent } from './pages/mentors/mentors.component';
import { StartupsComponent } from './pages/startups/startups.component';
import { SignupComponent } from './pages/signup/signup.component';
import { EmailVerificationComponent } from './pages/email-verification/email-verification.component';
import { GuestGuardService } from './core/guard/guest-guard.service';
import { AuthGuardService } from './core/guard/auth-guard.service';
import { LoginWithGoogleComponent } from './pages/login-with-google/login-with-google.component';
import { LoginWithLinkedinComponent } from './pages/login-with-linkedin/login-with-linkedin.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { emailVerificationResolver } from './core/resolvers/email-verification.resolver';
import { ForgotPasswordEmailComponent } from './pages/forgot-password-email/forgot-password-email.component';
import { TheChallengesComponent } from './pages/challenges/the-challenges/the-challenges.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { RequestLaunchChallengeComponent } from './pages/request-launch-challenge/request-launch-challenge.component';
import { LaunchAChallengeComponent } from './pages/launch-a-challenge/launch-a-challenge.component';
import { ChallengeListingComponent } from './pages/challenge-listing/challenge-listing.component';
import { FiltersComponent } from './pages/filters/filters.component';
import { ChallengeListComponent } from './pages/challenge-list/challenge-list.component';
import { ChallengeDetailsComponent } from './pages/challenge-details-components/challenge-details/challenge-details.component';
import { ViewPostInterestFormComponent } from './view-post-interest-form/view-post-interest-form.component';
import { UserRole, UserRoleSlug } from './core/enum/UserRole.enum';
import { SolverFlowComponent } from './solver-forms-module/solver-flow/solver-flow.component';
import { SolverFlowStep3WeakSignalComponent } from './solver-forms-module/solver-flow-step-3-weak-signal/solver-flow-step-3-weak-signal.component';
import { ProceedToPaymentComponent } from './proceed-to-payment/proceed-to-payment.component';
import { PaymentStatusComponentComponent } from './payment-status-component/payment-status-component.component';
import { GuidelinesInformationFormComponent } from './shared/guidelines-information-form/guidelines-information-form.component';
import { GuidelinesComponentComponent } from './guidelines-component/guidelines-component.component';
import { RegularSolverFormComponent } from './solver-forms-module/regular-solver-form/regular-solver-form.component';
import { EvaluationSoluionsComponent } from './evaluation-soluions/evaluation-soluions.component';

export const routes: Routes = [
    // {
    //     path: '',
    //     component: DummyComponent, // This component will be used to capture the query parameters
    //     outlet: 'queryParamsOutlet',
    //   }
    // ,
    {
        path: '',
        component: MainLayoutComponent,
        children: [
            {
                path: '',
                title: 'Home',
                component: HomeComponent,
            },
            {
                path: 'about-us',
                title: 'About Us',
                component: AboutComponent,
            },
            {
                path: 'contact-us',
                title: 'Contact Us',
                component: ContactComponent,
            },
            {
                path: 'terms-conditions',
                title: 'Terms & Conditions',
                component: TermsConditionsComponent,
            },
            {
                path: 'legal',
                title: 'Legal',
                component: LegalComponent,
            },
            {
                path: 'intern',
                title: 'Intern',
                component: InternComponent,
            },
            {
                path: 'filters',
                title: 'Filters',
                component: FiltersComponent,
            },
            {
                path: 'organization',
                title: 'Organization',
                component: OrganizationsComponent,
            },
            {
                path: 'innovator',
                title: 'Innovator',
                component: InnovatorsComponent,
            },
            {
                path: 'mentors',
                title: 'Mentors',
                component: MentorsComponent,
            },
            {
                path: 'startups',
                title: 'Startups',
                component: StartupsComponent,
            },
            {
                path: 'solver',
                title: 'solver',
                component: SolverFlowStep3WeakSignalComponent,
            },
            {
                path: 'email-verification',
                title: 'Email Verification',
                component: EmailVerificationComponent,
                resolve: { data: emailVerificationResolver },
            },
            {
                path: 'my-profile',
                title: 'My Profile',
                component: MyProfileComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'the-challenges',
                title: 'The Challenges',
                component: TheChallengesComponent,
                canActivate: [AuthGuardService],
                // data: { roles: ['Admin'] },
            },

            {
                path: 'launch-a-challenge',
                title: 'Launch A Challenge',
                component: LaunchAChallengeComponent,
                data: {
                    roles: [UserRoleSlug.SUPERORG, UserRoleSlug.SUPERADMIN],
                },
                canActivate: [AuthGuardService],
                // data: { roles: ['Admin'] },
            },
            {
                path: 'proceed-to-payment/:id',
                title: 'Payment',
                component: ProceedToPaymentComponent,

                canActivate: [AuthGuardService],
                // data: { roles: ['Admin'] },
            },
            {
                path: 'return-status',
                title: 'Payment | Success',
                component: PaymentStatusComponentComponent,

                canActivate: [AuthGuardService],
                // data: { roles: ['Admin'] },
            },
            {
                path: 'request-a-challenge',
                title: 'Request A Challenge',
                component: RequestLaunchChallengeComponent,
                data: {
                    roles: [UserRoleSlug.INTERN, UserRoleSlug.ORGANIZATION],
                },
                canActivate: [AuthGuardService],
                // data: { roles: ['Admin'] },
            },
            {
                path: 'challenges-listing',
                title: 'Challenges Listing',
                component: ChallengeListingComponent,
                canActivate: [AuthGuardService],
                data: { roles: ['Super Admin'] },
            },
            {
                path: 'solver-flow/:challengeId',
                title: 'Solver Flow',
                component: SolverFlowComponent,
            },
            {
                path: 'regular-solver-flow',
                title: 'Solver Flow',
                component: RegularSolverFormComponent,
            },
            {
                path: 'evaluation/solutions',
                title: 'Overview | Evaludation-Solutions',
                component: EvaluationSoluionsComponent,
            },

            {
                path: 'solver-flow-guidelines/:challengeId',
                title: 'Solver Flow | Guidelines',
                component: GuidelinesComponentComponent,
            },
            {
                path: 'challenges-list',
                title: 'Challenges List',
                component: ChallengeListComponent,
            },
            {
                path: 'interests/view/:id',
                title: 'Interest View',
                component: ViewPostInterestFormComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'overview/challenge-details/:id',
                title: 'Challenges Details',
                component: ChallengeDetailsComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'challenge-details/:id',
                title: 'Challenges Details',
                component: ChallengeDetailsComponent,
            },
            {
                path: 'preview-challenge-details',
                title: 'Challenges Details',
                component: ChallengeDetailsComponent,
                canActivate: [AuthGuardService],
            },

            {
                path: 'request-launch-challenge',
                title: 'Request Launch Challenge',
                component: RequestLaunchChallengeComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'launch-a-challenge/edit',
                title: 'Launch A Challenge',
                component: LaunchAChallengeComponent,
                canActivate: [AuthGuardService],
            },

            {
                path: 'change-password',
                title: 'Change Password',
                component: ChangePasswordComponent,
                canActivate: [AuthGuardService],
            },
        ],
    },

    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'login',
                title: 'Login',
                component: LoginComponent,
                canActivate: [GuestGuardService],
            },
            {
                path: 'signup',
                title: 'Sign Up',
                component: SignupComponent,
                canActivate: [GuestGuardService],
            },
            {
                path: 'signup/sso',
                title: 'Sign Up via SSO',
                component: SignupComponent,
                canActivate: [AuthGuardService], // Guard for authenticated users
            },
            {
                path: 'auth/google/redirect',
                title: 'login google',
                component: LoginWithGoogleComponent,
                canActivate: [GuestGuardService],
            },
            {
                path: 'auth/linkedin/redirect',
                title: 'login linkedin',
                component: LoginWithLinkedinComponent,
                canActivate: [GuestGuardService],
            },
            {
                path: 'reset-password',
                title: 'Reset Password',
                component: ResetPasswordComponent,
                canActivate: [GuestGuardService],
            },
            {
                path: 'forgot-password',
                title: 'Forgot Password',
                component: ForgotPasswordEmailComponent,
                canActivate: [GuestGuardService],
            },
        ],
    },

    {
        path: '',
        component: MainLayoutComponent,
        children: [{ path: '**', component: NotFoundComponent }],
    },
];
