import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthState } from '../store/auth/auth.state';
import { JwtService } from '../services/jwt.service';

@Injectable({
    providedIn: 'root',
})
export class GuestGuardService implements CanActivate {
    constructor(
        private router: Router,
        private store: Store<{ auth: AuthState }>,
        private jwtService: JwtService
    ) {}

    canActivate(): Observable<boolean> {
        return this.store.select('auth').pipe(
            map((authState) => {
                if (!this.jwtService.isTokenExpired(authState.token ?? '')) {
                    this.router.navigate(['/']);
                    return false;
                }
                return true;
            })
        );
    }
}
