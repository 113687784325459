<div>
    <!-- Overview Page -->
                 <div class="overiew-wrapper">
                     <h6 class="section-title">Judging Process</h6>
                     <p class="section-desc" [innerHTML]="challenge?.metaData?.judgingProcess" >
                     </p>
                     <h6 class="section-title">Evaluation Criteria</h6>
                     <p class="section-desc" [innerHTML]="challenge?.metaData?.evaluationCriteria" >
                        
                     </p>
                     <h6 class="section-title">Judges and Expertise</h6>
                     <p class="section-desc" [innerHTML]="challenge?.metaData?.judgesAndExpertise" >
                     </p>
                   
                 </div>
 </div>
 