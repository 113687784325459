<div class="row">
    <h3 class="heading-mon-22">
        PRIORITIZE NEAR TERM TASKS FOR IMMEDIATE ACTION
    </h3>
    <div class="col-md-11">
        <p class="info-msg">Drag and drop to change priority</p>
    </div>
    <div class="col-md-1">
        <p style="font-size: 15px; font-weight: 500">Priority</p>
    </div>
    <div class="col-md-11" style="margin-bottom: 60px">
        <div
            cdkDropList
            class="prority-list"
            (cdkDropListDropped)="dropNearTermActions($event)"
        >
            @for (action of nearTermActions; track $index) {
            <div class="example-box" cdkDrag>
                <img src="/images/solver-flow/dragable.svg" />
                <!-- <div class="example-custom-placeholder" *cdkDragPlaceholder></div> -->
                <div class="title">{{action.title}}</div>
            </div>
            }
        </div>
    </div>
    <div class="col-md-1">
        @for (action of nearTermActions; track $index) {
        <span class="priority-no">{{$index + 1}}</span>
        }
    </div>
</div>
<div class="row">
    <h3 class="heading-mon-22">
        PRIORITIZE MEDIUM TERM TASKS FOR IMMEDIATE ACTION
    </h3>
    <div class="col-md-11">
        <p class="info-msg">Drag and drop to change priority</p>
    </div>
    <div class="col-md-1">
        <p style="font-size: 15px; font-weight: 500">Priority</p>
    </div>
    <div class="col-md-11" style="margin-bottom: 60px">
        <div
            cdkDropList
            class="prority-list"
            (cdkDropListDropped)="dropMediumTermActions($event)"
        >
            @for (action of mediumTermActions; track $index) {
            <div class="example-box" cdkDrag>
                <img src="/images/solver-flow/dragable.svg" />
                <!-- <div class="example-custom-placeholder" *cdkDragPlaceholder></div> -->
                <div class="title">{{action.title}}</div>
            </div>
            }
        </div>
    </div>
    <div class="col-md-1">
        @for (action of mediumTermActions; track $index) {
        <span class="priority-no">{{$index + 1}}</span>
        }
    </div>
</div>
<div class="row">
    <h3 class="heading-mon-22">
        PRIORITIZE LONG TERM TASKS FOR IMMEDIATE ACTION
    </h3>
    <div class="col-md-11">
        <p class="info-msg">Drag and drop to change priority</p>
    </div>
    <div class="col-md-1">
        <p style="font-size: 15px; font-weight: 500">Priority</p>
    </div>
    <div class="col-md-11">
        <div
            cdkDropList
            class="prority-list"
            (cdkDropListDropped)="dropLongTermActions($event)"
        >
            @for (action of longTermActions; track $index) {
            <div class="example-box" cdkDrag>
                <img src="/images/solver-flow/dragable.svg" />
                <!-- <div class="example-custom-placeholder" *cdkDragPlaceholder></div> -->
                <div class="title">{{action.title}}</div>
            </div>
            }
        </div>
    </div>
    <div class="col-md-1">
        @for (action of longTermActions; track $index) {
        <span class="priority-no">{{$index + 1}}</span>
        }
    </div>
</div>

<div class="solver-document-url-ai-wrapper">
    <app-input-solution-references
        [formGroup]="formGroup"
        [solutionReferences]="solutionReferences"
    >
    </app-input-solution-references>
</div>
