import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class ValidationMessageService {
    constructor() {}

    private messages: { [key: string]: string } = {
        pattern: ':attribute is invalid',
        required: 'The :attribute field is required',
        noWhitespace:
            'The :attribute field must not contain whitespace at start or end',
        email: 'Please enter a valid email address',
        minlength: 'The minimum length of :attribute must be :requiredLength.',
        maxlength: 'The maximum length of :attribute must be :requiredLength.',
        minArrayItems:
            'The minimum number of :attribute must be :requiredLength.',
        maxArrayItems:
            'The maximum number of :attribute must be :requiredLength.',
        duplicateArrayItems: 'Duplicate items are not allowed',
        // Add other validators as needed
    };

    getMessage(form: FormGroup, fieldName: string): string {
        if (!this.isInvalidAndTouched(form, fieldName)) {
            return '';
        }

        const control = form?.get(fieldName);
        if (!control) {
            return '';
        }

        const formattedFieldName = this.formatFieldName(fieldName);
        return this.getErrorMessages(control, formattedFieldName).join(', ');
    }

    private formatFieldName(fieldName: string): string {
        return fieldName.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
    }

    private getErrorMessages(
        control: AbstractControl,
        fieldName: string
    ): string[] {
        const messages = [];
        for (const errorName in control.errors) {
            const errorMessage = this.getErrorMessage(
                errorName,
                control.errors[errorName],
                fieldName
            );
            if (errorMessage) {
                messages.push(errorMessage);
            }
        }

        return messages;
    }

    private getErrorMessage(
        errorName: string,
        error: any,
        fieldName: string
    ): string | null {
        if (errorName === 'server') {
            return error;
        }

        const requiredLength = error?.requiredLength;
        return (
            this.messages?.[errorName]
                ?.replace(':attribute', fieldName)
                ?.replace(':requiredLength', requiredLength) || null
        );
    }

    setServerErrors(form: FormGroup, errors: { [key: string]: string[] }) {
        for (const field in errors) {
            const control = form.get(field);
            if (control) control.setErrors({ server: errors[field] });
        }
    }

    clearServerErrors(form: FormGroup) {
        Object.keys(form.controls).forEach((key) => {
            const control = form.get(key);
            if (control) {
                control.setErrors(null);
                control.updateValueAndValidity({ onlySelf: true });
            }
        });
    }

    // Helper method to check if a control is invalid and has been touched
    isInvalidAndTouched(form: FormGroup, fieldName: string): boolean {
        const control = form?.get(fieldName);
        return (
            (control?.invalid && (control?.dirty || control?.touched)) ?? true
        );
    }
}
export function wordCountValidator(maxWords: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value as string;
        if (value) {
            const wordCount = value.trim().split(/\s+/).length;
            // console.log(`Word Count: ${wordCount}`);
            return wordCount > maxWords ? { wordCount: { value } } : null;
        }
        return null;
    };
}
