import { Component, inject, Input } from '@angular/core';
import { ChallengeService } from '../../../core/services/apis/challenge.service';
import { GenerateSignedUrlRequest } from '../../../core/Model/generateSignedUrlRequest';
import { S3FolderNames } from '../../../core/enum/S3FolderNames.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-challenge-details-resources',
  standalone: true,
  imports: [],
  templateUrl: './challenge-details-resources.component.html',
  styleUrl: './challenge-details-resources.component.scss'
})
export class ChallengeDetailsResourcesComponent {
  @Input() resources = '';
  @Input() challenge:any = {};
  challengeService: ChallengeService = inject(ChallengeService);
  router:Router=inject(Router)

  // downloadResource(file: any): void {
  //   let fileObject:GenerateSignedUrlRequest={
  //     fileName:'',

  //     folderPath  : ''
  //   }
  //   fileObject.folderPath=S3FolderNames.CHALLENGE_RESOURCE
  //   fileObject.fileName = file; // Assuming file object has a 'name' property

  //   this.challengeService.downloadResourceFile(fileObject).subscribe((response) => {
  //     const link = document.createElement('a');
  //     link.href = response.signedUrl;
  //     link.download = 'Resource-file';
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }, (error) => {
  //   }


  // )
  // }
  handleURLRedirection(url:string){
    if (url.startsWith('http')) {
      window.open(url,'_blank')

    } else {
      window.open('//'+url,'_blank')

    }
  }
  downloadResource(fileName: any): void {
    let fileObject: GenerateSignedUrlRequest = {
      fileName: '',
      folderPath: ''
    };
    fileObject.folderPath = S3FolderNames.CHALLENGE_RESOURCE;
    fileObject.fileName = fileName;

    this.challengeService.downloadResourceFile(fileObject).subscribe(
      (response) => {
        const link = document.createElement('a');
        link.href = response.signedUrl;
        link.download = fileObject.fileName;

        // Set an additional attribute to force the download
        link.setAttribute('download', fileObject.fileName);
        link.setAttribute('target', '_blank'); // This will open in a new tab instead of replacing the current tab
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      (error) => {
        console.error(error, 'error from downloadResourceFile');
      }
    );
  }

}
