import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-benefits-section',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './benefits-section.component.html',
    styleUrl: './benefits-section.component.scss',
})
export class BenefitsSectionComponent {
    @Input() title: string = '';
    @Input() description: string = '';
    @Input() additionalLine: string =
        'The benefits of social impact experience and Strategic Futures skills are:';
    @Input() points: string[] = [];
    @Input() bulletColor: string = '#000';
    @Input() lightStrapColor: string = '#99C7E6';
    @Input() darkStrapColor: string = '#5CA6D7';
    @Input() buttonBgColor: string = '#FF7C09';
    @Input() buttonText: string = 'Button Text';
    @Input() marginZero: number | undefined;
    @Input() buttonLink: string = '#';
    @Input() imageSrc: string = '';
    @Input() arrowIconSrc: string | undefined;
}
