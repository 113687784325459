import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidationMessageService } from '../../core/services/validation-message.service';

@Component({
    selector: 'app-input-error-message',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './input-error-message.component.html',
    styleUrl: './input-error-message.component.scss',
})
export class InputErrorMessageComponent {
    @Input() formData!: FormGroup;
    @Input() controlName!: string;

    constructor(protected validationMessageService: ValidationMessageService) {}
}
