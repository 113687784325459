import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-banner-section',
  standalone: true,
  imports: [ CommonModule, RouterModule],
  templateUrl: './banner-section.component.html',
  styleUrl: './banner-section.component.scss'
})
export class BannerSectionComponent {
  @Input() imageSrc: string | undefined;
  @Input() title: string | undefined;
  @Input() description: string | undefined;
  @Input() buttonText: string | undefined;
  @Input() buttonLink: string | undefined;
  @Input() lightColor: string = '';
  @Input() darkColor: string = '';
  @Input() buttonBgColor: string = '';
  @Input() iconColor: string = '';
  @Input() overlayText: string = '';
  @Input() overlayBgColor: string = '';
  @Input() overlayTextColor: string = '';
  @Input() overlayWidth: string = '';
  @Input() overlayHeight: string = '';
  @Input() arrowIconSrc: string | undefined;
}
