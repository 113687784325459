<app-banner-section [imageSrc]="'/images/organization/organization-main.png'"
    [title]="'Launch a Challenge and Unlock Innovation'"
    [description]="'Are you ready to tap into the collective intelligence of innovators worldwide? Follow these simple steps to launch a challenge on our open innovation platform and start solving your toughest business, economic, social problems or pain points.'"
    [buttonText]="'JUMP INTO THE CHALLENGES'" [buttonLink]="'#'" [lightColor]="'#99C7E6'" [darkColor]="'#5CA6D7'"
    [buttonBgColor]="'linear-gradient(270deg, #0073C0, #004877)'" [iconColor]="'#FF7C09'"
    [overlayText]="'Organizations'" [overlayBgColor]="'rgba(0, 0, 0, 0.5)'" [overlayTextColor]="'#FFFFFF'"
    [overlayWidth]="'280px'" [overlayHeight]="'70px'" [arrowIconSrc]="'/images/home/right-arrow.svg'">
</app-banner-section>
<div class="container">
    <h2 class="text-center" style="margin-bottom: 20px;">How it works?</h2>
    <div class="steps-container col-md-12 mx-auto fs-15px">
        <div class="step ">
            <div class="row">
                <div class="col-md-10">
                    <div class="step-number fs-12px">STEP 1</div>
                </div>
                <div class="col-md-2 text-end">
                    <img src="/images/organization/orgs_icon_1.svg" alt="Step 1 Icon" class="step-icon">
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-12">
                    <h3 class="step-title mt-3 mb-2 fw-semibold">DEFINE YOUR CHALLENGE</h3>
                    <hr class="blue-line">
                    <p class="step-description mt-2">
                        Identify a specific business, economic or social problem or pain point you’d like to address. Be
                        clear and
                        concise about what you’re trying to achieve. Who is suffering most from the problem(pain target
                        group/community), who else is indirectly affected, what is the (measurable) negative impact
                        created or
                        increased by the problem not being solved, who has been trying to solve the problem so far and
                        how? What are
                        the key objectives and constraints?
                        <br><br>
                        Need help refining your challenge? Ask Multiverz to advise on your challenge and get expert
                        guidance to
                        ensure you’re asking the right questions.
                    </p>
                </div>
            </div>
        </div>
        <div class="step">
            <div class="row">
                <div class="col-md-10">
                    <div class="step-number fs-12px">STEP 2</div>
                </div>
                <div class="col-md-2 text-end">
                    <img src="/images/organization/orgs_icon_2.svg" alt="Step 2 Icon" class="step-icon">
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-12">
                    <h3 class="step-title mt-3 mb-2 fw-semibold">Set Your Goals and Criteria</h3>
                    <hr class="blue-line">
                    <p class="step-description mt-2">
                        Determine what success looks like for your challenge. Think beyond conventional profit-oriented
                        KPIs and
                        include impact-oriented measures. What are the evaluation criteria for submissions? Be specific
                        about what
                        you’re looking for in a solution.
                    </p>
                </div>
            </div>
        </div>

        <div class="step">
            <div class="row">
                <div class="col-md-10">
                    <div class="step-number fs-12px">STEP 3</div>
                </div>
                <div class="col-md-2 text-end">
                    <img src="/images/organization/orgs_icon_3.svg" alt="Step 3 Icon" class="step-icon">
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-md-12">
                    <h3 class="step-title mt-3 mb-2 fw-semibold">Choose Your Challenge Category</h3>
                    <hr class="blue-line">
                    <p class="step-description mt-2">
                        Decide on the type of challenge that best fits your needs:
                    </p>
                    <ul class="bullet-points">
                        <li>Solutions: Seek innovative solutions to a specific problem</li>
                        <li>Strategy: Develop a new strategy or approach to seize or develop a business opportunity</li>
                        <li>Actionable Futures: Identify emerging business opportunities and risks</li>
                        <li>Actionable Futures: Identify emerging business opportunities and risks</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="step">
            <div class="row">
                <div class="col-md-10">
                    <div class="step-number fs-12px">STEP 4</div>
                </div>
                <div class="col-md-2 text-end">
                    <img src="/images/organization/orgs_icon_4.svg" alt="Step 4 Icon" class="step-icon">
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-md-12">
                    <h3 class="step-title mt-3 mb-2 fw-semibold">Craft Your Challenge Statement</h3>
                    <hr class="blue-line">
                    <p class="step-description mt-2">
                        Write a clear, concise, and compelling challenge statement that inspires innovators to take
                        action. Make
                        sure to include:
                    </p>
                    <ul class="bullet-points">
                        <li>A brief overview of the challenge</li>
                        <li>The problem you're trying to solve</li>
                        <li>The goals and objectives</li>
                        <li>The evaluation criteria</li>
                        <li>Any specific requirements or constraints</li>
                        <li>Need help crafting a compelling challenge statement? Ask Multiverz to advise on your
                            challenge and get
                            expert guidance to ensure your challenge resonates with innovators.</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="step">
            <div class="row">
                <div class="col-md-10">
                    <div class="step-number fs-12px">STEP 5</div>
                </div>
                <div class="col-md-2 text-end">
                    <img src="/images/organization/orgs_icon_5.svg" alt="Step 5 Icon" class="step-icon">
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-md-12">
                    <h3 class="step-title mt-3 mb-2 fw-semibold">Set a Timeline and Budget</h3>
                    <hr class="blue-line">
                    <p class="step-description mt-2">
                        Determine the duration of your challenge and the budget for prizes or awards. Consider the
                        complexity of the
                        challenge, the scope of the problem, and the resources required to support the challenge.
                    </p>
                </div>
            </div>
        </div>

        <div class="step ">
            <div class="row">
                <div class="col-md-10">
                    <div class="step-number fs-12px">STEP 6</div>
                </div>
                <div class="col-md-2 text-end">
                    <img src="/images/organization/orgs_icon_6.svg" alt="Step 6 Icon" class="step-icon">
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-md-12">
                    <h3 class="step-title mt-3 mb-2 fw-semibold">Launch and Promote Your Challenge</h3>
                    <hr class="blue-line">
                    <p class="step-description mt-2">
                        Launch your challenge on our platform and promote it on your social media. Share it on social
                        media, email,
                        and other channels to reach a wide range of innovators.
                        <br><br>
                        Want to maximize your challenge's visibility and impact? Ask Multiverz to help launch and
                        promote your
                        challenge and leverage our expertise to reach a broader audience.
                    </p>
                </div>
            </div>
        </div>

        <div class="step">
            <div class="row">
                <div class="col-md-10">
                    <div class="step-number fs-12px">STEP 7</div>
                </div>
                <div class="col-md-2 text-end">
                    <img src="/images/organization/orgs_icon_7.svg" alt="Step 7 Icon" class="step-icon">
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-12">
                    <h3 class="step-title mt-3 mb-2 fw-semibold">Set Your Goals and Criteria</h3>
                    <hr class="blue-line">
                    <p class="step-description mt-2">
                        Review submissions, evaluate them against your criteria, and select the winners. Provide
                        feedback to
                        participants and recognize the winners publicly.
                        <br><br>
                        Get Started Today! Launch your challenge now and tap into the power of open innovation. Our
                        platform is
                        designed to help you succeed, with tools and resources to support you every step of the way.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center" style="margin-top: 10px; margin-bottom: 90px;">
        <a href="#"
            class="btn btn-gradient btn-bg-blue-gradient border-0 mt-4 rounded-start-0 fs-18px fw-semibold custom-btn">
            START CREATING A CHALLENGE
            <span class="arrow-btn-wrapper"> <img src="/images/home/right-arrow.svg" alt="Arrow" height="20"
                    class="arrow-icon1"></span>
        </a>
    </div>
</div>
