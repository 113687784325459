export enum UserRole {
    SUPERADMIN = 'Super Admin',
    INTERN = 'Intern',
    ORGANIZATION = 'Organization',
    INNOVATOR = 'Innovator',
    STARTUP = 'Startup',
    MENTOR = 'Mentor',
}

export enum UserRoleSlug {
    SUPERADMIN = 'SUPER-ADMIN',
    INTERN = 'INTERN',
    ORGANIZATION = 'ORGANIZATION',
    INNOVATOR = 'INNOVATOR',
    STARTUP = 'STARTUP',
    MENTOR = 'MENTOR',
    SUPERORG = 'SUPER_ORGANIZATION',
}
