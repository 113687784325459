import { Component, HostListener, inject } from '@angular/core';
import { UserRoleSlug } from '../core/enum/UserRole.enum';
import { IconType } from '../core/enum/iconsType.enum';
import { FiltersService } from '../filters.service';
// import { Router } from 'express';
import { SweetAlertService } from '../core/services/sweet-alert.service';
import { AuthService } from '../core/services/apis/auth.service';
import { showStatus } from '../shared/utility/checkChallengeStatus';
import { Router } from '@angular/router';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';

@Component({
  selector: 'app-evaluation',
  standalone: true,
  imports: [DatePipe,CommonModule, FormsModule, NgSelectComponent,InfiniteScrollDirective],
  templateUrl: './evaluation.component.html',
  styleUrl: './evaluation.component.scss'
})
export class EvaluationComponent {
  challengeService: FiltersService = inject(FiltersService);
  route: Router = inject(Router);
  challenges: any[] = []
  actionIcon: string = '';
  state: string = '';
  hideDelete: boolean = false;
  showScrollToTopButton = false;
  sweetAlertService: SweetAlertService = inject(SweetAlertService);
  sortList = [{ id: 'DESC', title: 'Latest' }, { id: 'ASC', title: 'Oldest' }]
  user$: any;
  currentOrgId: any;
  authService: AuthService=inject(AuthService);
  role: string = '';
  showStatus = showStatus;
  params = {
    pageLimit: 10,
    pageIndex: 1,
    searchKeyword: '',
    sortKey: 'createdAt',
    sortDirection: 'DESC',

  }
  public IconType=IconType
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showScrollToTopButton = window.scrollY > 300;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  handleSearchChange(searchValue: string): void {
    this.params.searchKeyword = searchValue;
    this.params.pageIndex = 1
    this.fetchChallenges()

  }
  onSortChange(sortValue: any): void {
    console.log(sortValue, 'sort value')
    this.params.sortDirection = sortValue
    this.params.pageIndex = 1
    this.fetchChallenges()
  }
  handleRedirection: (challengeId: string) => void = () => { };
  fetchChallenges(): void {
    this.challengeService.getMyChallenges(this.params).subscribe(
      (data: any) => {
        console.log('Challenges fetched from get my challenge', data);
        if (this.params.pageIndex === 1) {
          this.challenges = data.challenges;
        } else {
          this.challenges = [...this.challenges, ...data.challenges];
          console.log("my challenges", this.challenges)
        }
      },
      (error: any) => {
        console.error('Error fetching challenges', error);
      }
    )
  };

  redirectSpecificChallengeSolutionList(challengeId: string): void {
    this.route.navigate(['/evaluation/solutions'], {
      queryParams: { id: challengeId },
    });
  }

  onScroll(): void {
    console.log('scroll triggered');
    this.params.pageIndex += 1;
    // this.fetchChallenges()
  }



 

  onRedirectToEditChallengeDetails(challengeId: string): void {
    this.route.navigate(['/launch-a-challenge/edit'], {
      queryParams: { id: challengeId },
    });
  }

  onRedirectToViewChallengeDetails(challengeId: string): void {
    this.route.navigate(['/overview/challenge-details', challengeId]);
  }
 

  




  ngOnInit() {
    this.fetchChallenges();
    this.user$ = this.authService.getUser();
    this.user$.subscribe((user:any) => {
      if (user) {
        console.log('user in the challenges component', user);
      }
      this.role=user.roles[0].slug
    })
  }

  

  

}
