import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { CommonUtilities } from '../../core/utilities/common-utilities';
import { WeakSignalDialogComponent } from '../components/weak-signal-dialog/weak-signal-dialog.component';
import { InputErrorMessageComponent } from '../../shared/input-error-message/input-error-message.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { SolverFormStoreService } from '../store/solver-form-store.service';
import { InputSolutionReferencesComponent } from '../../shared/input-solution-references/input-solution-references.component';
import { SolutionReferences } from '../../shared/input-solution-references/types/input-solution-references-types';
import {
    SolutionForm3Uncertainty,
    SolutionForm3WeakSignal,
    WeakSignalType,
} from '../types/solution-form-3-types';
import { firstValueFrom } from 'rxjs';
import { SolverFlowService } from '../../core/services/apis/solver-flow.service';

@Component({
    selector: 'app-solver-flow-step-3-weak-signal',
    templateUrl: './solver-flow-step-3-weak-signal.component.html',
    styleUrls: ['./solver-flow-step-3-weak-signal.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatDialogModule,
        CommonModule,
        InputErrorMessageComponent,
        InputSolutionReferencesComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush, // Using OnPush strategy
})
export class SolverFlowStep3WeakSignalComponent {
    public formGroup!: FormGroup;
    readonly dialog = inject(MatDialog);
    private cdr = inject(ChangeDetectorRef);
    @Output() successEvent: EventEmitter<void> = new EventEmitter<void>();
    public solutionReferences!: SolutionReferences;
    public readonly WeakSignalType = WeakSignalType;
    protected weakSignals: SolutionForm3WeakSignal[] = [];
    protected uncertainties: SolutionForm3Uncertainty[] = [];

    constructor(
        private readonly fb: FormBuilder,
        private readonly sweetAlertService: SweetAlertService,
        private readonly solverFormStoreService: SolverFormStoreService,
        private readonly solverFlowService: SolverFlowService
    ) {
        this.formGroup = this.fb.group({});
    }

    ngOnInit(): void {
        this.solverFormStoreService.selectForm3$.subscribe((form3) => {
            if (form3) {
                this.weakSignals = form3.weakSignals || [];
                this.uncertainties = form3.uncertainties || [];
                if (form3.solutionReferences) {
                    this.solutionReferences = form3.solutionReferences;
                }
                this.cdr.detectChanges();
            }
        });
    }

    async openDialog(type: WeakSignalType, data?: any): Promise<void> {
        this.dialog
            .open(
                WeakSignalDialogComponent,
                CommonUtilities.getDialogConfig({
                    data,
                    type,
                    solutionId: await firstValueFrom(
                        this.solverFormStoreService.selectSolutionId$
                    ),
                })
            )
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.solverFormStoreService.getSolutionDetails();
                }
            });
    }

    async removeWeakSignal(item: SolutionForm3WeakSignal): Promise<void> {
        const solutionId = await firstValueFrom(
            this.solverFormStoreService.selectSolutionId$
        );
        this.solverFlowService
            .deleteWeakSignal(solutionId as number, item.id as number)
            .subscribe({
                next: (response) => {
                    this.solverFormStoreService.getSolutionDetails();
                    this.cdr.detectChanges();
                    this.sweetAlertService.successToaster(
                        'Weak Signal Deleted Successfully'
                    );
                },
                error: (err) => {
                    this.sweetAlertService.errorToaster(
                        err.error.message || 'Error deleting weak signal'
                    );
                },
            });
    }

    async removeUncertainty(item: SolutionForm3Uncertainty): Promise<void> {
        const solutionId = await firstValueFrom(
            this.solverFormStoreService.selectSolutionId$
        );
        this.solverFlowService
            .deleteWeakSignal(solutionId as number, item.id as number)
            .subscribe({
                next: (response) => {
                    this.solverFormStoreService.getSolutionDetails();
                    this.cdr.detectChanges();
                    this.sweetAlertService.successToaster(
                        'Uncertainty Deleted Successfully'
                    );
                },
                error: (err) => {
                    this.sweetAlertService.errorToaster(
                        err.error.message || 'Error deleting uncertainty'
                    );
                },
            });
    }

    async onSubmit(isDraft?: boolean): Promise<void> {
        if (!isDraft) {
            this.formGroup.markAllAsTouched();
        }
        if (this.formGroup.valid || isDraft) {
            const solutionId = await firstValueFrom(
                this.solverFormStoreService.selectSolutionId$
            );
            this.solverFlowService
                .submitForm3(
                    solutionId as number,
                    this.formGroup.value,
                    isDraft
                )
                .subscribe({
                    next: () => {
                        this.solverFormStoreService.getSolutionDetails();
                        this.successEvent.emit();
                        this.sweetAlertService.successToaster(
                            'Weak Signal Submitted Successfully'
                        );
                    },
                    error: (err) => {
                        this.sweetAlertService.errorToaster(
                            err.error.message || 'Error submitting weak signal'
                        );
                    },
                });
        } else {
            this.sweetAlertService.errorToaster(
                'Please add atleast two weak signal'
            );
        }
    }
}
