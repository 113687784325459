import { Component } from '@angular/core';
import { BenefitsSectionComponent } from '../../shared/benefits-section/benefits-section.component';
import { HowItWorksComponent } from '../../shared/how-it-works/how-it-works.component';
import { BannerSectionComponent } from '../../shared/banner-section/banner-section.component';

@Component({
    selector: 'app-startups',
    standalone: true,
    imports: [
        BannerSectionComponent,
        BenefitsSectionComponent,
        HowItWorksComponent,
    ],
    templateUrl: './startups.component.html',
    styleUrl: './startups.component.scss',
})
export class StartupsComponent {
    stepsData = [
        {
            icon: '/images/startups/icons/startups_icon_1.svg',
            title: 'Browse Social Impact Challenges',
            description:
                'Explore our current challenges and find one that aligns with your startup\'s mission and expertise.'
        },
        {
            icon: '/images/startups/icons/startups_icon_2.svg',
            title: 'Submit a Solution',
            description:
                'Submit your solution to the challenge, including a brief description, prototype, or proof of concept.'
        },
        {
            icon: '/images/startups/icons/startups_icon_3.svg',
            title: 'Collaborate with the Challenge Owner',
            description:
                'Work with the challenge owner to refine and develop your solution, and receive feedback and support.'
        },
        {
            icon: '/images/startups/icons/startups_icon_4.svg',
            title: 'Win Recognition and Rewards',
            description:
                'Compete for recognition and rewards, including funding, mentorship, and access to resources and networks.'
        },
        {
            icon: '/images/startups/icons/startups_icon_5.svg',
            title: 'Scale Your Solution',
            description:
                'Use the resources and support gained through the open innovation challenge to scale your solution and grow your business.'
        }
    ];
}
