<section>
    <div class="preview-header navbar navbar-expand-lg bg-white py-3">
        <div class="container">
            <button class="btn" style="color: #000; font-size: 15px; font-weight: 600;"
                (click)="redirectToChallengeDetails()">
                < Back to Challenge detail page </button> <div>
                    <button class="add-new-btn" style="margin-right: 20px;" (click)="onSaveAndNext(true)">
                        Save as draft
                    </button>
                    <button class="btn btn-gradient btn-bg-blue-gradient custom-btn" (click)="onSaveAndNext()">
                        Submit
                        <span class="arrow-btn-wrapper">
                            <img src="/images/home/right-arrow.svg" alt="Arrow" height="20" class="arrow-icon1" />
                        </span>
                    </button>
        </div>
    </div>
    </div>
    <div class="solver-flow-header navbar-expand-lg bg-white shadow-sm sticky-top">
        <div class="container">
            <div class="accordion-wrapper">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id=" headingOne">
                            <button class="heading-mon-22 accordion-button collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                                aria-controls="collapseOne">
                                {{ challenge?.title }}
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <img [src]="challenge?.downloadUrl" />
                                <div class="challenge-details-wrapper">
                                    <p [innerHTML]="
                                            challenge?.shortDescription
                                        "></p>
                                    <div class="owner-date-details">
                                        <label>Challenge Owner</label>
                                        <label>{{ challenge?.organizedBy?.title }}
                                        </label>
                                    </div>
                                    <div class="owner-date-details">
                                        <label>Submission Deadline</label>
                                        @if
                                        (challengeSubmissionTimeline?.length>0)
                                        {
                                        <label>
                                            {{
                                                challengeSubmissionTimeline[0]
                                                    ?.date
                                                    | date : "dd MMM yyyy"
                                            }}
                                        </label>
                                        } @else {
                                        <label>NA </label>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container solver-flow-container" style="padding: 0">
        <div class="col-md-12 d-flex justify-content-end stepper-status-res-wrapper">
            <label>Status: Draft</label>
            <span>|</span>
            <label (click)="redirectToGuidelines()">
                <img src="/images/solver-flow/guildance.svg" />
                <span class="resource-label">Guidance for Step {{ currentStep }}</span>
            </label>
            <span>|</span>
            <label>
                <img src="/images/solver-flow/video.svg" />
                <span class="resource-label">Video for Step 1</span>
            </label>
            <span>|</span>
            <label>
                <img src="/images/solver-flow/resources.svg" />
                <span class="resource-label">Resources</span>
            </label>
        </div>
        <div class="step-heading-wrapper">
            <ng-container [ngSwitch]="currentStep">
                <ng-container *ngSwitchCase="1">
                    <h2>
                        Step 1: The Problem Explorer's Checklist <sup>©</sup>
                    </h2>
                    <h3>A FRAMEWORK FOR COMPREHENSIVE PROBLEM ANALYSIS</h3>
                </ng-container>
                <ng-container *ngSwitchCase="2">
                    <h2>Step 2: Best Practices</h2>
                </ng-container>
                <ng-container *ngSwitchCase="3">
                    <h2>Step 3: Horizon Scanning</h2>
                </ng-container>
                <ng-container *ngSwitchCase="4">
                    <h2>Step 4: Scenario Planning</h2>
                </ng-container>
                <ng-container *ngSwitchCase="5">
                    <h2>Step 5: Research Synthesis</h2>
                </ng-container>
                <ng-container *ngSwitchCase="6">
                    <h2>Step 6: Opportunities & Risks</h2>
                </ng-container>
                <ng-container *ngSwitchCase="7">
                    <h2>Step 7: High Impact Initiatives</h2>
                </ng-container>
                <ng-container *ngSwitchCase="8">
                    <h2>Step 8: Backcasting</h2>
                </ng-container>
                <ng-container *ngSwitchCase="9">
                    <h2>Step 9: Executive Summary & Future Press Release</h2>
                </ng-container>
                <ng-container *ngSwitchCase="10">
                    <h2>Step 10: Final Report</h2>
                </ng-container>
            </ng-container>
        </div>
        <mat-horizontal-stepper [linear]="false" #stepper (selectionChange)="handleStepChange($event)">
            <mat-step label="Step 1">
                <app-solver-flow-step-1 *ngIf="currentStep === 1" (successEvent)="handleStep1Success($event)">
                </app-solver-flow-step-1>
            </mat-step>
            <mat-step label="Step 2">
                <app-solver-flow-step-2-best-practice *ngIf="currentStep === 2" (successEvent)="handleStepSuccess()">
                </app-solver-flow-step-2-best-practice>
            </mat-step>
            <mat-step label="Step 3">
                <app-solver-flow-step-3-weak-signal *ngIf="currentStep === 3" #solverFlowStep3WeakSignalComponent
                    (successEvent)="handleStepSuccess()"></app-solver-flow-step-3-weak-signal>
            </mat-step>
            <mat-step label="Step 4">
                <app-solver-flow-step-4-scenario-planning *ngIf="currentStep === 4" #solverFlowStep4Component
                    (successEvent)="handleStepSuccess()"></app-solver-flow-step-4-scenario-planning>
            </mat-step>
            <mat-step label="Step 5">
                <app-solver-flow-step-5 *ngIf="currentStep === 5" (successEvent)="handleStepSuccess()">
                </app-solver-flow-step-5>
            </mat-step>
            <mat-step label="Step 6">
                <app-solver-flow-step-6 *ngIf="currentStep === 6" (successEvent)="handleStepSuccess()">
                </app-solver-flow-step-6>
            </mat-step>
            <mat-step label="Step 7">
                <app-solver-flow-step-7-impact-initiatives *ngIf="currentStep === 7"
                    (successEvent)="handleStepSuccess()"></app-solver-flow-step-7-impact-initiatives>
            </mat-step>
            <mat-step label="Step 8">
                <app-solver-flow-step-8-backcasting *ngIf="currentStep === 8" (handleSuccess)="handleStepSuccess()">
                </app-solver-flow-step-8-backcasting>
            </mat-step>
            <mat-step label="Step 9">
                <app-solver-flow-step-9 *ngIf="currentStep === 9" (handleSuccess)="handleStepSuccess()">
                </app-solver-flow-step-9>
            </mat-step>
            <mat-step label="Step 10">
                <app-solver-flow-step-10 *ngIf="currentStep === 10"></app-solver-flow-step-10>
            </mat-step>
        </mat-horizontal-stepper>
        <!-- <div class="row mt-4" style="margin-right: 20px;">
            <div class="col-12 text-end">
                <button type="button" class="btn btn-gradient btn-bg-blue-gradient fw-semibold custom-btn"
                    (click)="onSaveAndNext()">
                    {{ currentStep === 10 ? 'Review & Submit' : 'Save & Next' }}
                    <span class="arrow-btn-wrapper">
                        <img src="/images/home/right-arrow.svg" alt="Arrow" height="20" class="arrow-icon1" />
                    </span>
                </button>
            </div>

        </div> -->
    </div>

</section>
