import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { GenerateSignedUrlRequest } from '../../Model/generateSignedUrlRequest';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class S3UploadService {
    constructor(private http: HttpClient) {}

    generateSignedUrl(
        request: GenerateSignedUrlRequest
    ): Observable<{ signedUrl: string }> {
        return this.http.post<{ signedUrl: string }>(`${environment.apiUrl}/s3/generate-signed-url-for-upload`, request);
    }
}
