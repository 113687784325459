<div class="dots-wrapper">
    <span class="dots"></span>
    <span class="dots"></span>
    <span class="dots"></span>
    <span class="dots"></span>
    <span class="dots"></span>
    <span class="dots"></span>
    <span class="dots"></span>
    <span class="dots"></span>
    <span class="dots"></span>
</div>
