import { MatDialogConfig } from '@angular/material/dialog';

export class CommonUtilities {
    public static getDialogConfig(
        data: any,
        maxWidth: string = '1140px',
        height: string = 'calc(100vh - 120px)'
    ): MatDialogConfig {
        return {
            width: '100%',
            maxWidth,
            height,
            data,
            autoFocus: false,
            restoreFocus: false,
        };
    }

    public static generateUUID(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                const r = (Math.random() * 16) | 0;
                const v = c === 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    }

    public static windowResize(): void {
        const event = new Event('resize');
        window.dispatchEvent(event);
    }
}
