import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BestPracticeDailogComponent } from '../components/best-practice-dailog/best-practice-dailog.component';
import { CommonUtilities } from '../../core/utilities/common-utilities';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { InputErrorMessageComponent } from '../../shared/input-error-message/input-error-message.component';
import { ReferenceHandlerUploadsComponent } from '../../shared/input-solution-references/reference-handler-uploads/reference-handler-uploads.component';
import { QuillEditorComponent } from '../../shared/quill-editor/quill-editor.component';
import { ReferenceHandlerComponent } from '../../shared/input-solution-references/reference-handler/reference-handler.component';
import { InputSolutionReferencesComponent } from '../../shared/input-solution-references/input-solution-references.component';
import { SolutionReferences } from '../../shared/input-solution-references/types/input-solution-references-types';
import { firstValueFrom } from 'rxjs';
import { SolverFormStoreService } from '../store/solver-form-store.service';
import { SolverFlowService } from '../../core/services/apis/solver-flow.service';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { SolutionForm2BestPractice } from '../types/solution-form-2-types';

@Component({
    selector: 'app-solver-flow-step-2-best-practice',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ReferenceHandlerComponent,
        InputErrorMessageComponent,
        ReferenceHandlerUploadsComponent,
        QuillEditorComponent,
        InputSolutionReferencesComponent,
    ],
    templateUrl: './solver-flow-step-2-best-practice.component.html',
    styleUrl: './solver-flow-step-2-best-practice.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush, // Using OnPush strategy
})
export class SolverFlowStep2BestPracticeComponent {
    formGroup!: FormGroup;
    readonly dialog = inject(MatDialog);
    private cdr = inject(ChangeDetectorRef);
    solutionReferences: SolutionReferences = {} as SolutionReferences;
    @Output() successEvent = new EventEmitter();

    constructor(
        private fb: FormBuilder,
        protected solverFormStoreService: SolverFormStoreService,
        private solverFlowService: SolverFlowService,
        private sweetAlertService: SweetAlertService
    ) {
        this.formGroup = this.fb.group({});
    }

    async ngOnInit(): Promise<void> {
        this.solverFormStoreService.selectForm2$.subscribe((form2) => {
            if (form2?.solutionReferences) {
                this.solutionReferences = form2.solutionReferences;
            }
        });
    }

    async deleteBestPractice(bestPracticeId: number): Promise<void> {
        const solutionId = await firstValueFrom(
            this.solverFormStoreService.selectSolutionId$
        );
        if (solutionId) {
            this.solverFlowService
                .deleteBestPractice(solutionId, bestPracticeId)
                .subscribe({
                    next: (response) => {
                        this.solverFormStoreService.getSolutionDetails();
                        this.cdr.detectChanges();
                        this.sweetAlertService.successToaster(
                            'Best Practice Deleted Successfully'
                        );
                    },
                    error: (err) => {},
                });
        }
    }

    async openDialog(data?: SolutionForm2BestPractice): Promise<void> {
        this.dialog
            .open(
                BestPracticeDailogComponent,
                CommonUtilities.getDialogConfig({
                    data,
                    solutionId: await firstValueFrom(
                        this.solverFormStoreService.selectSolutionId$
                    ),
                })
            )
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.solverFormStoreService.getSolutionDetails();
                    this.cdr.detectChanges();
                }
            });
    }

    async onSubmit(isDraft?: boolean): Promise<void> {
        const solutionId = await firstValueFrom(
            this.solverFormStoreService.selectSolutionId$
        );
        if (!isDraft) {
            this.formGroup.markAllAsTouched();
        }
        if (this.formGroup.valid || isDraft) {
            this.solverFlowService
                .saveBestPracticeForm2(
                    solutionId as number,
                    this.formGroup.value,
                    isDraft
                )
                .subscribe({
                    next: (response) => {
                        this.solverFormStoreService.getSolutionDetails();
                        this.successEvent.emit();
                    },
                    error: (err) => {
                        console.log(err);
                        this.sweetAlertService.errorToaster(
                            err.error.message ||
                                'Error submitting form, please try again'
                        );
                    },
                });
        }
    }
}
