<div class="container">
    <div class="payment-status-design">
        <img src="/images/solver-flow/payment.svg" />
        <div class="payment-msg-wrapper">
            <h2>Payment successfully received!</h2>
            <p>You are now ready to solve the challenge and make a positive impact on the world!</p>
        </div>
    </div>
    <h3>WHEN YOU BEGIN THE CHALLENGE, YOU CAN ACCESS HELP RESOURCES FROM THE ORANGE COLOURED LINKS</h3>
    <img src="/images/solver-flow/challenge-header.svg" width="100%" />
    <div class="col-md-12 text-end btn-wrapper">
        <button class="btn btn-gradient btn-bg-blue-gradient custom-btn" (click)="redirectToSolver()">
            CONTINUE
            <span class="arrow-btn-wrapper">
                <img src="/images/home/right-arrow.svg" alt="Arrow" height="20" class="arrow-icon1" />
            </span>
        </button>
    </div>
</div>
