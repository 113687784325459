<section class="container">
    <div class="row payment-flow-instruction-wrapper">
        <div class="col-md-12 step-details">
            <h3 class="heading-mon-22">Please read the following steps to participate in a challenge</h3>
            <div class="timeline-wrapper">
                <ul class="timeline">
                    <li class="timeline-inverted">
                        <div class="timeline-badge"></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4 class="timeline-title">STEP 1</h4>
                                <p class="timeline-desc">Pay fee to continue to attempt the challenge</p>
                            </div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-badge"></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4 class="timeline-title">STEP 2</h4>
                                <p class="timeline-desc">Solve the challenge as per the steps defined in it</p>
                            </div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-badge"></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4 class="timeline-title">STEP 3</h4>
                                <p class="timeline-desc">Submit the final report in PDF format</p>
                            </div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-badge"></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4 class="timeline-title">STEP 4</h4>
                                <p class="timeline-desc">If the report is accepted, earn incentives</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div style="display: flex; justify-content: center;" class="col-md-12">
        <div id="stripe-container">
        </div>
    </div>
</section>
