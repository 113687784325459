<div>
    <!-- Overview Page -->
    <div class="overiew-wrapper">
        <h6 class="section-title">Notes for resources</h6>
        <p class="section-desc" [innerHTML]="challenge?.challengeResources[0]?.note.length>0?challenge?.challengeResources[0]?.note:'No specific notes for this resources'">
            
        </p>

        <h6 class="section-title">URLs for references</h6>
        @if (challenge.challengeResources[0]?.referenceUrls.length>0) {
        @for (url of challenge.challengeResources[0]?.referenceUrls; track $index) {
        <p class="uploaded-files-wrapper">
            <label> {{url}} </label><img src="/images/common/redirect.svg" (click)="handleURLRedirection(url)" />
        </p>}
        } @else {

        <p>No specific URLs for references</p>
        }

        <hr class="custom-border" />

        <h6 class="section-title">Documents for references</h6>
        @if (challenge.challengeResources[0]?.media.length>0){
        @for (media of challenge.challengeResources[0]?.media; track $index) {
        <a class="uploaded-files-wrapper" (click)="downloadResource(media)">
            <label> {{media}} </label><img src="/images/common/download-doc.svg" />
        </a>}


        } @else {
        <p>No specific documents for references</p> 
        }
         </div>
    </div>