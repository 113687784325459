import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, signal } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatChipsModule, MatChipInputEvent } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { InputErrorMessageComponent } from '../../../shared/input-error-message/input-error-message.component';
import { ReferenceHandlerUploadsComponent } from '../../../shared/input-solution-references/reference-handler-uploads/reference-handler-uploads.component';
import { QuillEditorComponent } from '../../../shared/quill-editor/quill-editor.component';
import { ReferenceHandlerComponent } from '../../../shared/input-solution-references/reference-handler/reference-handler.component';
import { SolutionReferences } from '../../../shared/input-solution-references/types/input-solution-references-types';
import { InputSolutionReferencesComponent } from '../../../shared/input-solution-references/input-solution-references.component';
import { SolverFlowService } from '../../../core/services/apis/solver-flow.service';
import {
    BestPracticeTag,
    SolutionForm2BestPractice,
} from '../../types/solution-form-2-types';
import { SweetAlertService } from '../../../core/services/sweet-alert.service';

interface DialogData {
    solutionId: number;
    data: SolutionForm2BestPractice;
}

@Component({
    selector: 'app-best-practice-dailog',
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatChipsModule,
        MatInputModule,
        MatIconModule,
        NgSelectModule,
        InputErrorMessageComponent,
        ReferenceHandlerComponent,
        ReferenceHandlerUploadsComponent,
        QuillEditorComponent,
        InputSolutionReferencesComponent,
    ],
    templateUrl: './best-practice-dailog.component.html',
    styleUrl: './best-practice-dailog.component.scss',
})
export class BestPracticeDailogComponent implements OnInit {
    formGroup!: FormGroup;
    solutionReferences!: SolutionReferences;
    readonly tagList = signal<BestPracticeTag[]>([]);

    constructor(
        private readonly fb: FormBuilder,
        private dialogRef: MatDialogRef<BestPracticeDailogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
        private readonly solverFlowService: SolverFlowService,
        private readonly sweetAlertService: SweetAlertService
    ) {
        const data = dialogData?.data;
        this.formGroup = this.fb.group({
            title: [data?.title || '', Validators.required],
            date: [data?.date || '', Validators.required],
            organizationName: [
                data?.organizationName || '',
                Validators.required,
            ],
            challenge: [data?.challenge || '', Validators.required],
            solutionText: [data?.solutionText || '', Validators.required],
            implementation: [data?.implementation || '', Validators.required],
            outcome: [data?.outcome || '', Validators.required],
            tags: [data?.tags || []],
            citations: [data?.citations || '', Validators.required],
        });
        this.tagList.set(data?.tags || []);
    }
    ngOnInit(): void {
        const data = this.dialogData?.data;
        if (data) {
            this.solutionReferences = {
                referenceDocuments: data?.metaData?.fileNames || [],
                referenceUrls: data?.metaData?.url || [],
                prompts: data?.metaData?.prompts || '',
            };
        } else {
            this.solutionReferences = {} as SolutionReferences;
        }
    }
    removeTagList(keyword: BestPracticeTag) {
        this.tagList.update((keywords) => {
            const index = keywords.findIndex(
                (item) => item.title === keyword.title
            );
            if (index < 0) {
                return keywords;
            }

            keywords.splice(index, 1);
            return [...keywords];
        });
    }

    addTagList(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            this.tagList.update((keywords) => [...keywords, { title: value }]);
        }
        event.chipInput!.clear();
    }

    onSubmitClick() {
        this.formGroup.markAllAsTouched();
        if (this.formGroup.valid) {
            let bestPractice = {
                ...this.formGroup.value,
                tags: this.tagList().map((item) => item.title),
                fileNames: this.formGroup.get(
                    'solutionReferences.referenceDocuments'
                )?.value,
                url: this.formGroup.get('solutionReferences.referenceUrls')
                    ?.value,
            };
            delete bestPractice?.['solutionReferences'];
            this.solverFlowService
                .createbestPractice(
                    this.dialogData.solutionId,
                    bestPractice,
                    this.dialogData.data?.id
                )
                .subscribe({
                    next: (response) => this.dialogRef.close(response),
                    error: (err) => {
                        this.sweetAlertService.errorToaster(
                            'Error submitting form, please try again'
                        );
                    },
                });
        }
    }
}
