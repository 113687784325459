<!-- interns listing -->
<section>


    <div class="filters-wrapper">
        <div class="search-wrapper" style="max-width: 164px; max-height: 40px">
            <input type="text" class="form-control search-color" placeholder="Search" [(ngModel)]="params.searchKeyword"
                (ngModelChange)="handleSearchChange($event)" />
            <img src="/images/common/search-black.svg" alt="Search" height="20" />
        </div>
        <div class=" list-sort-btn" style="margin-left: 20px;">
            <div class="custom-multi-select">
                <label for="sort" class="form-label fw-semibold">Sort By</label>
                <ng-select [items]="sortList" bindValue="id" bindLabel="title" [closeOnSelect]="true"
                    [ngModel]="params.sortDirection" [multiple]="false" (ngModelChange)="onSortChange($event)"
                    [searchable]=" false">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        {{ item?.title }}
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </div>
    <button class="top-nav" (click)="scrollToTop()" *ngIf="showScrollToTopButton">
        <img src="/images/common/top.svg" alt="Scroll to Top" />
    </button>
    <div class="listing-wrapper" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()">
        <table>
            <tbody>
                @if (challenges.length>0) {
                @for (challenge of challenges; track challenge.id) {
                <tr class="list-item">
                    <td class="title">{{challenge.title}}</td>
                    <td class="light-text">Last updated: {{challenge.updatedAt|date:'mediumDate'}}</td>
                    <td class="light-text org-name">{{challenge.organizedBy.title}}</td>
                    <td class="status">{{showStatus(challenge.startDate,challenge.endDate,challenge.status)}}</td>
                    <td class="action">
                        <img src="/images/common/edit.svg"
                            [hidden]="shouldShowIcon(showStatus(challenge.startDate,challenge.endDate,challenge.status),role,IconType.EDIT)"
                            (click)="onRedirectToEditChallengeDetails(challenge.id)" />
                        <img src="/images/common/open-eye.svg"
                            (click)="redirectToChallengeDetails(challenge.id,showStatus(challenge.startDate,challenge.endDate,challenge.status))"
                           [hidden]="shouldShowIcon(showStatus(challenge.startDate,challenge.endDate,challenge.status),role,IconType.VIEW)"
                            style="margin-left: 15px;" />

                        <img alt="delete" src="/images/common/delete.svg" 
                        [hidden]="shouldShowIcon(showStatus(challenge.startDate,challenge.endDate,challenge.status),role,IconType.DELETE)"

                            (click)="deleteChallenge(challenge.id)" style="margin-left: 15px;" height="25px" />

                        <!-- <img src="/images/common/comment.svg" />
                            <img src="/images/common/download.svg" />
                            <img src="/images/common/open-eye.svg" /> -->
                    </td>
                </tr>}
                } @else {
                <div>
                    <img class="no-records" src="/images/common/no-records.svg" alt="No Data" height="100" />
                    <p class="no-records-p">No records found</p>
                </div>
                }

                <!-- <tr class="list-item">
                        <td class="title">Empowering Local Business Development in Vietnam</td>
                        <td class="light-text">Last updated: 1 October 2024</td>
                        <td class="light-text org-name">World Bank</td>
                        <td class="status">Published</td>
                        <td class="action"><img src="/images/common/open-eye.svg" /></td>
                    </tr>
                    <tr class="list-item">
                        <td class="title">Training and Development Upskilling in India</td>
                        <td class="light-text">Last updated: 1 November 2024</td>
                        <td class="light-text org-name">ITU</td>
                        <td class="status">Open</td>
                        <td class="action"><img src="/images/common/edit.svg" /></td>
                    </tr>
                    <tr class="list-item">
                        <td class="title">Empowering Local Business Development in Vietnam</td>
                        <td class="light-text">Last updated: 1 December 2024</td>
                        <td class="light-text org-name">Nagarjuna Agrocsdfjk</td>
                        <td class="status">Closed</td>
                        <td class="action"><img src="/images/common/open-eye.svg" /></td>
                    </tr>
                    <tr class="list-item">
                        <td class="title">Textbooks for Under-priviledged Children in Albania</td>
                        <td class="light-text">Last upddated: 1 August 2024</td>
                        <td class="light-text org-name">Bill & Melinda Ga</td>
                        <td class="status">Published</td>
                        <td class="action"><img src="/images/common/edit.svg" /></td>
                    </tr> -->
            </tbody>
        </table>
    </div>
</section>
