import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    CdkDrag,
    CdkDragPlaceholder,
    CdkDropList,
    moveItemInArray,
} from '@angular/cdk/drag-drop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InputSolutionReferencesComponent } from '../../shared/input-solution-references/input-solution-references.component';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { SolverFormStoreService } from '../store/solver-form-store.service';
import { ImmediateAction } from '../types/solution-form-8-types';
import { SolverFlowService } from '../../core/services/apis/solver-flow.service';
import { SolutionReferences } from '../../shared/input-solution-references/types/input-solution-references-types';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
@Component({
    selector: 'app-solver-flow-step-8-backcasting',
    standalone: true,
    imports: [
        CdkDropList,
        CdkDrag,
        CdkDragPlaceholder,
        InputSolutionReferencesComponent,
    ],
    templateUrl: './solver-flow-step-8.component-backcasting.html',
    styleUrl: './solver-flow-step-8.component-backcasting.scss',
})
export class SolverFlowStep8Component implements OnInit, OnDestroy {
    @Output() handleSuccess = new EventEmitter();
    solutionReferences: SolutionReferences = {} as SolutionReferences;
    formGroup!: FormGroup;

    nearTermActions: ImmediateAction[] = [];
    mediumTermActions: ImmediateAction[] = [];
    longTermActions: ImmediateAction[] = [];

    private destroy$ = new Subject();

    constructor(
        private readonly fb: FormBuilder,
        private readonly solverFormStoreService: SolverFormStoreService,
        private readonly solverFlowService: SolverFlowService,
        private readonly sweetAlertService: SweetAlertService
    ) {
        this.formGroup = this.fb.group({});
    }

    ngOnInit(): void {
        this.solverFormStoreService.selectForm8$
            .pipe(takeUntil(this.destroy$))
            .subscribe((form8) => {
                if (form8) {
                    this.solutionReferences = form8.solutionReferences;
                    this.nearTermActions = form8.immediateActions
                        .filter((item) => item.termType === 'NEAR')
                        .sort((a, b) => a.priority - b.priority);
                    this.mediumTermActions = form8.immediateActions
                        .filter((item) => item.termType === 'MEDIUM')
                        .sort((a, b) => a.priority - b.priority);
                    this.longTermActions = form8.immediateActions
                        .filter((item) => item.termType === 'LONG')
                        .sort((a, b) => a.priority - b.priority);
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.complete();
    }

    dropNearTermActions(event: any) {
        moveItemInArray(
            this.nearTermActions,
            event.previousIndex,
            event.currentIndex
        );
    }
    dropMediumTermActions(event: any) {
        moveItemInArray(
            this.mediumTermActions,
            event.previousIndex,
            event.currentIndex
        );
    }
    dropLongTermActions(event: any) {
        moveItemInArray(
            this.longTermActions,
            event.previousIndex,
            event.currentIndex
        );
    }

    async onSubmit(isDraft?: boolean) {
        const solutionId = await firstValueFrom(
            this.solverFormStoreService.selectSolutionId$
        );
        const request = {
            nearTermActions: this.nearTermActions,
            midTermActions: this.mediumTermActions,
            longTermActions: this.longTermActions,
            solutionReferences: this.formGroup.value.solutionReferences,
        };
        this.solverFlowService
            .submitForm8(solutionId as number, request, isDraft)
            .subscribe({
                next: (response) => {
                    this.solverFormStoreService.updateForm8(response);
                    this.handleSuccess.emit();
                },
                error: (error) => {
                    this.sweetAlertService.errorToaster(
                        error.error.message ||
                            'Error submitting form, please try again'
                    );
                },
            });
    }
}
