import { Component } from '@angular/core';
import {
    ActivatedRoute,
    NavigationEnd,
    Router,
    RouterLink,
    RouterModule,
} from '@angular/router';
import { catchError, Observable, of, switchMap, take, tap } from 'rxjs';
import { AuthService } from '../../core/services/apis/auth.service';
import { CommonModule } from '@angular/common';
import { AuthState } from '../../core/store/auth/auth.state';
import { Store } from '@ngrx/store';
import * as AuthActions from '../../core/store/auth/auth.actions';
import { selectChallengeId } from '../../core/store/launchChallengeForm/launchChallenge.selectors';
import { ChallengeService } from '../../core/services/apis/challenge.service';
import { SweetAlertService } from '../../core/services/sweet-alert.service';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [CommonModule, RouterLink, RouterModule],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent {
    isAuthenticated$: Observable<boolean>;
    user$: Observable<any>;
    isPreviewPage: boolean = false;
    challengeId: string | null = null;
    queryParams: any;
    role:any
    constructor(
        private authService: AuthService,
        private store: Store<{ auth: AuthState }>,
        private router: Router,
        private challengeService: ChallengeService,
        private route: ActivatedRoute,
         private activatedRoute: ActivatedRoute,
         private alertService: SweetAlertService
    ) {
        this.isAuthenticated$ = this.authService.isAuthenticated();
        this.user$ = this.authService.getUser();
        this.challengeId=this?.route?.snapshot?.queryParams['id'];
        this.route.queryParams.subscribe(params => {
            // Access your query params here
          });
          this.user$.subscribe((user) => {
            if (user) {
               this.role=user.roles;
            }
        });
    }

    logout(): void {
        this.store.dispatch(AuthActions.logout());
    }

    ngOnInit() {
        // Check the current route and set the isPreviewPage flag
        this.router.events.subscribe(() => {
            this.isPreviewPage = this.router.url.includes(
                '/preview-challenge-details'
            );
        });

        this.route.queryParams.subscribe(params => {
            this.challengeId=params['id']
            // Access your query params here
          });



       // Retrieve challengeId from route parameters on component initialization
    //    this.route.paramMap.subscribe(params => {
    //     this.challengeId = params.get('id');
    //     console.log('challengeId from paramMap:', this.challengeId);
    // });

    // Debug using snapshot
    // const challengeIdSnapshot = this.route.snapshot.paramMap.get('id');
    // console.log('challengeId from snapshot:', challengeIdSnapshot);
    }

    navigateBackToEditing() {
        // Assume you get challengeId from the URL or some service
        // const challengeId = this.route.snapshot.params['id'];
        if (this.challengeId) {
            this.router.navigate(['/launch-a-challenge'], {
                queryParams: { id: this?.route?.snapshot?.queryParams['id'] }
            });
        } else {
            this.router.navigate(['/launch-a-challenge']);
        }
    }

    // publishChallenge(): void {
    //     this.route.paramMap.subscribe(params => {
    //         const challengeId = params.get('id');
    //         console.log('challengeId from route:', challengeId);

    //         if (challengeId) {
    //             this.store
    //                 .select(selectChallengeId)
    //                 .pipe(
    //                     take(1),
    //                     switchMap(() =>
    //                         this.challengeService.publishLaunchChallenge(challengeId)
    //                     ),
    //                     tap(() => {
    //                         console.log('Challenge published successfully');

    //                     }),
    //                     catchError(error => {
    //                         console.error('Error publishing challenge:', error);

    //                         return of(null);
    //                     })
    //                 )
    //                 .subscribe();
    //         } else {
    //             console.error('Challenge ID is not available');
    //         }
    //     });
    // }


    publishChallenge(): void {
        const challengeId = this?.route?.snapshot?.queryParams['id'];

        if(challengeId){
            this.challengeService.publishLaunchChallenge(challengeId).subscribe({
                next: (response: any) => {
                    this.alertService.successPopup(response.message);
                    this.router.navigate(['/the-challenges']);
                }
            })
        }
        // if (challengeId) {
        //     this.store
        //         .select(selectChallengeId)
        //         .pipe(
        //             take(1),
        //             switchMap(() =>
        //                 this.challengeService.publishLaunchChallenge(
        //                     challengeId
        //                 )
        //             ),
        //             tap(() => {
        //                 console.log('Challenge published successfully');
        //             }),
        //             catchError((error) => {
        //                 console.error('Error publishing challenge:', error);

        //                 return of(null);
        //             })
        //         )
        //         .subscribe();
        // } else {
        //     console.error('Challenge ID is not available');
        // }
    }

}
