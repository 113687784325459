const ARN_DELIMITER = ":";
const RESOURCE_DELIMITER = "/";
export const parseArn = value => {
  const segments = value.split(ARN_DELIMITER);
  if (segments.length < 6) return null;
  const [arn, partition, service, region, accountId, ...resourcePath] = segments;
  if (arn !== "arn" || partition === "" || service === "" || resourcePath.join(ARN_DELIMITER) === "") return null;
  const resourceId = resourcePath.map(resource => resource.split(RESOURCE_DELIMITER)).flat();
  return {
    partition,
    service,
    region,
    accountId,
    resourceId
  };
};