<h3 mat-dialog-title class="step-7-popup-heading">
    ACTIONABLE NO. 1 TEXT COMES HERE AS A SINGLE LINE
</h3>
<mat-dialog-content class="mat-typography" [formGroup]="formGroup">
    <div class="row">
        <div class="col-md-12 form-field-margin">
            <label class="form-label fw-semibold"
                >Why is the actionable item important?
            </label>
            <textarea
                class="form-control"
                formControlName="actionableImportance"
                rows="4"
            ></textarea>
            <app-input-error-message
                [formData]="formGroup"
                controlName="actionableImportance"
            ></app-input-error-message>
        </div>
        <div class="col-md-12 form-field-margin">
            <label class="form-label fw-semibold"
                >For whom is this important?
            </label>
            <textarea
                class="form-control"
                formControlName="targetAudience"
                rows="4"
            ></textarea>
            <app-input-error-message
                [formData]="formGroup"
                controlName="targetAudience"
            ></app-input-error-message>
        </div>

        <div class="col-md-12 form-field-margin">
            <label class="form-label fw-semibold"
                >What is the cost associated with making the actionable item a
                reality?
            </label>
            <textarea
                class="form-control"
                formControlName="associatedCost"
                rows="4"
            ></textarea>
            <app-input-error-message
                [formData]="formGroup"
                controlName="associatedCost"
            ></app-input-error-message>
        </div>
        <div class="col-md-12 form-field-margin">
            <label class="form-label fw-semibold"
                >What does success look like?
            </label>
            <textarea
                class="form-control"
                formControlName="successLooksLike"
                rows="4"
            ></textarea>
            <app-input-error-message
                [formData]="formGroup"
                controlName="successLooksLike"
            ></app-input-error-message>
        </div>
        <div class="col-md-12 form-field-margin" formGroupName="task">
            <label class="form-label fw-semibold"
                >Immediate Tasks To Be Taken Up
            </label>
            <div class="add-tasks-wrapper">
                <input class="form-control" formControlName="taskInput" />
                <div class="check-btn">
                    <button type="button check-btn" (click)="addTask()">
                        <img src="/images/common/check.png" />
                    </button>
                </div>
            </div>
            <app-input-error-message
                [formData]="taskForm"
                controlName="tasks"
            ></app-input-error-message>

            <div class="listing-wrapper">
                <div
                    class="list-item"
                    *ngFor="let task of tasks.controls; let i = index"
                >
                    <span class="title" (click)="editTask(i, task.value)">
                        {{ task.value.title }}
                    </span>
                    <span class="action" (click)="removeTask(i)">
                        <img src="/images/common/cancel.svg" />
                    </span>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        class="add-new-btn solver-dialog-btn"
        type="submit"
        (click)="onSubmit()"
    >
        Save
    </button>
</mat-dialog-actions>
