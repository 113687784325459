<h3 mat-dialog-title class="heading-mon-22">
    {{
        dialogData.type === weakSignalType.WEAK_SIGNAL
            ? "Add Weak Signal"
            : "Add Uncertainty"
    }}
</h3>
<mat-dialog-content class="mat-typography" [formGroup]="formGroup">
    <div class="row">
        <div class="col-md-12 form-field-margin">
            <label for="" class="form-label fw-semibold"></label>
            <div class="custom-ng-select-dropdown-list">
                <label for="sort" class="form-label fw-semibold">Select Domain</label>
                <ng-select [items]="domainList" bindValue="id" bindLabel="title" [closeOnSelect]="true"
                    [multiple]="false" [searchable]="false" formControlName="domain" class="form-control">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        {{ item?.title }}
                    </ng-template>
                </ng-select>
            </div>
            <app-input-error-message [formData]="formGroup" controlName="domain"></app-input-error-message>
        </div>
        <div class="col-md-9 form-field-margin">
            <label class="form-label fw-semibold">Title </label>
            <small class="form-text text-muted info-msg" style="margin-bottom: 10px; display: block">
                Briefly describe the weak signal (e.g., "Rise of Virtual Reality
                in Education")
            </small>
            <input formControlName="title" type="text" class="form-control" placeholder="" />
            <app-input-error-message [formData]="formGroup" controlName="title"></app-input-error-message>
        </div>
        <div class="col-md-3">
            <label for="" class="form-label fw-semibold">Date</label>
            <small class="form-text text-muted info-msg" style="margin-bottom: 10px; display: block">
                Help text
            </small>
            <div class="custom-calender">
                <mat-form-field appearance="fill">
                    <input matInput [matDatepicker]="datePickerRef" formControlName="weakSignalDate" readonly />
                    <mat-datepicker-toggle matSuffix [for]="datePickerRef">
                    </mat-datepicker-toggle>
                    <mat-datepicker #datePickerRef></mat-datepicker>
                </mat-form-field>
            </div>
            <app-input-error-message [formData]="formGroup" controlName="weakSignalDate"></app-input-error-message>
        </div>
        <div class="col-md-12 form-field-margin">
            <label class="form-label fw-semibold">Description </label>
            <textarea formControlName="description" class="form-control" placeholder=""></textarea>
            <app-input-error-message [formData]="formGroup" [controlName]="'description'">
            </app-input-error-message>
        </div>
        <div class="col-md-12 form-field-margin">
            <label class="form-label fw-semibold">Evidence / Early Signals
            </label>
            <small class="form-text text-muted info-msg" style="margin-bottom: 10px; display: block">
                Subtitle comes here
            </small>
            <textarea formControlName="evidenceOrEarlySignal" class="form-control" placeholder=""></textarea>
            <app-input-error-message [formData]="formGroup" [controlName]="'evidenceOrEarlySignal'">
            </app-input-error-message>
        </div>
        <div class="col-md-12 form-field-margin">
            <label class="form-label fw-semibold">Implications </label>
            <small class="form-text text-muted info-msg" style="margin-bottom: 10px; display: block">
                Subtitle comes here
            </small>
            <textarea formControlName="implications" type="text" class="form-control" placeholder=""></textarea>
            <app-input-error-message [formData]="formGroup" [controlName]="'implications'">
            </app-input-error-message>
        </div>

        <div>
            <label class="form-label fw-semibold">ASSESSEMENT </label>
            <div class="slider-container form-field-margin"
                style="border-top: 1px solid #bcbcbc; padding-top: 30px; margin-bottom: 50px !important;">
                <label class="form-label fw-semibold">How significant could the impact of this weak signal be if
                    it becomes a reality? [1 - minimal, 10 - transformational]
                </label>
                <ngx-slider [options]="options" formControlName="impact"></ngx-slider>
            </div>
            <div class="slider-container form-field-margin" style="margin-bottom: 50px !important;">
                <label class="form-label fw-semibold">How uncertain are you about the accuracy or interpretion of
                    this week signal? [1 - very uncertain, 10 - very certain]
                </label>
                <ngx-slider [options]="options" formControlName="uncertainty"></ngx-slider>
            </div>
            <div class="slider-container form-field-margin" style="margin-bottom: 50px !important;">
                <label class="form-label fw-semibold">What is the probability that the weak signal will becomes a
                    significant trend or reality in the next 10 years? [1 - very
                    low, 10 - very high]
                </label>
                <ngx-slider [options]="options" formControlName="probability"></ngx-slider>
            </div>

            <label class="form-label fw-semibold">How much time do you think this weak signal will take to become
                a strong trend or a reality?
            </label>
            <div style="display: flex" style="
                    border-bottom: 1px solid #bcbcbc;
                    padding-bottom: 50px;
                    margin-bottom: 20px;
                ">
                <div class="custom-radio">
                    <div class="form-check form-check-inline" style="margin-right: 60px">
                        <input class="form-check-input" type="radio" name="" id="" formControlName="timeRange"
                            [value]="weakSignalAssessmentTimeEnum.lessThan2Year" />

                        <label class="form-check-label checkbox-label" for="">
                            {{
                                weakSignalAssessmentTimeTitle[
                                    weakSignalAssessmentTimeEnum.lessThan2Year
                                ]
                            }}
                        </label>
                    </div>
                    <div class="form-check form-check-inline" style="margin-right: 60px">
                        <input class="form-check-input" type="radio" name="" id=""
                            [value]="weakSignalAssessmentTimeEnum.twoToFiveYear" formControlName="timeRange" />
                        <label class="form-check-label checkbox-label" for="">
                            {{
                                weakSignalAssessmentTimeTitle[
                                    weakSignalAssessmentTimeEnum.twoToFiveYear
                                ]
                            }}
                        </label>
                    </div>
                    <div class="form-check form-check-inline" style="margin-right: 60px">
                        <input class="form-check-input" type="radio" name="" id=""
                            [value]="weakSignalAssessmentTimeEnum.fiveToTenYear" formControlName="timeRange" />
                        <label class="form-check-label checkbox-label" for="">
                            {{
                                weakSignalAssessmentTimeTitle[
                                    weakSignalAssessmentTimeEnum.fiveToTenYear
                                ]
                            }}
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="" id="" [value]="
                                weakSignalAssessmentTimeEnum.moreThanFiveYear
                            " formControlName="timeRange" />
                        <label class="form-check-label checkbox-label" for="">
                            {{
                                weakSignalAssessmentTimeTitle[
                                    weakSignalAssessmentTimeEnum
                                        .moreThanFiveYear
                                ]
                            }}
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12 form-field-margin">
            <label class="form-label fw-semibold">Additional Notes </label>
            <small class="form-text text-muted info-msg" style="margin-bottom: 10px; display: block">
                Any additional context, insights, or questions related to the
                weak signal
            </small>
            <textarea formControlName="additionalNotes" type="text" class="form-control" placeholder=""></textarea>
            <app-input-error-message [formData]="formGroup" [controlName]="'additionalNotes'">
            </app-input-error-message>
        </div>
        <div class="col-md-12 form-field-margin">
            <label class="form-label fw-semibold">Tags </label>
            <input placeholder="New keyword..." [matChipInputFor]="reactiveChipGrid"
                (matChipInputTokenEnd)="addTagList($event)" />
            <mat-chip-grid #reactiveChipGrid formControlName="tags">
                @for (keyword of tagList(); track $index;) {
                <mat-chip-row (removed)="removeTagList(keyword)" class="type">
                    {{ keyword }}
                    <button matChipRemove aria-label="'remove reactive form' + keyword">
                        <span>x</span>
                    </button>
                </mat-chip-row>
                }
            </mat-chip-grid>
        </div>
        <div class="col-md-12 form-field-margin">
            <label class="form-label fw-semibold">Related Weak Signals </label>
            <textarea formControlName="relatedWeakSignals" type="text" class="form-control" placeholder=""></textarea>
            <app-input-error-message [formData]="formGroup" [controlName]="'relatedWeakSignals'">
            </app-input-error-message>
        </div>

        <div class="col-md-12 form-field-margin">
            <label class="form-label fw-semibold">Citations </label>
            <textarea formControlName="citations" type="text" class="form-control" placeholder=""></textarea>
            <app-input-error-message [formData]="formGroup" [controlName]="'citations'">
            </app-input-error-message>
        </div>
    </div>

    <app-input-solution-references [showPromptBox]="false" [formGroup]="formGroup"
        [solutionReferences]="solutionReferences"></app-input-solution-references>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="add-new-btn solver-dialog-btn" type="submit" (click)="onSubmitClick()">
        {{
            dialogData.type === weakSignalType.WEAK_SIGNAL
                ? "Add Weak Signal"
                : "Add Uncertainty"
        }}
    </button>
</mat-dialog-actions>
