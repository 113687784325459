<div class="container-fluid">
    <form [formGroup]="basicInformationForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-md-9">
                <div class="form-field-margin">
                    <label class="form-label fw-semibold">Challenge Hero Image <span
                            style="color: #B21818;">*</span></label>
                    <div class="upload-info-wrapper">
                        <label for="challengeHeroImage" class="image-upload-wrapper">+</label>
                        <input type="file" (change)="onFileChange($event)" class="form-control" style="display: none;"
                            accept=".jpg, .jpeg, .bmp, .png" id="challengeHeroImage">
                        <small class="form-text text-muted info-msg">Documents of .jpg, .jpeg, .bmp, .png for size
                            not more than 10 MB can be uploaded here</small>
                    </div>
                    <div *ngIf="selectedImage" class="uploaded-files-wrapper mt-4">
                        <label formControlName="imageName">{{ selectedImage.name }}</label>
                        <span class="cancel" (click)="removeImage()"><img src="/images/common/cancel.svg" /></span>
                    </div>
                    <div
                        *ngIf="basicInformationForm.get('imageName')?.hasError('required') && basicInformationForm.get('imageName')?.touched">
                        <small class="form-text text-danger">Image is required.</small>
                    </div>

                    <!-- Show file type error for image name  -->
                    <div
                        *ngIf="basicInformationForm.get('imageName')?.hasError('invalidFileType') && basicInformationForm.get('imageName')?.touched">
                        <small class="form-text text-danger">Invalid file type. Only .jpg, .jpeg, .bmp, .png formats are
                            allowed.</small>
                    </div>
                    <!-- Show file size error for imageName -->
                    <div
                        *ngIf="basicInformationForm.get('imageName')?.hasError('fileSizeExceeded') && basicInformationForm.get('imageName')?.touched">
                        <small class="form-text text-danger">File size exceeded. The maximum allowed size is
                            10MB.</small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row form-field-margin">
            <div class="col-md-6">
                <label for="title" class="form-label fw-semibold">Challenge Title <span style="color: #B21818;">*</span>
                    <span class="label-info">(50
                        characters
                        max.)</span></label>
                <input type="text" formControlName="title" class="form-control" id="title" placeholder="Challenge Title"
                    maxlength="50">
                <app-input-error-message [formData]="basicInformationForm" [controlName]="'title'">
                </app-input-error-message>
            </div>

            <div class="col-md-6">
                <label for="challengeOwnerId" class="form-label fw-semibold">Challenge Owner <span
                        style="color: #B21818;">*</span></label>
                <!-- <select formControlName="challengeOwnerId" class="form-select" id="challengeOwnerId">
                    <option *ngFor="let owner of challengeOwners" [value]="owner.id">{{ owner.title }}</option>
                </select> -->
                <div class="custom-ng-select-dropdown-list">
                    <ng-select [items]="challengeOwners" bindLabel="title" bindValue="id"
                        placeholder="Select Challenge Owner" formControlName="challengeOwnerId" class="form-control"
                        id="challengeOwnerId">
                    </ng-select>
                </div>
                <app-input-error-message [formData]="basicInformationForm" [controlName]="'challengeOwnerId'">
                </app-input-error-message>
            </div>
        </div>

        <div class="row form-field-margin">
            <div class="col-md-6">
                <label for="category" class="form-label fw-semibold">Challenge Category <span
                        style="color: #B21818;">*</span></label>
                <div class="custom-ng-select-dropdown-list multi-select-with-chip">
                    <!-- [compareWith]="compareWith" -->
                    <ng-select #categorySelect [items]="categories" [multiple]="true" bindLabel="title"
                        [selectableGroupAsModel]="false" [closeOnSelect]="true" bindValue="id"
                        (add)="onSelectionChange($event)" (remove)="onRemoveChange($event)" formControlName="category"
                        [compareWith]="compareWith" [searchable]="true" [addTag]="true" class="form-control">
                        <!-- Template for the selected items as chips -->
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <div class="ng-value">
                                <span *ngFor="let item of items" class="ng-value-label">
                                    {{ item.title }}
                                    <span class="ng-value-icon left" (click)="clear(item)">
                                        &times;
                                    </span>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{ index }}" type="checkbox" [checked]="isSelected(item)" />
                            <span>{{ item?.title }}</span>
                        </ng-template>

                    </ng-select>
                    <!-- show chips below -->
                    <div class="challenges-type-wrapper">
                        <span class="type" *ngFor="let item of selectedCategories">
                            <span *ngIf="item?.title; else stringItem">{{ item?.title }}</span>
                            <ng-template #stringItem>{{ item }}</ng-template>
                            <span class="cancel" (click)="onRemoveChange(item)">x</span>
                        </span>
                    </div>


                    <!-- <div class="custom-ng-select-dropdown-list">
                    <ng-select [items]="categories" bindLabel="title" bindValue="title" placeholder="Select categories"
                        [multiple]="true" [searchable]="true" [addTag]="true" formControlName="category"
                        [compareWith]="compareWith" class="form-control">
                       
                        <ng-template ng-tag-tmp let-item="item" let-clear="clear">
                            <span class="challenges-type-wrapper type">
                                {{ item.title }}
                            </span>
                        </ng-template>
                    </ng-select> -->
                    <!-- <ng-select [items]="categories" bindLabel="title" bindValue="title" placeholder="Select categories"
                        [multiple]="true" [searchable]="true" [addTag]="true" formControlName="category"
                        [compareWith]="compareWith" class="form-control">
                    </ng-select> -->
                    <app-input-error-message [formData]="basicInformationForm" [controlName]="'category'">
                    </app-input-error-message>

                </div>


            </div>
            <!-- Multi Country -->
            <div class="col-md-6">
                <div class="mb-3">
                    <label for="geographicScopeType" class="form-label fw-semibold">Select Geographic Scope <span
                            style="color: #B21818;">*</span></label>
                    <div class="geographic-scope">
                        <!-- <select formControlName="geographicScopeType" class="form-select" id="geographicScopeType"
                            (change)="onGeographicScopeChange($event)">
                            <option value="" disabled selected>Select Geographic Scope</option>
                            <option value="Global">Global</option>
                            <option value="Multi Country">Multi Country</option>
                            <option value="National">National</option>
                            <option value="Local">Local</option>
                        </select> -->
                        <div class="custom-ng-select-dropdown-list"
                            style="max-width: 160px; width: 100%; margin-right: 10px;">
                            <ng-select [items]="['Global', 'Multi Country', 'National', 'Local']" placeholder="Select"
                                formControlName="geographicScopeType" (change)="onGeographicScopeChange($event)"
                                class="form-control">
                            </ng-select>
                        </div>

                        <!-- <ng-select [items]="geographicScopes" 
                                    bindLabel="label"
                                    bindValue="value"
                                    placeholder="Select Geographic Scope"
                                    formControlName="geographicScopeType"
                                    class="form-control"
                                    id="geographicScopeType"
                                    (change)="onGeographicScopeChange($event)">
                        </ng-select> -->
                        <!-- Single input field shown for all options -->
                        <input formControlName="geographicScopeValue" type="text" class="form-control"
                            placeholder="Please specify the geographic scope type">
                    </div>
                    <!-- Validation Messages -->
                    <small class="text-danger">
                        {{ validationMessageService.getMessage(basicInformationForm, 'geographicScopeType') }}
                    </small>
                    <small class="text-danger">
                        {{ validationMessageService.getMessage(basicInformationForm, 'geographicScopeValue') }}
                    </small>
                </div>
            </div>

        </div>

        <div class="row form-field-margin">
            <div class="col-md-6">
                <label for="challengeType" class="form-label fw-semibold">Challenge Type <span
                        style="color: #B21818;">*</span></label>
                <!-- <select formControlName="hasActionableFuture" class="form-select" id="hasActionableFuture">
                    <option value="" disabled selected>Challenge Type</option>
                    <option value="Actionable Futures">Actionable Futures</option>
                    <option value="Regular">Regular</option>
                </select> -->
                <div class="custom-ng-select-dropdown-list">
                    <ng-select [items]="challengeTypes" bindLabel="label" bindValue="value" placeholder="Challenge Type"
                        formControlName="hasActionableFuture" class="form-control" id="hasActionableFuture">
                    </ng-select>
                </div>
                <app-input-error-message [formData]="basicInformationForm" [controlName]="'hasActionableFuture'">
                </app-input-error-message>
            </div>

            <div class="col-md-6">
                <div class="custom-ng-select-dropdown-list">
                    <label for="organizationType" class="form-label fw-semibold">Organization Type </label>
                    <div class="custom-ng-select-dropdown-list">
                        <ng-select placeholder="Select Organization Type" [items]="organisationsType" bindLabel="title"
                            bindValue="title" [compareWith]="compareWith" [searchable]="true" [addTag]="true"
                            formControlName="orgType" class="form-control">
                        </ng-select>
                    </div>
                </div>
                <app-input-error-message [formData]="basicInformationForm" [controlName]="'orgType'">
                </app-input-error-message>
            </div>
        </div>

        <div class="row form-field-margin">
            <div class="col-md-10">
                <div style="display: flex;">
                    <label class="form-label fw-semibold" style="margin-right: 40px;">Challenge Management
                        Authority <span style="color: #B21818;">*</span></label>
                    <div class="custom-radio">
                        <div class="form-check form-check-inline" style="margin-right: 60px;">
                            <input class="form-check-input" type="radio" name="challengeManagementAuthority"
                                id="multiverz" formControlName="challengeManagementAuthority" value="Multiverz">
                            <label class="form-check-label checkbox-label" for="multiverz">Multiverz</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="challengeManagementAuthority"
                                id="challengeOwner" value="ChallengeOwner"
                                formControlName="challengeManagementAuthority"
                                [attr.disabled]="isChallengeOwnerDisabled ? true : null">
                            <label class="form-check-label checkbox-label" for="challengeOwner">Challenge Owner</label>
                        </div>
                    </div>
                    <app-input-error-message [formData]="basicInformationForm"
                        [controlName]="'challengeManagementAuthority'">
                    </app-input-error-message>
                </div>
            </div>
        </div>


        <div class="row form-field-margin">
            <div class="col-12">
                <app-quill-editor label="Challenge Description" infoText="" controlName="shortDescription"
                    placeholder="" [parentForm]="basicInformationForm" [required]="true">
                </app-quill-editor>
                <div *ngIf="basicInformationForm.get('shortDescription')?.hasError('wordCount')">
                    <small class="text-danger">Description cannot exceed 150 words.</small>
                </div>
                <app-input-error-message [formData]="basicInformationForm" [controlName]="'shortDescription'">
                </app-input-error-message>
            </div>
        </div>

    </form>
</div>