// Success messages
export enum SuccessMessages {
POST_INTEREST_DELETE = 'Post Interest Deleted Successfully',
CHALLENGE_DELETE = "Challenege Deleted Successfully",
}

// Error messages
export enum ErrorMessages {
COMMON_ERROR = 'Something went wrong. Please try again later',
}
