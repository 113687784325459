<div class="container mt-5">
    <div class="row">
        <div class="col-md-6">
            <a [routerLink]="['/the-challenges']" [queryParams]="{tab:1}" class="launch-challenge-back-wrapper">&#60;
                Back to Overview</a>
        </div>
        <div class="col-md-6 text-end mb-2">
            <span class="launch-challenge-back-wrapper"> Status:
                {{showStatus(receivedTimeline?.startDate,receivedTimeline?.endDate,receivedStatus)}}</span>
        </div>
        <hr>
    </div>
    <div class="row mt-3">
        <div class="col-12 text-center color-text">
            <h1 class="color-text">Launch A Challenge</h1>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12 px-0">
            <mat-horizontal-stepper [linear]="true" #stepper [selectedIndex]="currentStep - 1"
                (selectionChange)="handleStepChange($event)">
                <ng-container *ngFor="let step of steps; let i = index">
                    <mat-step [label]="step.title" [stepControl]="getStepControl(i)"
                        [ngClass]="{'completed-stepper': stepsCompleted[i]}" [optional]="i !== 0">
                        <!-- Step Content -->
                        <ng-container [ngSwitch]="i + 1" class='stepper-details'>
                            <app-basic-information-form (statusFetched)="handleStatusFetched($event)" *ngSwitchCase="1"
                                (idGenerated)="handleIdGenerated($event)" (successEvent)="handleStepSuccess()">
                            </app-basic-information-form>
                            <app-problem-statement-form *ngSwitchCase="2" (successEvent)="handleStepSuccess()">
                            </app-problem-statement-form>
                            <app-challenge-details-form *ngSwitchCase="3" (successEvent)="handleStepSuccess()">
                            </app-challenge-details-form>
                            <app-sdgs-form *ngSwitchCase="4" (successEvent)="handleStepSuccess()"></app-sdgs-form>
                            <app-prizes-reward-form *ngSwitchCase="5" (successEvent)="handleStepSuccess()">
                            </app-prizes-reward-form>
                            <app-guidelines-information-form *ngSwitchCase="6" (successEvent)="handleStepSuccess()">
                            </app-guidelines-information-form>
                            <app-evaluation-criteria-form *ngSwitchCase="7" (successEvent)="handleStepSuccess()">
                            </app-evaluation-criteria-form>
                            <app-timelines-form *ngSwitchCase="8" (timelineDetails)="handleTimelineFetched($event)"
                                (successEvent)="handleStepSuccess()"></app-timelines-form>
                            <app-resources-form *ngSwitchCase="9" (successEvent)="handleStepSuccess()">
                            </app-resources-form>
                            <app-faqs-form *ngSwitchCase="10" (successEvent)="handleStepSuccess()"></app-faqs-form>
                        </ng-container>

                        <!-- Navigation buttons -->
                        <div class="row mt-4" style="margin-bottom: 30px; margin-right: 25px !important;">
                            <div class="col-12 text-end">
                                <button type="button"
                                    class="btn btn-gradient btn-bg-blue-gradient fw-semibold custom-btn"
                                    (click)="currentStep === 10 ? redirectToReviewPage() : onSaveAndNext()">
                                    {{ currentStep === 10 ? 'Review & Submit' : 'Save & Next' }}
                                    <span class="arrow-btn-wrapper">
                                        <img src="/images/home/right-arrow.svg" alt="Arrow" height="20"
                                            class="arrow-icon1" />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </mat-step>
                </ng-container>
            </mat-horizontal-stepper>
        </div>
    </div>



</div>


<!-- <div class="row mt-4">
    <div class="col-md-12 px-0">
        <mat-horizontal-stepper [linear]="true" #stepper [selectedIndex]="currentStep - 1">
            <ng-container *ngFor="let step of steps; let i = index">
                <mat-step [label]="step.title" [stepControl]="getStepControl(i)"
                    [ngClass]="{'completed-stepper': stepsCompleted[i]}" [optional]="i !== 0">
                  
                    <ng-container [ngSwitch]="i + 1" class='stepper-details'>
                        <app-basic-information-form (statusFetched)="handleStatusFetched($event)" *ngSwitchCase="1" (idGenerated)="handleIdGenerated($event)"
                            (successEvent)="handleStepSuccess()"></app-basic-information-form>
                        <app-problem-statement-form *ngSwitchCase="2" (successEvent)="handleStepSuccess()">
                        </app-problem-statement-form>
                        <app-challenge-details-form *ngSwitchCase="3" (successEvent)="handleStepSuccess()">
                        </app-challenge-details-form>
                        <app-sdgs-form *ngSwitchCase="4" (successEvent)="handleStepSuccess()"></app-sdgs-form>
                        <app-prizes-reward-form *ngSwitchCase="5" (successEvent)="handleStepSuccess()">
                        </app-prizes-reward-form>
                        <app-guidelines-information-form *ngSwitchCase="6" (successEvent)="handleStepSuccess()">
                        </app-guidelines-information-form>
                        <app-evaluation-criteria-form *ngSwitchCase="7" (successEvent)="handleStepSuccess()">
                        </app-evaluation-criteria-form>
                        <app-timelines-form *ngSwitchCase="8" (timelineDetails)="handleTimelineFetched($event)" (successEvent)="handleStepSuccess()">
                        </app-timelines-form>
                        <app-resources-form *ngSwitchCase="9" (successEvent)="handleStepSuccess()">
                        </app-resources-form>
                        <app-faqs-form *ngSwitchCase="10" (successEvent)="handleStepSuccess()"></app-faqs-form>
                    </ng-container>

                
                    <div class="row mt-4" style="margin-bottom: 30px; margin-right: 25px !important;">
                        <div class="col-12 text-end">
                            <button type="button"
                                class="btn btn-gradient btn-bg-blue-gradient fw-semibold custom-btn "
                                (click)="currentStep === 10 ? redirectToReviewPage() : onSaveAndNext()">
                                {{ currentStep === 10 ? 'Review & Submit' : 'Save & Next' }}
                                <span class="arrow-btn-wrapper">
                                    <img src="/images/home/right-arrow.svg" alt="Arrow" height="20"
                                        class="arrow-icon1" />
                                </span>
                            </button>
                        </div>
                    </div>
                </mat-step>
             
            </ng-container>
        </mat-horizontal-stepper>
    </div>
</div> -->
