import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InputErrorMessageComponent } from '../../../shared/input-error-message/input-error-message.component';
import { CommonValidators } from '../../../core/utilities/common-validators';
import {
    ImpactInitiativesTasksImmediateAction,
    TermActionable,
} from '../../types/solution-form-6-type';
import { SolverFlowService } from '../../../core/services/apis/solver-flow.service';

interface DialogData {
    solutionId: number;
    data: TermActionable;
}

@Component({
    selector: 'app-step-7-actionable-edit-dialog',
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        InputErrorMessageComponent,
    ],
    templateUrl: './step-7-actionable-edit-dialog.component.html',
    styleUrl: './step-7-actionable-edit-dialog.component.scss',
})
export class Step7ActionableEditDialogComponent implements OnInit {
    public formGroup!: FormGroup;
    public selectedTask: ImpactInitiativesTasksImmediateAction | null = null;

    constructor(
        private readonly formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<Step7ActionableEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private readonly solverFlowService: SolverFlowService
    ) {
        this.formGroup = this.formBuilder.group({
            actionableImportance: ['', Validators.required],
            targetAudience: ['', Validators.required],
            associatedCost: ['', Validators.required],
            successLooksLike: ['', Validators.required],
            task: this.formBuilder.group({
                taskInput: [''],
                tasks: this.formBuilder.array(
                    [],
                    [
                        CommonValidators.minArrayItemValidator(1),
                        CommonValidators.maxArrayItemValidator(5),
                        CommonValidators.duplicateArrayItemValidator('title'),
                    ]
                ),
            }),
        });
    }

    ngOnInit(): void {
        if (this.data.data.highImpactInitiative) {
            const data = this.data.data.highImpactInitiative;
            this.formGroup.patchValue({
                actionableImportance: data.actionableImportance,
                targetAudience: data.targetAudience,
                associatedCost: data.associatedCost,
                successLooksLike: data.successLooksLike,
            });
            data.immediateActions.forEach((item) => {
                this.tasks.push(
                    this.formBuilder.control({
                        title: item.title,
                        id: item.id,
                    })
                );
            });
        }
    }

    get taskForm() {
        return this.formGroup.get('task') as FormGroup;
    }

    get tasks() {
        return this.taskForm.get('tasks') as FormArray;
    }

    addTask() {
        const taskInputValue = this.taskForm.get('taskInput')?.value;
        this.tasks.push(
            this.formBuilder.control({
                title: taskInputValue,
                id: this.selectedTask?.id,
            })
        );
        this.taskForm.get('taskInput')?.reset(); // Clear input field after adding
        this.selectedTask = null;
    }

    // Function to edit a task
    editTask(index: number, task: ImpactInitiativesTasksImmediateAction) {
        this.selectedTask = task;
        const selectedTask = this.tasks.at(index).value;
        this.taskForm.get('taskInput')?.setValue(selectedTask.title);

        // Remove the selected task from the array to allow editing
        this.tasks.removeAt(index);
    }

    removeTask(index: number) {
        this.tasks.removeAt(index);
    }

    async onSubmit() {
        this.formGroup.markAllAsTouched();
        if (this.formGroup.valid) {
            const values = this.formGroup.value;
            values.immediateActions = values.task.tasks;
            delete values.task;
            values.termActionableId = this.data.data.id;
            // this.dialogRef.close(values);
            this.solverFlowService
                .UpdateImpactInitiativesTasksRequestStep7(
                    this.data.solutionId as number,
                    values
                )
                .subscribe((res) => this.dialogRef.close(res));
        }
    }
}
