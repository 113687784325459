import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChallengeService } from '../core/services/apis/challenge.service';
import { SweetAlertService } from '../core/services/sweet-alert.service';

@Component({
  selector: 'app-payment-status-component',
  standalone: true,
  imports: [],
  templateUrl: './payment-status-component.html',
  styleUrl: './payment-status-component.scss'
})
export class PaymentStatusComponentComponent {
  sessionId: any;
  challengeId: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ChallengeService: ChallengeService,
    private sweetAlertService: SweetAlertService
  ) { }
  ngOnInit(): void {
    this.sessionId = this.route.snapshot.queryParams['session_id'];
    this.ChallengeService.getSessionStatus(this.sessionId).subscribe(
      (res) => {
      this.challengeId = res.challengeId;
    },
    (error)=>{
        console.error(error, 'error');
      }
    )
  }
  redirectToSolver(){
    this.router.navigate(['/solver-flow',this.challengeId]);
  }
}
