import {
    ApplicationConfig,
    importProvidersFrom,
    provideZoneChangeDetection,
    isDevMode,
} from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { AuthEffects } from './core/store/auth/auth.effects';
import { authReducer } from './core/store/auth/auth.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';
import { launchChallengeReducer } from './core/store/launchChallengeForm/launchChallenge.reducer';
import { LaunchChallengeEffects } from './core/store/launchChallengeForm/launchChallenge.effects';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideNgxStripe, StripeService } from 'ngx-stripe';
import { environment } from '../environments/environment';

export function localStorageSyncReducer(reducer: any) {
    return localStorageSync({
        keys: ['auth'],
        rehydrate: true,
        storage: localStorage,
    })(reducer);
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideNgxStripe(environment.stripeKey),
        importProvidersFrom(JwtModule.forRoot({})),
        provideHttpClient(withFetch()),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(
            routes,
            withInMemoryScrolling({
                scrollPositionRestoration: 'top',
            })
        ),
        // provideClientHydration(), this is for ssr
        provideStore(
            {
                auth: authReducer,
                launchChallenge: launchChallengeReducer,
            },
            { metaReducers: [localStorageSyncReducer] }
        ),
        provideEffects([AuthEffects, LaunchChallengeEffects]),
        provideStoreDevtools({
            maxAge: 25, // Retains last 25 states
            logOnly: !isDevMode(), // Restrict extension to log-only mode
            autoPause: true, // Pauses recording actions and state changes when the extension window is not open
            trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
            traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
            connectInZone: true, // If set to true, the connection is established within the Angular zone
        }),
        provideAnimationsAsync(),
    ],
};
