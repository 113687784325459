import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from './auth.service';
import { EditUserProfileDto } from '../../Model/edit-profile.dto';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    token: string = '';
    constructor(private http: HttpClient, private authService: AuthService) {
        this.authService.getToken().subscribe((token) => {
            if (token) {
                this.token = token;
            }
        });
    }
    // organizationName: string, organizationUrl: string,contactFirstName: string, contactLastName: string,contactEmail: string
    update(data: EditUserProfileDto): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', `Bearer ${this.token}`);

        return this.http.patch(
            `${environment.apiUrl}/users/edit-profile`,
            data,
            { headers }
        );
    }

    changePassword(data: {
        oldPassword: string;
        newPassword: string;
    }): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', `Bearer ${this.token}`);

        return this.http.patch(
            `${environment.apiUrl}/users/change-password`,
            data,
            { headers }
        );
    }
}
