<div [class]="customClass" [formGroup]="parentForm">
    <label [for]="controlName" class="form-label fw-semibold">{{ label }} <span *ngIf="required"
            style="color: #B21818;">*</span>
        <span *ngIf="infoTextWord" class="info-msg">(max
            500 words)</span></label>
    <small class="form-text text-muted info-msg">{{ infoText }}</small>
    <div>
        <quill-editor [formControlName]="controlName" [ngStyle]="{ height: '200px', width: '100%' }"
            [placeholder]="placeholder" [modules]="editorModules">
        </quill-editor>
    </div>
</div>
