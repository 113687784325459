import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-challenge-details-guidelines',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './challenge-details-guidelines.component.html',
  styleUrl: './challenge-details-guidelines.component.scss'
})
export class ChallengeDetailsGuidelinesComponent {
  @Input() guidelines = '';
  @Input() challenge: any = {};
  eligbilityCriteria: string = ''
  participantsRequirements: string = ''
  custom: {
    ageRestriction: string,
    geographicRestrictions: string,
    otherRestrictions: string
  } = {
      ageRestriction: '',
      geographicRestrictions: '',
      otherRestrictions: ''
    };
  teamRequirements: string = ''
  constructor() {


  }
  ngOnInit() {
    this.eligbilityCriteria = this.challenge?.challengeGuideline?.participantEligibility === 'CUSTOM' ? 'Custom' : 'Open to All';

    this.custom['ageRestriction'] = this.challenge?.challengeGuideline?.metaData?.ageRestrictions ?? '';
    this.custom['geographicRestrictions'] = this.challenge?.challengeGuideline?.metaData?.geographicRestrictions ?? '';
    this.custom['otherRestrictions'] = this.challenge?.challengeGuideline?.metaData?.otherRestrictions ?? '';

    this.teamRequirements = this.challenge?.challengeGuideline?.teamRequirements === "TEAM" ?
      'Team required of size - ' + this?.challenge?.challengeGuideline?.metaData?.teamSizeLimit
      : this.challenge?.challengeGuideline?.teamRequirements === "INDIVIDUAL" ?
        'Individual participation allowed' :
        this.challenge?.challengeGuideline?.teamRequirements === "BOTH" ? 'Both individual and team involvement allowed with team size of ' + this?.challenge?.challengeGuideline?.metaData?.teamSizeLimit : ''
  }
}
