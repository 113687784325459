<div class="container">
    <form [formGroup]="evaluationCriteriaForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-md-12 form-field-margin">
                <app-quill-editor label="Judging Process"
                    infoText="Describe the evaluation stages and methods (e.g., initial screening, finalist selection, live pitches)"
                    controlName="judgingProcess" placeholder="" [parentForm]="evaluationCriteriaForm">
                </app-quill-editor>

                <app-input-error-message [formData]="evaluationCriteriaForm" [controlName]="'judgingProcess'">
                </app-input-error-message>
            </div>
            <div class="col-md-12 form-field-margin">
                <app-quill-editor label="Evaluation Criteria"
                    infoText="For each criterion, provide a clear description and its relative importance (e.g., percentage weighting)"
                    controlName="evaluationCriteria" placeholder="" [parentForm]="evaluationCriteriaForm" [required]="true">
                </app-quill-editor>

                <app-input-error-message [formData]="evaluationCriteriaForm" [controlName]="'evaluationCriteria'">
                </app-input-error-message>
            </div>
            <div class="col-md-12 form-field-margin">
                <app-quill-editor label="Judges and Expertise"
                    infoText="Describe the composition of the judging panel and their relevant expertise"
                    controlName="judgesAndExpertise" placeholder="" [parentForm]="evaluationCriteriaForm">
                </app-quill-editor>

                <app-input-error-message [formData]="evaluationCriteriaForm" [controlName]="'judgesAndExpertise'">
                </app-input-error-message>
            </div>
        </div>
    </form>
</div>
