<div class="custom-container-fluid">
    <div class="container">
        <div class="row my-4">
            <div class="col-md-5">
                <a routerLink="/">
                    <img src="/logo.svg" alt="Main Logo" height="40px" />
                </a>
                <div class="form-container mt-5 pt-5">
                    <h2 class="fw-semibold">Forgot Password</h2>

                    <form [formGroup]="emailVerificationForm">
                        <div class="mb-3 mt-5">
                            <label for="inputEmail" class="form-label fw-semibold fs-15px">Email ID</label>
                            <input type="email" formControlName="email" class="form-control" id="inputEmail" />

                            <small class="text-danger">
                                {{
                                validationMessageService.getMessage(
                                    emailVerificationForm,
                                    "email"
                                )
                            }}
                            </small>
                        </div>
                        <button type="submit" (click)="onForgotPassword()"
                            class="btn btn-gradient btn-bg-blue-gradient mt-4 custom-btn">FORGOT PASSWORD
                            <span class="arrow-btn-wrapper"> <img src="/images/home/right-arrow.svg" alt="Arrow"
                                    height="20" class="arrow-icon1"></span>
                        </button>
                    </form>
                </div>
            </div>
            <div class="col-md-7">
                <div class="right-img-container">
                    <img src="/images/login/main.png" class="img-fluid" alt="Login Main" />
                </div>
            </div>
        </div>
    </div>
</div>
