<div class="faq-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="accordion-wrapper">
                    <div class="accordion custom-accordion" id="accordionExample">
                        @for(question of challenge.challengeQuestions;track question.id; let idx = $index){
                        <div class="accordion-item" style="margin-bottom: 20px;">
                            <h2  class="accordion-header" [id]="'heading' + question.id">
                                <button  class="accordion-button" type="button" data-bs-toggle="collapse"
                                    [attr.data-bs-target]="'#collapse' + question.id" [attr.aria-expanded]="idx === 0 ? 'true' : 'false'"
                                    [attr.aria-controls]="'collapse' + question.id"  [ngClass]="{'collapsed': idx !== 0}" >
                                    {{question.title}}
                                    
                                </button>
                            </h2>
                            <div [id]="'collapse' + question.id" class="accordion-collapse collapse " [ngClass]="{'show': idx==0}"
                                [attr.aria-labelledby]="'heading' + question.id" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    {{question.answer}}
                                </div>
                            </div>
                        </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    </div>
