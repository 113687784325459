import { Component } from '@angular/core';

@Component({
  selector: 'app-regular-solver-form',
  standalone: true,
  imports: [],
  templateUrl: './regular-solver-form.component.html',
  styleUrl: './regular-solver-form.component.scss'
})
export class RegularSolverFormComponent {

}
