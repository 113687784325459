import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    inject,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { ProblemStatementDto } from '../../core/Model/problem-statement.dto';
import { InputErrorMessageComponent } from '../input-error-message/input-error-message.component';
import { AppState } from '../../core/store/Global/app.state';
import { Store } from '@ngrx/store';
import { LaunchChallengeState } from '../../core/store/launchChallengeForm/launchChallenge.state';
import {
    loadProblemStatement,
    updateProblemStatement,
} from '../../core/store/launchChallengeForm/launchChallenge.actions';
import {
    selectChallengeId,
    selectProblemStatement,
    selectProblemStatementError,
} from '../../core/store/launchChallengeForm/launchChallenge.selectors';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
    catchError,
    filter,
    of,
    Subject,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs';
import { QuillEditorComponent } from '../quill-editor/quill-editor.component';
import { error } from 'console';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { ChallengeService } from '../../core/services/apis/challenge.service';

@Component({
    selector: 'app-problem-statement-form',
    standalone: true,
    imports: [
        CommonModule,
        QuillModule,
        ReactiveFormsModule,
        InputErrorMessageComponent,
        QuillEditorComponent,
    ],
    templateUrl: './problem-statement-form.component.html',
    styleUrl: './problem-statement-form.component.scss',
})
export class ProblemStatementFormComponent implements OnInit, OnDestroy {
    problemStatementForm!: FormGroup;
    private unsubscribe$ = new Subject<void>();
    route: ActivatedRoute = inject(ActivatedRoute);
    @Output() successEvent = new EventEmitter<void>();
    challengeId: any;
    constructor(
        private fb: FormBuilder,
        protected validationMessageService: ValidationMessageService,
        private store: Store<{ launchChallenge: LaunchChallengeState }>,
        private router: Router,
        private alertService: SweetAlertService,
        private challengeService: ChallengeService
    ) {
        this.statementForm();
    }

    statementForm(){
        this.problemStatementForm = this.fb.group({
            detailedProblemStatement: [
                '',
                [Validators.required, Validators.maxLength(5000)],
            ],
            rootCauses: [''],
            previousAttempts: ['', Validators.maxLength(5000)],
            urgencyImportance: ['', Validators.maxLength(5000)],
            targetBeneficiaries: ['', Validators.maxLength(5000)],
        });
    }

    ngOnInit() {
        // const id1 = this.route.snapshot.queryParams['id'];
        this.challengeId = this.route.snapshot.queryParams['id'];
        // Call loadProblemStatement only if 'id1' or 'id2' is present
        if (this.challengeId) {
            this.loadProblemStatement();
        }
        // Fetch initial data for the form
    }

    loadProblemStatement(): void {
        // Retrieve the challengeId from the route parameters
        const challengeId = this.challengeId;// This is the dynamic challenge ID from the route
        // Ensure challengeId is available
        if (challengeId) {
            this.challengeService
                .getProblemStatement(challengeId)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: (data) => {
                        // Patch the form with the problem statement data
                        this.problemStatementForm.patchValue({
                            detailedProblemStatement:
                            data.detailedProblemStatement,
                            rootCauses: data.rootCauses,
                            previousAttempts: data.previousAttempts,
                            urgencyImportance: data.urgencyImportance,
                            targetBeneficiaries: data.targetBeneficiaries,
                        });
                    },
                    error: (error: HttpErrorResponse) => {
                        // Handle error, possibly show an error message to the user
                        this.alertService.errorPopup(
                            error.error.message
                        );
                        console.error(
                            'Error loading problem statement:',
                            error
                        );
                    },
                });
        } else {
            // Handle the case where the challengeId is not available
            console.error('Challenge ID is missing from the route parameters.');
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    // NgRx
    // onSubmit() {
    //     this.problemStatementForm.markAllAsTouched();

    //     if (this.problemStatementForm.valid) {
    //         this.store
    //             .select(selectChallengeId)
    //             .pipe(take(1))
    //             .subscribe((challengeId) => {
    //                 if (challengeId) {
    //                     const formValues = this.problemStatementForm.value;

    //                     const problemStatementData: ProblemStatementDto = {
    //                         detailedProblemStatement: formValues.detailedProblemStatement,
    //                         rootCauses: formValues.rootCauses,
    //                         previousAttempts: formValues.previousAttempts,
    //                         urgencyImportance: formValues.urgencyImportance,
    //                         targetBeneficiaries: formValues.targetBeneficiaries,
    //                     };

    //                     // Clear errors before making the API call
    //                     this.validationMessageService.clearServerErrors(this.problemStatementForm);

    //                     // // Dispatch the update action Ngrx working code
    //                     // this.store.dispatch(
    //                     //     updateProblemStatement({
    //                     //         id: challengeId,
    //                     //         data: problemStatementData,
    //                     //     })
    //                     // );

    //                     // // Listen for any new errors after the API call
    //                     // this.store
    //                     //     .pipe(
    //                     //         withLatestFrom(this.store.select(selectProblemStatementError)),
    //                     //         tap(([_, error]) => {
    //                     //             if (error) {
    //                     //                 this.validationMessageService.setServerErrors(
    //                     //                     this.problemStatementForm,
    //                     //                     error
    //                     //                 );
    //                     //             } else {
    //                     //                 // Clear errors again in case they were fixed
    //                     //                 this.validationMessageService.clearServerErrors(this.problemStatementForm);
    //                     //             }
    //                     //         })
    //                     //     )
    //                     //     .subscribe();
    //                 }
    //             });
    //     }
    // }

    onSubmit() {
        this.problemStatementForm.markAllAsTouched();

        if (this.problemStatementForm.valid) {
            const challengeId = this.route.snapshot.queryParams['id'];
            // Replace this with the actual challengeId obtained in step 1
            const formValues = this.problemStatementForm.value;

            const problemStatementData: ProblemStatementDto = {
                detailedProblemStatement: formValues.detailedProblemStatement,
                rootCauses: formValues.rootCauses,
                previousAttempts: formValues.previousAttempts,
                urgencyImportance: formValues.urgencyImportance,
                targetBeneficiaries: formValues.targetBeneficiaries,
            };

            // Clear errors before making the API call
            this.validationMessageService.clearServerErrors(
                this.problemStatementForm
            );

            // Make the API call
            this.challengeService
                .updateProblemStatement(challengeId, problemStatementData)
                // .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: (response) => {
                        // Handle successful response
                        this.alertService.successPopup(response.message);
                        this.successEvent.emit();
                    },
                    error: (error: HttpErrorResponse) => {
                        // Handle error response
                        if (error.status === 422) {
                            const errors = error?.error?.errors;
                            if (errors) {
                                this.validationMessageService.setServerErrors(
                                    this.problemStatementForm,
                                    errors
                                );
                            }
                        } else {
                            this.alertService.errorPopup(error.error.message);
                        }
                    },
                });
        }
    }
}
