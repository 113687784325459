import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    inject,
    Input,
    input,
} from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ChallengeDetailsOverviewComponent } from '../challenge-details-overview/challenge-details-overview.component';
import { ChallengeDetailsGuidelinesComponent } from '../challenge-details-guidelines/challenge-details-guidelines.component';
import { ChallengeDetailsEvaluationComponent } from '../challenge-details-evaluation/challenge-details-evaluation.component';
import { ChallengeDetailsTimelineComponent } from '../challenge-details-timeline/challenge-details-timeline.component';
import { ChallengeDetailsResourcesComponent } from '../challenge-details-resources/challenge-details-resources.component';
import { ChallengeDetailsFaqComponent } from '../challenge-details-faq/challenge-details-faq.component';
import { ChallengeDetailsTalktousComponent } from '../challenge-details-talktous/challenge-details-talktous.component';
import { ChallengeService } from '../../../core/services/apis/challenge.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SweetAlertService } from '../../../core/services/sweet-alert.service';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AuthState } from '../../../core/store/auth/auth.state';
import { Store } from '@ngrx/store';
import { AuthService } from '../../../core/services/apis/auth.service';
import { Observable, switchMap } from 'rxjs';
import { StripeService } from 'ngx-stripe';
@Component({
    selector: 'app-challenge-details',
    standalone: true,
    imports: [
        MatTabsModule,
        ChallengeDetailsOverviewComponent,
        ChallengeDetailsGuidelinesComponent,
        ChallengeDetailsEvaluationComponent,
        ChallengeDetailsTimelineComponent,
        ChallengeDetailsResourcesComponent,
        ChallengeDetailsFaqComponent,
        ChallengeDetailsTalktousComponent,
        RouterModule,
    ],
    templateUrl: './challenge-details.component.html',
    styleUrl: './challenge-details.component.scss',
})
export class ChallengeDetailsComponent {
    user$: Observable<any>;
    existingSolutionId:any;
    challengeDetails = inject(ChallengeService);
    route: ActivatedRoute = inject(ActivatedRoute);
    alertService: SweetAlertService = inject(SweetAlertService);
    role: any;
    readonly legalDocumentDialog = inject(MatDialog);
    redirectToLogin() {
        this.router.navigate(['/login'], {
            queryParams: { redirectUrl: this.router.url },
        });
    }
    openDialog() {
        const dialogRef = this.legalDocumentDialog.open(
            DialogContentExampleDialog,
            {
                width: '100%',
                maxWidth: '1140px',
                height: 'calc(100vh - 120px)',
                data: { challengeId: this.challengeId },
            }
        );

        dialogRef.afterClosed().subscribe((result) => {});
    }
    isPreviewMode: any;
    redirectedFromOverview: boolean = false;
    challenge: any = {};
    challengeId: any;
    paymentStatus: any;
    constructor(
        private router: Router,
        private authService: AuthService,
        private store: Store<{ auth: AuthState }>
    ) {
        this.user$ = this.authService.getUser();

        this.challengeId = this.route.snapshot.queryParams['id']
            ? this?.route?.snapshot?.queryParams['id']
            : this?.route?.snapshot?.params['id'];
        this.route.url.subscribe((segments) => {
            this.isPreviewMode = segments.some(
                (segment) => segment.path === 'preview-challenge-details'
            );
            this.redirectedFromOverview = segments.some(
                (segment) => segment.path === 'overview'
            );
        });
        this.user$.subscribe((user) => {
            if (user) {
                this.role = user.roles;
            }
        });
    }

    ngOnInit() {
        this.challengeDetails.getChallengeDetail(this.challengeId).subscribe(
            (data) => {
                this.challenge = data;
            },
            (error) => {
                console.error('error from details page - ', error);
                this.alertService.errorToaster(error.error.message);
                // this.router.navigate(['/challenges-list'])
            }
        );
        this.user$ = this.authService.getUser();
        this.user$.subscribe((user) => {
            if (user) {
                this.role = user.roles;
            }
        });
        this.challengeDetails.checkChallengePaymentStatus(this.challengeId).subscribe({
            next: (response) => {
                console.log('response from check challenge payment status', response);
                this.paymentStatus = response.status;
            },
            error: (error) => {
                console.error('error from check challenge payment status', error);
            },
        })
        this.checkForExistingSolution()
    }

    checkForExistingSolution(){
        this.challengeDetails.fetchExistingSolution(this.challengeId).subscribe({
            next:(response)=>{
                console.log("response from existing solution id",response)
                this.existingSolutionId=response?.solution?.id
            },
            error:(error)=>{
                console.log("error from existing solution",error)
            }
        })
    }

    checksForPayment() {
        if (
            this.challenge.hasActionableFuture &&
            this?.challenge?.challengeRewards.some(
                (reward: any) => reward.title === 'Virtual Internship'
            )
        ) {
            return true;
        } else {
            return false;
        }
    }
    redirectToSolverForm() {
        if (this.checksForPayment()) {
            if (this.existingSolutionId) {
                this.router.navigate(['solver-flow', this.challengeId],{
                    queryParams:{
                        id:this.existingSolutionId,
                        step:1
                    }
                });
                
            }
           else if (this.paymentStatus) {
                this.router.navigate(['solver-flow', this.challengeId]);
            } else {
            this.openDialog();
            }
        } else {
            // redirect to regular solver form
        }
    }
}

@Component({
    selector: 'legal-document-dialog',
    templateUrl: 'legal-document-dialog.html',
    standalone: true,
    imports: [MatDialogModule, MatButtonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogContentExampleDialog {
    termsAndConditions: boolean = false;
    challengeId: any;
    constructor(
        private dailogRef: MatDialogRef<DialogContentExampleDialog>,
        private router: Router,
        private challengeService: ChallengeService,
        private stripeService: StripeService,
        private route: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        // this.challengeId=this?.route?.snapshot?.params['id']
        this.challengeId = data.challengeId;
    }
    handleTermsAndConditions() {
        this.termsAndConditions = !this.termsAndConditions;
    }
    ngOnInIt() {
        this.challengeId = this?.route?.snapshot?.params['id'];
    }

    agreeLegalTerms(): void {
        this.challengeService.agreeLegalTerms(this.challengeId, ).subscribe({
            next: (response) => {
                console.log('response from agree legal terms', response);
                this.dailogRef.close('After closing I agree terms and conditions');
                this.router.navigate(['proceed-to-payment', this.challengeId]);
            },
            error: (error) => {
                console.error('error from agree legal terms', error);
            },
            
        });
    }

    onAcceptingTermsAndConditions() {

        this.termsAndConditions&&  this.agreeLegalTerms();
    }
}
