import { Component, ElementRef, ViewChild } from '@angular/core';
import { QuillEditorComponent } from '../../shared/quill-editor/quill-editor.component';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { InputErrorMessageComponent } from '../../shared/input-error-message/input-error-message.component';
import { SolverFlowService } from '../../core/services/apis/solver-flow.service';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { ValidationMessageService } from '../../core/services/validation-message.service';
import { S3UploadService } from '../../core/services/apis/s3-upload.service';
import { CommonUtilities } from '../../core/utilities/common-utilities';
import { SolverFormStoreService } from '../store/solver-form-store.service';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-solver-flow-step-10',
    standalone: true,
    imports: [
        QuillEditorComponent,
        ReactiveFormsModule,
        InputErrorMessageComponent,
    ],
    templateUrl: './solver-flow-step-10.component.html',
    styleUrl: './solver-flow-step-10.component.scss',
})
export class SolverFlowStep10Component {
    solverFormStep10!: FormGroup;
    step10Data = {
        finalReportFileName: '',
        finalReportDisplayName: '',
        finalReportFileType: '',
        supportingFileName: '',
        supportingDisplayName: '',
        supportFileType: '',
    };
    solutionId: any;

    @ViewChild('inputFileRef') inputFileRef!: ElementRef;
    supportedFile: File | null = null;
    finalReport: File | null = null;

    constructor(
        private fb: FormBuilder,
        private solverFlowService: SolverFlowService,
        private alertService: SweetAlertService,
        protected validationMessageService: ValidationMessageService,
        private imageUploadService: S3UploadService,
        private solverFormStoreService: SolverFormStoreService
    ) {
        this.step10Form();
    }

    step10Form() {
        this.solverFormStep10 = this.fb.group({
            finalReport: [null, Validators.required],
            supportedFile: [null, Validators.required],
        });
    }

    async ngOnInIt() {
        this.fetchInitialData();
        this.solutionId = await firstValueFrom(
            this.solverFormStoreService.selectSolutionId$
        );
    }

    async fetchInitialData(): Promise<void> {
        const solutionId = await firstValueFrom(
            this.solverFormStoreService.selectSolutionId$
        );
        solutionId &&
            this.solverFlowService.fetchFinalReport(solutionId).subscribe({
                next: (data) => {
                    this.solverFormStep10.patchValue({
                        finalReport: {
                            fileName: data.finalReportFileName,
                            displayName: data.finalReportDisplayName,
                            fileType: data.finalReportFileType,
                        },
                        supportedFile: {
                            fileName: data.supportingFileName,
                            displayName: data.supportingDisplayName,
                            fileType: data.supportFileType,
                        },
                    });
                },
                error: (error) => {
                    this.alertService.errorToaster(
                        error.error.message ||
                            'Error submitting form, please try again'
                    );
                },
            });
    }

    async submitFinalReport(data: any, isDraft?: boolean): Promise<void> {
        const solutionId = await firstValueFrom(
            this.solverFormStoreService.selectSolutionId$
        );
        solutionId &&
            this.solverFlowService
                .uploadFinalReport(solutionId, data, isDraft)
                .subscribe({
                    next: (response) => {
                        this.alertService.successToaster(
                            'Final report uploaded successfully'
                        );
                    },
                    error: (error) => {
                        this.alertService.errorToaster(
                            error.error.message ||
                                'Error submitting form, please try again'
                        );
                    },
                });
    }

    onSubmit(isDraft?: boolean) {
        console.log('onSubmit called', isDraft);
        this.solverFormStep10.markAllAsTouched();
        if (this.solverFormStep10.valid || isDraft) {
            this.step10Data = {
                finalReportDisplayName:
                    this.solverFormStep10.value.finalReport?.name,
                finalReportFileName:
                    this.solverFormStep10.value.finalReport?.name,
                finalReportFileType:
                    this.solverFormStep10.value.finalReport?.type,
                supportingDisplayName:
                    this.solverFormStep10.value.supportedFile?.name,
                supportingFileName:
                    this.solverFormStep10.value.supportedFile?.name,
                supportFileType:
                    this.solverFormStep10.value.supportedFile?.type,
            };
            if (isDraft) {
                this.submitFinalReport(this.step10Data, isDraft);
            } else {
                this.alertService.confirmSubmission().subscribe({
                    next: (isConfirmed) => {
                        if (isConfirmed) {
                            this.submitFinalReport(this.step10Data, isDraft);
                        }
                    },
                });
            }
        } else {
            this.alertService.errorToaster(
                'Please fill all the required fields'
            );
        }
    }
    downloadReportTemplate() {
        // Logic to download the report template
        const link = document.createElement('a');
        link.href = 'path/to/report-template.pdf'; // Replace with the actual path to the report template
        link.download = 'report-template.pdf';
        link.click();
    }

    uploadFinalReport() {
        // Logic to upload the final report in PDF format
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'application/pdf';
        input.onchange = (event: any) => {
            const file = event.target.files[0];
            if (file) {
                this.finalReport = file;
                this.solverFormStep10.patchValue({ finalReport: file });

                // Use optional chaining to call uploadFileToS3 if finalReport is not null
                this.finalReport && this.uploadFileToS3(this.finalReport);
            }
        };
        input.click();
    }

    removeFile(type: 'finalReport' | 'supportedFile') {
        // Logic to remove the file
        if (type === 'finalReport') {
            this.finalReport = null;
            this.solverFormStep10.patchValue({ finalReport: null });
        } else if (type === 'supportedFile') {
            this.supportedFile = null;
            this.solverFormStep10.patchValue({ finalReport: null });
        }
    }

    uploadSupportingFiles() {
        // Logic to upload supporting files
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.zip';
        input.multiple = true;
        input.onchange = (event: any) => {
            const file = event.target.files[0];
            if (file) {
                // Handle the files upload
                this.supportedFile = file;
                this.solverFormStep10.patchValue({ supportedFile: file });

                // Add your file upload logic here
                this.supportedFile && this.uploadFileToS3(this.supportedFile);
            }
        };
        input.click();
    }

    uploadFileToS3(file: File) {
        const folderPath = 'challenge-resources';
        const fileName = file?.name;
        const fileType = file?.type;
        const uniqueFileName = CommonUtilities.generateUUID();
        this.imageUploadService
            .generateSignedUrl({
                folderPath,
                fileName: uniqueFileName,
                fileType,
            })
            .subscribe({
                next: (response) => {
                    const signedUrl = response.signedUrl;
                    fetch(signedUrl, {
                        method: 'PUT',
                        headers: { 'Content-Type': fileType },
                        body: file,
                    }).then((updateResources) => {
                        if (updateResources) {
                            this.alertService.successToaster(
                                'Document uploaded successfully'
                            );
                        } else {
                            this.alertService.errorToaster(
                                'Document upload failed'
                            );
                        }
                    });
                },
                error: (error) => {
                    this.alertService.errorToaster('Document upload failed');
                },
                complete: () => {
                    this.inputFileRef.nativeElement.value = null;
                },
            });
    }
}
