import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InputErrorMessageComponent } from '../../input-error-message/input-error-message.component';
import { CommonValidators } from '../../../core/utilities/common-validators';

@Component({
    selector: 'app-reference-handler',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, InputErrorMessageComponent],
    templateUrl: './reference-handler.component.html',
    styleUrl: './reference-handler.component.scss',
})
export class ReferenceHandlerComponent {
    @Input({ required: true }) controlName!: string;
    @Input({ required: true }) formGroup!: FormGroup;
    @Input() maxUrls: number = 10;

    public localFormGroup!: FormGroup;

    constructor(private formBuilder: FormBuilder) {
        this.localFormGroup = this.formBuilder.group({
            url: new FormControl('', [
                CommonValidators.noWhitespaceValidator(),
                Validators.pattern(
                    /^(https?:\/\/|www\.)[\w\-]+(\.[\w\-]+)+([#?&].*)?$/
                ),
                Validators.maxLength(2048),
            ]),
        });
    }

    get urlList() {
        return this.formGroup.get(this.controlName) as FormArray;
    }

    addItem() {
        const itemInputValue = this.localFormGroup.get('url')?.value;
        if (itemInputValue) {
            this.urlList.push(this.formBuilder.control(itemInputValue));
            this.localFormGroup.get('url')?.reset();
            this.urlList.markAllAsTouched();
        }
    }

    editItem(index: number) {
        const itemInputValue = this.localFormGroup.get('url')?.value;
        if (!itemInputValue) {
            const selecteditem = this.urlList.at(index).value;
            this.localFormGroup.get('url')?.setValue(selecteditem);
            this.urlList.removeAt(index);
            this.urlList.markAllAsTouched();
        }
    }

    removeItem(index: number, event: Event) {
        event.stopPropagation();
        this.urlList.removeAt(index);
        this.urlList.markAllAsTouched();
    }

    clearItems() {
        this.urlList.clear();
    }
}
