<div
    class="row justify-content-center align-items-center"
    *ngIf="!weakSignals.length && !uncertainties.length"
>
    <img src="/images/common/no-records.svg" width="236px" height="166px" />
    <div
        class="solver-add-btn-wrapper justify-content-center align-items-center"
    >
        <button
            type="button"
            class="add-new-btn"
            data-toggle="modal"
            data-target="#exampleModal"
            (click)="openDialog(WeakSignalType.WEAK_SIGNAL)"
        >
            + Add New Weak Signal Data
        </button>
    </div>
    <!-- <div
        class="solver-add-btn-wrapper justify-content-center align-items-center"
    >
        <button
            type="button"
            class="add-new-btn"
            data-toggle="modal"
            data-target="#exampleModal"
            (click)="openDialog(WeakSignalType.UNCERTAINTY)"
        >
            + Add New Uncertainty Data
        </button>
    </div> -->
</div>
<ng-container *ngIf="weakSignals.length || uncertainties.length">
    <div *ngIf="weakSignals.length" class="row p-5">
        <div
            class="d-flex justify-content-between align-items-center"
            style="padding: 0; margin-bottom: 20px"
        >
            <h3 class="heading-mon-22">WEAK SIGNAL YOU HAVE ADDED</h3>
            <button
                type="button"
                class="add-new-btn"
                style="margin-top: 0; margin-bottom: 0"
                (click)="openDialog(WeakSignalType.WEAK_SIGNAL)"
            >
                + Add New
            </button>
        </div>
        @for (item of weakSignals; track $index; let index = $index) {
        <div class="best-practice-list-wrapper">
            <div
                class="best-practice-list cursor-pointer"
                (click)="openDialog(WeakSignalType.WEAK_SIGNAL, item)"
            >
                <span class="org-name">Organization name</span>
                <span class="divider">|</span>
                <span class="best-practice-text">{{ item.title }}</span>
            </div>
            <div class="cancel" (click)="removeWeakSignal(item)">
                <span><img src="/images/common/cancel.svg" /></span>
            </div>
        </div>
        }
    </div>
    <div *ngIf="uncertainties.length" class="row p-5">
        <div
            class="d-flex justify-content-between align-items-center"
            style="padding: 0; margin-bottom: 20px"
        >
            <h3 class="heading-mon-22">UNCERTAINTY YOU HAVE ADDED</h3>
            <button
                type="button"
                class="add-new-btn"
                style="margin-top: 0; margin-bottom: 0"
                (click)="openDialog(WeakSignalType.UNCERTAINTY)"
            >
                + Add New
            </button>
        </div>
        @for (item of uncertainties; track $index; let index = $index) {
        <div class="best-practice-list-wrapper">
            <div
                class="best-practice-list cursor-pointer"
                (click)="openDialog(WeakSignalType.UNCERTAINTY, item)"
            >
                <span class="org-name">Organization name</span>
                <span class="divider">|</span>
                <span class="best-practice-text">{{ item.title }}</span>
            </div>
            <div class="cancel" (click)="removeUncertainty(item)">
                <span><img src="/images/common/cancel.svg" /></span>
            </div>
        </div>
        }
    </div>

    <app-input-solution-references
        [formGroup]="formGroup"
        [solutionReferences]="solutionReferences"
    ></app-input-solution-references>
</ng-container>
