import { Component, HostListener, inject } from '@angular/core';
import { FiltersService } from '../filters.service';
import { NgClass, DatePipe, CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import Swal from 'sweetalert2';
import { SweetAlertService } from '../core/services/sweet-alert.service';
import { showStatus } from '../shared/utility/checkChallengeStatus';
import { IconType } from '../core/enum/iconsType.enum';
import { UserRoleSlug } from '../core/enum/UserRole.enum';
import { AuthService } from '../core/services/apis/auth.service';

@Component({
  selector: 'app-organisation-challenge',
  standalone: true,
  imports: [DatePipe,CommonModule, FormsModule, NgSelectComponent,InfiniteScrollDirective],
  templateUrl: './organisation-challenge.component.html',
  styleUrl: './organisation-challenge.component.scss'
})
export class OrganisationChallengeComponent {
  challengeService: FiltersService = inject(FiltersService);
  route: Router = inject(Router);
  challenges: any[] = []
  actionIcon: string = '';
  state: string = '';
  hideDelete: boolean = false;
  showScrollToTopButton = false;
  sweetAlertService: SweetAlertService = inject(SweetAlertService);
  sortList = [{ id: 'DESC', title: 'Latest' }, { id: 'ASC', title: 'Oldest' }]
  user$: any;
  currentOrgId: any;
  authService: AuthService=inject(AuthService);
  role: string = '';
  showStatus = showStatus;
  params = {
    pageLimit: 10,
    pageIndex: 1,
    searchKeyword: '',
    sortKey: 'createdAt',
    sortDirection: 'DESC',

  }
  public IconType=IconType
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showScrollToTopButton = window.scrollY > 300;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  handleSearchChange(searchValue: string): void {
    this.params.searchKeyword = searchValue;
    this.params.pageIndex = 1
    this.fetchChallenges()

  }
  onSortChange(sortValue: any): void {
    console.log(sortValue, 'sort value')
    this.params.sortDirection = sortValue
    this.params.pageIndex = 1
    this.fetchChallenges()
  }
  handleRedirection: (challengeId: string) => void = () => { };
  fetchChallenges(): void {
    this.challengeService.getMyChallenges(this.params).subscribe(
      (data: any) => {
        console.log('Challenges fetched from get my challenge', data);
        if (this.params.pageIndex === 1) {
          this.challenges = data.challenges;
        } else {
          this.challenges = [...this.challenges, ...data.challenges];
          console.log("my challenges", this.challenges)
        }
      },
      (error: any) => {
        console.error('Error fetching challenges', error);
      }
    )
  };

  onScroll(): void {
    console.log('scroll triggered');
    this.params.pageIndex += 1;
    // this.fetchChallenges()
  }



  deleteChallenge(challengeId: string): void {
    this.sweetAlertService.confirmDeletion().subscribe((isConfirmed:boolean) => {
      if (isConfirmed) {
        this.challengeService.deleteChallenge(challengeId).subscribe(
          (data: any) => {
            console.log('Challenge deleted', data);
            this.params.pageIndex = 1;
            this.fetchChallenges();
            this.sweetAlertService.showDeletionSuccess();
          },
          (error: any) => {
            console.error('Error deleting challenge', error);
          }
        );
      }
    });
  }

  onRedirectToEditChallengeDetails(challengeId: string): void {
    this.route.navigate(['/launch-a-challenge/edit'], {
      queryParams: { id: challengeId },
    });
  }

  onRedirectToViewChallengeDetails(challengeId: string): void {
    this.route.navigate(['/overview/challenge-details', challengeId]);
  }
 

  // for showing/hiding icons based on status and role of the user using hidden attribute
  shouldShowIcon(status: string, role: string, icon: string): boolean {
    switch (status) {
        case 'Draft':
            if (icon === IconType.EDIT || icon === IconType.DELETE) {
                console.log('draft - ', status, icon);
                return this.hideIconBasedOnRole(role, icon);
            } else if (icon === IconType.VIEW) {
                return true;
            }
            break;
        case 'Upcoming':
            if (icon === IconType.EDIT || icon === IconType.DELETE || icon === IconType.VIEW) {
                return this.hideIconBasedOnRole(role, icon);
            }
            break;
        case 'Open':
            if (icon === IconType.EDIT || icon === IconType.VIEW) {
                return this.hideIconBasedOnRole(role, icon);
            } else if (icon === IconType.DELETE) {
                return true;
            }
            break;
        case 'Closed':
            if (icon === IconType.EDIT || icon === IconType.DELETE) {
                return true;
            } else if (icon === IconType.VIEW) {
                return this.hideIconBasedOnRole(role, icon);
            }
            break;
        default:
            break;
    }
    return false;
}

  redirectToChallengeDetails(challengeId: string, status: string): void {

    if (status === 'Draft') {
      console.log("status", status)
      return this.onRedirectToEditChallengeDetails(challengeId);

    } else if (status === 'Publish' || status === 'Open' || status === 'Closed'|| status === 'Upcoming') {
      console.log('status in else', status)
      this.onRedirectToViewChallengeDetails(challengeId);
    }
  }



  ngOnInit() {
    this.fetchChallenges();
    this.user$ = this.authService.getUser();
    this.user$.subscribe((user:any) => {
      if (user) {
        console.log('user in the challenges component', user);
      }
      this.role=user.roles[0].slug
    })
  }

  manageAuthority(currentOrgId:any,managedBy:any): boolean {
    return currentOrgId === managedBy;
  }

  hideIconBasedOnRole(role: string,icon:string): boolean {
    switch (icon) {
      case IconType.EDIT:
          switch (role) {
              case UserRoleSlug.INTERN:
                  return true;
              case UserRoleSlug.ORGANIZATION:
              case UserRoleSlug.SUPERADMIN:
              case UserRoleSlug.SUPERORG:
                  return false;
              default:
                  break;
          }
          break;
      case IconType.DELETE:
          switch (role) {
              case UserRoleSlug.INTERN:
                  return true;
              case UserRoleSlug.ORGANIZATION:
              case UserRoleSlug.SUPERADMIN:
              case UserRoleSlug.SUPERORG:
                  return false;
              default:
                  break;
          }
          break;
      case IconType.VIEW:
          switch (role) {
              case UserRoleSlug.INTERN:
              case UserRoleSlug.ORGANIZATION:
              case UserRoleSlug.SUPERADMIN:
              case UserRoleSlug.SUPERORG:
                  return false;
              default:
                  break;
          }
          break;
      default:
          break;
  }
  return false;
  }


}
