<section>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 search-container">
                <div class="container px-0">
                    <div class="input-group search-color" style="max-height: 50px">
                        <input type="text" class="form-control search-color"
                            style="border-top-right-radius: 0 !important;border-bottom-right-radius: 0 !important;"
                            placeholder="Search through challenges here..." [(ngModel)]="params.searchKeyword"
                            (ngModelChange)="handleSearchChange($event)" />
                        <span class="input-group-text  search-color" style="border: 0; background-color: #fff;"><img
                                src="/header/search.svg" alt="Search" height="20" /></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">

        <div class="row section-space">
            <div class="col-md-3" style="padding-right: 40px;">
                <div class="filter-wrapper">
                    <label>Filters</label>
                    <label style="text-decoration: underline; cursor: pointer;" (click)="removeAllFilters()">Clear
                        All</label>
                </div>
                <div class="custom-multi-select">
                    <label for="stageOfChallenge" class="form-label fw-semibold">Stage of Challenge</label>
                    <ng-select [items]="stagesOptions" bindValue="id" bindLabel="title" [multiple]="true"
                        [selectableGroupAsModel]="false" [closeOnSelect]="false" [searchable]="false"
                        (add)="onSelectionChange($event, 'challengeStages')"
                        (remove)="onRemoveChange($event,'challengeStages')">
                        <!-- <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        Optional: Add content for optgroup template if needed
                      </ng-template> -->

                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{ index }}" type="checkbox"
                                [checked]="isSelected(item,'challengeStages')" />
                            {{ item?.title }}
                        </ng-template>
                    </ng-select>
                </div>


                <div class="custom-multi-select">
                    <label for="sdgs" class="form-label fw-semibold">SDG Involved</label>
                    <ng-select [items]="sdgs" [multiple]="true" [selectableGroupAsModel]="false" [closeOnSelect]="false"
                        (add)="onSelectionChange($event,'sdgs')" (remove)="onRemoveChange($event,'sdgs')" bindValue="id"
                        bindLabel="title" searchable="false">
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <!-- Optional: Add content for optgroup template if needed -->
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{ index }}" type="checkbox" [checked]="isSelected(item,'sdgs')" />
                            <span>{{ item.title }}</span>
                        </ng-template>
                    </ng-select>
                </div>

                <div class="custom-multi-select">
                    <label for="sdgs" class="form-label fw-semibold">Organization Type</label>
                    <ng-select bindValue="id" bindLabel="title" [items]="orgTypes" [multiple]="true"
                        [selectableGroupAsModel]="false" [closeOnSelect]="false" [searchable]="false"
                        (add)="onSelectionChange($event,'orgTypes')" (remove)="onRemoveChange($event,'orgTypes')"
                        bindValue="id" bindLabel="title">
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <!-- Optional: Add content for optgroup template if needed -->
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{ index }}" type="checkbox" [checked]="isSelected(item,'orgTypes')" />
                            <span>{{ item.title }}</span>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="custom-multi-select">
                    <label for="stageOfChallenge" class="form-label fw-semibold">Challenge Category</label>
                    <ng-select #categorySelect [items]="categories" [multiple]="true" bindLabel="title"
                        [selectableGroupAsModel]="false" [closeOnSelect]="false" bindValue="id"
                        (add)="onSelectionChange($event,'categories')" (remove)="onRemoveChange($event,'categories')"
                        [searchable]="true">
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{ index }}" type="checkbox" [checked]="isSelected(item,'categories')" />
                            <span>{{ item?.title }}</span>

                        </ng-template>
                    </ng-select>
                </div>

                <div class="custom-multi-select">
                    <label for="sdgs" class="form-label fw-semibold">Incentive Involved</label>
                    <ng-select [items]="incentivesOptions" bindValue="id" bindLabel="title" [multiple]="true"
                        [selectableGroupAsModel]="false" [closeOnSelect]="false"
                        (add)="onSelectionChange($event,'incentives')" (remove)="onRemoveChange($event,'incentives')"
                        searchable="false">
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <!-- Optional: Add content for optgroup template if needed -->
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{ index }}" type="checkbox" [checked]="isSelected(item,'incentives')" />
                            <span>{{ item?.title }}</span>
                        </ng-template>
                    </ng-select>
                </div>

                <div class="custom-multi-select">
                    <label for="stageOfChallenge" class="form-label fw-semibold">Challenge Type</label>
                    <ng-select [items]="typeOptions" bindValue="id" bindLabel="title" [multiple]="true"
                        [selectableGroupAsModel]="false" [closeOnSelect]="false" [searchable]="false"
                        (add)="onSelectionChange($event,'challengeTypes')"
                        (remove)="onRemoveChange($event,'challengeTypes')">
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <!-- Optional: Add content for optgroup template if needed -->
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">

                            <input id="item-{{ index }}" type="checkbox"
                                [checked]="isSelected(item,'challengeTypes')" />
                            <span>{{ item?.title }}</span>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="col-md-9" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                (scrolled)="onScroll()">
                <div class=" list-sort-btn">
                    <div class="custom-multi-select">
                        <label for="sdgs" class="form-label fw-semibold">Sort By</label>
                        <ng-select [items]="sortList" bindValue="id" bindLabel="title" [closeOnSelect]="true"
                            [multiple]="false" (change)="onSortChange($event)" [searchable]="false"
                            [(ngModel)]="params.sortDirection">
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <span>{{ item?.title }}</span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="challenges-type-wrapper">
                    <span class="type" *ngFor="let item of selectedItems">
                        <span *ngIf="item?.title; else stringItem">{{ item?.title }}</span>
                        <ng-template #stringItem>{{ item }}</ng-template>
                        <span class="cancel" (click)="onRemoveChange(item,item?.key)">x</span>
                    </span>
                </div>
                <button class="top-nav" (click)="scrollToTop()" *ngIf="showScrollToTopButton">
                    <img src="/images/common/top.svg" alt="Scroll to Top" />
                </button>
                @if (challenges.length>0) {
                <div *ngFor="let challenge of challenges">
                    <div [routerLink]="['/challenge-details',challenge.id]" class="challenge-details">
                        <div class="challenge-details-img">
                            <img [src]="challenge.downloadUrl" height="200px" />
                        </div>
                        <div>
                            <label class="challenge-title" [innerHTML]="challenge.title"></label>
                            <label class="challenge-desc" [innerHTML]="challenge.shortDescription"></label>
                            <div class="challenges-type-wrapper">

                                @if(challenge.hasActionableFuture){
                                <span class="type">Actionable Futures</span> <!-- Actionable future or not -->
                                } @else {
                                <span class="type">Regular</span> <!-- Actionable future or not -->
                                }
                                @if(challenge.challengeSDGs.length > 1){
                                <span class="type">SDGs</span> <!-- Internship or not -->
                                } @else {
                                <span class="type">SDG</span> <!-- Internship or not -->
                                }
                                @if(challenge.challengeRewards.length > 1){
                                <!-- Internship or not -->
                                } @else {
                                <span class="type">Reward name to be shown</span> <!-- Internship or not -->
                                }

                                @if( (challenge.challengeRewards.length >= 2) || (challenge.challengeRewards.length
                                >=1
                                &&
                                challenge.challengePrize.length >= 1)){
                                <span class="type">Incentives</span>

                                }
                                @else{
                                @if(challenge.challengePrize.length == 1){
                                <span class="type">Prize Money</span>
                                } @if(challenge.challengeRewards.length == 1) {
                                <span class="type">{{challenge.challengeRewards[0].title}}</span>
                                }
                                }
                                <span class="type">{{challenge.geographicScope.value}}</span> <!-- geographic scope -->
                            </div>
                        </div>
                    </div>
                </div>
                } @else {
                <div>
                    <img class="no-records" src="/images/common/no-records.svg" alt="No Data" height="100" />
                    <p class="no-records-p">No records found</p>
                </div>
                }
            </div>
        </div>
    </div>
</section>
