import {
    ReferenceDocument,
    SolutionReferences,
} from '../../shared/input-solution-references/types/input-solution-references-types';

export enum WeakSignalAssessmentTimeEnum {
    lessThan2Year = '<2',
    twoToFiveYear = '2-5',
    fiveToTenYear = '5-10',
    moreThanFiveYear = '>10',
}

export const WeakSignalAssessmentTimeTitle = {
    [WeakSignalAssessmentTimeEnum.lessThan2Year]: 'Less than 2 years',
    [WeakSignalAssessmentTimeEnum.twoToFiveYear]: '2 to 5 years',
    [WeakSignalAssessmentTimeEnum.fiveToTenYear]: '5 to 10 years',
    [WeakSignalAssessmentTimeEnum.moreThanFiveYear]: 'More than 10 years',
};

export enum WeakSignalType {
    WEAK_SIGNAL = 'Weak Signal',
    UNCERTAINTY = 'Uncertainty',
}

export interface SolutionForm3WeakSignalType {
    weakSignals: SolutionForm3WeakSignal[];
    uncertainties: SolutionForm3Uncertainty[];
    solutionReferences: SolutionReferences;
}

export interface SolutionForm3WeakSignal {
    id?: number;
    title: string;
    type: WeakSignalType;
    domain: string;
    weakSignalDate: string;
    description: string;
    evidenceOrEarlySignal: string;
    implications: string;
    impact: number;
    uncertainty: number;
    probability: number;
    timeRange: string;
    citations: string;
    relatedWeakSignals: string;
    additionalNotes: string;
    keyDrivers: string;
    briefImpactDesc: string;
    tags: WeakSignalTags[];
    metaData: WeakSignalMetaData;
    fileNames: ReferenceDocument[];
}

export interface SolutionForm3Uncertainty {
    id: number;
    title: string;
    type: WeakSignalType;
    domain: string;
    weakSignalDate: any;
    description: string;
    evidenceOrEarlySignal: any;
    implications: string;
    impact: number;
    uncertainty: number;
    probability: number;
    timeRange: string;
    citations: string;
    relatedWeakSignals: string;
    additionalNotes: string;
    keyDrivers: any;
    briefImpactDesc: any;
    metaData: WeakSignalMetaData;
    activated: boolean;
    createdAt: string;
    updatedAt: string;
    tags: WeakSignalTags[];
}

export interface WeakSignalTags {
    id: number;
    title: string;
    activated: boolean;
}

export interface WeakSignalMetaData {
    url: string[];
    fileNames: ReferenceDocument[];
}

export interface SolutionForm3WeakSignalRequest {
    id?: number;
    title: string;
    type: WeakSignalType;
    domain: string;
    weakSignalDate: string;
    description: string;
    evidenceOrEarlySignal: string;
    implications: string;
    impact: number;
    uncertainty: number;
    probability: number;
    timeRange: string;
    citations: string;
    relatedWeakSignals: string;
    additionalNotes: string;
    keyDrivers: string;
    briefImpactDesc: string;
    tags: string[];
    url: string[];
    fileNames: ReferenceDocument[];
}
