import { Component } from '@angular/core';
import { BenefitsSectionComponent } from '../../shared/benefits-section/benefits-section.component';
import { BannerSectionComponent } from '../../shared/banner-section/banner-section.component';

@Component({
    selector: 'app-mentors',
    standalone: true,
    imports: [BannerSectionComponent, BenefitsSectionComponent],
    templateUrl: './mentors.component.html',
    styleUrl: './mentors.component.scss',
})
export class MentorsComponent {
    cards = [
        {
            icon: "/images/mentors/icons/mentor_icon_1.svg",
            title: "Register as a Mentor",
            content: "Create a profile and register as a mentor on our platform.",
        },
        {
            icon: "/images/mentors/icons/mentor_icon_2.svg",
            title: "Connect with Your Mentees",
            content: "Challenges.one will assign you to specific interns, startups, or innovators who align with your expertise and interests.",
        },
        {
            icon: "/images/mentors/icons/mentor_icon_3.svg",
            title: "Provide Guidance and Support",
            content: "Offer advice, guidance, and support to your mentees as they work on solving social impact challenges and contributing innovative ideas.",
        },
        {
            icon: "/images/mentors/icons/mentor_icon_4.svg",
            title: "Celebrate Successes",
            content: "Celebrate the successes and achievements of your mentees and the impact they're making in the world.",
        },
    ];
}
