import { createSelector, createFeatureSelector } from '@ngrx/store';
import { LaunchChallengeState } from './launchChallenge.state';
import { Faqs } from '../../Model/faqs.dto';

export const selectLaunchChallengeState =
    createFeatureSelector<LaunchChallengeState>('launchChallenge');

// Selector to get basic information
export const selectBasicInformation = createSelector(
  selectLaunchChallengeState,
  (state: LaunchChallengeState) => state.basicInformation
);

export const selectBasicInformationSuccess = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => !!state.basicInformation && !state.error
);

// Selector for basic information error
export const selectBasicInformationError = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.error
);
// Challenge Id
export const selectChallengeId = createSelector(
  selectLaunchChallengeState,
  (state: LaunchChallengeState) => state.challengeId
);

export const selectProblemStatement = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.problemStatement
);

export const selectChallengeDetails = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.challengeDetails
);
// Selector for Challenge Details error
export const selectChallengeDetailsError = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.error
);

// Selector for Problem Statement error
export const selectProblemStatementError = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.error
);

export const selectSdgs = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.sdgs
);

// Selector for Select Sdgs error
export const selectSdgsError = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.error
);

export const selectPrizeRewards = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.prizeRewards
);

// Selector for Prize Rewards error
export const selectPrizeRewardsError = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.error
);

export const selectGuidelines = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.guidelines
);

// Selector for Guide Lines  error
export const selectGuidelinesError = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.error
);

export const selectEvaluationCriteria = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.evaluationCriteria
);

// Evaluation Criteria error 
export const selectEvaluationCriteriaError = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.error
);

export const selectTimelines = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.timelines
);

// Timelines error 
export const selectTimelinesError = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.error
);

export const selectResources = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.resources
);

// Resources error 
export const selectResourcesError = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.error
);
// Selector for FAQs state
export const selectFaqs = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.faqs
);

// Selector to get only the FAQ IDs and filter out undefined IDs
export const selectFaqIds = createSelector(
    selectFaqs,
    (faqs: Faqs[]) => faqs.map(faq => faq.id).filter((id): id is number => id !== undefined)
);

// Faqs error 
export const selectFaqsError = createSelector(
    selectLaunchChallengeState,
    (state: LaunchChallengeState) => state.error
);