<div class="input-group">
    <input
        [type]="showPassword ? 'text' : 'password'"
        class="form-control"
        [id]="id"
        autocomplete="off"
        (input)="handleInput($event)"
        (blur)="handleBlur()"
        [value]="value"
    />
    <button
        class="input-group-text"
        type="button"
        (click)="togglePasswordVisibility()"
    >
    <img src="{{ showPassword ? '/images/common/open-eye.svg' : '/images/common/close-eye.svg' }}" alt="Eye Icon">
    </button>
</div>
