
export const challengeTypeOptions = [{'id':1,'title':"Actionable Future",key:'challengeTypes'},{'id':2,'title':"Regular",key:'challengeTypes'}] 
export const challengeStages = [{'id':1,'title':"Upcoming",key:'challengeStages',},{'id':2,'title':"Open for submission",key:'challengeStages'},{'id':3,key:'challengeStages','title':"Closed"}]
export const challengeIncentiveOptions = [{'id':1,'title':'Virtual Internship',key:'incentives'},
 {'id':2,'title':'Potential Hiring',key:'incentives'},
    {'id':3,'title':'Publication Opportunity',key:'incentives'},
    {'id':4,'title':'Equity Or Royalties',key:'incentives'},
    {'id':5,'title':'Funding For Proposed Solutions',key:'incentives'},
    {'id':6,'title':'Other Incentives',key:'incentives'},
    {'id':7,'title':'Prize Money',key:'incentives'}
 
]