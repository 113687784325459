import { Component } from '@angular/core';
import { CardComponent } from '../../../shared/card/card.component';
import { ActivatedRoute, Router, RouterLink, UrlSegment } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { OrganisationChallengeComponent } from '../../../organisation-challenge/organisation-challenge.component';
import { OrganisationOverviewDashboardComponent } from '../../../organisation-overview-dashboard/organisation-overview-dashboard.component';
import { Observable } from 'rxjs';
import { AuthService } from '../../../core/services/apis/auth.service';
import { DatePipe } from '@angular/common';
import { OrganisationInterestsListComponent } from "../../../organisation-interests-list/organisation-interests-list.component";
import { UserRoleSlug } from '../../../core/enum/UserRole.enum';
import { EvaluationComponent } from "../../../evaluation/evaluation.component";

@Component({
  selector: 'app-the-challenges',
  standalone: true,
  imports: [CardComponent, RouterLink, MatTabsModule, OrganisationChallengeComponent, OrganisationOverviewDashboardComponent, DatePipe, OrganisationInterestsListComponent, EvaluationComponent],
  templateUrl: './the-challenges.component.html',
  styleUrl: './the-challenges.component.scss'
})
export class TheChallengesComponent {
  user$: Observable<any>;
  name: string = '';
  date: Date = new Date();
  role: string = '';
  ROLE_SLUG=UserRoleSlug
  selectTabIndex :string|number= 0;
  navigation:any
  constructor(
    private authService: AuthService,
    private route:ActivatedRoute,
    private router: Router
  ) {
    this.user$ = this.authService.getUser();

    this.user$.subscribe((user) => {
      if (user) {
          this.name = user.firstName;
          this.role = user.roles[0].slug;
      }
  });
  }
  ngOnInit(): void {
    this.selectTabIndex=this?.route?.snapshot?.paramMap?.get('tab')?this?.route?.snapshot?.paramMap?.get('tab'):this?.route?.snapshot?.queryParams['tab']??0;

    // Reset the URL without query parameters
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      replaceUrl: true
    });
  }

}
