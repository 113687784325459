import { Injectable } from '@angular/core';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';

@Injectable({
    providedIn: 'root',
})
export class AwsS3ClientService {
    private s3Obj: S3Client;

    private s3Region = '123';
    private s3Bucket = '';

    constructor() {
        this.s3Obj = new S3Client({
            region: this.s3Region,
            credentials: {
                accessKeyId: '',
                secretAccessKey: '',
            },
        });
    }

    async upload(file: File): Promise<string> {
        const command = new PutObjectCommand({
            Bucket: this.s3Bucket,
            Key: `user-profile/${file.name}`,
            Body: file,
            ContentType: file.type,
        });

        try {
            await this.s3Obj.send(command);
            // Return the file URL or key
            return `https://${this.s3Bucket}.s3.${this.s3Region}.amazonaws.com/user-profile/${file.name}`;
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    }
}
