<app-banner-section [imageSrc]="'/images/startups/main.png'"
    [title]="'Join the Movement: Contribute Solutions to Global Challenges'" [description]="
        'Collaborate with corporations, governments, and organizations to develop innovative solutions to pressing challenges and accelerate your startup\'s growth'
    " [buttonText]="'JUMP INTO THE CHALLENGES'" [buttonLink]="'#'" [lightColor]="'#D2C9E9'" [darkColor]="'#B3A4DA'"
    [buttonBgColor]="
        'transparent linear-gradient(270deg, #6A4EB5 0%, #3D1C94 100%) 0% 0% no-repeat padding-box'
    " [iconColor]="'#FF7C09'" [overlayText]="'Startups'" [overlayBgColor]="'rgba(0, 0, 0, 0.5)'"
    [overlayTextColor]="'#FFFFFF'" [overlayWidth]="'280px'" [overlayHeight]="'70px'"
    [arrowIconSrc]="'/images/common/right-arrow-purple.svg'">
</app-banner-section>

<app-how-it-works [title2]="'What is Open Innovation for Startups?'" [description2]="
        'Open innovation is a collaborative approach to solving complex problems by sharing knowledge, resources, and expertise between startups, corporations, governments, academic institutions and innovators.'
    " [steps]="stepsData" [stepNumberColor]="'#6A4EB5'" [stepNumberBgColor]="'#E1DBF0'" [stepLineColor]="'#6A4EB5'">
</app-how-it-works>

<app-benefits-section [title]="'Why Open Innovation Matters for Startups?'" [description]="
        'Open innovation offers a unique opportunity for startups to collaborate with corporations, governments, academic institutions and innovators to develop innovative solutions to pressing challenges and accelerate their growth.'
    " [additionalLine]="'The benefits of open innovation for startups include:'" [points]="[
        'Access to new markets and customers',
        'Opportunities for collaboration and co-creation',
        'Potential for funding and resources to support your startup',
        'Increased visibility and recognition for your startup',
        'Accelerated growth and scaling'
    ]" [bulletColor]="'#B3A4DA'" [lightStrapColor]="'#B3A4DA '" [buttonBgColor]="
        'transparent linear-gradient(270deg, #6A4EB5 0%, #3D1C94 100%) 0% 0% no-repeat padding-box'
    " [buttonText]="'READY TO MAKE A DIFFERENCE?'" [buttonLink]="''" [imageSrc]="'/images/startups/benefits.png'"
    [arrowIconSrc]="'/images/common/right-arrow-purple.svg'">
</app-benefits-section>