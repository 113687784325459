import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SolverFlowStep1Component } from '../solver-flow-step-1/solver-flow-step-1.component';
import { SolverFlowStep2BestPracticeComponent } from '../solver-flow-step-2-best-practices/solver-flow-step-2-best-practice.component';
import { SolverFlowStep3WeakSignalComponent } from '../solver-flow-step-3-weak-signal/solver-flow-step-3-weak-signal.component';
import { SolverFlowStep5Component } from '../solver-flow-step-5/solver-flow-step-5.component';
import { MatStepper } from '@angular/material/stepper';
import { MatStepperModule } from '@angular/material/stepper';
import { SolverFlowStep10Component } from '../solver-flow-step-10/solver-flow-step-10.component';
import { SolverFlowStep6Component } from '../solver-flow-step-6/solver-flow-step-6.component';
import { SolverFlowStep7ImpactInitiativesComponent } from '../solver-flow-step-7-impact-initiatives/solver-flow-step-7-impact-initiatives.component';
import { SolverFlowStep8Component } from '../solver-flow-step-8 - backcasting/solver-flow-step-8.component-backcasting';
import { SolverFlowStep9Component } from '../solver-flow-step-9/solver-flow-step-9.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { SolverFlowStep4ScenarioPlanningComponent } from '../solver-flow-step-4-scenario-planning/solver-flow-step-4-scenario-planning.component';
import { SolverFormStoreService } from '../store/solver-form-store.service';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { firstValueFrom } from 'rxjs';
import { ChallengeService } from '../../core/services/apis/challenge.service';

@Component({
    selector: 'app-solver-flow',
    standalone: true,
    imports: [
        SolverFlowStep1Component,
        SolverFlowStep2BestPracticeComponent,
        SolverFlowStep3WeakSignalComponent,
        SolverFlowStep4ScenarioPlanningComponent,
        SolverFlowStep5Component,
        SolverFlowStep6Component,
        SolverFlowStep7ImpactInitiativesComponent,
        SolverFlowStep8Component,
        SolverFlowStep9Component,
        SolverFlowStep10Component,
        MatStepper,
        MatStepperModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        CommonModule,
        SolverFlowStep3WeakSignalComponent,
        RouterLink,
    ],
    templateUrl: './solver-flow.component.html',
    styleUrl: './solver-flow.component.scss',
    providers: [SolverFormStoreService],
})
export class SolverFlowComponent implements OnInit, AfterViewInit {
    isLinear = false;
    currentStep = 1;
    stepsCompleted: boolean[] = Array(10).fill(false);
    challengeId: any;
    challenge: any;
    challengeSubmissionTimeline: any;

    @ViewChild(MatStepper) stepper!: MatStepper;
    @ViewChild(SolverFlowStep1Component)
    solverFlowStep1Component!: SolverFlowStep1Component;
    @ViewChild(SolverFlowStep2BestPracticeComponent)
    solverFlowStep2Component!: SolverFlowStep2BestPracticeComponent;
    @ViewChild(SolverFlowStep3WeakSignalComponent)
    solverFlowStep3WeakSignalComponent!: SolverFlowStep3WeakSignalComponent;
    @ViewChild(SolverFlowStep4ScenarioPlanningComponent)
    solverFlowStep4Component!: SolverFlowStep4ScenarioPlanningComponent;
    @ViewChild(SolverFlowStep5Component)
    solverFlowStep5Component!: SolverFlowStep5Component;
    @ViewChild(SolverFlowStep6Component)
    solverFlowStep6Component!: SolverFlowStep6Component;
    @ViewChild(SolverFlowStep7ImpactInitiativesComponent)
    solverFlowStep7Component!: SolverFlowStep7ImpactInitiativesComponent;
    @ViewChild(SolverFlowStep8Component)
    solverFlowStep8Component!: SolverFlowStep8Component;
    @ViewChild(SolverFlowStep9Component)
    solverFlowStep9Component!: SolverFlowStep9Component;
    @ViewChild(SolverFlowStep10Component)
    solverFlowStep10Component!: SolverFlowStep10Component;

    constructor(
        private readonly router: Router,
        private readonly solverFormStoreService: SolverFormStoreService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly challengeService: ChallengeService
    ) {}

    async ngOnInit() {
        this.solverFormStoreService.updateChallengeId(
            coerceNumberProperty(
                this.activatedRoute.snapshot.paramMap.get('challengeId') || ''
            )
        );
        this.challengeId = this.activatedRoute.snapshot.params['challengeId'];

        this.activatedRoute.queryParams.subscribe(async (params) => {
            const solutionId = await firstValueFrom(
                this.solverFormStoreService.selectSolutionId$
            );
            if (
                params['id'] &&
                solutionId !== coerceNumberProperty(params['id'])
            ) {
                this.solverFormStoreService.updateSolutionId(
                    coerceNumberProperty(params['id'])
                );
                this.solverFormStoreService.getSolutionDetails();
            }

            if (params['step']) {
                this.currentStep = coerceNumberProperty(params['step']);
                if (this.stepper) {
                    this.stepper.selectedIndex = this.currentStep - 1;
                }
            }
        });
        this.fetchChallengeDetails();
    }

    ngAfterViewInit(): void {
        if (this.stepper) {
            this.stepper.selectedIndex = this.currentStep - 1;
        }
    }

    redirectToChallengeDetails() {
        this.router.navigate(['/challenge-details', this.challengeId]);
    }

    redirectToGuidelines() {
        const queryParams = this.activatedRoute.snapshot.queryParams;

        this.router.navigate(['solver-flow-guidelines', this.challengeId], {
            queryParams: { ...queryParams },
            queryParamsHandling: 'merge',
        });
        console.log('redirect to guidelines');
    }

    fetchChallengeDetails(): void {
        this.challengeService.getChallengeDetail(this.challengeId).subscribe({
            next: (response) => {
                this.challenge = response;

                this.challengeSubmissionTimeline =
                    this.challenge?.timeline?.filter(
                        (time: any) => time.title === 'End Date'
                    );
            },
            error: (error) => {
                console.log('Error fetching challenge details', error);
            },
        });
    }

    handleStepChange(event: any): void {
        this.currentStep = event.selectedIndex + 1;
        const queryParams = this.activatedRoute.snapshot.queryParams;
        console.log(
            'currentStep',
            this.currentStep,
            'queryParams',
            queryParams
        );
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { ...queryParams, step: this.currentStep },
            queryParamsHandling: 'merge',
        });
    }

    onSaveAndNext(isDraft?: boolean): void {
        switch (this.currentStep) {
            case 1:
                this.solverFlowStep1Component?.onSubmit(isDraft);
                break;
            case 2:
                this.solverFlowStep2Component?.onSubmit(isDraft);
                break;
            case 3:
                this.solverFlowStep3WeakSignalComponent?.onSubmit(isDraft);
                break;
            case 4:
                this.solverFlowStep4Component?.onSubmit(isDraft);
                break;
            case 5:
                this.solverFlowStep5Component?.onSubmit(isDraft);
                break;
            case 6:
                this.solverFlowStep6Component?.onSubmit(isDraft);
                break;
            case 7:
                this.solverFlowStep7Component?.onSubmit(isDraft);
                break;
            case 8:
                this.solverFlowStep8Component?.onSubmit(isDraft);
                break;
            case 9:
                this.solverFlowStep9Component?.onSubmit(isDraft);
                break;
            case 10:
                this.solverFlowStep10Component?.onSubmit(isDraft);
                break;
        }
    }

    handleStepSuccess(): void {
        if (this.currentStep > 0) {
            this.stepsCompleted[this.currentStep - 1] = true;
        }
        this.currentStep++;
        this.stepper.next();
        console.log('currentStep', this.currentStep);
    }

    handleStep1Success(id: number): void {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        console.log('queryParams', queryParams);
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { ...queryParams, id },
            queryParamsHandling: 'merge',
        });
        setTimeout(() => {
            this.handleStepSuccess();
        });
    }
}
