import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import * as LaunchChallengeActions from './launchChallenge.actions';
import { ChallengeService } from '../../services/apis/challenge.service';
import { SweetAlertService } from '../../services/sweet-alert.service';
import { ValidationMessageService } from '../../services/validation-message.service';
import { FormGroup } from '@angular/forms';
import { SdgsDto } from '../../Model/sdgs';
import { create } from 'domain';
import { error } from 'console';
import { Faqs } from '../../Model/faqs.dto';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class LaunchChallengeEffects {
    loadChallengeBasicInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.loadChallengeBasicInformation),
            mergeMap((action) =>
                this.challengeService
                    .getChallengeBasicInformation(action.id)
                    .pipe(
                        map((response) => {
                            // this.alertService.successPopup(response);
                            return LaunchChallengeActions.loadChallengeBasicInformationSuccess(
                                { data: response }
                            );
                        }),
                        catchError((error) => {
                            this.alertService.errorPopup(error.error.message);
                            return of(
                                LaunchChallengeActions.loadChallengeBasicInformationFailure(
                                    { error: error.error.message }
                                )
                            );
                        })
                    )
            )
        )
    );

    postChallengeBasicInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.postChallengeBasicInformation),
            mergeMap((action) =>
                this.challengeService
                    .postChallengeBasicInformation(action.data)
                    .pipe(
                        map((response) => {
                            this.alertService.successPopup(response.message);
                            return LaunchChallengeActions.postChallengeBasicInformationSuccess(
                                {
                                    message: response.message,
                                    challengeId: response.id,
                                }
                            );
                        }),
                        catchError((error: HttpErrorResponse) => {
                            if (error.status === 422) {
                                this.alertService.errorPopup(error.error.message);
                                // Handle validation errors
                                return of(
                                    LaunchChallengeActions.postChallengeBasicInformationFailure({
                                        error: error.error.errors // Pass the validation errors
                                    })
                                );
                            } else {
                                this.alertService.errorPopup(error.error.message);
                                return of(
                                    LaunchChallengeActions.postChallengeBasicInformationFailure({
                                        error: { message: error.error.message }
                                    })
                                );
                            }
                        })
                    )
            )
        )
    );

    postChallengeBasicInformationSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.postChallengeBasicInformationSuccess),
            map((action) =>
                LaunchChallengeActions.storeChallengeId({
                    challengeId: action.challengeId.toString(),
                })
            )
        )
    );

    updateChallengeBasicInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.updateChallengeBasicInformation),
            mergeMap((action) =>
                this.challengeService
                    .updateChallengeBasicInformation(action.id, action.data)
                    .pipe(
                        map((response) => {
                            this.alertService.successPopup(response.message);
                            return LaunchChallengeActions.updateChallengeBasicInformationSuccess(
                                { message: response.message }
                            );
                        }),
                        catchError((error: HttpErrorResponse) => {
                            if (error.status === 422) {
                                // Handle validation errors
                                return of(
                                    LaunchChallengeActions.updateChallengeBasicInformationFailure({
                                        error: error.error.errors // Pass the validation errors
                                    })
                                );
                            } else {
                                this.alertService.errorPopup(error.error.message);
                                return of(
                                    LaunchChallengeActions.updateChallengeBasicInformationFailure({
                                        error: { message: error.error.message }
                                    })
                                );
                            }
                        })
                    )
            )
        )
    );

    // Step 2
    // Effect to load problem statement
    loadProblemStatement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.loadProblemStatement),
            mergeMap((action) =>
                this.challengeService.getProblemStatement(action.id).pipe(
                    map((response) => {
                        // this.alertService.successPopup(response.message);
                        return LaunchChallengeActions.loadProblemStatementSuccess(
                            {
                                problemStatement: response,
                            }
                        );
                    }),
                    catchError((error) => {
                        this.alertService.errorPopup(error.error.message);
                        return of(
                            LaunchChallengeActions.loadProblemStatementFailure({
                                error: error.error.message,
                            })
                        );
                    })
                )
            )
        )
    );

    // Effect to update problem statement
    updateProblemStatement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.updateProblemStatement),
            mergeMap((action) =>
                this.challengeService
                    .updateProblemStatement(action.id, action.data)
                    .pipe(
                        map((response) => {
                            this.alertService.successPopup(response.message);
                            return LaunchChallengeActions.updateProblemStatementSuccess(
                                {
                                    message: response.message,
                                }
                            );
                        }),
                        catchError((error: HttpErrorResponse) => {
                            if (error.status === 422) {
                                // Handle validation errors
                                return of(
                                    LaunchChallengeActions.updateProblemStatementFailure({
                                        error: error.error.errors // Pass the validation errors
                                    })
                                );
                            } else {
                                this.alertService.errorPopup(error.error.message);
                                return of(
                                    LaunchChallengeActions.updateProblemStatementFailure(
                                        {
                                            error: { message: error.error.message },
                                        }
                                    )
                                );
                            }
                        })
                    )
            )
        )
    );

    // step 3
    loadChallengeDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.loadChallengeDetails),
            mergeMap((action) =>
                this.challengeService.getChallengeDetails(action.id).pipe(
                    map((response) =>
                        LaunchChallengeActions.loadChallengeDetailsSuccess({
                            challengeDetails: response,
                        })
                    ),
                    catchError((error) => {
                        this.alertService.errorPopup(error.error.message);
                        return of(
                            LaunchChallengeActions.loadChallengeDetailsFailure({
                                error: error.error.message,
                            })
                        );
                    })
                )
            )
        )
    );

    updateChallengeDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.updateChallengeDetails),
            mergeMap((action) =>
                this.challengeService
                    .updateChallengeDetails(action.id, action.data)
                    .pipe(
                        map((response) => {
                            this.alertService.successPopup(response.message);
                            return LaunchChallengeActions.updateChallengeDetailsSuccess(
                                { message: response.message }
                            );
                        }),
                        catchError((error: HttpErrorResponse) => {
                            if (error.status === 422) {
                                // Handle validation errors
                                return of(
                                    LaunchChallengeActions.updateChallengeDetailsFailure({
                                        error: error.error.errors // Pass the validation errors
                                    })
                                );
                            } else {
                                this.alertService.errorPopup(error.error.message);
                                return of(
                                    LaunchChallengeActions.updateChallengeDetailsFailure({
                                        error: { message: error.error.message }
                                    })
                                );
                            }
                        })
                    )
            )
        )
    );

    // Step 4

    loadSdgs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.loadSdgs),
            mergeMap((action) =>
                this.challengeService.getSdgs(action.id).pipe(
                    map(
                        (
                            sdgs: SdgsDto[] // Ensure this is an array
                        ) => LaunchChallengeActions.loadSdgsSuccess({ sdgs })
                    ),
                    catchError((error) =>
                        of(
                            LaunchChallengeActions.loadSdgsFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateSdgs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.updateSdgs),
            mergeMap((action) =>
                this.challengeService
                    .updateSdgs(action.challengeId, { sdgs: action.data.sdgs })
                    .pipe(
                        map((sdgs: SdgsDto[]) => {
                            this.alertService.successPopup('SDGs updated successfully.');
                            return LaunchChallengeActions.updateSdgsSuccess({ sdgs });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            if (error.status === 422) {
                                // Handle validation errors
                                return of(
                                    LaunchChallengeActions.updateSdgsFailure({
                                        error: error.error.errors // Pass the validation errors
                                    })
                                );
                            } else {
                                this.alertService.errorPopup(error.error.message);
                                return of(
                                    LaunchChallengeActions.updateSdgsFailure({
                                        error: { message: error.error.message }
                                    })
                                );
                            }
                        })
                    )
            )
        )
    );
    
    // Step 5
    // Load Prize Rewards
    loadPrizeRewards$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.loadPrizeRewards),
            mergeMap(({ id }) =>
                this.challengeService.getPrizeRewards(id).pipe(
                    map((prizeRewards) =>
                        LaunchChallengeActions.loadPrizeRewardsSuccess({
                            prizeRewards,
                        })
                    ),
                    catchError((error) =>
                        of(
                            LaunchChallengeActions.loadPrizeRewardsFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    // Update Prize Rewards
    updatePrizeRewards$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.updatePrizeRewards),
            mergeMap(({ challengeId, data }) =>
                this.challengeService
                    .updatePrizeRewards(challengeId, data)
                    .pipe(
                        map((response) => {
                            this.alertService.successPopup(response.message);
                            return LaunchChallengeActions.updatePrizeRewardsSuccess(
                                {
                                    message: response.message,
                                }
                            );
                        }),
                        catchError((error: HttpErrorResponse) => {
                            if (error.status === 422) {
                                // Handle validation errors
                                return of(
                                    LaunchChallengeActions.updatePrizeRewardsFailure({
                                        error: error.error.errors // Pass the validation errors
                                    })
                                );
                            } else {
                                this.alertService.errorPopup(error.error.message);
                                return of(
                                    LaunchChallengeActions.updatePrizeRewardsFailure({
                                        error: { message: error.error.message }
                                    })
                                );
                            }
                        })
                    )
            )
        )
    );

    // Step 6
    // Load Guidelines
    loadGuidelines$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.loadGuidelines),
            mergeMap(({ id }) =>
                this.challengeService.getGuideline(id).pipe(
                    map((guidelines) =>
                        LaunchChallengeActions.loadGuidelinesSuccess({
                            guidelines,
                        })
                    ),
                    catchError((error) =>
                        of(
                            LaunchChallengeActions.loadGuidelinesFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    // Update Guidelines
    updateGuidelines$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.updateGuidelines),
            mergeMap(( action ) =>
                this.challengeService.updateGuideline(action.challengeId, action.data).pipe(
                    map((response: any) => {
                        this.alertService.successPopup(response.message);
                        return LaunchChallengeActions.updateGuidelinesSuccess(
                            {
                                message: response.message,
                            }
                        );
                    }),
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 422) {
                            // Handle validation errors
                            return of(
                                LaunchChallengeActions.updateGuidelinesFailure({
                                    error: error.error.errors // Pass the validation errors
                                })
                            );
                        } else {
                            this.alertService.errorPopup(error.error.message);
                            return of(
                                LaunchChallengeActions.updateGuidelinesFailure({
                                    error: { message: error.error.message }
                                })
                            );
                        }
                    })
                )
            )
        )
    );

    // Step 7 - Load Evaluation Criteria
    loadEvaluationCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.loadEvaluationCriteria),
            mergeMap(({ id }) =>
                this.challengeService.getEvaluationCriteria(id).pipe(
                    map((evaluationCriteria) =>
                        LaunchChallengeActions.loadEvaluationCriteriaSuccess({
                            evaluationCriteria,
                        })
                    ),
                    catchError((error) =>
                        of(
                            LaunchChallengeActions.loadEvaluationCriteriaFailure(
                                {
                                    error,
                                }
                            )
                        )
                    )
                )
            )
        )
    );

    updateEvaluationCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.updateEvaluationCriteria),
            mergeMap(({ challengeId, data }) =>
                this.challengeService
                    .updateEvaluationCriteria(challengeId, data)
                    .pipe(
                        map((response: any) => {
                            this.alertService.successPopup(response.message);
                            return LaunchChallengeActions.updateEvaluationCriteriaSuccess(
                                {
                                    message: response.message,
                                }
                            );
                        }),
                        catchError((error: HttpErrorResponse) => {
                            if (error.status === 422) {
                                // Handle validation errors
                                return of(
                                    LaunchChallengeActions.updateEvaluationCriteriaFailure({
                                        error: error.error.errors // Pass the validation errors
                                    })
                                );
                            } else {
                                this.alertService.errorPopup(error.error.message);
                                return of(
                                    LaunchChallengeActions.updateEvaluationCriteriaFailure({
                                        error: { message: error.error.message }
                                    })
                                );
                            }
                        })
                    )
            )
        )
    );

    // Step 8
    // Load Timelines
    loadTimelines$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.loadTimelines),
            mergeMap(({ id }) =>
                this.challengeService.getTimelines(id).pipe(
                    map((timelines) =>
                        LaunchChallengeActions.loadTimelinesSuccess({
                            timelines,
                        })
                    ),
                    catchError((error) =>
                        of(
                            LaunchChallengeActions.loadTimelinesFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    // Update Timelines
    updateTimelines$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.updateTimelines),
            mergeMap(({ challengeId, data }) =>
                this.challengeService.updateTimelines(challengeId, data).pipe(
                    map((response: any) => {
                        this.alertService.successPopup(response.message);
                        return LaunchChallengeActions.updateTimelinesSuccess(
                            {
                                message: response.message,
                            }
                        );
                    }),
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 422) {
                            // Handle validation errors
                            return of(
                                LaunchChallengeActions.updateTimelinesFailure({
                                    error: error.error.errors // Pass the validation errors
                                })
                            );
                        } else {
                            this.alertService.errorPopup(error.error.message);
                            return of(
                                LaunchChallengeActions.updateTimelinesFailure({
                                    error: { message: error.error.message }
                                })
                            );
                        }
                    })
                )
            )
        )
    );

    // Step 9
    // Load Resources
    loadResources$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.loadResources),
            mergeMap(({ id }) =>
                this.challengeService.getResources(id).pipe(
                    map((resources) =>
                        LaunchChallengeActions.loadResourcesSuccess({
                            resources,
                        })
                    ),
                    catchError((error) =>
                        of(
                            LaunchChallengeActions.loadResourcesFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    // Update Resources
    updateResources$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.updateResources),
            mergeMap(({ challengeId, data }) =>
                this.challengeService.updateResources(challengeId, data).pipe(
                    map((response: any) => {
                        this.alertService.successPopup(response.message);
                        return LaunchChallengeActions.updateResourcesSuccess(
                            {
                                message: response.message,
                            }
                        );
                    }),
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 422) {
                            // Handle validation errors
                            return of(
                                LaunchChallengeActions.updateResourcesFailure({
                                    error: error.error.errors // Pass the validation errors
                                })
                            );
                        } else {
                            this.alertService.errorPopup(error.error.message);
                            return of(
                                LaunchChallengeActions.updateResourcesFailure({
                                    error: { message: error.error.message }
                                })
                            );
                        }
                    })
                )
            )
        )
    );

    // Step 10
    // Load FAQs
    loadFaqs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.loadFaqs),
            mergeMap(({ id }) =>
                this.challengeService.getFaqs(id).pipe(
                    map((faqs: Faqs[]) =>
                        LaunchChallengeActions.loadFaqsSuccess({ faqs })
                    ),
                    catchError((error) =>
                        of(LaunchChallengeActions.loadFaqsFailure({ error }))
                    )
                )
            )
        )
    );

    // Update FAQs 
    updateFaqs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.updateFaqs),
            mergeMap(({ challengeId, data }) =>
                this.challengeService.updateFaqs(challengeId, data).pipe(
                    map((response: Faqs) => {
                        this.alertService.successPopup('FAQs added successfully.');
                        return LaunchChallengeActions.updateFaqsSuccess({
                            faqs: [response],
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 422) {
                            // Handle validation errors
                            return of(
                                LaunchChallengeActions.updateFaqsFailure({
                                    error: error.error.errors // Pass the validation errors
                                })
                            );
                        } else {
                            this.alertService.errorPopup(error.error.message);
                            return of(
                                LaunchChallengeActions.updateFaqsFailure({
                                    error: { message: error.error.message }
                                })
                            );
                        }
                    })
                )
            )
        )
    );
    

    // Delete FAQ
    deleteFaq$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LaunchChallengeActions.deleteFaq),
            mergeMap(({ challengeId, faqsId }) =>
                this.challengeService.deleteFaqs(challengeId, faqsId).pipe( // challengeId: string, faqsId: number
                    map(() =>
                        LaunchChallengeActions.deleteFaqSuccess({ faqsId }) // faqsId should be a number here
                    ),
                    catchError((error) =>
                        of(LaunchChallengeActions.deleteFaqFailure({ error }))
                    )
                )
            )
        )
    );
    

    constructor(
        private actions$: Actions,
        private challengeService: ChallengeService,
        private alertService: SweetAlertService,
        private validationMessageService: ValidationMessageService
    ) {}
}
