export const serializeFloat = value => {
  if (value !== value) {
    return "NaN";
  }
  switch (value) {
    case Infinity:
      return "Infinity";
    case -Infinity:
      return "-Infinity";
    default:
      return value;
  }
};
export const serializeDateTime = date => date.toISOString().replace(".000Z", "Z");