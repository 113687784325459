<div class="card shadow rounded-start-0 custom-container border-0 container">
    <div class="card-body" style="padding: 0;">

        <form class="row" style="padding: 50px; padding-bottom: 40px;" [formGroup]="formGroup"
            (ngSubmit)="onFormSubmit()">
            <div *ngIf="showIntroText" class="mb-5">
                <h1 class="text-start fw-semibold fs-33px text-color">Get In Touch</h1>
                <h1 class="text-start fw-semibold fs-22px">Launch a Challenge or Use Our Crowdsourced Consulting
                    Services</h1>
            </div>

            <div class="col-md-6 mb-3">
                <label for="firstName" class="form-label fw-semibold fs-15px">First Name<span
                    style="color: #B21818;">*</span></label>
                <input type="text" class="form-control" id="firstName" formControlName="firstName" />
                <app-input-error-message [formData]="formGroup" controlName="firstName"></app-input-error-message>
            </div>
            <div class="col-md-6 mb-3">
                <label for="lastName" class="form-label fw-semibold fs-15px">Last Name<span
                    style="color: #B21818;">*</span></label>
                <input type="text" class="form-control" id="lastName" formControlName="lastName" />
                <app-input-error-message [formData]="formGroup" controlName="lastName"></app-input-error-message>
            </div>
            <div class="col-md-6 mb-3">
                <label for="email" class="form-label fw-semibold fs-15px">Email<span
                    style="color: #B21818;">*</span></label>
                <input type="email" class="form-control" id="email" formControlName="email" />
                <app-input-error-message [formData]="formGroup" controlName="email"></app-input-error-message>
            </div>
            <div class="col-md-6 mb-3">
                <label for="designation" class="form-label fw-semibold fs-15px">Designation<span
                    style="color: #B21818;">*</span></label>
                <input type="text" class="form-control" id="designation" formControlName="designation" />
                <app-input-error-message [formData]="formGroup" controlName="designation"></app-input-error-message>
            </div>
            <div class="col-md-6 mb-3">
                <label for="organizationName" class="form-label fw-semibold fs-15px">Organization<span
                    style="color: #B21818;">*</span></label>
                <input type="text" class="form-control" id="organizationName" formControlName="organizationName" />
                <app-input-error-message [formData]="formGroup" controlName="organizationName">
                </app-input-error-message>
            </div>
            <div class="col-md-6 mb-3">
                <label for="organizationUrl" class="form-label fw-semibold fs-15px">Website<span
                    style="color: #B21818;">*</span></label>
                <input type="url" class="form-control" id="organizationUrl" formControlName="organizationUrl" />
                <app-input-error-message [formData]="formGroup" controlName="organizationUrl"></app-input-error-message>
            </div>
            <div class="col-md-12 mb-3">
                <label for="subject" class="form-label fw-semibold fs-15px">Subject<span
                    style="color: #B21818;">*</span></label>
                <input type="text" class="form-control" id="subject" formControlName="subject" />
                <app-input-error-message [formData]="formGroup" controlName="subject"></app-input-error-message>
            </div>
            <div class="col-md-12 mb-3">
                <label for="message" class="form-label fw-semibold fs-15px">Message<span
                    style="color: #B21818;">*</span></label>
                <textarea class="form-control" id="message" formControlName="message" rows="3"></textarea>
                <app-input-error-message [formData]="formGroup" controlName="message"></app-input-error-message>
            </div>
            <div class="col-md-12 mt-4 d-flex justify-content-end">
                <button type="submit" class="btn btn-gradient btn-bg-blue-gradient custom-btn me-4">SUBMIT
                    <span class="arrow-btn-wrapper"> <img src="/images/home/right-arrow.svg" alt="Arrow" height="20"
                            class="arrow-icon1"></span>
                </button>
            </div>
        </form>
    </div>
</div>
