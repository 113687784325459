import { inject, Injectable } from '@angular/core';
import { Category } from './core/Model/category';
import { ChallengeService } from './core/services/apis/challenge.service';
import { SweetAlertService } from './core/services/sweet-alert.service';
import { catchError, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  // categories: Category[] = [];
challengeService: ChallengeService=inject(ChallengeService);
alertService: SweetAlertService=inject(SweetAlertService);
getCategories(): Observable<Category[]> {
  return this.challengeService.listAllCategoryOptionsPublic().pipe(
    map((data: Category[]) =>
      data.map(category => ({
        id: category.id,
        title: category.title
      }))
    ),
    catchError(error => {
      this.alertService.errorPopup(error.error.message);
      throw error; // rethrow the error after handling it
    })
  );
}


getSdgs(): Observable<any[]> {
  return this.challengeService.listAllSDGsPublic().pipe(
    map((response: any) => {

      const data = response.data;
      if (!Array.isArray(data)) {
        console.error('Expected an array but received:', data);
        throw new Error('Expected an array from listAllSDGs');
      }

      return data.map((sdg: any) => ({
        id: sdg.id,
        title: sdg.title
      }));
    }),
    catchError(error => {
      this.alertService.errorPopup(error?.error?.message);
      throw error; // rethrow the error after handling it
    })
  );
}
getOrgTypes(): Observable<any[]> {
  return this.challengeService.listAllOrgTypesPublic().pipe(
    map((data: any) => {

      return data.map((orgType: any) => ({
        id: orgType.id,
        title: orgType.title
      }));
    }),
    catchError(error => {
      this.alertService.errorPopup(error?.error?.message);
      throw error; // rethrow the error after handling it
    })
  );
}

getChallenges(params: any): Observable<any> {
  return this.challengeService.getChallenges(params).pipe(
    map((response: any) => {
      const data = response.data;
     return data
    }),
    catchError(error => {
      this.alertService.errorPopup(error?.error?.message);
      throw error; // rethrow the error after handling it
    })
  );
}
getMyChallenges(params: any): Observable<any> {
  return this.challengeService.getMyChallenges(params).pipe(
    map((response: any) => {
      const data = response.data;
     return data
    }),
    catchError(error => {
      if (error?.error?.message!=="No organization is associated with current user") {
        this.alertService.errorPopup(error?.error?.message);

      }
      throw error; // rethrow the error after handling it
    })
  );
}
deleteChallenge(challengeId: string): Observable<any> {
return this.challengeService.deleteChallenge(challengeId).pipe(
    map((response: any) => {
      this.alertService.successPopup('Challenge deleted successfully');
    }),
    catchError(error => {
      console.error('Error deleting challenge', error);
      this.alertService.errorPopup(error?.error?.message);
      throw error; // rethrow the error after handling it
    }))
  }

  deletePostInterest(postId:string):Observable<any>{
    return this.challengeService.deletePostInterest(postId).pipe(
      map((response:any)=>{
        this.alertService.successPopup('Interest deleted successfully');
      }),
      catchError(error=>{
        console.error('Error deleting interest',error);
        this.alertService.errorPopup(error?.error?.message);
        throw error;
      })
    )
  }

  constructor() { }
}
