<div>
<!-- TimeLine Page -->
                <div class="timeline-wrapper">
                    <ul class="timeline">
                        @for (date of updatedTimeline; track date.title) {
                            <li class="timeline-inverted" [ngClass]="{'current-timeline': date.upcomingEvent}">
                                <div class="timeline-badge"></div>
                                <div class="timeline-panel ">
                                    <div class="timeline-heading">
                                        <h4 class="timeline-title">{{date.formattedDate}}</h4>
                                        <p class="timeline-desc">{{date.title}}</p>
                                    </div>
                                </div>
                            </li>
                        }
                        <!-- <li class="timeline-inverted">
                            <div class="timeline-badge"></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4 class="timeline-title">March 27, 2024, 6:30 p.m. IST</h4>
                                    <p class="timeline-desc">Date Launched</p>
                                </div>
                            </div>
                        </li>
                        <li class="timeline-inverted">
                            <div class="timeline-badge"></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4 class="timeline-title">Feb. 23, 2024, 9:35 p.m. IST</h4>
                                    <p class="timeline-desc">Enter | Open to submissions (Phase 1)</p>
                                </div>
                            </div>
                        </li>
                        <li class="timeline-inverted current-timeline">
                            <div class="timeline-badge"></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4 class="timeline-title">March 27, 2024, 6:30 p.m. IST</h4>
                                    <p class="timeline-desc">Enter | Open to submissions (Phase 1)</p>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                </div>
</div>
