import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { SolverFormState } from './solver-form-state-type';
import { HttpClient } from '@angular/common/http';
import { switchMap, tap, withLatestFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/services/apis/auth.service';
import { SolutionForm6Type } from '../types/solution-form-6-type';
import { SolutionForm8Type } from '../types/solution-form-8-types';
import { SolutionForm9Type } from '../types/solution-form-9-types';
import { SolutionForm2BestPracticeType } from '../types/solution-form-2-types';
import { SolutionForm7Type } from '../types/solution-form-7-types';
import { SolutionForm1Type } from '../types/solution-form-1-types';
import { SolutionForm5Type } from '../types/solution-form-5-types';
import { SolutionForm10Type } from '../types/solution-form-10-types';
import { SolutionFormAllResponseTypes } from '../types/solution-form-all-response-types';
import { SolutionForm3WeakSignalType } from '../types/solution-form-3-types';
import { SolutionForm4SolutionFrameworkType } from '../types/solution-form-4-types';

const initialState: SolverFormState = {
    challengeId: null,
    solutionId: null,
    form1: null,
    form2: null,
    form3: null,
    form4: null,
    form5: null,
    form6: null,
    form7: null,
    form8: null,
    form9: null,
    form10: null,
    // step 6 solution state
};

@Injectable()
export class SolverFormStoreService extends ComponentStore<SolverFormState> {
    constructor(
        private readonly http: HttpClient,
        private readonly authService: AuthService
    ) {
        super(initialState);
    }

    readonly selectSolverFormState$ = this.select((state) => state);
    readonly selectChallengeId$ = this.select((state) => state.challengeId);
    readonly selectSolutionId$ = this.select((state) => state.solutionId);
    readonly selectForm1$ = this.select((state) => state.form1);
    readonly selectForm2$ = this.select((state) => state.form2);
    readonly selectForm3$ = this.select((state) => state.form3);
    readonly selectForm4$ = this.select((state) => state.form4);
    readonly selectForm5$ = this.select((state) => state.form5);
    readonly selectForm6$ = this.select((state) => state.form6);
    readonly selectForm7$ = this.select((state) => state.form7);
    readonly selectForm8$ = this.select((state) => state.form8);
    readonly selectForm9$ = this.select((state) => state.form9);
    readonly selectForm10$ = this.select((state) => state.form10);

    get isEdit(): boolean {
        return !!this.get().solutionId;
    }

    readonly updateChallengeId = this.updater((state, challengeId: number) => ({
        ...state,
        challengeId,
    }));

    readonly updateSolutionId = this.updater((state, solutionId: number) => ({
        ...state,
        solutionId,
    }));

    readonly updateForm1 = this.updater((state, form1: SolutionForm1Type) => ({
        ...state,
        form1,
    }));

    readonly updateForm2 = this.updater(
        (state, form2: SolutionForm2BestPracticeType) => ({
            ...state,
            form2,
        })
    );

    readonly updateForm3 = this.updater(
        (state, form3: SolutionForm3WeakSignalType) => ({
            ...state,
            form3,
        })
    );

    readonly updateForm4 = this.updater(
        (state, form4: SolutionForm4SolutionFrameworkType[]) => ({
            ...state,
            form4,
        })
    );

    readonly updateForm5 = this.updater((state, form5: SolutionForm5Type) => ({
        ...state,
        form5,
    }));

    readonly updateForm6 = this.updater((state, form6: SolutionForm6Type) => ({
        ...state,
        form6,
    }));

    readonly updateForm7 = this.updater((state, form7: SolutionForm7Type) => ({
        ...state,
        form7,
    }));

    readonly updateForm8 = this.updater((state, form8: SolutionForm8Type) => ({
        ...state,
        form8,
    }));

    readonly updateForm9 = this.updater((state, form9: SolutionForm9Type) => ({
        ...state,
        form9,
    }));

    readonly updateForm10 = this.updater(
        (state, form10: SolutionForm10Type) => ({
            ...state,
            form10,
        })
    );

    readonly getSolutionDetails = this.effect<void>((trigger$) => {
        return trigger$.pipe(
            withLatestFrom(this.selectSolutionId$),
            switchMap(async ([_, solutionId]) => {
                const headers = await this.authService.getHeaders();
                return this.http
                    .get<any>(`${environment.apiUrl}/solution/${solutionId}`, {
                        headers,
                    })
                    .pipe(
                        tap({
                            next: (response: SolutionFormAllResponseTypes) => {
                                this.updateForm1(response.step1);
                                this.updateForm2(response.step2);
                                this.updateForm3(response.step3);
                                this.updateForm4(response.step4);
                                this.updateForm5(response.step5);
                                this.updateForm6(response.step6);
                                this.updateForm7(response.step7);
                                this.updateForm8(response.step8);
                                this.updateForm9(response.step9);
                                this.updateForm10(response.step10);
                            },
                            error: (error) => {
                                console.error(error);
                            },
                        })
                    )
                    .subscribe();
            })
        );
    });
}
