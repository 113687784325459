import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { HowItWorksComponent } from '../../shared/how-it-works/how-it-works.component';
import { BenefitsSectionComponent } from '../../shared/benefits-section/benefits-section.component';
import { BannerSectionComponent } from '../../shared/banner-section/banner-section.component';

@Component({
  selector: 'app-innovators',
  standalone: true,
  imports: [CommonModule,HowItWorksComponent,BenefitsSectionComponent, BannerSectionComponent],
  templateUrl: './innovators.component.html',
  styleUrl: './innovators.component.scss'
})
export class InnovatorsComponent {
  stepsData = [
    {
      icon: '/images/innovator/innovs_icon_1.svg',
      title: 'Browse Social Impact Challenges',
      description: 'Explore our current challenges and find one that aligns with your interests and expertise.'
    },
    {
      icon: '/images/innovator/innovs_icon_2.svg', 
      title: 'Join to contribute ideas to a solution',
      description: 'Sign up to submit your solution or join a team to contribute ideas to a solution including a brief description, prototype, or proof of concept.'
    },
    {
      icon: '/images/innovator/innovs_icon_3.svg', 
      title: 'Collaborate with the Community',
      description: 'Engage with other innovators, entrepreneurs, and organizations to refine and develop your solution.'
    },
    {
      icon: '/images/innovator/innovs_icon_4.svg', 
      title: 'Receive Feedback and Support',
      description: 'Get feedback and support from AI tools on the platform, so that your solutions are curated and presented in the best manner possible.'
    },
    {
      icon: '/images/innovator/innovs_icon_5.svg', 
      title: 'Win Recognition and Rewards',
      description: 'Compete for recognition and rewards, including career enhancement opportunities.'
    }
  ];
}
