import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, signal } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatChipsModule, MatChipInputEvent } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { InputErrorMessageComponent } from '../../../shared/input-error-message/input-error-message.component';
import {
    WeakSignalAssessmentTimeEnum,
    WeakSignalAssessmentTimeTitle,
} from '../../types/solution-form-3-types';
import { NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { SolutionReferences } from '../../../shared/input-solution-references/types/input-solution-references-types';
import { InputSolutionReferencesComponent } from '../../../shared/input-solution-references/input-solution-references.component';
import { CommonUtilities } from '../../../core/utilities/common-utilities';
import {
    SolutionForm3WeakSignal,
    WeakSignalType,
} from '../../types/solution-form-3-types';
import { SolverFlowService } from '../../../core/services/apis/solver-flow.service';

interface WeakSignalDialogData {
    data: SolutionForm3WeakSignal;
    type: WeakSignalType;
    solutionId: number;
}

@Component({
    selector: 'app-weak-signal-dialog',
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatChipsModule,
        MatInputModule,
        MatIconModule,
        NgSelectModule,
        InputErrorMessageComponent,
        InputSolutionReferencesComponent,
        NgxSliderModule,
    ],
    templateUrl: './weak-signal-dialog.component.html',
    styleUrl: './weak-signal-dialog.component.scss',
})
export class WeakSignalDialogComponent implements AfterViewInit {
    public formGroup!: FormGroup;
    options: Options = {
        floor: 1,
        ceil: 10,
        step: 1,
        showTicks: true,
        showTicksValues: true,
        ticksArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    };
    public domainList = [
        { id: '1', title: '1' },
        { id: '2', title: '2' },
        { id: '3', title: '3' },
    ];
    readonly tagList = signal<string[]>([]);
    public weakSignalAssessmentTimeEnum = WeakSignalAssessmentTimeEnum;
    public weakSignalAssessmentTimeTitle = WeakSignalAssessmentTimeTitle;
    public solutionReferences: SolutionReferences = {} as SolutionReferences;
    public weakSignalType = WeakSignalType;

    constructor(
        private readonly fb: FormBuilder,
        private dialogRef: MatDialogRef<WeakSignalDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: WeakSignalDialogData,
        private readonly solverFlowService: SolverFlowService
    ) {
        console.log(this.dialogData);
        const data = this.dialogData.data;
        this.formGroup = this.fb.group({
            domain: [data?.domain || '', Validators.required],
            description: [data?.description || '', Validators.required],
            title: [data?.title || '', Validators.required],
            weakSignalDate: [data?.weakSignalDate || '', Validators.required],
            evidenceOrEarlySignal: [
                data?.evidenceOrEarlySignal || '',
                Validators.required,
            ],
            implications: [data?.implications || '', Validators.required],
            impact: [data?.impact || 1, Validators.required],
            uncertainty: [data?.uncertainty || 1, Validators.required],
            probability: [data?.probability || 1, Validators.required],
            timeRange: [
                data?.timeRange ||
                    this.weakSignalAssessmentTimeEnum.lessThan2Year,
                Validators.required,
            ],
            additionalNotes: [data?.additionalNotes || ''],
            tags: [
                (data?.tags || []).map((item) => item.title) || [],
                Validators.required,
            ],
            relatedWeakSignals: [data?.relatedWeakSignals || ''],
            citations: [data?.citations || '', Validators.required],
        });
        if (data?.tags) {
            this.tagList.set(data?.tags.map((item) => item.title) || []);
        }
        this.solutionReferences = {
            referenceDocuments: data?.metaData?.fileNames || [],
            referenceUrls: data?.metaData?.url || [],
        };
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            CommonUtilities.windowResize();
        }, 1000);
    }

    removeTagList(keyword: string) {
        this.tagList.update((keywords) => {
            const index = keywords.indexOf(keyword);
            if (index < 0) {
                return keywords;
            }

            keywords.splice(index, 1);
            return [...keywords];
        });
    }

    addTagList(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            this.tagList.update((keywords) => [...keywords, value]);
        }
        event.chipInput!.clear();
    }

    onSubmitClick(): void {
        this.formGroup.markAllAsTouched();
        if (this.formGroup.valid) {
            let weakSignal = {
                ...this.formGroup.value,
                tags: this.tagList(),
                fileNames: this.formGroup.get(
                    'solutionReferences.referenceDocuments'
                )?.value,
                url: this.formGroup.get('solutionReferences.referenceUrls')
                    ?.value,
                type: this.dialogData.type,
            };
            delete weakSignal?.['solutionReferences'];
            this.solverFlowService
                .addWeakSignal(
                    this.dialogData.solutionId,
                    weakSignal,
                    this.dialogData.data?.id
                )
                .subscribe({
                    next: (response) => this.dialogRef.close(response),
                    error: (err) => {
                        console.error('error', err);
                    },
                });
        }
    }
}
