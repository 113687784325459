<div class="container">
    <div
        class="row justify-content-center align-items-center"
        *ngIf="
            !(solverFormStoreService.selectForm2$ | async)?.bestPractices
                ?.length
        "
    >
        <img src="/images/common/no-records.svg" width="236px" height="166px" />
        <div
            class="solver-add-btn-wrapper justify-content-center align-items-center"
        >
            <button
                type="button"
                class="add-new-btn"
                data-toggle="modal"
                data-target="#exampleModal"
                (click)="openDialog()"
            >
                + Add New Best Practice
            </button>
        </div>
    </div>
    <div
        class="row"
        *ngIf="
            (solverFormStoreService.selectForm2$ | async)?.bestPractices?.length
        "
    >
        <div
            class="d-flex justify-content-between align-items-center"
            style="padding: 0; margin-bottom: 20px"
        >
            <h3 class="heading-mon-22">BEST PRACTICES YOU HAVE ADDED</h3>
            <button
                type="button"
                class="add-new-btn"
                style="margin-top: 0; margin-bottom: 0"
                (click)="openDialog()"
            >
                + Add New
            </button>
        </div>

        @for (item of (solverFormStoreService.selectForm2$ |
        async)?.bestPractices; track $index;let index=$index) {
        <div class="best-practice-list-wrapper">
            <div
                class="best-practice-list"
                (click)="openDialog(item)"
            >
                <span class="org-name">{{ item.organizationName }}</span>
                <span class="divider">|</span>
                <span class="best-practice-text">{{ item.title }}</span>
            </div>
            <div class="cancel" (click)="deleteBestPractice(item.id)">
                <span><img src="/images/common/cancel.svg" /></span>
            </div>
        </div>
        }

        <div class="solver-document-url-ai-wrapper">
            <div class="row">
                <!-- Reference Handler for Document Uploads -->
                <app-input-solution-references
                    [formGroup]="formGroup"
                    [solutionReferences]="solutionReferences"
                ></app-input-solution-references>
            </div>
        </div>
    </div>
</div>
