<div [formGroup]="solutionReferencesFormGroup">
    <app-reference-handler
        #referenceUrlsControlRef
        [controlName]="'referenceUrls'"
        [formGroup]="solutionReferencesFormGroup"
        [maxUrls]="maxReferenceUrls"
    >
    </app-reference-handler>
</div>
<div class="row">
    <app-reference-handler-uploads
        #referenceDocumentsControlRef
        [controlName]="'referenceDocuments'"
        [formGroup]="solutionReferencesFormGroup"
        [maxFiles]="maxReferenceDocuments"
        [maxFileSize]="maxFileSize"
        [allowedFileTypes]="allowedFileTypes"
    >
    </app-reference-handler-uploads>
    <app-input-error-message
        [formData]="solutionReferencesFormGroup"
        [controlName]="'referenceDocuments'"
    >
    </app-input-error-message>
    @if (showPromptBox) {
        <div class="ai-model-wrapper">
            <h3 class="heading-mon-22">
                PLEASE SHARE THE GEN AI MODEL(S) AND THE PROMPTS THAT YOU HAVE USED,
                IF ANY
            </h3>
            <app-quill-editor
                controlName="prompts"
                [parentForm]="solutionReferencesFormGroup"
            >
            </app-quill-editor>
        </div>
    }
</div>
